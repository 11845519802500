import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { loginByPhone } from '../../../redux/actions/login';
import notificationList from '../../../utils/notifications';
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-back.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import AuthCode from 'react-auth-code-input';
import LoginSocialMedia from '../../LoginSocialMedia';
import styles from '../style.module.scss';

const LoginPhoneForm = ({ actionLogin, loginShowPhoneCodeVerify, setLoginShowPhoneCodeVerify, fetchingUser }) => {
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => () => resetForm(), []);

  const resetForm = () => {
    form.resetFields();
    setLoginShowPhoneCodeVerify(false);
  };

  const getBack = () => {
    setLoginShowPhoneCodeVerify(false);
    form.resetFields(['verifyCode']);
  }

  const onFinish = async values => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    try {
      await actionLogin(
        `+${values.phone.trim()}`,
        values.password.trim(),
        values.verifyCode ? values.verifyCode.trim() : undefined,
        recaptcha
      );
    } catch (err) {
      notificationList.failed(err?.data?.message);
    }

    recaptchaRef.current.reset();
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ phone: '', password: '', verifyCode: '' }}
        onFinish={onFinish}
        className={styles['login-form']}
      >
        {!loginShowPhoneCodeVerify && <span className={styles['login-form__label']}>{t('phone')}</span>}
        <Form.Item
          name="phone"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={loginShowPhoneCodeVerify}
        >
          <PhoneInput containerClass="login-form__input-phone" inputClass="login-form__input" country={'us'} />
        </Form.Item>

        {!loginShowPhoneCodeVerify && (
          <div className={styles['login-form__label']}>
            <span>{t('password')}</span>
            <Link to="/password-send" className="login-form__link">
              {t('auth.forgotPassword')}
            </Link>
          </div>
        )}
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={loginShowPhoneCodeVerify}
        >
          <Input.Password className={styles['login-form__input']} placeholder={t('password')} />
        </Form.Item>

        {loginShowPhoneCodeVerify && (
          <div className={styles["back__button"]} onClick={getBack}>
            <ArrowBackIcon /> {t('swap.backButton')}
          </div>
        )}

        {loginShowPhoneCodeVerify && <span className={styles['login-form__label']}>{t('auth.verifyCode')}</span>}
        <Form.Item
          name="verifyCode"
          rules={[{ required: loginShowPhoneCodeVerify, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={!loginShowPhoneCodeVerify}
        >
          <AuthCode containerClassName={styles['login-form__2fa']} inputClassName={styles['login-form__2fa-input']} />
        </Form.Item>

        <Button className={styles['login-form__button']} type="submit" disabled={fetchingUser} loading={fetchingUser}>
          {t('submit')}
        </Button>

        <span className={styles['login-form__divider']}>Or</span>

        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
      </Form>
      <LoginSocialMedia userType="corporate" />
    </>
  );
};

const mapStateToProps = state => ({
  fetchingUser: state.login.fetching,
  loginShowPhoneCodeVerify: state.login.loginShowPhoneCodeVerify,
});

const mapDispatchToProps = dispatch => ({
  actionLogin: loginByPhone(dispatch),
  setLoginShowPhoneCodeVerify: isShowCode =>
    dispatch({
      type: 'LOGIN_SHOW_PHONE_CODE_VERIFY',
      payload: isShowCode,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPhoneForm);
