import React from 'react'
import Button from '../Button'
import { useTranslation } from 'react-i18next';
import yomiCard from '../../img/debitCard/yomiCard.png'

const StartStep = ({currentStep, nextStep}) => {
  const { t } = useTranslation();

  const buttonName = currentStep === 0 ? t('orderCard.stepZero.button') : t('orderCard.stepThree.button')

  return (
    <div className='main-step'>
      <div className='main-step__image' >
       <img src={yomiCard} alt='Yomi Card' />
      </div>
      {currentStep !== 0 ? <h4>{t('orderCard.stepThree.description')}</h4> : null}
      {currentStep === 0 ?
         <p>{t('orderCard.stepZero.textInfo')}</p> :
         <p>{t('orderCard.stepThree.textInfo')}</p>
      }
      <Button onClick={() => nextStep()}>{buttonName}</Button>
    </div>
  )
}

export default StartStep