export default (endpoint, { body, isFormData, ...customConfig } = {}) => {
  const token = localStorage.getItem('authToken');
  const headers = {};
  let data;

  if (!isFormData) {
    headers['Content-Type'] = !endpoint.includes('marketing') ? 'application/json' : 'blob';
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    responseType: endpoint.includes('marketing') ? 'blob' : undefined,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = isFormData ? body : JSON.stringify(body);
  }

  const response = fetch(`${process.env.REACT_APP_API_URL}/api/${endpoint}`, config).then(async response => {
    if (response.status === 401 && endpoint !== 'user/login' && endpoint !== 'user/login-by-phone') {
      localStorage.removeItem('authToken');
      localStorage.removeItem('merchantId');
      localStorage.removeItem('cpayNetworkFilter');
      window.location.replace('/login');
    }

    if (!endpoint.includes('user/export')) {
      data = endpoint.includes('marketing') ? response : { ...(await response.json()), code: response.status };
    } else {
      data = await response.text();
    }

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });

  return response;
};
