import jwt from 'jwt-decode';
import { ERROR_EVENT } from 'web3modal';
import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';
import { showWelcomeModal } from './user';

// CONSTANTS
export const LOGIN = 'LOGIN';
export const LOGIN_SIGN_IN = 'LOGIN_SET_TOKEN';
export const LOGIN_SOFT_SIGN_IN = 'LOGIN_SOFT_SIGN_IN';
export const LOGIN_SIGN_OUT = 'LOGIN_SIGN_OUT';
export const LOGIN_SET_APP_TWO_FA = 'LOGIN_SET_APP_TWO_FA';
export const LOGIN_SET_EMAIL_TWO_FA = 'LOGIN_SET_EMAIL_TWO_FA';
export const LOGIN_SHOW_PHONE_CODE_VERIFY = 'LOGIN_SHOW_PHONE_CODE_VERIFY';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const LOGIN_METAMASK = 'LOGIN_METAMASK';

// ACTIONS GENERATORS
export const loginSignIn = () => ({
  type: LOGIN,
});

export const loginSignOut = () => ({
  type: LOGIN_SIGN_OUT,
});

export const loginSetToken = data => ({
  type: LOGIN_SIGN_IN,
  payload: data,
});

export const loginSetAppTwoFa = data => ({
  type: LOGIN_SET_APP_TWO_FA,
  payload: data,
});

export const loginSetEmailTwoFa = data => ({
  type: LOGIN_SET_EMAIL_TWO_FA,
  payload: data,
});

export const loginShowPhoneCodeVerify = loginShowPhoneCodeVerify => ({
  type: LOGIN_SHOW_PHONE_CODE_VERIFY,
  payload: loginShowPhoneCodeVerify,
});

export const loginError = err => ({
  type: LOGIN_ERROR,
  payload: err,
});

export const softLogin = jwtToken => ({
  type: LOGIN_SOFT_SIGN_IN,
  payload: { ...jwt(jwtToken), jwt: jwtToken },
  fetching: 'done',
});

export const googleLogin = () => ({
  type: LOGIN_GOOGLE,
});

export const metamaskLogin = () => ({
  type: LOGIN_METAMASK,
});

// ASYNC ACTIONS
export const logout = dispatch => () => {
  if (localStorage.getItem('authToken')) {
    cryptoApi.logout();
    localStorage.removeItem('authToken');
  }
  localStorage.removeItem('cpayNetworkFilter');
  dispatch(loginSignOut());
};

export const login = dispatch => async (login, password, appTwoFaKey, emailTwoFaKey, recaptcha) => {
  dispatch(loginSignIn());

  try {
    const response = await cryptoApi.login(login, password, appTwoFaKey, emailTwoFaKey, recaptcha);

    if (response.token) {
      // const res = jwt(response.token);
      localStorage.setItem('authToken', response.token);
      // dispatch(loginSetToken({ ...res, jwt: response.token }));
      window.location.href = '/welcome-user';
      return;
    }

    if (response.data.emailSent && response.data.twoFactorToken) {
      dispatch(loginSetEmailTwoFa(true));
      dispatch(loginSetAppTwoFa(true));
      return;
    }

    if (response.data.emailSent && !response.data.twoFactorToken) {
      dispatch(loginSetEmailTwoFa(true));
      dispatch(loginSetAppTwoFa(false));
      return;
    }

    if (!response.data.emailSent && response.data.twoFactorToken) {
      dispatch(loginSetEmailTwoFa(false));
      dispatch(loginSetAppTwoFa(true));
      return;
    }
  } catch (err) {
    localStorage.removeItem('authToken');
    dispatch(loginError(err));
    throw err;
  }

  dispatch(loginError());
};

export const loginByPhone = dispatch => async (phone, password, verifyCode, recaptcha) => {
  dispatch(loginSignIn());

  try {
    const response = await cryptoApi.loginByPhone(phone, password, verifyCode, recaptcha);

    if (response.token) {
      // const res = jwt(response.token);
      localStorage.setItem('authToken', response.token);
      // dispatch(loginSetToken({ ...res, jwt: response.token }));
      window.location.href = '/welcome-user';
      return;
    }

    if (response.data.codeSent) {
      dispatch(loginShowPhoneCodeVerify(true));
      return;
    }
  } catch (err) {
    localStorage.removeItem('authToken');
    dispatch(loginError(err));
    throw err;
  }

  dispatch(loginError());
  return false;
};

const standardLogIn = (response, dispatch) => {
  if (response.token && window.location.href.includes('login')) {
    localStorage.setItem('authToken', response.token);
    window.location.href = '/welcome-user';
    return true;
  }

  if (response.token && window.location.href.includes('sign-up')) {
    localStorage.setItem('authToken', response.token);
    dispatch(showWelcomeModal(true));
    window.location.href = '/registration-completed';
    return true;
  }
};

export const loginWithSocialMedia = dispatch => async (accessToken, network, role) => {
  dispatch(googleLogin());

  try {
    const response = await cryptoApi.loginWithSocials(accessToken, network, role);
    standardLogIn(response, dispatch);
  } catch (err) {
    dispatch(loginError(err.data));
    dispatch(notificationsErrorShow(err.data));
    localStorage.removeItem('authToken');
  }
};

export const loginWithMetamask = dispatch => async data => {
  const { address, signature, roles } = data;
  dispatch(metamaskLogin());
  let result;

  try {
    const response = await cryptoApi.loginWithMetamask(address, signature, roles);
    standardLogIn(response, dispatch);
    result = true;
  } catch (err) {
    dispatch(loginError(err.data));
    dispatch(notificationsErrorShow(err.data));
    localStorage.removeItem('authToken');
    result = false;
  }

  return result;
};
