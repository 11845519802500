import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import Button from '../../Button';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../../redux/actions/user';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import PasswordStrength from '../../PasswordStrength';
import emailIcon from '../../../img/email.svg';
import styles from '../style.module.scss';

const ResetPasswordForm = ({ resetPassword, notificationsErrorShow }) => {
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const defaultPasteSplit = data => {
    return data.split(' ').map(d => d.trim());
  };

  const handleSubmit = values => {
    if (values.newPassword === values.confirmPassword) {
      resetPassword({
        email: values.email.trim(),
        newPassword: values.newPassword.trim(),
        words: values.words,
      });
    } else {
      notificationsErrorShow({ message: t('validation.required') });
    }
  };

  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['login-wrapper__title']}>{t('auth.resetPassword')}</div>

      <Form
        initialValues={{ email: '', newPassword: '', confirmPassword: '', words: [] }}
        onFinish={handleSubmit}
        className={styles['login-form']}
      >
        <span className={styles['login-form__label']}>{t('email')}</span>
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input
            className={styles['login-form__input']}
            placeholder={t('email')}
            suffix={<img src={emailIcon} alt="email icon" />}
          />
        </Form.Item>

        <span className={styles['login-form__label']}>{t('newPassword')}</span>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input.Password
            className={styles['login-form__input']}
            placeholder={t('newPassword')}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>

        <span className={styles['login-form__label']}>{t('confirmPassword')}</span>
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input.Password className={styles['login-form__input']} placeholder={t('confirmPassword')} />
        </Form.Item>

        <PasswordStrength password={password} />

        <span className={styles['login-form__label']}>{t('auth.mnemonicPhrase')}</span>
        <span className="login-form__tip">{t('auth.mnemonicTip')}</span>
        <Form.Item
          name="words"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <TagsInput
            className={styles['login-form__phrase']}
            addKeys={[9, 13, 32]}
            maxTags="12"
            addOnPaste={true}
            pasteSplit={defaultPasteSplit}
            inputProps={{
              placeholder: t('auth.addTag'),
            }}
          />
        </Form.Item>

        <Button className={styles['login-form__button']} type="submit">
          {t('auth.resetPassword')}
        </Button>
      </Form>

      <div className={styles['login-form__auth-links']}>
        <Link to="/login">{t('logIn')}</Link>
        <Link to="/sign-up">{t('signUp')}</Link>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  resetPassword: resetPassword(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
