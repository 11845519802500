import {
  MULTISEND_ESTIMATE_RESET,
  MULTISEND_ESTIMATE_SYSTEM_FEE_GET,
  MULTISEND_ESTIMATE_SYSTEM_FEE_SET,
  MULTISEND_ESTIMATE_SYSTEM_FEE_ERROR,
  MULTISEND_APPROVE_GET,
  MULTISEND_APPROVE_SET,
  MULTISEND_APPROVE_ERROR,
  MULTISEND_ESTIMATE_MINER_FEE_GET,
  MULTISEND_ESTIMATE_MINER_FEE_SET,
  MULTISEND_ESTIMATE_MINER_FEE_ERROR,
  MULTISEND_WITHDRAW_GET,
  MULTISEND_WITHDRAW_SET,
  MULTISEND_WITHDRAW_ERROR,
} from '../actions/multisend';

export default function reducer(
  state = {
    estimate: {
      data: null,
      fetching: false,
    },
    withdraw: {
      data: null,
      fetching: false,
    },
  },
  action
) {
  switch (action.type) {
    case MULTISEND_ESTIMATE_RESET:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          data: null,
          fetching: false,
        },
      };
    case MULTISEND_ESTIMATE_SYSTEM_FEE_GET:
    case MULTISEND_APPROVE_GET:
    case MULTISEND_ESTIMATE_MINER_FEE_GET:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          fetching: true,
        },
      };
    // case MULTISEND_ESTIMATE_SYSTEM_FEE_SET:
    // case MULTISEND_APPROVE_SET:
    case MULTISEND_ESTIMATE_MINER_FEE_SET:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          data: action.payload,
          fetching: false,
        },
      };
    case MULTISEND_ESTIMATE_SYSTEM_FEE_ERROR:
    case MULTISEND_APPROVE_ERROR:
    case MULTISEND_ESTIMATE_MINER_FEE_ERROR:
      return {
        ...state,
        estimate: {
          ...state.estimate,
          fetching: false,
        },
      };
    case MULTISEND_WITHDRAW_GET:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          fetching: true,
        },
      };
    case MULTISEND_WITHDRAW_SET:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          data: action.payload,
          fetching: false,
        },
      };
    case MULTISEND_WITHDRAW_ERROR:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
