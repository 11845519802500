import React, { useState, useEffect } from 'react';
import styles from '../AuthForms/style.module.scss';
import { Col, Form, Row } from 'antd';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { deleteUser } from '../../redux/actions/user';
import { connect } from 'react-redux';

const DeleteAccountStepTwo = ({ deleteUser, isDeleteEmailTwoFa, isDeleteTwoFactorToken }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isRequired, setIsRequired] = useState(true);
  const [timer, setTimer] = useState(0);
  const [resendText, setResendText] = useState(t('resendEmail'));

  const resendEmail = () => {
    setIsRequired(false);
    setTimer(120);
    deleteUser();
  };

  const getMinutes = seconds => {
    let date = new Date(1970, 0, 0, 0, 0, +seconds || 0);
    let time = date.toLocaleTimeString('ru');

    return time.toString().slice(-5);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
      const minutes = getMinutes(timer);
      setResendText(minutes);
    } else {
      setResendText(t('resendEmail'));
    }
  }, [timer]);

  const handleSubmit = async values => {
    setIsRequired(true);
    try {
      await deleteUser(
        values.emailTwoFaKey ? values.emailTwoFaKey.trim() : undefined,
        values.twoFactorToken ? values.twoFactorToken.trim() : undefined
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <>
          <Form.Item
            label={t('auth.keyFromEmail')}
            name="emailTwoFaKey"
            rules={[{ required: isDeleteEmailTwoFa && isRequired, message: t('validation.required') }]}
            className={styles['login-form__item']}
          >
            <AuthCode
              autoFocus={true}
              containerClassName={styles['login-form__2fa']}
              inputClassName={styles['login-form__2fa-input']}
            />
          </Form.Item>

          <Form.Item
            label={t('auth.keyFromGA')}
            name="twoFactorToken"
            rules={[{ required: isDeleteTwoFactorToken, message: t('validation.required') }]}
            className={styles['login-form__item']}
          >
            <AuthCode
              autoFocus={false}
              containerClassName={styles['login-form__2fa']}
              inputClassName={styles['login-form__2fa-input']}
            />
          </Form.Item>
        </>

        <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
          {isDeleteEmailTwoFa && !isDeleteTwoFactorToken && (
            <Col span="12">
              <Button
                disabled={timer > 0}
                type="primary"
                className="modal__button modal__button-create"
                onClick={resendEmail}
              >
                {resendText}
              </Button>
            </Col>
          )}
          <Col span={isDeleteEmailTwoFa && isDeleteTwoFactorToken ? '24' : '12'}>
            <Button
              className={`modal__button modal__button-create ${
                isDeleteEmailTwoFa && isDeleteTwoFactorToken && 'modal__button-center'
              }`}
              type="submit"
            >
              {t('confirm')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  isDeleteTwoFactorToken: state.user.isDeleteTwoFactorToken,
  isDeleteEmailTwoFa: state.user.isDeleteEmailTwoFa,
});

const mapDispatchToProps = dispatch => ({
  deleteUser: deleteUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountStepTwo);
