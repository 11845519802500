import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input, InputNumber, Radio, Select } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { createMerchant } from '../../redux/actions/merchants';
import CurrencyDropdown from '../CurrencyDropdown';
import './MerchantCreateModal.scss';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const MerchantCreateModal = ({
  isFetching,
  createMerchant,
  isCreateMerchantModalVisible,
  setIsCreateMerchantModalVisible,
  currencies,
  merchants
}) => {
  const currencyIds = currencies.map(c => c._id)

  const [merchantName, setMerchantName] = useState('');
  const [merchantCurrencies, setMerchantCurrencies] = useState(currencyIds);
  const [merchantComissionPays, setMerchantComissionPays] = useState('merchant');
  const [merchantComissionPaysReplenish, setMerchantComissionPaysReplenish] = useState('merchant');
  const [merchantFee, setMerchantFee] = useState('');
  const [merchantFeeReplenish, setMerchantFeeReplenish] = useState('');
  const [merchantNetwork, setMerchantNetwork] = useState('mainnet');
  const { t } = useTranslation();

  useEffect(() => {
    const walletNumber = merchants?.length + 1

    setMerchantName(`Account ${walletNumber}`)
  }, [merchants])

  const handleOk = e => {
    createMerchant({
      name: merchantName.length ? merchantName : 'Multiwallet',
      currencies: currencyIds, //merchantCurrencies,
      fee: 0,
      feeReplenish: 0,
      typeNetwork: 'mainnet',
      middleWallet: false,
      payerCommission: 'merchant',  // merchantComissionPays ?? 'merchant',
      payerCommissionReplenish: 'merchant' // merchantComissionPaysReplenish ?? 'merchant'
    }).then(() => {
      handleCancel();
    }).catch(err => alert(err))
  };

  const handleCancel = e => {
    const walletNumber = merchants?.length + 1

    setMerchantName(`Account ${walletNumber}`);
    setMerchantCurrencies([]);
    setMerchantComissionPays('');
    setMerchantComissionPaysReplenish('');
    setMerchantFee('');
    setMerchantFeeReplenish('');
    setMerchantNetwork('testnet');
    setIsCreateMerchantModalVisible(false);
  };

  return (
    <Modal
      title={t('merchants.createMerchant')}
      visible={isCreateMerchantModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      className="modal"
      destroyOnClose={true}
    >
      <Form
        {...layout}
        name="basic"
        initialValues={{ merchantNetwork: 'mainnet' }}
        className="modal-form form-create-merchant"
      >
        <Form.Item label={t('merchants.merchantName')} name="merchantName" className="modal-form__label">
          <Input
            placeholder={merchantName.trim() !== '' ? merchantName : t('merchants.merchantName')}
            className="modal-form__input"
            maxLength={30}
            value={merchantName}
            onChange={e => {
              if (e.target.value.length > 30) {
                return false;
              } else {
                setMerchantName(e.target.value);
              }
            }}
          />
          <div className="modal-form__chars-limit">{merchantName.length}/30</div>
        </Form.Item>

        {/* <Form.Item
          label={t('merchants.chooseMerchantCurrency')}
          name="merchantCurrencies"
          className="modal-form__label"
        >
          <CurrencyDropdown setFunc={setMerchantCurrencies} multiple={true} />
        </Form.Item> */}

        {/* withdrawal */}
        {/* <Form.Item
          label={t('merchants.whoPaysComission')}
          name="merchantComission"
          className="modal-form__label"
        // rules={[{ required: true, message: 'Please input merchant name!' }]}
        >
          <Radio.Group className="modal-form__radio" onChange={e => setMerchantComissionPays(e.target.value)}>
            <Radio value="buyer">{t('merchants.customer')}</Radio>
            <Radio value="merchant">{t('merchants.me')}</Radio>
          </Radio.Group>
        </Form.Item> */}

        {/* replenish */}
        {/* <Form.Item
          label={t('merchants.whoPaysComissionReplenish')}
          name="merchantComissionReplenish"
          className="modal-form__label"
        // rules={[{ required: true, message: 'Please input merchant name!' }]}
        >
          <Radio.Group className="modal-form__radio" onChange={e => setMerchantComissionPaysReplenish(e.target.value)}>
            <Radio value="buyer">{t('merchants.customer')}</Radio>
            <Radio value="merchant">{t('merchants.me')}</Radio>
          </Radio.Group>
        </Form.Item> */}

               {/* withdrawal */}
               {/* <Form.Item
          label={t('merchants.merchantFee')}
          name="merchantFee"
          className="modal-form__label"
        >
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFee(value > 100 ? 100 : value)}
            className="modal-form__input"
          />
        </Form.Item> */}

        {/* replenishment */}
        {/* <Form.Item
          label={t('merchants.merchantFeeReplenish')}
          name="merchantFeeReplenish"
          className="modal-form__label"
        >
          <InputNumber
            placeholder="0"
            min={0}
            max={100}
            onChange={value => setMerchantFeeReplenish(value > 100 ? 100 : value)}
            className="modal-form__input"
          />
        </Form.Item> */}

        {/* <p className="modal-form__description">{t('merchants.commissionDescription')}</p>

        <div className="form-create-merchant__comission-info">{t('merchants.systemFee')}</div>

        <a
          href="https://docs.cpay.world/quickstart/general-concepts-about-cpay"
          target="_blank"
          rel="noopener noreferrer"
          className="form-create-merchant__fee-docs"
        >
          {t('merchants.feeDocs')}
        </a> */}
        {/* <Form.Item
          name="merchantNetwork"
          className="modal-form__label"
        // rules={[{ required: true, message: 'Please input merchant name!' }]}
        >
          <Select onChange={value => setMerchantNetwork(value)}>
            <Select.Option value="testnet">{t('testnet')}</Select.Option>
            <Select.Option value="mainnet">{t('mainnet')}</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
      <Row className="modal__buttons-wrapper">
        <Col span="12">
          <Button
            type="primary"
            className="modal__button modal__button-create"
            loading={isFetching}
            onClick={!isFetching && handleOk}
          >
            {t('create')}
          </Button>
        </Col>
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchants.fetching,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  createMerchant: createMerchant(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantCreateModal);
