import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input, Spin, Tooltip } from 'antd';
import AuthCode from 'react-auth-code-input';
import Button from '../Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { notificationsErrorShow } from '../../redux/actions/notifications';

import '../../layout/Modal.scss';
import './PrivateKeyModal.scss';
import styles from '../AuthForms/style.module.scss';

const PrivateKeyModal = ({
  isAppTwoFa,
  isEmailTwoFa,
  merchantId,
  walletId,
  setWalletId,
  isFetching,
  isKeyModalOpen,
  closeModal,
  openNotification,
  getWalletPrivateKey,
  notificationsErrorShow,
}) => {
  const [walletKey, setWalletKey] = useState('');
  // const [appTwoFa, setAppTwoFa] = useState('');
  // const [emailTwoFa, setEmailTwoFa] = useState('');
  const { t } = useTranslation();

  console.log(isAppTwoFa, isEmailTwoFa);

  const [twoFaForm] = Form.useForm();

  useEffect(() => {
    if (!isKeyModalOpen && !isAppTwoFa && !isEmailTwoFa) {
      twoFaForm.resetFields();
      setWalletKey('');
      setWalletId('');
    }
  }, [isKeyModalOpen, isAppTwoFa, isEmailTwoFa]);

  const handleSubmitTwoFa = values => {
    const { appTwoFa, emailTwoFa } = values;
    getWalletPrivateKey(merchantId, walletId, appTwoFa, emailTwoFa).then(response => {
      if (response && response.status === 'success') {
        setWalletKey(response.data);
        twoFaForm.resetFields(['emailTwoFaCode', 'appTwoFaCode']);
      }

      if (response && response.status === 'fail') {
        if (isAppTwoFa && !appTwoFa) {
          notificationsErrorShow({ message: t('validation.twoFaRequired') });
          return;
        }

        if (isEmailTwoFa && !emailTwoFa) {
          notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
          return;
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Modal
        title={t('merchants.yourPrivateKey')}
        visible={isKeyModalOpen && (isAppTwoFa || isEmailTwoFa)}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        className="modal modal__private-key"
      >
        {isFetching && !isEmailTwoFa && (
          <div className="modal__spinner">
            <Spin />
          </div>
        )}
        <Form
          form={twoFaForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmitTwoFa}
          // className="modal-form"
        >
          {!walletKey && (
            <>
              <Form.Item
                label={t('auth.keyFromEmail')}
                rules={[{ required: true, message: t('validation.required') }]}
                name="emailTwoFa"
                className={styles['login-form__item']}
              >
                <AuthCode
                  autoFocus={true}
                  containerClassName={styles['login-form__2fa']}
                  inputClassName={styles['login-form__2fa-input']}
                  // onChange={setEmailTwoFa}
                />
              </Form.Item>

              <Form.Item
                label={t('auth.keyFromGA')}
                rules={[{ required: true, message: t('validation.required') }]}
                name="appTwoFa"
                className={styles['login-form__item']}
              >
                <AuthCode
                  autoFocus={false}
                  containerClassName={styles['login-form__2fa']}
                  inputClassName={styles['login-form__2fa-input']}
                  // onChange={setAppTwoFa}
                />
              </Form.Item>
            </>
          )}
        </Form>

        {walletKey && (
          <React.Fragment>
            <div className="modal__text" onClick={openNotification}>
              <Tooltip title={t('clickToCopy')} placement="top">
                <CopyToClipboard text={walletKey}>
                  <span>{walletKey}</span>
                </CopyToClipboard>
              </Tooltip>
            </div>

            <div className="modal__warning">
              <span className="modal__warning_red">{t('important')}</span>
              {t('merchants.clickToCopyPrivateKey')}
            </div>
          </React.Fragment>
        )}

        <Row className="modal__buttons-wrapper">
          <Col span="24">
            <Button
              // type="primary"
              className={`modal__button modal__button-create modal__button-center`}
              loading={isFetching && isEmailTwoFa}
              onClick={walletKey ? closeModal : () => twoFaForm.submit()}
            >
              {walletKey ? t('done') : t('confirm')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isFetching: state.merchantWallets.modalFetching,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateKeyModal);
