import React from 'react';
import { Link } from 'react-router-dom';
import ReleaseNotes from '../components/ReleaseNotes';
import logo from '../img/logo.svg';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import MerchantSelector from '../components/MerchantSelector/MerchantSelector';
import useWindowSize from '../utils/resizeHook';
import Hamburger from '../components/Hamburger';
import './Header.scss';

function Header({ toggleCollapseSider, siderState }) {
  const { currentWidth, isLg } = useWindowSize();

  return (
    <div className="header">
      <div className="header__left">
        <Hamburger handler={toggleCollapseSider} opened={siderState} />
        {isLg && (
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        )}
      </div>
      <div className="header__right">
        <div className="header__controls-wrapper header__merchant-selector-wrapper">
          <MerchantSelector />
        </div>
         <div className="header__controls-wrapper header__release-notes">
          <ReleaseNotes />
        </div> 
        <div className="header__controls-wrapper">
          <HeaderUser />
        </div>
        {currentWidth < 768 && (
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
