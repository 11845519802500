import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CurrencyItem from './CurrencyItem';
import emptyImage from '../../../img/empty.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TotalBalance from '../../HomeCurrency/TotalBalance/index';
import './style.scss';

const CurrencySelector = ({
  merchantBalances,
  balancesFetching,
  currency,
  setCurrency,
  balancesTotal,
  getMerchantBalances,
  merchantId,
  networkFilter,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <TotalBalance
          merchantBalances={merchantBalances}
          balancesFetching={balancesFetching}
          balancesTotal={balancesTotal}
          getMerchantBalances={getMerchantBalances}
          merchantId={merchantId}
          networkFilter={networkFilter}
        />
      </Col>
      <Col span={24}>
        <div className="withdraw-currencies">
          <div className="withdraw-currencies__title">
            {t('coin')}
            {currency && (
              <div className="withdraw-currencies__select-all" onClick={() => setCurrency('')}>
                {t('showAllCurrency')}
              </div>
            )}
          </div>
          <div className="withdraw-currencies__items-wrapper">
            {!balancesFetching ? (
              merchantBalances.length ? (
                merchantBalances.map(currencyBalance => (
                  <CurrencyItem
                    currency={currency}
                    setCurrency={setCurrency}
                    key={currencyBalance.currency.id}
                    currencyBalance={currencyBalance}
                    title={currencyBalance.currency.title}
                  />
                ))
              ) : (
                <div className="withdraw-wallets__empty">
                  <span className="transactions-list__empty-title">{t('wallets.emptyList')}</span>
                  <img src={emptyImage} className="transactions-list__empty-image" alt="empty" />
                </div>
              )
            ) : (
              <div className="withdraw-currencies__loader">
                <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CurrencySelector;
