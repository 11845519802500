import React, {useEffect} from 'react'
import { useHistory } from "react-router-dom";

const WelcomeUser = () => {
    const history = useHistory();

    useEffect(() => {
        // history.replace replaces current URL with new URL
        history.replace('/');
        // history.push adds a new URL to the browser's history
        history.push('/');
     },[history])


  return (
    <></>
  )
}

export default WelcomeUser