import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const WithdrawModeToggler = ({ multisendMode, setMultisendMode }) => {
  const { t } = useTranslation();

  return (
    <div className="withdraw-mode-toggler-wrapper">
      <div className="withdraw-mode-toggler">
        <span
          className={`withdraw-mode-toggler__item ${!multisendMode && 'active'}`}
          onClick={() => setMultisendMode(false)}
        >
          {t('wallets.myWallets')}
        </span>
        <span
          className={`withdraw-mode-toggler__item ${multisendMode && 'active'}`}
          onClick={() => setMultisendMode(true)}
        >
          {t('multisend')}
        </span>
      </div>
    </div>
  );
};

export default WithdrawModeToggler;
