import { notification } from 'antd';
const noteConfig = {
  placement: 'topRight',
  duration: 2,
};

const notificationList = {
  failed: message => {
    notification['error']({
      message,
      ...noteConfig,
    });
  },
  loginSuccess: () => {
    notification['success']({
      message: 'Logged in',
      description: 'You are successfuly logged in',
      ...noteConfig,
    });
  },
  loginFailed: () => {
    notification['error']({
      message: 'Login failed',
      description: 'Login or password are incorrect',
      ...noteConfig,
    });
  },
  logout: () => {
    notification['info']({
      message: 'You are now logged out',
      ...noteConfig,
    });
  },
};

export default notificationList;
