import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Button';
import { softLogin } from '../../../redux/actions/login';
import JwtDecode from 'jwt-decode';
import styles from '../style.module.scss';

const PasswordChanged = ({ responseToken, softLogin }) => {
  const logIn = () => {
    localStorage.setItem('authToken', responseToken);
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decodedToken = JwtDecode(authToken);
      if (Date.now() < decodedToken.exp * 1000) softLogin(authToken);
    }
  };

  return (
    <div className={styles['password-changed']}>
      <div className={styles['password-changed__title']}>Password Changed</div>

      <div className={styles['password-changed__subtitle']}>Your profile is activate you can start working</div>

      <Button type="primary" className={styles['login-form__button']} onClick={logIn}>
        Log In
      </Button>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  softLogin: token => dispatch(softLogin(token)),
});

export default connect(null, mapDispatchToProps)(PasswordChanged);
