import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SwapExchange from '../SwapExchange';
import { getSwapExchangeWallets, getSwapExchangeWallet } from '../../../redux/actions/swap';
import './style.scss';

const SwapForm = ({
  merchantId,
  networkFilter,
  walletFromId,
  exchangeWalletsFrom,
  currencies,
  exchangeFields,
  setExchangeFields,
  getSwapExchangeWallets,
  swapOffers,
  swapOffersFetching,
  swapOffersReset,
  swapOffersUpdating,
  polkaDotLimitWarning,
  setPolkaDotLimitWarning,
  dataIsFrozen,
  setDataIsFrozen,
  setCurrencyFieldIsEdited,
  currencyFieldIsEdited,
  getSwapExchangeWallet
}) => {
  useEffect(() => {
    if (currencies.length && !exchangeFields[0].selectedCurrency && !exchangeFields[1].selectedCurrency) {
      setExchangeFields([
        {
          ...exchangeFields[0],
          selectedCurrency: currencies[0]._id,
        },
        {
          ...exchangeFields[1],
          selectedCurrency: currencies[1]._id,
        },
      ]);
    }
  }, [currencies]);

  useEffect(() => {

    if (merchantId && networkFilter && exchangeFields[0].selectedCurrency && exchangeFields[1].selectedCurrency) {
      const tokenFromMainCurrency = currencies.find(
        currency => currency._id === exchangeFields[0].selectedCurrency
      ).mainCurrency;

      const tokenToMainCurrency = currencies.find(
        currency => currency._id === exchangeFields[1].selectedCurrency
      ).mainCurrency;


      if (!dataIsFrozen) {

        if (currencyFieldIsEdited === 'none' || currencyFieldIsEdited === 'both') {
          getSwapExchangeWallets(merchantId, {
            limit: -1,
            typeWallet: 'merchant',
            typeNetwork: networkFilter,
            currencyIds: [
              `${exchangeFields[0].selectedCurrency}${tokenFromMainCurrency ? `,${tokenFromMainCurrency}` : ''}`,
              `${exchangeFields[1].selectedCurrency}${tokenToMainCurrency ? `,${tokenToMainCurrency}` : ''}`,
            ]
          });
          return;
        }

        const token = currencies.find(
          currency => {
            return currency._id === exchangeFields[currencyFieldIsEdited].selectedCurrency
          }
        ).mainCurrency;

        getSwapExchangeWallet(merchantId, {
          limit: -1,
          typeWallet: 'merchant',
          typeNetwork: networkFilter,
          currencyIds:
            `${exchangeFields[currencyFieldIsEdited].selectedCurrency}${token ? `,${token}` : ''}`
        }, currencyFieldIsEdited);
      }
  }
  }, [merchantId, networkFilter, exchangeFields[0].selectedCurrency, exchangeFields[1].selectedCurrency, currencyFieldIsEdited]);

return (
  <SwapExchange
    currencies={currencies}
    exchangeFields={exchangeFields}
    setExchangeFields={setExchangeFields}
    merchantId={merchantId}
    networkFilter={networkFilter}
    walletFromId={walletFromId}
    exchangeWalletsFrom={exchangeWalletsFrom}
    swapOffers={swapOffers}
    swapOffersFetching={swapOffersFetching}
    swapOffersReset={swapOffersReset}
    swapOffersUpdating={swapOffersUpdating}
    polkaDotLimitWarning={polkaDotLimitWarning}
    setPolkaDotLimitWarning={setPolkaDotLimitWarning}
    setDataIsFrozen={setDataIsFrozen}
    setCurrencyFieldIsEdited={setCurrencyFieldIsEdited}
    currencyFieldIsEdited={currencyFieldIsEdited}
  />
);
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  getSwapExchangeWallets: dispatch(getSwapExchangeWallets),
  getSwapExchangeWallet: dispatch(getSwapExchangeWallet)

});

export default connect(mapStateToProps, mapDispatchToProps)(SwapForm);
