export const tokenColors = {
  BTC: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  ETH: {
    color: '#2988EF',
    bgColor: '#EBF6FF',
  },
  USDT: {
    color: '#1BA27A',
    bgColor: 'rgba(113, 251, 135, 0.22)',
  },
  BNB: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  BUSD: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  TRX: {
    color: '#FC0D24',
    bgColor: 'rgba(252, 13, 36, 0.07)',
  },
  LOTT: {
    color: '#390C7F',
    bgColor: 'rgba(57, 12, 127, 0.1)',
  },
  DOT: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  OP: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  ARB: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  KSM: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  USDC: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  ADA: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  DOGE: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  SHIB: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  EOS: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  MATIC: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  MANAV: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
  default: {
    color: '#4D7F70',
    bgColor: '#F5F8F7',
  },
};
