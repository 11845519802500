import React, {useState} from 'react';
import { Row, Col, Menu, Tooltip, Dropdown } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import WalletDeleteModal from './WalletDeleteModal';
import moment from 'moment';
import TokenIcon from '../TokenIcon';
import formatCurrency from '../../utils/currencyFormatter';
import useWindowSize from '../../utils/resizeHook';
import {tokenNodeTypeObj} from '../../utils/tokenNodeTypes';
import cn from 'classnames';
import { ReactComponent as ArrowDownIcon } from '../../img/swap-select-arrow.svg';
import moreIcon from '../../img/more.svg';

import './Wallet.scss';

const Wallet = ({
  wallet,
  typeWallet,
  page,
  pages,
  countItem,
  walletsFilters,
  openModal,
  openNotification,
  deleteMerchantWallet,
  merchantId,
  handleReplenishButton,
  setWalletAsMain,
  currencies,
  networkFilter,
  getWalletPrivateKey,
}) => {
  const [isWalletOpened, setIsWalletOpened] = useState(false);

  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  const getMinReplenishValue = () => {
    const walletCurrency = currencies.find(currency => currency._id === wallet.currencyId);

    if (wallet.typeWallet === 'merchant') {
      return `0.${new Array(walletCurrency.decimals).join(0)}1`;
    } else {
      return walletCurrency.replenishMinimumValue;
    }
  };

  return (
    <div className={`wallet ${wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__secondary' : ''}`}>
      <Row align="middle">
      <Col span={currentWidth >= 1024 ? 1 : 2}>
          {wallet.tokens && wallet.tokens.length ? (
            <div
              className={cn('wallet__expand', {
                wallet__expand_opened: isWalletOpened,
              })}
            >
              <ArrowDownIcon onClick={() => setIsWalletOpened(prevValue => !prevValue)} />
            </div>
          ) : null}
        </Col>
        <Col span={currentWidth >= 1024 ? 1 : 3}>
          <div className="wallet__icon">
            <TokenIcon tokenName={wallet.currency} />
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 6 : 19}>
          <div className="wallet__address" onClick={openNotification}>
            <Tooltip title={t('clickToCopy')} placement="top">
              <CopyToClipboard text={wallet.address}>
                <span>{wallet.address}</span>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 4 : 13} offset={currentWidth >= 1024 ? 2 : 3}>
          <div className="wallet__currency-amount">
            <span>
              {wallet.balance.value} {wallet.currency} {' '} {wallet.currencyType === 'token' ? tokenNodeTypeObj[wallet.nodeType] : null}

              {wallet.currencyType === 'token' && wallet.nodeType === 'optimism' ?
              wallet.currency === 'OP' ? null : 'OP' : null }
              {wallet.currency === 'ETH' && wallet.currencyType === 'currency' && wallet.nodeType === 'optimism' ? 'OP' : null}

              {wallet.currencyType === 'currency' && wallet.nodeType === 'arbitrum' ? wallet.currency === 'arbitrum' ? null : 'ARB' : null}

              {(wallet.currency === 'USDT' || wallet.currency === 'USDC' || wallet.currency === 'ETH') && wallet.currencyType === 'token' && wallet.nodeType === 'arbitrum' ? 'ARB' : null}

              {(wallet.currency === 'USDT' || wallet.currency === 'USDC') && wallet.currencyType === 'token' && wallet.nodeType === 'matic' ? 'MATIC' : null}

            </span>
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 3 : 8}>
          <div className="wallet__usd-amount">
            <span>{formatCurrency(wallet.balance.usd || 0.00)}</span>
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 3 : 13} offset={currentWidth >= 1024 ? 0 : 3}>
          {t(moment(wallet.createdAt).format('MMM').toLowerCase())}{' '}
          {moment(wallet.createdAt).format('D, YYYY, HH:mm')}
        </Col>
        <Col span={currentWidth >= 1024 ? 2 : 7} offset={currentWidth >= 1024 ? 1 : 0}>
          <div
            className={`wallet__status ${
              wallet.typeWallet === 'merchant' && !wallet.main ? 'wallet__status_secondary' : 'wallet__status_default'
            }`}
          >
            {wallet.typeWallet === 'merchant' && !wallet.main ? (
              <span>{t('secondary')}</span>
            ) : (
              <span>{t('default')}</span>
            )}
          </div>
        </Col>
        <Col span={1}>
          <Dropdown
            overlay={
              <Menu>
                {wallet.typeWallet === 'merchant' && !wallet.main && (
                  <Menu.Item
                    key="0"
                    onClick={() =>
                      setWalletAsMain(
                        merchantId,
                        wallet._id,
                        currencies.find(currency => currency.name === wallet.currency && currency.nodeType === wallet.nodeType)._id,
                        typeWallet,
                        page,
                        walletsFilters,
                        networkFilter
                      )
                    }
                  >
                    <a>{t('wallets.setAsDefault')}</a>
                  </Menu.Item>
                )}
                <Menu.Item key="1" onClick={() => handleReplenishButton(wallet.address, wallet.currency, true)}>
                  <a>{t('merchants.replenish')}</a>
                </Menu.Item>
                <Menu.Item key="2">
                  <a
                    onClick={() => {
                      getWalletPrivateKey(merchantId, wallet._id);
                      openModal(wallet._id);
                    }}
                  >
                    {t('showPrivateKey')}
                  </a>
                </Menu.Item>
                <Menu.Item key="3">
                  <WalletDeleteModal
                    merchantId={merchantId}
                    walletId={wallet._id}
                    walletAddress={wallet.address}
                    deleteMerchantWallet={deleteMerchantWallet}
                    typeWallet={typeWallet}
                    page={page}
                    pages={pages}
                    countItem={countItem}
                    walletsFilters={walletsFilters}
                  />
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div className="wallet__options">
              <img src={moreIcon} alt="options" />
            </div>
          </Dropdown>
        </Col>
      </Row>

      <div
        className={cn('wallet-tokens', {
          'wallet-tokens_opened': isWalletOpened,
        })}
      >
        {wallet.tokens && wallet.tokens.length && wallet.typeWallet !== 'user' ? (
          wallet.tokens.map(token => (
            <Row align="middle" className="wallet-tokens__item" key={crypto.randomUUID()}>
              <Col span={currentWidth >= 1024 ? 1 : 3} offset={currentWidth >= 1024 ? 1 : 2}>
                <TokenIcon className="wallet-tokens__icon" tokenName={token.currency} />
              </Col>
              <Col span={currentWidth >= 1024 ? 6 : 19}>
                <div className="wallet-tokens__address">{wallet.address}</div>
              </Col>
              <Col span={currentWidth >= 1024 ? 4 : 13} offset={2}>
                <div>
                  {token.balance.value} {token.currency}
                </div>
              </Col>
              <Col span={currentWidth >= 1024 ? 3 : 8}>
                <div>{formatCurrency(token.balance.usd || 0)}</div>
              </Col>
            </Row>
          ))
        ) : (
          <></>
        )}
      </div>
      {/*<div className="wallet__min-replenish">{t('wallets.minimumDeposit')}  {getMinReplenishValue()}</div>*/}
    </div>
  );
};

export default Wallet;
