import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { Redirect, useParams } from 'react-router-dom';

import { verifyEmail, changeTwoFactorStatus } from '../../redux/actions/user';
import { softLogin } from '../../redux/actions/login';
import EmailVerified from '../AuthForms/EmailVerified';


const EmailVerification = ({ verifyEmail, changeTwoFactorStatus, softLogin }) => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { key } = useParams();

  useEffect(() => {
    verifyEmail(key).then(response => {
      if (response?.token) {
        localStorage.setItem('authToken', response?.token);
        changeTwoFactorStatus(true);
        setEmailVerified(true);
      } else {
        setIsRedirect(true);
      }
    })
      .catch(error => {
        setIsRedirect(true);
      });
  }, [key]);

  const handleClick = () => {
    if (emailVerified) {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const decodedToken = JwtDecode(authToken);
        if (Date.now() < decodedToken.exp * 1000) softLogin(authToken);
      }
    }

    setIsRedirect(true);
  }

  return (
    <>
      {isRedirect && <Redirect to="/" />}
      {emailVerified && <EmailVerified handleClick={handleClick} />}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  verifyEmail: verifyEmail(dispatch),
  changeTwoFactorStatus: changeTwoFactorStatus(dispatch),
  softLogin: (token)=>dispatch(softLogin(token))
});


export default connect(null, mapDispatchToProps)(EmailVerification);
