import {
  GET_ADMIN_USERS_REQUEST,
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USER_BY_ID_REQUEST,
  GET_ADMIN_USER_BY_ID_SUCCESS,
  UPDATE_ADMIN_USER_REQUEST,
  UPDATE_ADMIN_USER_SUCCESS,
  RESET_ADMIN_USER_STATE,
  GET_ADMIN_USER_MERCHANTS_REQUEST,
  GET_ADMIN_USER_MERCHANTS_SUCCESS,
  UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REQUEST,
  UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_SUCCESS,
  UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_REQUEST,
  UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_SUCCESS
} from '../../actions/adminPanel/adminUsers';

export default function reducer(
  state = {
    entities: [],
    page: 0,
    pages: 0,
    countItems: 0,
    fetching: false,
    editableUser: {
      data: {},
      fetching: false,
    },
    userMerchants: {
      data: [],
      fetching: false,
      systemFeeFetching: false,
      systemFeeReplenishFetching:false
    },
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_USERS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    case GET_ADMIN_USER_BY_ID_REQUEST:
    case UPDATE_ADMIN_USER_REQUEST:
      return {
        ...state,
        editableUser: {
          ...state.editableUser,
          fetching: true,
        },
      };
    case GET_ADMIN_USER_BY_ID_SUCCESS:
    case UPDATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        editableUser: {
          ...state.editableUser,
          data: {
            ...state.editableUser.data,
            ...action.payload,
          },
          fetching: false,
        },
      };
    case RESET_ADMIN_USER_STATE:
      return {
        ...state,
        editableUser: {
          ...state.editableUser,
          data: {},
          fetching: false,
        },
      };
    case GET_ADMIN_USER_MERCHANTS_REQUEST:
      return {
        ...state,
        userMerchants: {
          ...state.userMerchants,
          fetching: true,
        },
      };
    case GET_ADMIN_USER_MERCHANTS_SUCCESS:
      return {
        ...state,
        userMerchants: {
          ...state.userMerchants,
          data: action.payload,
          fetching: false,
        },
      };
    case UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REQUEST:
      return {
        ...state,
        userMerchants: {
          ...state.userMerchants,
          systemFeeFetching: true,
        },
      };
      case UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_REQUEST:
        return {
          ...state,
          userMerchants: {
            ...state.userMerchants,
            systemFeeReplenishFetching: true,
          },
        };
    case UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_SUCCESS:
      return {
        ...state,
        userMerchants: {
          ...state.userMerchants,
          systemFeeFetching: false,
        },
      };
      case UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_SUCCESS:
        return {
          ...state,
          userMerchants: {
            ...state.userMerchants,
            systemFeeReplenishFetching: false,
          },
        };
    default:
      return state;
  }
}
