import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import SignUpEmail from './SignUpEmail';
import SignUpPhone from './SignUpPhone';
import styles from '../style.module.scss';

const SignUpOptions = () => {
  const [signUpType, setSignUpType] = useState(0);
  const { t } = useTranslation();

  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['login-wrapper__title']}>{t('signUp')}</div>
      <div className={styles['login-wrapper__type-selector']}>
        <div
          className={cn(styles['login-wrapper__type-item'], {
            [styles['active']]: !signUpType,
          })}
          onClick={() => setSignUpType(0)}
        >
          {t('email')}
        </div>
        <div
          className={cn(styles['login-wrapper__type-item'], {
            [styles['active']]: signUpType,
          })}
          onClick={() => setSignUpType(1)}
        >
          {t('phone')}
        </div>
      </div>

      {signUpType ? <SignUpPhone /> : <SignUpEmail />}

      <div className={styles['login-form__text']}>
        {t('auth.alreadyHaveAccount')} <Link to="/login">{t('logIn')}!</Link>
      </div>
    </div>
  );
};

export default SignUpOptions;
