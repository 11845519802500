import React, { useEffect, useState } from "react";
import { Form, Spin } from 'antd';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { ethers } from "ethers";
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { loginWithMetamask } from "../../../redux/actions/login";
import { getUser, installMetamask, removeMetamask } from "../../../redux/actions/user";
import MetamaskSignInForm from "./MetamaskSignInForm";
import metamaskFunctions from './helpers';
import metamaskIcon from '../../../img/metamask.svg';
import './styles.scss';

const { detectProvider, connectToMetamask, sendMetamaskRequest } = metamaskFunctions;

const LoginMetamask = ({ notificationsErrorShow, loginWithMetamask, userType, installMetamask, metamaskConnected, getUser, removeMetamask, metamaskRequestError, metamaskRequestStatus, metamaskRequestFetching }) => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const provider = window?.ethereum;
    const { language } = i18n;

    const [currentAccount, setCurrentAccount] = useState(metamaskConnected ? metamaskConnected : null);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [signature, setSignature] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState(!metamaskConnected ? "I am signing my address: " : "Disconnecting my address: ");
    const [messageTranslated, setMessageTranslated] = useState(!metamaskConnected ? t('metamaskAuth.messageConnectMetamask') : t('metamaskAuth.messageDisconnectMetamask'));
    const [authOperation, setAuthOperation] = useState(null);
    const [isSigned, setIsSigned] = useState(false);
    const [form] = Form.useForm();

    const [btnClicked, setBtnClicked] = useState(false);

    //set initial text for textarea for be request in en
    const setMessageForTextField = () => {
        !metamaskConnected
            ? setMessage("I am signing my address: ")
            : setMessage("Disconnecting my address: ");

        setTranslatedMessage();
    };

    //reset transl message fn
    const setTranslatedMessage = () => {
        !metamaskConnected
            ? setMessageTranslated(t('metamaskAuth.messageConnectMetamask'))
            : setMessageTranslated(t('metamaskAuth.messageDisconnectMetamask'));
    };

    //reset textarea input value to ""
    const resetMessageInput = () => form.setFieldsValue({
        message: ""
    });

    //reset translated message according to the lang change
    useEffect(() => {
        setTranslatedMessage();
    }, [language]);

    //set type of operation according to the location
    useEffect(() => {
        setAuthOperation(pathname.substring(1))
    }, [pathname]);

    //change the text for text area
    useEffect(() => {
        setMessageForTextField();
    }, [metamaskConnected]);

    //send request to BE according to the pathname: authorization/installing/remove
    useEffect(() => {
        isSigned && sendMetamaskRequest(signature,
            currentAccount,
            verifiedAddress,
            message,
            ethers,
            notificationsErrorShow,
            authOperation,
            metamaskAuth,
            setIsSigned,
            metamaskConnected,
            installMetamask,
            removeMetamask);
    }, [isSigned]);

    //if the request to BE was successfully fullfilled
    useEffect(() => {
        if (!metamaskRequestError && metamaskRequestStatus) {
            getUser();
            closeModal();
        }

    }, [metamaskRequestError, metamaskRequestStatus]);

    //change metamask wallet account according to the changes in extension
    useEffect(() => {
        if (window?.ethereum) {
            const handleAccountsChanged = accounts => {
                setCurrentAccount(
                    accounts[0]
                );
                resetMessageInput();
            };

            provider.on('accountsChanged', handleAccountsChanged);


            return () => {
                if (provider.removeListener) {
                    provider.removeListener('accountsChanged', handleAccountsChanged);
                }
            };
        }
    }, [provider]);


    //close modal + reset all creds + reset text for text area
    const closeModal = () => {
        setShowModal(false)
        resetMetamaskCreds();
        setMessageForTextField();
    }

    const signMessage = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signature = !metamaskConnected ? await signer.signMessage(message + currentAccount?.toLowerCase()) : await signer.signMessage(message + metamaskConnected?.toLowerCase());
            const address = await signer.getAddress();
            setSignature(signature);
            setVerifiedAddress(address);
            setIsSigned(true);
            setBtnClicked(false);
        } catch (err) {
            console.log(err);
            resetMetamaskCreds();
        }
    };

    //sign the form with the message for getting a signature & verifying the address
    const handleButtonClick = () => {
        setBtnClicked(true);
        if (detectProvider()) {
            connectToMetamask(provider, setCurrentAccount,notificationsErrorShow, t('metamaskAuth.connectionMessage'),setBtnClicked);
            if (metamaskConnected) signMessage();
            if (!metamaskConnected && currentAccount) signMessage();
            // setShowModal(true);
            return;
        }

        notificationsErrorShow({ message: t('metamaskAuth.installWarning')});
    };

    useEffect(() => {
        if (currentAccount && !metamaskConnected && btnClicked) signMessage();
        if (metamaskConnected && currentAccount !== null && metamaskConnected !== currentAccount) {
            notificationsErrorShow({ message: t('metamaskAuth.walletsSwitching')});
        }
    }, [currentAccount, metamaskConnected])

    //reset the state with the user's metamask creds
    const resetMetamaskCreds = () => {
        setCurrentAccount(null);
        setVerifiedAddress(null);
        setSignature(null);
        resetMessageInput();
        setBtnClicked(false);
    };

    //auth operation for metamask
    const metamaskAuth = () => {
        if (loginWithMetamask(
            {
                address: verifiedAddress.toLowerCase(),
                signature,
                roles: [userType]
            }
        ) === true) {
            resetMetamaskCreds();
        }
    };

    return (
        <>
            <button className={`login-options__google-button metamask ${pathname === '/profile' ? "profile" : ""}
            ${(!metamaskConnected && pathname === '/profile') ? "connected" : "disconnected"}`
            }
             id={pathname === '/login' ? 'login_metamask' : pathname === '/sign-up' ? 'signup_metamask' : 'profile' }
                onClick={handleButtonClick}
            >
                <img src={metamaskIcon} alt="Metamask logo" />
                {pathname === '/login' && t('metamaskAuth.login')}
                {pathname === '/sign-up' && t('metamaskAuth.signUp')}
                {(pathname === '/profile' && !metamaskConnected) && t('metamaskAuth.connect')}
                {(pathname === '/profile' && metamaskConnected) && t('metamaskAuth.remove')}
            </button>
        </>
    );
};

const mapStateToProps = state => ({
    metamaskConnected: state.user?.data?.metamask?.address,
    metamaskRequestError: state.user?.metamaskStatus?.error,
    metamaskRequestStatus: state.user?.metamaskStatus?.status,
    metamaskRequestFetching: state.user?.metamaskStatus?.fetching
});


const mapDispatchToProps = dispatch => ({
    notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
    loginWithMetamask: loginWithMetamask(dispatch),
    installMetamask: installMetamask(dispatch),
    removeMetamask: removeMetamask(dispatch),
    getUser: getUser(dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(LoginMetamask);
