import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { ButtonSecondary } from '../../components/Button';
import { PercentInput } from '../../components/FormFields';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import {
  getUserMerchants,
  updateMerchantsSystemFee,
  updateMerchantsSystemFeeReplenish,
} from '../../../redux/actions/adminPanel/adminUsers';
import { getAdminSettings } from '../../../redux/actions/adminPanel/adminSettings';
import { ReactComponent as SaveSVG } from '../../../img/adminPanel/save.svg';
import moment from 'moment';
import './style.scss';

const UserMerchants = ({
  userMerchants,
  userMerchantsFetching,
  adminSettingsData,
  systemFeeFetching,
  getUserMerchants,
  updateMerchantsSystemFee,
  getAdminSettings,
  updateMerchantsSystemFeeReplenish,
// systemFeeReplenishFetching,
}) => {
  const [fetchingId, setFetchingId] = useState({});
  const { userId } = useParams();

  useEffect(() => {
    getAdminSettings();
  }, []);

  useEffect(() => {
    setFetchingId(
      userMerchants.reduce((resultObj, merchant) => {
        resultObj[merchant._id] = false;
        return resultObj;
      }, {})
    );
  }, [userMerchants]);

  useEffect(() => {
    if (userId) {
      getUserMerchants(userId);
    }
  }, [userId]);

  const updateMerchantFetchingStatus = (merchantId, isFetching) => {
    setFetchingId({ ...fetchingId, [merchantId]: isFetching });
  };

  const updateMerchant = async (merchantId, systemFeePercent, type) => {
    updateMerchantFetchingStatus(merchantId, true);
    type === 'withdraw'
      ? await updateMerchantsSystemFee(merchantId, systemFeePercent)
      : await updateMerchantsSystemFeeReplenish(merchantId, systemFeePercent);
    updateMerchantFetchingStatus(merchantId, false);
  };

  return (
    <div className="user-merchants">
      <Card>
        <TEXT.pageTitle>Merchants</TEXT.pageTitle>
        <div className="user-merchants__list">
          {!userMerchantsFetching ? (
            userMerchants.map(merchant => (
              <div className="user-merchants__item" key={merchant._id}>
                <div>
                  <div className="user-merchants__name">
                    <TEXT.darkBold>{merchant.name}</TEXT.darkBold>
                  </div>
                  <div className="user-merchants__created-date">
                    <TEXT.sectionDescription>
                      {moment(merchant.createdAt).format('MMMM D, YYYY, HH:mm;')}
                    </TEXT.sectionDescription>
                  </div>
                </div>
                <div className="user-merchants__right-col">
                  <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>% System fee for withdrawal</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        systemFeePercent:
                          merchant.systemFee >= 0 ? merchant.systemFee : adminSettingsData.systemFeePercent,
                      }}
                      onFinish={({ systemFeePercent }) => updateMerchant(merchant._id, systemFeePercent, 'withdraw')}
                    >
                      <div className="user-merchants__input-wrap">
                        <PercentInput name="systemFeePercent" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemFeeFetching && fetchingId[merchant._id]}
                        >
                          {systemFeeFetching && fetchingId[merchant._id] ? (
                            <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 16 }} spin />} />
                          ) : (
                            <SaveSVG />
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>

                  {/* <div className="user-merchants__right-col_wrapper">
                    <TEXT.sectionDescription>% System fee for replenishment</TEXT.sectionDescription>
                    <Form
                      name="user-merchant-form"
                      initialValues={{
                        systemFeeReplenish:
                          merchant.systemFeeReplenish >= 0
                            ? merchant.systemFeeReplenish
                            : adminSettingsData.systemFeePercentReplenish,
                      }}
                      onFinish={({ systemFeeReplenish }) =>
                        updateMerchant(merchant._id, systemFeeReplenish, 'replenish')
                      }
                    >
                      <div className="user-merchants__input-wrap">
                        <PercentInput name="systemFeeReplenish" className="user-merchants__input" />
                        <button
                          type="submit"
                          className="user-merchants__save"
                          disabled={systemFeeFetching && fetchingId[merchant._id]}
                        >
                          {systemFeeReplenishFetching && fetchingId[merchant._id] ? (
                            <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 16 }} spin />} />
                          ) : (
                            <SaveSVG />
                          )}
                        </button>
                      </div>
                    </Form>
                  </div> */}
                </div>
              </div>
            ))
          ) : (
            <Spin
              className="user-merchants__loader"
              indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />}
            />
          )}
        </div>
      </Card>
      <div className="user-merchants-buttons">
        <Link to="/admin/users">
          <ButtonSecondary>Back</ButtonSecondary>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userMerchants: state.adminUsers.userMerchants.data,
  userMerchantsFetching: state.adminUsers.userMerchants.fetching,
  adminSettingsData: state.adminSettings.data,
  systemFeeFetching: state.adminUsers.userMerchants.systemFeeFetching,
  systemFeeReplenishFetching: state.adminUsers.userMerchants.systemFeeReplenishFetching,
});

const mapDispatchToProps = {
  getUserMerchants,
  updateMerchantsSystemFee,
  getAdminSettings,
  updateMerchantsSystemFeeReplenish,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMerchants);
