import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LoginEmail from './LoginEmail';
import LoginPhone from './LoginPhone';
import styles from '../style.module.scss';

const LoginOptions = () => {
  const [loginType, setLoginType] = useState(0);
  const { t } = useTranslation();

  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['login-wrapper__title']}>{t('logIn')}</div>
      <div className={styles['login-wrapper__type-selector']}>
        <div
          className={cn(styles['login-wrapper__type-item'], {
            [styles['active']]: !loginType,
          })}
          onClick={() => setLoginType(0)}
        >
          {t('email')}
        </div>
        <div
          className={cn(styles['login-wrapper__type-item'], {
            [styles['active']]: loginType,
          })}
          onClick={() => setLoginType(1)}
        >
          {t('phone')}
        </div>
      </div>

      {loginType ? <LoginPhone /> : <LoginEmail />}

      <div className={styles['login-form__text']}>
        {t('auth.dontHaveAccount')} <Link to="/sign-up">{t('signUp')}!</Link>
      </div>
    </div>
  );
};

export default LoginOptions;
