import React from 'react';
import formatCurrency from '../../../../utils/currencyFormatter';
import TokenIcon from '../../../TokenIcon';
import './style.scss';

const CurrencyItem = ({ currency, setCurrency, currencyBalance, title }) => {
  return (
    <div
      className={`withdraw-currency-item ${
        currency === currencyBalance.currency.id && 'withdraw-currency-item_active'
      }`}
      onClick={() => setCurrency(currencyBalance.currency.id)}
    >
      <div className="withdraw-currency-item_left">
        <div className="currency-item__name-wrap">
          <TokenIcon className="withdraw-currency-item__logo" tokenName={currencyBalance.currency.name} />
          <div className="withdraw-currency-item__title">{title}</div>
        </div>
      </div>
      <div className="withdraw-currency-item_right">
        <div className="withdraw-currency-item__amount">
          {currencyBalance.balance.value} {currencyBalance.currency.name}
        </div>
        <div className="withdraw-currency-item__usd">{formatCurrency(currencyBalance.balance.usd)}</div>
      </div>
    </div>
  );
};

export default CurrencyItem;
