import {
  MERCHANT_WALLETS_RESET_PAGE,
  MERCHANT_WALLETS_GET,
  MERCHANT_WALLETS_GET_MORE,
  MERCHANT_WALLETS_SET,
  MERCHANT_WALLETS_RESET,
  MERCHANT_WALLETS_CREATE,
  MERCHANT_WALLETS_DELETE,
  MERCHANT_WALLETS_GET_PRIVATE_KEY,
  MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS,
  MERCHANT_WALLETS_SET_APP_TWO_FA,
  MERCHANT_WALLETS_SET_EMAIL_TWO_FA,
  MERCHANT_WALLETS_RESET_TWO_FA,
  MERCHANT_WALLETS_ERROR,
} from '../actions/merchantWallets';

export default function reducer(
  state = {
    data: [],
    page: 1,
    pages: null,
    fetching: false,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    modalFetching: false,
  },
  action
) {
  switch (action.type) {
    case MERCHANT_WALLETS_GET:
    case MERCHANT_WALLETS_GET_MORE:
      return {
        ...state,
        fetching: true,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_CREATE:
    case MERCHANT_WALLETS_DELETE:
    case MERCHANT_WALLETS_GET_PRIVATE_KEY:
      return {
        ...state,
        modalFetching: true,
      };
    case MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS:
      return {
        ...state,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET:
      return {
        ...state,
        data: action.payload.entities,
        page: action.payload.page,
        pages: action.payload.pages,
        countItem: action.payload.countItem,
        fetching: false,
      };
    case MERCHANT_WALLETS_RESET:
      return {
        data: [],
        page: 1,
        pages: null,
        fetching: false,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET_APP_TWO_FA:
      return {
        ...state,
        isAppTwoFa: action.isAppTwoFa,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.isEmailTwoFa,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_RESET_TWO_FA:
      return {
        ...state,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_RESET_PAGE:
      return {
        ...state,
        page: 1,
        pages: null,
      };
    case MERCHANT_WALLETS_ERROR:
      return {
        ...state,
        fetching: false,
        modalFetching: false,
      };
    default:
      return state;
  }
}
