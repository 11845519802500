import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MerchantDeleteButton from '../Merchant/MerchantDeleteModal';
import { useTranslation } from 'react-i18next';
import { Row, Col, Dropdown, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './Merchant.scss';
import editIcon from '../../img/edit.svg';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import useWindowSize from '../../utils/resizeHook';
import { ReactComponent as MoreSVG } from '../../img/more.svg';
import { setActiveMerchant } from '../../redux/actions/transactions';
import { connect } from 'react-redux';
import { deleteMerchant } from '../../redux/actions/merchants';

const Merchant = ({
  merchant,
  merchantId,
  isLastMerchant,
  userRole,
  quickNetworkSwitcherState,
  changeMerchantNetwork,
  setActiveMerchant,
  deleteMerchant,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { isSm } = useWindowSize();
  const { t } = useTranslation();

  const activeMerchantId = id => {
    localStorage.setItem('merchantId', id);
    setActiveMerchant(id);
  };

  return (
    <div
      className={cn('merchant-item', {
        ['mainnet']: merchant.typeNetwork.toLowerCase() === 'mainnet',
        ['testnet']: merchant.typeNetwork.toLowerCase() === 'testnet',
      })}
    >
      <div onClick={() => activeMerchantId(merchant._id)}>
        <div className="merchant-item__radio">
          {merchantId === merchant._id && <div className="merchant-item__radio-inner"></div>}
        </div>
      </div>
      <div className="merchant-item__wrapper">
        <div className="merchant-item__name">
          <h2>{merchant.name}</h2>
        </div>

        {/* FOR LOCAL TEST */}
        {/* <div className="merchant-item__network"> */}
        {/* <h2 style={{fontSize: '12px', color: '#4D7F70', fontWeight: 600, margin: '0px'}}>{t('mainnet')}</h2> */}
        {/* <Dropdown
            visible={isDropdownVisible}
            onVisibleChange={isVisible => setIsDropdownVisible(isVisible)}
            overlay={
              <div className="merchant-item__networks-dropdown">
                <Radio.Group
                  defaultValue={merchant.typeNetwork}
                  onChange={e => {
                    changeMerchantNetwork(merchant._id, { typeNetwork: e.target.value });
                  }}
                >
                  <Radio value="mainnet">{t('mainnet')}</Radio>
                  <Radio value="testnet">{t('testnet')}</Radio>
                </Radio.Group>
              </div>
            }
          >
            <div
              className={`merchant-item__networks-button ${
                isDropdownVisible ? 'merchant-item__networks-button_hover' : ''
              } merchant-item__networks-button_${merchant.typeNetwork.toLowerCase()}`}
            >
              {t(merchant.typeNetwork.toLowerCase())}
              {quickNetworkSwitcherState.fetching && quickNetworkSwitcherState.merchantId === merchant._id ? (
                <LoadingOutlined />
              ) : (
                <DownOutlined />
              )}
            </div>
          </Dropdown> */}
        {/* </div> */}
        <div className="merchant-item__wallets">
          <Link to={`/multiwallet/wallets/${merchant._id}`} className="merchant-item__wallets-button">
            {t('wallets.wallets')}
          </Link>
        </div>
      </div>
      <div className="merchant-item__more">
        <Dropdown
          trigger={['click']}
          destroyPopupOnHide={true}
          placement="bottomRight"
          overlay={
            <div className="merchant-item__more-dropdown">
              {/* <Link to={`/multiwallet/edit/${merchant._id}`} className="merchant-item__more-dropdown-edit">
                {t('edit')}
              </Link> */}
              <div
                className="merchant-item__more-dropdown-delete"
                onClick={() => {
                  setShowDelete(true);
                  deleteMerchant(merchantId);
                }}
              >
                {t('delete')}
              </div>
            </div>
          }
        >
          <div className="merchant-item__more-icon">
            <MoreSVG />
          </div>
        </Dropdown>
      </div>
      <MerchantDeleteButton
        merchantId={merchant._id}
        merchantName={merchant.name}
        isLastMerchant={isLastMerchant}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
});
const mapDispatchToProps = dispatch => ({
  setActiveMerchant: setActiveMerchant(dispatch),
  deleteMerchant: deleteMerchant(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
