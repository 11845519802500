import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input } from 'antd';
import cn from 'classnames';
import Button from '../../../../components/Button';
import {
  withdrawAdminSystemFee,
  withdrawAdminResetTwoFa,
  getWithdrawalAdminEstimateMax,
  withdrawalAdminEstimateMaxReset,
} from '../../../../redux/actions/adminPanel/adminFeeWithdraw';
import WithdrawTwoFaConfirm from './WithdrawTwoFaConfirm';
import { cryptoApi } from '../../../../service/cryptopay-api';
import repeatIcon from '../../../../img/repeat.svg';
import withdrawalIcon from '../../../../img/arrow-right.svg';
import TokenIcon from '../../../../components/TokenIcon';
import NumberFormat from 'react-number-format';
import './style.scss';

function WithdrawForm({
  currencies,
  setFrom,
  to,
  setTo,
  amount,
  setAmount,
  selectedWallet,
  setSelectedWallet,
  fetching,
  fetchingMaxAmount,
  withdrawAdminSystemFee,
  withdrawAdminResetTwoFa,
  getWithdrawalAdminEstimateMax,
  withdrawalAdminEstimateMaxReset,
}) {
  const [exchange, setExchange] = useState(0);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const [decimalsLimit, setDecimalsLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [form] = Form.useForm();
  const ratesFetchTimer = useRef();

  useEffect(() => () => ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current), []);

  useEffect(() => {
    if (to) setShowWarning(false);
  }, [to]);

  useEffect(() => {
    setDecimalsLimit(currencies.find(currency => selectedWallet.currencyId === currency._id).decimals);
    return () => withdrawalAdminEstimateMaxReset();
  }, []);

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };

  const getCurrenciesRate = async () => {
    const response = await cryptoApi.getCurrenciesRate();
    return response;
  };

  const clearWithdrawState = () => {
    form.setFieldsValue({
      walletTo: '',
      exchange: 0,
    });
    setFrom('');
    setTo('');
    setAmount('');
    setSelectedWallet(null);
    setExchange(0);
  };

  const handleSubmit = () => {
    withdrawAdminSystemFee(selectedWallet._id, {
      to,
      amount: `${amount}`,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
    }).then(response => {
      if (response && response.data) {
        if (response.data.twoFactorToken || response.data.emailSent) {
          setShowTwoFaModal(true);
        }
      }
    });
  };

  const convertTokensToUsd = async value => {
    const rates = await getCurrenciesRate();

    if (rates && rates.data) {
      setExchange(value * +rates.data[selectedWallet.currency] || 0);
      form.setFieldsValue({
        exchange: value * +rates.data[selectedWallet.currency] || 0,
      });
    }
  };

  const convertUsdToTokens = async value => {
    if (+value) {
      setExchange(value);
      const rates = await getCurrenciesRate();

      if (rates && rates.data) {
        setAmount((value / +rates.data[selectedWallet.currency]).toFixed(decimalsLimit) || 0);
        setShowDecimalsWarning(false);
      }
    } else {
      setAmount('');
      setExchange(0);
    }
  };

  const handleEstimateMax = e => {
    const { _id: walletId } = selectedWallet;
    const data = {
      to,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
    };

    if (!to) {
      setShowWarning(true);
    }

    if (selectedWallet && to) {
      getWithdrawalAdminEstimateMax(walletId, data);
    }
  };

  useEffect(() => {
    if (amount) {
      setShowDecimalsWarning(false);
      setFetchTimer(convertTokensToUsd, amount);
    }
  }, [amount]);

  return (
    <>
      <WithdrawTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={withdrawAdminSystemFee}
        fetching={fetching}
        clearWithdrawState={clearWithdrawState}
        walletId={selectedWallet._id}
        body={{
          to,
          amount: `${amount}`,
          currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
        }}
        withdrawResetTwoFa={withdrawAdminResetTwoFa}
      />
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          walletSelect: selectedWallet.address,
          exchange: exchange,
        }}
        className="form settings-form"
      >
        <Row>
          <Col span="24">
            <Row gutter={[20, 0]} align="bottom">
              <Col
                span="12"
                className={cn('admin-settings-form__exchange-wrapper', {
                  'admin-settings-form__exchange-wrapper_warning': showWarning,
                })}
              >
                <div className="admin-settings-form__selected-wallet-label">Currency:</div>
                <div className="admin-settings-form__selected-wallet">
                  <TokenIcon tokenName={selectedWallet.currency} />
                  <div className="admin-settings-form__selected-wallet-info">
                    {selectedWallet.currency} - {selectedWallet.balance.value}
                  </div>
                </div>
                <img src={withdrawalIcon} alt="exchange" className="admin-settings-form__exchange-wrapper-icon" />
              </Col>
              <Col span="12">
                <Form.Item
                  label='Send to:'
                  name="walletTo"
                  className="form__item"
                  onChange={e => setTo(e.target.value)}
                >
                  <Input className="form__input" placeholder="Wallet address" />
                  {showWarning ? <span className="max-warning">Fill in the recipient's address first</span> : ''}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 0]}>
              <Col span="12" className="admin-settings-form__exchange-wrapper">
                <label className="admin-withdraw-amount">
                  <div className="admin-settings-form__exchange-wrapper_label withdraw-amount__label">
                    Enter amount:{' '}
                    {/* <div className="admin-settings-form__exchange-wrapper_min-value">Minimum 50$</div> */}
                  </div>
                  <div className="admin-withdraw-amount__input-wrapper">
                    <input
                      type="number"
                      value={amount}
                      placeholder="Amount"
                      className="form__input settings-form__input-usd withdraw-amount__input"
                      onChange={e => {
                        if (e.target.value < 0) {
                          setAmount('');
                          return;
                        }

                        if (
                          !e.target.value.toString().split('.')[1] ||
                          e.target.value.toString().split('.')[1].length <= decimalsLimit
                        ) {
                          setAmount(e.target.value);
                          setFetchTimer(convertTokensToUsd, e.target.value);
                          setShowDecimalsWarning(false);
                        } else {
                          setShowDecimalsWarning(true);
                          setAmount(amount);
                          setFetchTimer(convertTokensToUsd, amount);
                        }
                      }}
                    />

                    <img
                      src={repeatIcon}
                      alt="exchange"
                      className={cn('admin-settings-form__exchange-wrapper-icon', {
                        'admin-settings-form__exchange-wrapper-icon-animated': fetchingMaxAmount,
                      })}
                    />
                    <div className="max-container" onClick={handleEstimateMax}>
                      <span className="max-text">max</span>
                    </div>
                  </div>

                  {showDecimalsWarning && (
                    <span className="admin-withdraw-amount__decimals-limit">
                      The maximum decimal value for {selectedWallet.currency} is {decimalsLimit}.
                    </span>
                  )}
                </label>
              </Col>
              <Col span="12">
                <Form.Item label="Exchange rate in USD:" name="exchange" className="form__item">
                  <NumberFormat
                    value={exchange}
                    className="form__input settings-form__input-usd"
                    thousandSeparator={true}
                    prefix={'$'}
                    onValueChange={(values, sourceInfo) => {
                      if (sourceInfo.event) {
                        setFetchTimer(convertUsdToTokens, values.value);
                      }
                    }}
                    onFocus={e => e.target.select()}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    allowNegative={false}
                    allowEmptyFormatting={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="withdraw__buttons">
              <Col span="8">
                <Button type="secondary" className="form__button" onClick={clearWithdrawState}>
                  Back
                </Button>
              </Col>
              <Col span="8" offset="8">
                <Button type="primary" className="form__button" onClick={handleSubmit} loading={fetching}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

const mapStateToProps = state => ({
  fetching: state.adminFeeWithdraw.fetching,
  fetchingMaxAmount: state.adminFeeWithdraw.fetchingMaxAmount,
});

const mapDispatchToProps = {
  withdrawAdminSystemFee,
  withdrawAdminResetTwoFa,
  getWithdrawalAdminEstimateMax,
  withdrawalAdminEstimateMaxReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm);
