import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { login } from '../../../redux/actions/login';
import notificationList from '../../../utils/notifications';
import emailIcon from '../../../img/email.svg';
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-back.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import AuthCode from 'react-auth-code-input';
import LoginSocialMedia from '../../LoginSocialMedia';
import styles from '../style.module.scss';

const LoginForm = ({ actionLogin, isAppTwoFa, setIsAppTwoFa, isEmailTwoFa, setIsEmailTwoFa, fetchingUser }) => {
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => () => resetForm(), []);

  const resetForm = () => {
    form.resetFields();
    setIsAppTwoFa(false);
    setIsEmailTwoFa(false);
  };

  const onFinish = async values => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    try {
      await actionLogin(
        values.email.trim(),
        values.password.trim(),
        values.appTwoFaKey ? values.appTwoFaKey.trim() : undefined,
        values.emailTwoFaKey ? values.emailTwoFaKey.trim() : undefined,
        recaptcha
      );
    } catch (err) {
      notificationList.failed(err?.data?.message);
    }

    recaptchaRef.current.reset();
  };

  const getBack = () => {
    if (isEmailTwoFa || isAppTwoFa) {
      setIsEmailTwoFa(false);
      setIsAppTwoFa(false);
      form.resetFields(['emailTwoFaKey', 'appTwoFaKey']);
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ email: '', password: '', appTwoFaKey: '', emailTwoFaKey: '' }}
        onFinish={onFinish}
        className={styles['login-form']}
      >
        {(isAppTwoFa || isEmailTwoFa) && (
          <div className={styles['back__button']} onClick={getBack}>
            <ArrowBackIcon /> {t('swap.backButton')}
          </div>
        )}
        {!isAppTwoFa && !isEmailTwoFa && <span className={styles['login-form__label']}>{t('email')}</span>}
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={isAppTwoFa || isEmailTwoFa}
        >
          <Input
            className={styles['login-form__input']}
            placeholder={t('email')}
            suffix={<img src={emailIcon} alt="email icon" />}
          />
        </Form.Item>

        {!isAppTwoFa && !isEmailTwoFa && (
          <div className={styles['login-form__label']}>
            <span>{t('password')}</span>
            <Link to="/password-send" className="login-form__link">
              {t('auth.forgotPassword')}
            </Link>
          </div>
        )}
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={isAppTwoFa || isEmailTwoFa}
        >
          <Input.Password className={styles['login-form__input']} placeholder={t('password')} />
        </Form.Item>

        {isEmailTwoFa && (
          <Form.Item
            label={t('auth.keyFromEmail')}
            name="emailTwoFaKey"
            rules={[{ required: isEmailTwoFa, message: t('validation.required') }]}
            className={styles['login-form__item']}
          >
            <AuthCode
              autoFocus={isEmailTwoFa && !isAppTwoFa}
              containerClassName={styles['login-form__2fa']}
              inputClassName={styles['login-form__2fa-input']}
            />
          </Form.Item>
        )}

        {isAppTwoFa && (
          <Form.Item
            label={t('auth.keyFromGA')}
            name="appTwoFaKey"
            rules={[{ required: isAppTwoFa, message: t('validation.required') }]}
            className={styles['login-form__item']}
          >
            <AuthCode
              autoFocus={!isEmailTwoFa && isAppTwoFa}
              containerClassName={styles['login-form__2fa']}
              inputClassName={styles['login-form__2fa-input']}
            />
          </Form.Item>
        )}

        <Button className={styles['login-form__button']} type="submit" disabled={fetchingUser} loading={fetchingUser}>
          {t('submit')}
        </Button>

        <span className={styles['login-form__divider']}>Or</span>

        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
      </Form>
      <LoginSocialMedia userType="corporate" />
    </>
  );
};

const mapStateToProps = state => ({
  fetchingUser: state.login.fetching,
  isAppTwoFa: state.login.isAppTwoFa,
  isEmailTwoFa: state.login.isEmailTwoFa,
});

const mapDispatchToProps = dispatch => ({
  actionLogin: login(dispatch),
  setIsAppTwoFa: isAppTwoFa =>
    dispatch({
      type: 'LOGIN_SET_APP_TWO_FA',
      isAppTwoFa,
    }),
  setIsEmailTwoFa: isEmailTwoFa =>
    dispatch({
      type: 'LOGIN_SET_EMAIL_TWO_FA',
      isEmailTwoFa,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
