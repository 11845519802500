import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeNetworkFilter } from '../../redux/actions/networkFilter';
import { Dropdown, Radio } from 'antd';
import dropdownIcon from '../../img/arrow-blue.svg';
import './style.scss';

const NetworkFilter = ({ networkFilter, changeNetworkFilter }) => {
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="network-filter">
      {/* <span className="header__user-role" onClick={() => changeNetworkFilter('testnet')}>
         Network filter - {t(networkFilter.toLowerCase())}
      </span> */}
      <Dropdown
        onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
        visible={isSortingVisible}
        placement="bottomCenter"
        overlay={
          <div className="network-filter__dropdown">
            <Radio.Group
              value={networkFilter}
              onChange={e => {
                changeNetworkFilter(e.target.value);
              }}
            >
              <Radio value="mainnet">{t('mainnet')}</Radio>
              <Radio value="testnet">{t('testnet')}</Radio>
            </Radio.Group>
          </div>
        }
      >
        <div className="header__merc-button">
          {window.location.pathname.includes('/admin') ? 'Network filter' : t('header.networkFilter')}
          <img
            className={`network-filter__button-icon ${isSortingVisible ? 'network-filter__button-icon_active' : ''}`}
            src={dropdownIcon}
            alt="dropdown"
          />
        </div>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  changeNetworkFilter: changeNetworkFilter(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkFilter);
