import {
  USER_GET,
  USER_SET,
  USER_SHOW_WELCOME_MODAL,
  USER_SHOW_APP_TOUR,
  USER_EDIT,
  USER_SIGN_UP,
  USER_SIGN_UP_BY_PHONE,
  USER_SHOW_PHONE_CODE_VERIFY,
  USER_SET_APP_TWO_FA,
  USER_SET_EMAIL_TWO_FA,
  USER_SIGN_UP_EMAIL_TWO_FA,
  USER_CONFIRM_EMAIL,
  USER_SET_EMAIL_CONFIRMED_STATUS,
  USER_DELETE_TWO_FACTOR_TOKEN,
  USER_DELETE_EMAIL_TWO_FA,
  USER_CHANGE_BACKUP_STATUS,
  USER_ERROR,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_DONE,
  USER_RESEND_CONFIRM_EMAIL,
  USER_RESEND_CONFIRM_EMAIL_DONE,
  USER_CHANGE_PASSWORD_EMAIL_TWO_FA,
  USER_CHANGE_PASSWORD_TWO_FACTOR_TOKEN,
  USER_CONFIRM_BACKUP,
  USER_CONFIRM_BACKUP_DONE,
  USER_INSTALL_METAMASK,
  USER_INSTALL_METAMASK_SUCCESS,
  USER_REMOVE_METAMASK,
  USER_REMOVE_METAMASK_SUCCESS,
  USER_METAMASK_ERROR
} from '../actions/user';

export default function reducer(
  state = {
    data: [],
    showWelcomeModal: false,
    showAppTour: false,
    fetching: false,
    isSignUpEmailTwoFa: false,
    isDeleteEmailTwoFa: false,
    isDeleteTwoFactorToken: false,
    isChangeEmailTwoFa: false,
    isChangeTwoFactorToken: false,
    passwordFetching: false,
    emailConfirmFetching: false,
    backupPhraseFetching: false,
    showPhoneCodeVerify: false,
    metamaskStatus: {
      fetching:false,
      status: null,
      error: null
    }
  },
  action
) {
  switch (action.type) {
    case USER_GET:
    case USER_EDIT:
    case USER_SIGN_UP:
    case USER_CONFIRM_EMAIL:
    case USER_SIGN_UP_BY_PHONE:
      return {
        ...state,
        fetching: action.fetching,
      };
    case USER_SIGN_UP_EMAIL_TWO_FA:
      return {
        ...state,
        isSignUpEmailTwoFa: action.payload,
      }
    case USER_DELETE_EMAIL_TWO_FA:
      return {
        ...state,
        isDeleteEmailTwoFa: action.payload
      }
    case USER_DELETE_TWO_FACTOR_TOKEN:
      return {
        ...state,
        isDeleteTwoFactorToken: action.payload
      }
    case USER_CHANGE_PASSWORD_EMAIL_TWO_FA:
      return {
        ...state,
        isChangeEmailTwoFa: action.payload
      }
    case USER_CHANGE_PASSWORD_TWO_FACTOR_TOKEN:
      return {
        ...state,
        isChangeTwoFactorToken: action.payload
      }
    case USER_SHOW_PHONE_CODE_VERIFY:
      return {
        ...state,
        showPhoneCodeVerify: action.showPhoneCodeVerify,
        fetching: action.fetching,
      };
    case USER_SET:
      return {
        ...state,
        data: action.payload,
        fetching: action.fetching,
      };
    case USER_SHOW_WELCOME_MODAL:
      return {
        ...state,
        showWelcomeModal: action.payload,
      };
    case USER_SHOW_APP_TOUR:
      return {
        ...state,
        showAppTour: action.payload,
      };
    case USER_SET_EMAIL_CONFIRMED_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          emailConfirmed: action.payload,
        },
      };
    case USER_SET_APP_TWO_FA:
      return {
        ...state,
        data: {
          ...state.data,
          totpTwoFaStatus: action.payload,
        },
      };
    case USER_SET_EMAIL_TWO_FA:
      return {
        ...state,
        data: {
          ...state.data,
          emailTwoFaStatus: action.payload,
        },
      };
    case USER_CHANGE_BACKUP_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          backupPhrase: action.payload,
        },
      };
    case USER_CHANGE_PASSWORD:
      return {
        ...state,
        passwordFetching: true,
      };
    case USER_CHANGE_PASSWORD_DONE:
      return {
        ...state,
        passwordFetching: false,
      };
    case USER_RESEND_CONFIRM_EMAIL:
      return {
        ...state,
        emailConfirmFetching: true,
      };
    case USER_RESEND_CONFIRM_EMAIL_DONE:
      return {
        ...state,
        emailConfirmFetching: false,
      };
    case USER_CONFIRM_BACKUP:
      return {
        ...state,
        backupPhraseFetching: true,
      };
    case USER_CONFIRM_BACKUP_DONE:
      return {
        ...state,
        backupPhraseFetching: false,
      };
    case USER_ERROR:
      return {
        ...state,
        fetching: false,
      };
    case USER_INSTALL_METAMASK:
    case USER_REMOVE_METAMASK:
      return {
        ...state,
        metamaskStatus:{
          fetching:true,
          status: null,
          error: null
        }
      };
    case USER_INSTALL_METAMASK_SUCCESS:
      return {
        ...state,
        metamaskStatus:{
          fetching:false,
          status: 'connected',
          error: null
        }
      };
    case USER_REMOVE_METAMASK_SUCCESS:
      return {
        ...state,
        metamaskStatus:{
          fetching:false,
          status: 'disconnected',
          error: null
        }
      };
      case  USER_METAMASK_ERROR:
        return {
          ...state,
          metamaskStatus:{
            fetching:false,
            status: state.metamaskStatus.status,
            error: true
          }
        };
    default:
      return state;
  }
}
