import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification, Empty } from 'antd';
import { formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  getMerchantWallets,
  getMoreMerchantWallets,
  deleteMerchantWallet,
  resetPageCounter,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
} from '../../redux/actions/merchantWallets';
import { getMerchantData } from '../../redux/actions/merchantData';
import WalletsFilters from './WalletsFilters';
import WalletsPagination from './WalletsPagination';
import ReplenishModal from '../ReplenishModal/ReplenishModal';
import PrivateKeyModal from './PrivateKeyModal';
import Wallet from './Wallet';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './WalletsList.scss';

const WalletsList = ({
  formName,
  merchantId,
  networkFilter,
  wallets,
  typeWallet,
  currencies,
  getMerchantWallets,
  getMoreMerchantWallets,
  page,
  pages,
  countItem,
  resetPageCounter,
  walletsFilters,
  fetching,
  getMerchantData,
  merchantData,
  deleteMerchantWallet,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
}) => {
  const [walletId, setWalletId] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { t } = useTranslation();

  const defaultFilterValues = {
    order: 'DESC',
    search: '',
    limit: 5,
    currencyIds:
      typeWallet === 'merchant'
        ? currencies.filter(currency => currency.currencyType === 'currency').map(currency => currency._id)
        : currencies.map(currency => currency._id),
    typeNetwork: networkFilter,
  };

  useEffect(() => {
    if (merchantId) {
      getMerchantData(merchantId);
    }
  }, [merchantId]);

  useEffect(() => {
    if (
      Object.keys(walletsFilters).length !== 0 &&
      walletsFilters.constructor === Object &&
      merchantId &&
      merchantId.length
    ) {
      if (walletsFilters.limit || walletsFilters.order) {
        submitFilters();
      }
    }
  }, [merchantId, walletsFilters.limit, walletsFilters.order, networkFilter]);

  const handleReplenishButton = (address, currency, isModalOpen) => {
    setWalletAddress(address);
    setWalletCurrency(currency);
    setIsReplenishModalOpen(isModalOpen);
  };

  const openNotification = () => {
    notification.success({
      message: t('copied'),
      duration: 2,
    });
  };

  const openModal = walletId => {
    setWalletId(walletId);
    setIsKeyModalOpen(true);
  };

  const closeModal = () => {
    setIsKeyModalOpen(false);
    resetTwoFaState(false);
  };

  const loadMoreWallets = page => {
    const { order, limit, search, currencyIds } = walletsFilters;
    getMoreMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: page,
      typeNetwork: networkFilter,
      showTokens: true
    });
  };

  const submitFilters = () => {
    const { order, limit, search, currencyIds } = walletsFilters;
    resetPageCounter();
    getMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: 1,
      typeNetwork: networkFilter,
      showTokens: true
    }).then(() => setIsFetched(true));
  };

  const resetFilters = () => {
    const { currencyIds } = defaultFilterValues;
    getMerchantWallets(merchantId, {
      ...defaultFilterValues,
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: 1,
      typeNetwork: networkFilter,
      showTokens: true
    }).then(() => setIsFetched(true));
  };

  return (
    <React.Fragment>
      <ReplenishModal
        walletAddress={walletAddress}
        walletCurrency={walletCurrency}
        isReplenishModalOpen={isReplenishModalOpen}
        handleReplenishButton={handleReplenishButton}
      />
      <PrivateKeyModal
        merchantId={merchantId}
        walletId={walletId}
        setWalletId={setWalletId}
        isKeyModalOpen={isKeyModalOpen}
        closeModal={closeModal}
        openNotification={openNotification}
        getWalletPrivateKey={getWalletPrivateKey}
      />
      {currencies && currencies.length && (
        <WalletsFilters
          formName={formName}
          title={typeWallet === 'user' ? 'Clients Merchant' : 'Merchant'}
          currencies={currencies}
          merchantName={merchantData.name}
          initialValues={defaultFilterValues}
          submitFilters={submitFilters}
          resetFilters={resetFilters}
          fetching={fetching}
        />
      )}
      {isFetched && wallets && wallets.length > 0 ? (
        <>
          <div className={`wallets-page ${fetching ? 'wallets-page_fetching' : ''}`}>
            {wallets.map((wallet, index) => {
              return (
                <Wallet
                  key={index}
                  merchantId={merchantId}
                  wallet={wallet}
                  typeWallet={typeWallet}
                  page={page}
                  pages={pages}
                  countItem={countItem}
                  walletsFilters={walletsFilters}
                  openModal={openModal}
                  openNotification={openNotification}
                  deleteMerchantWallet={deleteMerchantWallet}
                  handleReplenishButton={handleReplenishButton}
                  setWalletAsMain={setWalletAsMain}
                  currencies={currencies}
                  networkFilter={networkFilter}
                  getWalletPrivateKey={getWalletPrivateKey}
                />
              );
            })}
          </div>
          {!fetching && page && pages ? (
            <WalletsPagination
              page={page}
              pages={pages}
              walletsFilters={walletsFilters}
              loadMoreWallets={loadMoreWallets}
              submitFilters={submitFilters}
              formName={formName}
            />
          ) : null}
        </>
      ) : (
        !fetching && <Empty description={t('nodata')} />
      )}
      {fetching && (
        <div className="wallets__loader">
          <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  wallets: state.merchantWallets.data,
  page: state.merchantWallets.page,
  pages: state.merchantWallets.pages,
  countItem: state.merchantWallets.countItem,
  fetching: state.merchantWallets.fetching,
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
  walletsFilters: formValueSelector(props.formName)(state, 'search', 'order', 'limit', 'currencyIds'),
});

const mapDispatchToProps = dispatch => ({
  getMerchantWallets: getMerchantWallets(dispatch),
  getMoreMerchantWallets: getMoreMerchantWallets(dispatch),
  getMerchantData: getMerchantData(dispatch),
  deleteMerchantWallet: deleteMerchantWallet(dispatch),
  setWalletAsMain: setWalletAsMain(dispatch),
  resetTwoFaState: resetTwoFaState(dispatch),
  getWalletPrivateKey: getWalletPrivateKey(dispatch),
  resetPageCounter,
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletsList);
