import React from 'react';
import Wallet from '../Wallet';
import emptyImage from '../../../img/empty.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const WalletsList = ({ withdrawWallets, walletsFetching }) => {
  return (
    <div className="fee-wallets-list">
      {!walletsFetching ? (
        <div className="fee-wallets-list__list">
          {withdrawWallets.length ? (
            withdrawWallets.map(wallet => <Wallet key={wallet._id} wallet={wallet} />)
          ) : (
            <div className="fee-wallets-list__empty">
              <span className="transactions-list__empty-title">All available wallets will show up here.</span>
              <img src={emptyImage} className="transactions-list__empty-image" alt="empty" />
            </div>
          )}
        </div>
      ) : (
        <div className="fee-wallets-list__loader">
          <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default WalletsList;
