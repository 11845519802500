import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MultisendRecipients from './MultisendRecipients';
import MultisendSummary from './MultisendSummary';
import { Modal, Spin, Row, Col, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthCode from 'react-auth-code-input';
import Button from '../../../Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../../../img/success.svg';
import './style.scss';
import styles from '../../../AuthForms/style.module.scss';

const MultisendConfirm = ({
  currency,
  setText,
  data,
  setData,
  multisendEstimate,
  wallet,
  setShowConfirm,
  multisendWithdraw,
  merchantId,
  handleEstimate,
  multisendFetching,
}) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [isAppTwoFa, setIsAppTwoFa] = useState(false);
  const [isEmailTwoFa, setIsEmailTwoFa] = useState(false);
  // const [appTwoFaCode, setAppTwoFaCode] = useState('');
  // const [emailTwoFaCode, setEmailTwoFaCode] = useState('');
  const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
  const { t } = useTranslation();
  const [multisendConfirmForm] = Form.useForm();

  const handleSubmit = async values => {
    const { appTwoFaCode, emailTwoFaCode } = values;
    setShowStatusModal(true);

    try {
      const response = await multisendWithdraw(
        merchantId,
        wallet._id,
        multisendEstimate.estimationId,
        appTwoFaCode || undefined,
        emailTwoFaCode || undefined
      );

      if (response && response.data && response.data.twoFactorToken) {
        setIsAppTwoFa(true);
      }

      if (response && response.data && response.data.emailSent) {
        setIsEmailTwoFa(true);
      }

      if (response && response.status === 'success') {
        resetTwoFa();
        setModalSuccessStatus(true);
      }
    } catch (error) {
      setShowStatusModal(false);
      resetTwoFa();
    }
  };

  const resetTwoFa = () => {
    setIsAppTwoFa(false);
    setIsEmailTwoFa(false);
    multisendConfirmForm.resetFields();
  };

  return (
    <>
      <Modal centered={true} wrapClassName="multisend-confirm__modal" width={400} visible={showStatusModal}>
        <div className="multisend-confirm__modal-wrap">
          {multisendFetching ? (
            <>
              <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 40 }} spin />} />
              <span className="multisend-confirm__modal-text">{t('multisendWithdraw.sending')}</span>
            </>
          ) : modalSuccessStatus ? (
            <>
              <SuccessIcon />
              <span className="multisend-confirm__modal-text multisend-confirm__modal-text-success">
                {t('multisendWithdraw.success')}
              </span>
              <span className="multisend-confirm__modal-subtext">
                {t('multisendWithdraw.theTransactionHasBeenSuccessfullyProcessed')}
              </span>
              <div className="multisend-confirm__modal-buttons">
                <Link to="/">
                  <Button>{t('multisendWithdraw.goToTransactions')}</Button>
                </Link>
              </div>
            </>
          ) : (
            <>
              <Form
                form={multisendConfirmForm}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  rules={[{ required: true, message: t('validation.required') }]}
                  label={t('auth.keyFromEmail')}
                  name="emailTwoFaCode"
                  className={styles['login-form__item']}
                >
                  <AuthCode
                    autoFocus={true}
                    containerClassName={styles['login-form__2fa']}
                    inputClassName={styles['login-form__2fa-input']}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t('validation.required') }]}
                  label={t('auth.keyFromGA')}
                  name="appTwoFaCode"
                  className={styles['login-form__item']}
                >
                  <AuthCode
                    autoFocus={false}
                    containerClassName={styles['login-form__2fa']}
                    inputClassName={styles['login-form__2fa-input']}
                  />
                </Form.Item>
                <Col span="24">
                  <Button
                    className={`modal__button modal__button-create modal__button-center`}
                    onClick={() => multisendConfirmForm.submit()}
                  >
                    {t('multisendWithdraw.confirm')}
                  </Button>
                </Col>
              </Form>

              {modalSuccessStatus && (
                <>
                  <SuccessIcon />
                  <span className="multisend-confirm__modal-text multisend-confirm__modal-text-success">
                    {t('multisendWithdraw.success')}
                  </span>
                  <span className="multisend-confirm__modal-subtext">
                    {t('multisendWithdraw.theTransactionHasBeenSuccessfullyProcessed')}
                  </span>
                  <div className="multisend-confirm__modal-buttons">
                    <Link to="/">
                      <Button>{t('multisendWithdraw.goToTransactions')}</Button>
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
      <div className="multisend-confirm">
        <MultisendRecipients
          currency={currency}
          setText={setText}
          data={data}
          setData={setData}
          handleEstimate={handleEstimate}
          setShowConfirm={setShowConfirm}
        />
        <MultisendSummary currency={currency} multisendEstimate={multisendEstimate} wallet={wallet} />
        <div className="multisend-confirm__buttons">
          <Button
            type="secondary"
            className="multisend-confirm__button multisend-confirm__button"
            onClick={() => setShowConfirm(false)}
          >
            {t('back')}
          </Button>
          <Button className="multisend-confirm__button" onClick={() => handleSubmit(data)}>
            {t('multisendWithdraw.confirm')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MultisendConfirm;
