import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-back.svg';
import './style.scss';

const SwapBack = ({ action, maxWidth }) => {
  const {t} = useTranslation();

  return (
    <div className="swap-back" style={{ maxWidth }}>
      <div className="swap-back__button" onClick={action}>
        <ArrowBackIcon /> {t('swap.backButton')}
      </div>
    </div>
  );
};

export default SwapBack;
