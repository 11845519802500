import {
  SWAP_HISTORY_GET,
  SWAP_HISTORY_SET,
  SWAP_HISTORY_ERROR,
  SWAP_EXCHANGE_WALLETS_GET,
  SWAP_EXCHANGE_WALLETS_SET,
  SWAP_EXCHANGE_WALLETS_RESET,
  SWAP_EXCHANGE_WALLETS_ERROR,
  SWAP_OFFERS_GET,
  SWAP_OFFERS_SET,
  SWAP_OFFERS_RESET,
  SWAP_OFFERS_ERROR,
  SWAP_OFFERS_UPDATE_GET,
  SWAP_OFFERS_UPDATE_SET,
  SWAP_OFFERS_UPDATE_APPLY,
  SWAP_OFFERS_UPDATE_ERROR,
  RESET_SWAP_OFFERS_UPDATED_DATA,
  SWAP_EXCHANGE_GET,
  SWAP_EXCHANGE_SET,
  SWAP_EXCHANGE_ERROR,
  RESET_SWAP_EXCHANGE_ERROR,
  SWAP_EXCHANGE_WALLET_SET,
  SWAP_EXCHANGE_WALLET_GET

} from '../actions/swap';

export default function reducer(
  state = {
    history: {
      data: {
        page: 0,
        pages: 0,
        countItems: 0,
        entities: [],
      },
      fetching: false,
    },
    exchangeWallets: {
      data: [null, null],
      fetching: false,
    },
    offers: {
      data: null,
      fetching: false,
      // updating: false,
    },
    updatedOffers: {
      data: null,
      fetching: false,
    },
    exchange: {
      data: null,
      error: null,
      fetching: false,
    },
  },
  action
) {
  switch (action.type) {
    case SWAP_HISTORY_GET:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: !(action.page && action.page > 1),
        },
      };
    case SWAP_HISTORY_SET:
      return action.payload.page > 1
        ? {
            ...state,
            history: {
              ...state.history,
              data: {
                ...state.history.data,
                ...action.payload,
                entities: [...state.history.data.entities, ...action.payload.entities],
              },
              fetching: false,
            },
          }
        : {
            ...state,
            history: {
              ...state.history,
              data: {
                ...state.history.data,
                ...action.payload,
              },
              fetching: false,
            },
          };
    case SWAP_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_WALLETS_GET:
    case SWAP_EXCHANGE_WALLET_GET:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          fetching: true,
        },
      };
    case SWAP_EXCHANGE_WALLETS_SET:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          data: action.payload,
          fetching: false,
        },
      };
      case SWAP_EXCHANGE_WALLET_SET:
        return {
          ...state,
          exchangeWallets: {
            ...state.exchangeWallets,
            data: action.payload.number === 0 ? [action.payload.data,state.exchangeWallets.data[1]] : [state.exchangeWallets.data[0], action.payload.data],
            fetching: false,
          },
        };
    case SWAP_EXCHANGE_WALLETS_RESET:
      return {
        ...state,
        exchangeWallets: { data: [null, null], fetching: false },
      };

    case SWAP_EXCHANGE_WALLETS_ERROR:
      return {
        ...state,
        exchangeWallets: {
          ...state.exchangeWallets,
          fetching: false,
        },
      };
    case SWAP_OFFERS_GET:
      return {
        ...state,
        offers: {
          ...state.offers,
          fetching: true,
        },
      };
    // case SWAP_OFFERS_UPDATE:
    //   return {
    //     ...state,
    //     offers: {
    //       ...state.offers,
    //       updating: true,
    //     },
    //   };
    case SWAP_OFFERS_SET:
      return {
        ...state,
        offers: {
          ...state.offers,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_OFFERS_RESET:
      return {
        ...state,
        offers: {
          data: null,
          fetching: false,
        },
      };
    case SWAP_OFFERS_ERROR:
      return {
        ...state,
        offers: {
          ...state.offers,
          fetching: false,
        },
      };
    case SWAP_OFFERS_UPDATE_GET:
      return {
        ...state,
        updatedOffers: {
          ...state.updatedOffers,
          fetching: true,
        },
      };
    case SWAP_OFFERS_UPDATE_SET:
      return {
        ...state,
        updatedOffers: {
          ...state.updatedOffers,
          data: action.payload,
          fetching: false,
        },
      };
    case SWAP_OFFERS_UPDATE_APPLY:
      return {
        ...state,
        offers: {
          ...state.offers,
          data: state.updatedOffers.data,
          fetching: false,
        },
        updatedOffers: {
          ...state.updatedOffers,
          data: null,
          fetching: false,
        },
      };
      case RESET_SWAP_OFFERS_UPDATED_DATA:
        return {
          ...state,
          updatedOffers: {
            ...state.updatedOffers,
            data:null
          }
        }
    case SWAP_OFFERS_UPDATE_ERROR:
      return {
        ...state,
        updatedOffers: {
          ...state.offers,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_GET:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: null,
          fetching: true,
        },
      };
    case SWAP_EXCHANGE_SET:
      return {
        ...state,
        exchange: {
          ...state.offers,
          data: action.payload,
          error: null,
          fetching: false,
        },
      };
    case SWAP_EXCHANGE_ERROR:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: action.payload,
          fetching: false,
        },
      };
    case RESET_SWAP_EXCHANGE_ERROR:
      return {
        ...state,
        exchange: {
          ...state.offers,
          error: null,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
