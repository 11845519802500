import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import App from './App';
import store from './store';
import JwtDecode from 'jwt-decode';
import { softLogin } from './redux/actions/login';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
     <React.StrictMode>
    <Provider store={store}>
      {isLogedIn(store.dispatch)}
      <App />
    </Provider>
  </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

function isLogedIn(dispatch) {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    const decodedToken = JwtDecode(authToken);
    if (Date.now() < decodedToken.exp * 1000) dispatch(softLogin(authToken));
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
