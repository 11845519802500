import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Radio, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import resetIcon from '../../../img/reset.svg';
import './style.scss';

const RenderRadio = ({ input, options, disabled }) => {
  return (
    <Radio.Group disabled={disabled} {...input}>
      {options.map((option, index) => (
        <Radio key={index} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

const TransactionFilters = ({ search, setSearch, order, setOrder, type, setType, submitSearch, resetFilters }) => {
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  // const [sorting, setSorting] = useState('DESC');
  // const [type, setType] = useState('');
  const { t } = useTranslation();

  return (
    <div className="transaction-filters">
      <div className="transaction-filters__title">{t('transactions')}</div>
      <div className="transaction-filters__search-wrap">
        <div className="transaction-filters__search">
          <Input.Search
            // disabled={fetching}
            name="search"
            placeholder={t('searchBy')}
            onSearch={value => submitSearch(value)}
            className="transaction-filters__search-field"
            onChange={e => setSearch(e.target.value)}
            value={search}
            addonAfter={
              // fetching ? (
              // <Spin
              // className="transaction-filters__reset-button"
              // indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 14 }} spin />}
              // />
              // ) : (
              <a onClick={resetFilters} className="transaction-filters__reset-button">
                <img src={resetIcon} alt="Reset" />
              </a>
              // )
            }
          />
        </div>
        <div className="transaction-filters__sort-wrap">
          <div className="transaction-filters__sorting">
            <Dropdown
              onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
              visible={isSortingVisible}
              overlay={
                <div className="transaction-filters__currencies-dropdown">
                  <Radio.Group
                    value={order}
                    onChange={e => {
                      setOrder(e.target.value);
                      // handleChangeFilters({ search, order, type });
                    }}
                  >
                    <Radio value="DESC">{t('first')}</Radio>
                    <Radio value="ASC">{t('last')}</Radio>
                  </Radio.Group>
                  <div className="dropdown-divider"></div>
                  <Radio.Group
                    value={type}
                    onChange={e => {
                      setType(e.target.value);
                      // handleChangeFilters({ search, order, type });
                    }}
                  >
                    <Radio value="replenishment,withdrawal,Error,refund,multisend,InternalTransfer">{t('all')}</Radio>
                    <Radio value="replenishment">{t('transactionFilter.dropdown.replenishment')}</Radio>
                    <Radio value="withdrawal">{t('withdraw.withdrawal')}</Radio>
                    <Radio value="multisend">{t('transactionFilter.dropdown.multisend')}</Radio>
                    <Radio value="Error">{t('transactionFilter.dropdown.error')}</Radio>
                  </Radio.Group>
                </div>
              }
            >
              <div className="transaction-filters__currencies-button">
                {t('sorting')} <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionFilters;
