const partnerData = {
  alfacash: {
    score: 4.8,
    link: 'https://www.trustpilot.com/review/alfa.cash',
  },
  binance: {
    score: 2,
    link: 'https://www.trustpilot.com/review/binance.com',
  },
  changenow: {
    score: 4.8,
    link: 'https://www.trustpilot.com/review/changenow.io',
  },
  exolix: {
    score: 4.6,
    link: 'https://www.trustpilot.com/review/exolix.com',
  },
  fixedfloat: {
    score: 5,
    link: 'https://www.trustpilot.com/review/fixedfloat.com',
  },
  godex: {
    score: 4.9,
    link: 'https://www.trustpilot.com/review/godex.io',
  },
  instaswap: {
    score: 4.0,
    link: 'https://www.trustpilot.com/review/instaswap.io',
  },
  simpleswap: {
    score: 4.7,
    link: 'https://www.trustpilot.com/review/simpleswap',
  },
  stealthex: {
    score: 4.5,
    link: 'https://www.trustpilot.com/review/stealthex.io',
  },
  swapuz: {
    score: 4.1,
    link: 'https://www.trustpilot.com/review/swapuz.com',
  },
  switchain: {
    score: 2.5,
    link: 'https://www.trustpilot.com/review/switchain.com',
  },
  letsexchange: {
    score: 4.7,
    link: 'https://www.trustpilot.com/review/letsexchange.io',
  },
  changehero: {
    score: 4.7,
    link: 'https://www.trustpilot.com/review/changehero.io',
  },
  changelly: {
    score: 4.5,
    link: 'https://www.trustpilot.com/review/changelly.com',
  },
  quickex: {
    score: 4.4,
    link: 'https://www.trustpilot.com/review/quickex.io',
  },
  kucoin: {
    score: 1.8,
    link: 'https://www.trustpilot.com/review/kucoin.com',
  },
  swaple: {
    score: 4.0,
    link: 'https://www.trustpilot.com/review/swaple.trade',
  },
  default: null,
};

const swapPartnerTrustscore = partnerName => partnerData[partnerName] || partnerData.default;

export default swapPartnerTrustscore;
