import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Row, Col, Form, Input } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { createMerchantWallet } from '../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import CurrencyDropdown from '../CurrencyDropdown';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const WalletAddExisting = ({
  isFetching,
  merchantId,
  networkFilter,
  typeWallet,
  createMerchantWallet,
  notificationsErrorShow,
  handleCancel,
  page,
  walletsFilters,
}) => {
  const [walletCurrency, setWalletCurrency] = useState([]);
  const [walletPrivateKey, setWalletPrivateKey] = useState('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (!walletCurrency) {
      notificationsErrorShow({ message: "Currency is required"});
      return;
    }

    if (!walletPrivateKey) {
      notificationsErrorShow({ message: "Private key is required"});
      return;
    }

    createMerchantWallet(
      merchantId,
      typeWallet,
      {
        privateKey: walletPrivateKey,
        typeWallet: typeWallet,
        currenciesIds: walletCurrency,
      },
      page,
      walletsFilters,
      networkFilter
    ).then(() => handleCancel());
  };

  return (
    <>
      <CurrencyDropdown
        setFunc={setWalletCurrency}
        multiple={true}
        allCurrencies={false}
        noTokens={typeWallet === 'merchant'}
        merchantId={merchantId}
        individualTokens={typeWallet !== 'merchant' ? true : false}
      />

      <Form {...layout} initialValues={{ remember: true }} className="modal-form form-create-merchant">
        <Form.Item label={t('privateKey')} className="modal-form__label">
          <Input
            type="text"
            placeholder={t('privateKey')}
            className="modal-form__input"
            onChange={e => setWalletPrivateKey(e.target.value)}
          />
        </Form.Item>
      </Form>

      <Row className="modal__buttons-wrapper">
        <Col>
          <Button
            type="primary"
            className="modal__button modal__button-create"
            onClick={handleSubmit}
            loading={isFetching}
          >
            {t('create')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const filterSelectorFilters = formValueSelector('walletsFilters');

const mapStateToProps = (state, props) => ({
  page: state.merchantWallets.page,
  isFetching: state.merchantWallets.modalFetching,
  walletsFilters: formValueSelector(props.filtersFormName)(state, 'search', 'order', 'limit', 'currencyIds'),
});

const mapDispatchToProps = dispatch => ({
  createMerchantWallet: createMerchantWallet(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletAddExisting);
