import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { signUpByPhone } from "../../../redux/actions/user";
import PasswordStrength from '../../PasswordStrength';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-back.svg';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import AuthCode from 'react-auth-code-input';
import LoginSocialMedia from '../../LoginSocialMedia';
import styles from '../style.module.scss';
import notificationList from "../../../utils/notifications";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignupPhoneForm = ({ isFetching, signUpByPhone, showPhoneCodeVerify, setShowPhoneCodeVerify }) => {
  const [password, setPassword] = useState('');
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const query = useQuery();
  const { t } = useTranslation();

  console.log('showPhoneCodeVerify', showPhoneCodeVerify);

  useEffect(() => {
    if (query.get('inviterId')) {
      localStorage.setItem('cpayInviterId', query.get('inviterId'));
    }
  }, []);

  useEffect(
    () => () => {
      form.resetFields();
      setShowPhoneCodeVerify(false);
    },
    []
  );

  const getBack = () => {
    setShowPhoneCodeVerify(false);
    form.resetFields(['verifyCode']);
  }

  const handleFormSubmit = async values => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    try {
      const formData = {
        phone: `+${values.phone.trim()}`,
        password: values.password.trim(),
        roles: ['corporate'],
        verifyCode: values.verifyCode ? values.verifyCode.trim() : undefined,
        inviterId: query.get('inviterId') || localStorage.getItem('cpayInviterId') || undefined,
      };

      await signUpByPhone(formData, recaptcha);
    } catch (err) {}

    recaptchaRef.current.reset();
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ phone: query.get('phone') || '', password: '' }}
        onFinish={handleFormSubmit}
        className={styles['login-form']}
      >
        {!showPhoneCodeVerify && <span className={styles['login-form__label']}>{t('phone')}</span>}
        <Form.Item
          name="phone"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={showPhoneCodeVerify}
        >
          <PhoneInput containerClass="login-form__input-phone" inputClass="login-form__input" country={'us'} />
        </Form.Item>

        {!showPhoneCodeVerify && <span className={styles['login-form__label']}>{t('password')}</span>}
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={showPhoneCodeVerify}
        >
          <Input.Password
            className={styles['login-form__input']}
            placeholder={t('password')}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>

        {showPhoneCodeVerify && (
          <div className={styles["back__button"]} onClick={getBack}>
            <ArrowBackIcon /> {t('swap.backButton')}
          </div>
        )}

        {showPhoneCodeVerify && <span className={styles['login-form__label']}>{t('auth.verifyCode')}</span>}
        <Form.Item
          name="verifyCode"
          rules={[{ required: showPhoneCodeVerify, message: t('validation.required') }]}
          className={styles['login-form__item']}
          hidden={!showPhoneCodeVerify}
        >
          <AuthCode containerClassName={styles['login-form__2fa']} inputClassName={styles['login-form__2fa-input']} />
        </Form.Item>

        <PasswordStrength password={password} />

        <Button className={styles['login-form__button']} type="submit" disabled={isFetching} loading={isFetching} id={showPhoneCodeVerify ? "signup_phone" : null}>
          {t('submit')}
        </Button>

        <span className={styles['login-form__divider']}>Or</span>

        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
      </Form>
      <LoginSocialMedia userType="corporate" />
    </>
  );
};

const mapStateToProps = state => ({
  isFetching: state.user.fetching,
  showPhoneCodeVerify: state.user.showPhoneCodeVerify,
});

const mapDispatchToProps = dispatch => ({
  signUpByPhone: signUpByPhone(dispatch),
  setShowPhoneCodeVerify: isShowCode =>
    dispatch({
      type: 'USER_SHOW_PHONE_CODE_VERIFY',
      payload: isShowCode,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupPhoneForm);
