import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
} from '../../../redux/actions/adminPanel/adminDashboard';
import { getAdminUsers } from '../../../redux/actions/adminPanel/adminUsers';
import TopFive from './TopFive';
import TableData from './TableData';
import DashboardHeader from './DashBoardHeader';
import './styles.scss';
import { clearLocalStorage } from './helpers';

const currencyVolumesTrxArray = ['Replenishment', 'Withdrawal', 'SystemFee'];

const AdminDashboardPage = ({
  // STATE
  getCryptoCurrencyVolumeState,
  getCryptoCurrencyQuantityState,
  getCryptoCurrencyVolumeTrxByTypeState,
  getNewUsersState,
  getWithdrawTransactionsState,
  getReplenishTransactionsState,
  getSystemFeeTransactionsState,
  countItems,
  typeNetwork,
  // ACTIONS
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
  getAdminUsers,
}) => {
  useEffect(() => {
    // const thisMonth = {
    //   dateFrom: moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:01',
    //   dateTo: moment().endOf('month').format('YYYY-MM-DD') + 'T23:59:59',
    // };
    getCryptoCurrencyVolume({ typeNetwork: typeNetwork });
    getCryptoCurrencyQuantity({ typeNetwork: typeNetwork });

    currencyVolumesTrxArray.forEach(trxType =>
      getCryptoCurrencyVolumeTrxByType({ typeNetwork: typeNetwork, type: trxType })
    );
    // getNewUsers(thisMonth);
    // getWithdrawTransactions({
    //   ...thisMonth,
    //   type: 'Withdrawal',
    //   typeNetwork: typeNetwork,
    // });
    // getReplenishTransactions({
    //   ...thisMonth,
    //   type: 'Replenishment',
    //   typeNetwork: typeNetwork,
    // });
    // getSystemFeeTransactions({
    //   ...thisMonth,
    //   type: 'SystemFee',
    //   typeNetwork: typeNetwork,
    // });
    getAdminUsers();
  }, [typeNetwork]);

  const [sharedDate, setSharedDate] = useState((JSON.parse(localStorage.getItem('adm-date-all')))?.map(el => moment(el)) || null);
  const [changedFieldsArray, setChangedFieldsArray] = useState([]);
  const [changeAllDataPickers, setChangeAllDataPickers] = useState(localStorage.getItem('dates-status') ? Boolean(localStorage.getItem('dates-status')) : false);
  const [datesForAllExceptUsers, setDatesForAllExceptUsers] = useState(null);

  useEffect(() => {

    window.onbeforeunload = function() {
      return clearLocalStorage();
   };

  return () => {
      window.onbeforeunload = null;
  };
}, []);

useEffect(()=>{
  if(!changeAllDataPickers) {
    localStorage.removeItem('adm-date-all');
    localStorage.removeItem('dates-status');
    return;
  }

  const currentSharedData = (JSON.parse(localStorage.getItem('adm-date-users')))?.map(el => moment(el) || [moment().startOf('month'), moment().endOf('month')]);
  setSharedDate(currentSharedData);
  setChangedFieldsArray([]);
  localStorage.removeItem('adm-date-users');

},[changeAllDataPickers]);

useEffect(()=>{
  if(changeAllDataPickers && sharedDate) {
    localStorage.setItem(
      `adm-date-all`,
      JSON.stringify([sharedDate[0]?.format('YYYY-MM-DD') + 'T00:00:01', sharedDate[1]?.format('YYYY-MM-DD') + 'T23:59:59'])
    );
  }
},[sharedDate])

const onChangeHandler =(e)=>{
  setChangeAllDataPickers(e.target.value);
  localStorage.setItem('dates-status',e.target.value);

  if(e.target.value === false){
    setDatesForAllExceptUsers([moment().startOf('month'), moment().endOf('month')]);
    localStorage.setItem('adm-date-users', JSON.stringify(JSON.parse(localStorage.getItem('adm-date-all'))?.map(el => moment(el))));
  }
}

  return (
    <div className="admin-dashboard-container">
      <DashboardHeader />
      <TopFive title="Top 5 rated cryptocurrency" drawData={getCryptoCurrencyVolumeState} prefix={'$'} />
      {getCryptoCurrencyQuantityState.data && getCryptoCurrencyQuantityState.data.length ? (
        <TopFive title="Top 5 rated cryptocurrency by transaction " drawData={getCryptoCurrencyQuantityState} />
      ) : null}
      {getCryptoCurrencyVolumeTrxByTypeState?.data?.replenishment &&
      getCryptoCurrencyVolumeTrxByTypeState?.data?.replenishment?.length ? (
        <TopFive
          title="Top 5 rated cryptocurrency of receive"
          drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.replenishment }}
          prefix={'$'}
        />
      ) : null}
      {getCryptoCurrencyVolumeTrxByTypeState?.data?.withdrawal &&
      getCryptoCurrencyVolumeTrxByTypeState?.data?.withdrawal?.length ? (
        <TopFive
          title="Top 5 rated cryptocurrency of withdraw "
          drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.withdrawal }}
          prefix={'$'}
        />
      ) : null}
      {getCryptoCurrencyVolumeTrxByTypeState?.data?.systemfee &&
      getCryptoCurrencyVolumeTrxByTypeState?.data?.systemfee?.length ? (
        <TopFive
          title="Top 5 rated cryptocurrency of system fee"
          drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.systemfee }}
          prefix={'$'}
        />
      ) : null}


      <TableData
        title="New users"
        drawData={getNewUsersState}
        propName="Users"
        action={getNewUsers}
        redirectTo={'/admin/users'}
        countItems={countItems}
        sharedDate={sharedDate}
        setSharedDate={setSharedDate}
        setChangedFieldsArray={setChangedFieldsArray}
        changedFieldsArray={changedFieldsArray}
        type="users"
        changeAllDataPickers={changeAllDataPickers}
        onChangeHandler={onChangeHandler}
        datesForAllExceptUsers={datesForAllExceptUsers}
      />
      <TableData
        title="Withdraw"
        drawData={getWithdrawTransactionsState}
        propName="Withdraw"
        type="Withdrawal"
        action={getWithdrawTransactions}
        prefix={'$'}
        typeNetwork={typeNetwork}
        setSharedDate={setSharedDate}
        sharedDate={sharedDate}
        setChangedFieldsArray={setChangedFieldsArray}
        changedFieldsArray={changedFieldsArray}
        changeAllDataPickers={changeAllDataPickers}
        datesForAllExceptUsers={datesForAllExceptUsers}
      />
      <TableData
        title="Replenishment"
        drawData={getReplenishTransactionsState}
        propName="Replenishment"
        type="Replenishment"
        action={getReplenishTransactions}
        prefix={'$'}
        typeNetwork={typeNetwork}
        setSharedDate={setSharedDate}
        sharedDate={sharedDate}
        setChangedFieldsArray={setChangedFieldsArray}
        changedFieldsArray={changedFieldsArray}
        changeAllDataPickers={changeAllDataPickers}
        datesForAllExceptUsers={datesForAllExceptUsers}
      />
      <TableData
        title="System fee"
        drawData={getSystemFeeTransactionsState}
        propName="System fee"
        type="SystemFee"
        action={getSystemFeeTransactions}
        prefix={'$'}
        typeNetwork={typeNetwork}
        setSharedDate={setSharedDate}
        sharedDate={sharedDate}
        setChangedFieldsArray={setChangedFieldsArray}
        changedFieldsArray={changedFieldsArray}
        changeAllDataPickers={changeAllDataPickers}
        datesForAllExceptUsers={datesForAllExceptUsers}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  getCryptoCurrencyVolumeState: state.adminDashboard.getCryptoCurrencyVolume,
  getCryptoCurrencyQuantityState: state.adminDashboard.getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByTypeState: state.adminDashboard.getCryptoCurrencyVolumeTrxByType,
  getNewUsersState: state.adminDashboard.getNewUsers,
  getWithdrawTransactionsState: state.adminDashboard.getWithdrawTransactions,
  getReplenishTransactionsState: state.adminDashboard.getReplenishTransactions,
  getSystemFeeTransactionsState: state.adminDashboard.getSystemFeeTransactions,
  countItems: state.adminUsers.countItems,
  typeNetwork: state.networkFilter,
});

const mapDispatchToProps = {
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
  getAdminUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardPage);
