import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import getPasswordStrength from '../../utils/passwordStrength';
import helpCircleIcon from '../../img/help-circle.svg';
import helpCircleActiveIcon from '../../img/help-circle-blue.svg';
import './style.scss';

const PasswordStrength = ({ password }) => {
  const [passwordStrengthLevel, setPasswordStrengthLevel] = useState('');
  const [passwordStrengthText, setPasswordStrengthText] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const passwordStrength = getPasswordStrength(password);
    setPasswordStrengthLevel(passwordStrength.id);
    setPasswordStrengthText(passwordStrength.value);
  }, [password]);

  return (
    <div className="password-strength">
      <p className="login-form__label password-strength__title">
        {t('auth.passwordStrength')}
        <Popover
          className="password-strength__popover"
          placement="rightTop"
          content={<p className="password-strength__popover-text">{t('auth.passwordRule')}</p>}
        >
          <img className="password-strength__popover-icon" src={helpCircleIcon} alt="Help popup" />
          <img className="password-strength__popover-icon_active" src={helpCircleActiveIcon} alt="Help popup" />
        </Popover>
      </p>
      <div className="password-strength__scale" level={passwordStrengthLevel}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>{passwordStrengthText}</span>
    </div>
  );
};

export default PasswordStrength;
