import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';

// GET CURRENT ADMIN SETTINGS

export const GET_ADMIN_SETTINGS_REQUEST = 'GET_ADMIN_SETTINGS_REQUEST';
export const GET_ADMIN_SETTINGS_SUCCESS = 'GET_ADMIN_SETTINGS_SUCCESS';

export const getAdminSettingsRequest = () => ({
  type: GET_ADMIN_SETTINGS_REQUEST,
});

export const getAdminSettingsSuccess = payload => ({
  type: GET_ADMIN_SETTINGS_SUCCESS,
  payload,
});

export const getAdminSettings = () => async dispatch => {
  dispatch(getAdminSettingsRequest());

  try {
    const response = await adminApi.getAdminSettings();
    dispatch(getAdminSettingsSuccess(response.data));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// UPDATE ADMIN SETTINGS

export const UPDATE_ADMIN_SETTINGS_REQUEST = 'UPDATE_ADMIN_SETTINGS_REQUEST';
export const UPDATE_ADMIN_SETTINGS_SUCCESS = 'UPDATE_ADMIN_SETTINGS_SUCCESS';
export const UPDATE_ADMIN_SETTINGS_FAIL = 'UPDATE_ADMIN_SETTINGS_FAIL';

export const updateAdminSettingsRequest = () => ({
  type: UPDATE_ADMIN_SETTINGS_REQUEST,
});

export const updateAdminSettingsSuccess = payload => ({
  type: UPDATE_ADMIN_SETTINGS_SUCCESS,
  payload,
});

export const updateAdminSettingsFail = () => ({
  type: UPDATE_ADMIN_SETTINGS_FAIL,
});

export const updateAdminSettings = formData => async dispatch => {
  dispatch(updateAdminSettingsRequest());

  try {
    const response = await adminApi.updateAdminSettings({ ...formData, systemFeePercentReplenish: 1 });
    dispatch(updateAdminSettingsSuccess(response.data));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(updateAdminSettingsFail());
    dispatch(notificationsErrorShow(error.data));
  }
};
