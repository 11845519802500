import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import PageHeader from '../layout/PageHeader';
import MerchantList from '../components/MerchantList/MerchantList';
import MerchantCreateModal from '../components/Merchant/MerchantCreateModal';

function Merchants({ userRole, merchants }) {
  const [isCreateMerchantModalVisible, setIsCreateMerchantModalVisible] = useState();
  const { t } = useTranslation();

  const MultiwalletCreateButton = () => (
    <Button className="page-header__button-create" onClick={() => setIsCreateMerchantModalVisible(true)} disabled={merchants?.length >= 20}>
      {t('merchants.createNewMerchant')}
    </Button>
  );

  return userRole === 'individual' ? (
    <Redirect to="/" />
  ) : (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <MerchantCreateModal
        isCreateMerchantModalVisible={isCreateMerchantModalVisible}
        setIsCreateMerchantModalVisible={setIsCreateMerchantModalVisible}
        merchants={merchants}
      />
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={22} md={20} lg={18} xl={12} xxl={10}>
        <PageHeader
        pageHeaderText={t('merchants.merchantPageDescription')}
        CreateModalComponent={merchants?.length < 20 ? MultiwalletCreateButton : null}
      /></Col>

      </div>
      <Row justify='center' className="merchnats">
        <Col xs={24} sm={22} md={20} lg={18} xl={12} xxl={10} >
          <MerchantList />
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapDispatchToProps = state => ({
  userRole: state.user.data.role,
  merchants: state.merchants.data,
});

export default connect(mapDispatchToProps)(Merchants);
