import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

// CONSTANTS
export const TRANSACTIONS_GET = 'TRANSACTIONS_GET';
export const TRANSACTIONS_GET_MORE = 'TRANSACTIONS_GET_MORE';
export const TRANSACTIONS_SET = 'TRANSACTIONS_SET';
export const TRANSACTIONS_SET_MORE = 'TRANSACTIONS_SET_MORE';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const TRANSACTIONS_RESET_PAGE = 'TRANSACTIONS_RESET_PAGE';
export const TRANSACTIONS_SET_ACTIVE_MERCHANT = 'TRANSACTIONS_SET_ACTIVE_MERCHANT';

// ACTIONS GENERATORS
export const transactionsGet = () => ({
  type: TRANSACTIONS_GET,
  fetching: true,
});

export const transactionsGetMore = () => ({
  type: TRANSACTIONS_GET_MORE,
  loadMoreFetching: true,
});

export const transactionsSet = data => ({
  type: TRANSACTIONS_SET,
  fetching: false,
  payload: data,
});

export const transactionsSetMore = data => ({
  type: TRANSACTIONS_SET_MORE,
  loadMoreFetching: false,
  payload: data,
});

export const transactionsError = () => ({
  type: TRANSACTIONS_ERROR,
});

export const resetPageCounter = () => ({
  type: TRANSACTIONS_RESET_PAGE,
});

export const transactionsSetActiveMerchant = data => ({
  type: TRANSACTIONS_SET_ACTIVE_MERCHANT,
  payload: data,
});

// ASYNC ACTIONS
export const setActiveMerchant = dispatch => async merchantId => {
  localStorage.setItem('merchantId', merchantId);
  dispatch(transactionsSetActiveMerchant(merchantId));
};

export const getTransactions = dispatch => async (merchantId, params) => {
  dispatch(transactionsGet());
  let transactions;

  try {
    transactions = await cryptoApi.getTransactions(merchantId, params);
  } catch (err) {
    dispatch(transactionsError());
    dispatch(notificationsErrorShow(err.data));
    return;
  }

  if (transactions && transactions.data) {
    dispatch(transactionsSet(transactions.data));
    return;
  }

  dispatch(transactionsError());
};

export const getMoreTransactions = dispatch => async (merchantId, params) => {
  dispatch(transactionsGetMore());
  let transactions;

  try {
    transactions = await cryptoApi.getTransactions(merchantId, params);
  } catch (err) {
    dispatch(transactionsError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (transactions && transactions.data) {
    dispatch(transactionsSetMore(transactions.data));
    return;
  }

  dispatch(transactionsError());
};
