import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { Form, Radio } from 'antd';
import TEXT from '../Text';
import { ButtonPrimary, ButtonSecondary } from '../Button';
import { TextInput, TextPassword } from '../../components/FormFields';
import { createAdminNewUser } from '../../../redux/actions/adminPanel/adminUsers';
import './style.scss';

const AddUserModal = ({ showAddUserModal, setShowAddUserModal, createAdminNewUser, updateUsersList }) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    setShowAddUserModal(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add new user"
      visible={showAddUserModal}
      width={385}
      footerComponent={
        <div className="add-user-modal__buttons-wrap">
          <ButtonSecondary onClick={handleClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={form.submit}>Add</ButtonPrimary>
        </div>
      }
      onCancel={handleClose}
    >
      <div className="add-user-modal">
        <Form
          form={form}
          name="add-user-form"
          autoComplete="off"
          initialValues={{
            email: '',
            password: '',
            // roles: 'individual',
          }}
          onFinish={async values => {
            const response = await createAdminNewUser({ ...values, roles: ['individual'] });
            if (response) {
              handleClose();
              updateUsersList();
            }
          }}
        >
          <div className="add-user-modal__input-wrap">
            <TextInput
              name="email"
              label="Email"
              placeholder="Type your email"
              rules={[{ required: true, message: 'Required' }]}
            />
          </div>
          <div className="add-user-modal__input-wrap">
            <TextPassword
              name="password"
              label="Password"
              placeholder="Enter your password"
              autoComplete="new-password"
              rules={[{ required: true, message: 'Required' }]}
            />
          </div>
          {/* <div className="add-user-modal__radio">
            <TEXT.label>Role</TEXT.label>
            <Form.Item name="roles" className="form-field__form-item">
              <Radio.Group>
                <Radio value="individual">Individual</Radio>
                <Radio value="corporate">Business</Radio>
              </Radio.Group>
            </Form.Item>
          </div> */}
          <button type="submit" hidden></button>
        </Form>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  createAdminNewUser,
};

export default connect(null, mapDispatchToProps)(AddUserModal);
