import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CurrencySelect from './CurrencySelect.jsx';
import { ReactComponent as SearchIcon } from '../../../img/swap-search.svg';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-select-arrow.svg';
import { ReactComponent as SwapFiltersIcon } from '../../../img/swap-filters.svg';
import useWindowSize from '../../../utils/resizeHook.js';
import Button from '../../Button/index.jsx';
import './style.scss';

const SwapFilters = ({
  currencies,
  historyFilters,
  setHistoryFilters,
  defaultHistoryFilters,
  setResetFilters,
  countItems,
  swapHistoryFetching,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [form] = Form.useForm();
  const { currentWidth } = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    form &&
      setResetFilters(() => () => {
        form.resetFields();
        form.submit();
      });
  }, [form]);

  const filters = (
    <>
      <div className="swap-history-filters__currencies-pair">
        <CurrencySelect
          className="swap-history-filters__label"
          label={t('swap.filters.fromTo')}
          name="currencyFromId"
          currencies={currencies}
          action={currentWidth > 590 ? form.submit : undefined}
        />
        <div className="swap-history-filters__currencies-pair-divider">-</div>
        <CurrencySelect
          name="currencyToId"
          currencies={currencies}
          action={currentWidth > 590 ? form.submit : undefined}
        />
      </div>
      <Form.Item className="swap-history-filters__label" label={t('swap.filters.sortBy')} name="order">
        <Select
          className="swap-history-filters__select swap-history-filters__select-sort"
          suffixIcon={<SelectArrowIcon />}
          onChange={currentWidth > 590 ? form.submit : undefined}
        >
          <Select.Option value="DESC">{t('swap.filters.newest')}</Select.Option>
          <Select.Option value="ASC">{t('swap.filters.oldest')}</Select.Option>
        </Select>
      </Form.Item>
    </>
  );

  return (
    <Form
      className="swap-history-filters"
      initialValues={defaultHistoryFilters}
      form={form}
      onFinish={values =>
        setHistoryFilters({
          ...historyFilters,
          ...values,
        })
      }
    >
      <div className="swap-history-filters__search-wrap">
        <Form.Item name="search" className="swap-history-filters__search-label">
          <Input
            className="swap-history-filters__search"
            prefix={<SearchIcon onClick={form.submit} />}
            placeholder={t('search')}
          />
        </Form.Item>
        {!swapHistoryFetching && historyFilters.search && (
          <div className="swap-history-filters__search-found">
            {t('swap.filters.results')} <span>{countItems}</span>
          </div>
        )}
      </div>
      {currentWidth > 590 ? (
        filters
      ) : (
        <>
          <div className="swap-history-filters__filters-switch" onClick={() => setShowMobileFilters(true)}>
            <SwapFiltersIcon />
            {t('swap.filters.filters')}
          </div>
          <Modal
            title={t('swap.filters.filters')}
            visible={showMobileFilters}
            footer={null}
            onCancel={() => setShowMobileFilters(false)}
            className="swap-history-filters__modal"
            transitionName=""
          >
            {filters}
            <div className="swap-history-filters__modal-buttons">
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                {t('swap.filters.reset')}
              </Button>
              <Button onClick={form.submit}>{t('swap.filters.apply')}</Button>
            </div>
          </Modal>
        </>
      )}
      <button type="submit" hidden>
      {t('swap.filters.submit')}
      </button>
    </Form>
  );
};

export default SwapFilters;
