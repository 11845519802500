import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Spin, Select, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WithdrawModeToggler from '../WithdrawModeToggler';
import MultisendForm from './MultisendForm';
import CurrencySelector from './CurrencySelector';
import MultisendConfirm from './MultisendConfirm';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { getMerchantBalances } from '../../../redux/actions/merchantData';
import {
  getMultisendSystemFee,
  multisendApprove,
  getMultisendMinerFee,
  multisendWithdraw,
  multisendEstimateReset,
} from '../../../redux/actions/multisend';
import { getMerchantWallets, merchantWalletsReset } from '../../../redux/actions/merchantWallets';
import useWindowSize from '../../../utils/resizeHook';
import './style.scss';

const Multisend = ({
  multisendMode,
  setMultisendMode,
  getMerchantBalances,
  merchantId,
  networkFilter,
  currencies,
  currenciesFetching,
  multisendEstimate,
  merchants,
  merchantsFetching,
  merchantWallets,
  getMultisendSystemFee,
  multisendApprove,
  getMultisendMinerFee,
  multisendWithdraw,
  getMerchantWallets,
  merchantWalletsReset,
  multisendEstimateReset,
  multisendFetching,
}) => {
  const [text, setText] = useState('');
  const [data, setData] = useState([]);
  const [validation, setValidation] = useState('');
  const [currency, setCurrency] = useState('');
  const [wallet, setWallet] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [fixedMerchantId, setFixedMerchantId] = useState(false);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  const currencyRef = useRef();

  useEffect(() => {
    merchantWalletsReset();
    return () => {
      merchantWalletsReset();
      multisendEstimateReset();
    };
  }, []);

  useEffect(() => {
    if (merchantId && !showConfirm) {
      getMerchantBalances(merchantId, networkFilter, true);
    }
  }, [merchantId, networkFilter, showConfirm]);

  useEffect(() => {
    if (multisendEstimate) {
      setFixedMerchantId(merchantId);
      setShowConfirm(true);
      currencyRef.current = currency;
    }
  }, [multisendEstimate]);

  useEffect(() => {
    currency && !showConfirm && getMerchantWalletsByCurrency(merchantId, currency);
  }, [merchantId, currency, showConfirm]);

  const getMerchantWalletsByCurrency = (merchantId, currencyId) => {
    if (currencyRef.current !== currencyId) setWallet(null);
    const merchantNetwork = merchants.find(merchant => merchant._id === merchantId).typeNetwork;

    getMerchantWallets(merchantId, {
      typeNetwork: merchantNetwork,
      limit: -1,
      currencyIds: `${currencyId}`,
      order: 'DESC',
      positiveBalance: true,
      showTokens: true
    });
  };

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = async data => {
    // TODO: get rid of map below by changing data array of arrays to array of objects in MultisendForm component for optimization
    const multisendWallets = data.map(item => ({ address: item[0], amount: item[1] }));

    setShowStatusModal(true);
    setModalStatus(t('multisendWithdraw.estimatingSystemFee'));

    try {
      const systemFeeResponse = await getMultisendSystemFee(merchantId, wallet, {
        currencyToken: currency,
        multisendWallets,
      });

      let approveResponse;
      if (systemFeeResponse.needApprove) {
        setModalStatus(t('multisendWithdraw.approvingTransaction'));

        approveResponse = await multisendApprove(merchantId, wallet, systemFeeResponse.estimationId);
      }

      let minerFeeResponse;
      if (!systemFeeResponse.needApprove || !approveResponse.needApprove) {
        setModalStatus(t('multisendWithdraw.estimatingMinerFee'));

        approveResponse && (await timeout(10000));

        minerFeeResponse = await getMultisendMinerFee(
          merchantId,
          wallet,
          approveResponse ? approveResponse.estimationId : systemFeeResponse.estimationId
        );
      }

      setShowStatusModal(false);
      setModalStatus('');
    } catch (error) {
      let apiValidation;

      if (Array.isArray(error.data.message)) {
        apiValidation = error.data.message
          .filter(line => line.indexOf('Line') === 0)
          .map(line => line + ` ${t('multisendWithdraw.validationExample')}`)
          .join('\n');
      }

      if (apiValidation) {
        setValidation(apiValidation);
      }

      setShowStatusModal(false);
    }
  };

  return (
    <>
      <Modal centered={true} wrapClassName="multisend__modal" width={400} visible={showStatusModal}>
        <div className="multisend__modal-wrap">
          <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 40 }} spin />} />
          <span className="multisend__modal-text">{modalStatus}</span>
        </div>
      </Modal>
      {!showConfirm ? (
        <Row gutter={[12, 0]}>
          <Col span={currentWidth >= 1024 ? 8 : 24}>
            <CurrencySelector
              currencies={currencies}
              currenciesFetching={currenciesFetching || merchantsFetching}
              currency={currency}
              setCurrency={setCurrency}
              currencyRef={currencyRef}
            />
          </Col>
          <Col span={currentWidth >= 1024 ? 16 : 24}>
            <div className="multisend">
              <WithdrawModeToggler multisendMode={multisendMode} setMultisendMode={setMultisendMode} />
              <Select
                className="multisend__wallet-select"
                value={wallet}
                onChange={setWallet}
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('nodata')} />}
                placeholder={t('multisendWithdraw.chooseWalletAddress')}
              >
                {merchantWallets.map(merchantWallet => (
                  <Select.Option value={merchantWallet._id}>
                    <div className="multisend__wallet-select-option">
                      <div className="multisend__wallet-select-option-info">
                        {currency && (
                          <TokenIcon
                            tokenName={currencies.find(currenciesItem => currenciesItem._id === currency).name}
                          />
                        )}{' '}
                        {truncate(merchantWallet.address, 14, '..')}
                      </div>
                      <div className="multisend__wallet-select-option-balance">
                        <div className="multisend__wallet-select-option-balance-token">
                          {!merchantWallet.tokens[0]
                            ? merchantWallet.balance.value
                            : merchantWallet.tokens[0].balance.value}{' '}
                          {!merchantWallet.tokens[0] ? merchantWallet.currency : merchantWallet.tokens[0].currency}
                        </div>
                        <div className="multisend__wallet-select-option-balance-usd">
                          {formatCurrency(
                            !merchantWallet.tokens[0]
                              ? merchantWallet.balance.usd
                              : merchantWallet.tokens[0].balance.usd
                          )}
                        </div>
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
              <MultisendForm
                text={text}
                setText={setText}
                data={data}
                setData={setData}
                currency={currency}
                wallet={wallet}
                validation={validation}
                setValidation={setValidation}
                handleSubmit={handleSubmit}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <div className="multisend">
              <WithdrawModeToggler multisendMode={multisendMode} setMultisendMode={setMultisendMode} />
              <MultisendConfirm
                currency={currencies.find(currenciesItem => currenciesItem._id === currency)}
                setText={setText}
                data={data}
                setData={setData}
                multisendEstimate={multisendEstimate}
                wallet={merchantWallets.find(walletItem => walletItem._id === wallet)}
                setShowConfirm={setShowConfirm}
                multisendWithdraw={multisendWithdraw}
                merchantId={fixedMerchantId}
                handleEstimate={handleSubmit}
                multisendFetching={multisendFetching}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
  currenciesFetching: state.currencies.fetching,
  multisendEstimate: state.multisend.estimate.data,
  merchants: state.merchants.data,
  merchantsFetching: state.merchants.fetching,
  merchantWallets: state.merchantWallets.data,
  multisendFetching: state.multisend.withdraw.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantBalances: getMerchantBalances(dispatch),
  getMultisendSystemFee: getMultisendSystemFee(dispatch),
  multisendApprove: multisendApprove(dispatch),
  getMultisendMinerFee: getMultisendMinerFee(dispatch),
  multisendWithdraw: multisendWithdraw(dispatch),
  getMerchantWallets: getMerchantWallets(dispatch),
  merchantWalletsReset: () => dispatch(merchantWalletsReset()),
  multisendEstimateReset: () => dispatch(multisendEstimateReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Multisend);
