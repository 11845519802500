import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import AuthCode from 'react-auth-code-input';
import '../../layout/Modal.scss';
import './WalletDeleteModal.scss';
import styles from '../AuthForms/style.module.scss';
import { useTranslation } from 'react-i18next';
import { resetTwoFaState } from '../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../redux/actions/notifications';

const WalletDeleteModal = ({
  merchantId,
  walletId,
  walletAddress,
  deleteMerchantWallet,
  resetTwoFaState,
  notificationsErrorShow,
  isFetching,
  isAppTwoFa,
  isEmailTwoFa,
  typeWallet,
  page,
  pages,
  countItem,
  walletsFilters,
  networkFilter,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  // const [appTwoFaCode, setAppTwoFaCode] = useState('');
  // const [emailTwoFaCode, setEmailTwoFaCode] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleOk = values => {
    const { appTwoFaCode, emailTwoFaCode } = values;
    if (isAppTwoFa && !appTwoFaCode) {
      notificationsErrorShow({ message: t('validation.twoFaRequired') });
      return;
    }

    if (isEmailTwoFa && !emailTwoFaCode) {
      notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
      return;
    }

    deleteMerchantWallet(
      merchantId,
      walletId,
      typeWallet,
      page,
      pages,
      countItem,
      walletsFilters,
      appTwoFaCode,
      emailTwoFaCode,
      networkFilter
    ).then(response => response && setIsVisible(false));
  };

  const handleCancel = e => {
    setIsVisible(false);
  };

  return (
    <React.Fragment>
      <a
        onClick={() => {
          setIsVisible(true);
          deleteMerchantWallet(merchantId, walletId, typeWallet, page, pages, countItem, walletsFilters, networkFilter);
        }}
      >
        {t('delete')}
      </a>
      <Modal
        title={t('merchants.createMerchant')}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete-merc"
        afterClose={() => {
          // setAppTwoFaCode('');
          // setEmailTwoFaCode('');
          form.resetFields();
          resetTwoFaState();
        }}
      >
        <Row>
          <Col span="24">
            <p className="modal__text">
              {t('wallets.deleteWalletConfirm')} <b>{walletAddress}</b>?
            </p>
          </Col>
        </Row>
        <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="basic" onFinish={handleOk}>
          <>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromEmail')}
              name="emailTwoFaCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={true}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
                // onChange={setEmailTwoFaCode}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromGA')}
              name="appTwoFaCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={false}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
                // onChange={setAppTwoFaCode}
              />
            </Form.Item>
          </>
        </Form>
        <Row className="modal__buttons-wrapper modal-delete-merc__buttons-wrapper">
          <Col span="12">
            <Button
              type="primary"
              className="modal__button modal__button-create"
              onClick={() => form.submit()}
              loading={isFetching}
            >
              {t('delete')}
            </Button>
          </Col>
          <Col span="12">
            <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchantWallets.modalFetching,
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  resetTwoFaState: resetTwoFaState(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletDeleteModal);
