import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import AuthCode from 'react-auth-code-input';

import '../../layout/Modal.scss';
import './MerchantDeleteModal.scss';
import styles from '../AuthForms/style.module.scss';
import trashIcon from '../../img/trash.svg';
import { useTranslation } from 'react-i18next';
import { deleteMerchant, merchantsResetTwoFa } from '../../redux/actions/merchants';
import { notificationsErrorShow } from '../../redux/actions/notifications';

const MerchantDeleteModal = ({
  merchantId,
  merchantName,
  isLastMerchant,
  deleteMerchant,
  notificationsErrorShow,
  isFetching,
  isAppTwoFa,
  isEmailTwoFa,
  merchantsResetTwoFa,
  showDelete,
  setShowDelete,
}) => {
  // const [appTwoFaCode, setAppTwoFaCode] = useState('');
  // const [emailTwoFaCode, setEmailTwoFaCode] = useState('');
  const [merchantDeleteForm] = Form.useForm();
  const { t } = useTranslation();

  const handleOk = values => {
    const { appTwoFaCode, emailTwoFaCode } = values;

    console.log('ok');
    if (isAppTwoFa && !appTwoFaCode) {
      notificationsErrorShow({ message: t('validation.required') });
      return;
    }

    if (isEmailTwoFa && !emailTwoFaCode) {
      notificationsErrorShow({ message: t('validation.required') });
      return;
    }

    deleteMerchant(merchantId, appTwoFaCode, emailTwoFaCode).then(response => {
      if (response) {
        setShowDelete(false);
      }
    });
  };

  const handleCancel = e => {
    console.log('close');
    setShowDelete(false);
    merchantDeleteForm.resetFields();
    // setAppTwoFaCode('');
    // setEmailTwoFaCode('');
    merchantsResetTwoFa();
  };
  return (
    <React.Fragment>
      <Modal
        title={t('merchants.createMerchant')}
        visible={showDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete-merc"
      >
        <Row>
          <Col span="24">
            <p className="modal__text">
              {t('merchants.deleteMerchantConfirm')} <b>{merchantName}</b>?
            </p>
          </Col>
        </Row>
        <Form
          form={merchantDeleteForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleOk}
        >
          <>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromEmail')}
              name="emailTwoFaCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={true}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromGA')}
              name="appTwoFaCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={false}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
          </>
        </Form>
        <Row className="modal__buttons-wrapper modal-delete-merc__buttons-wrapper">
          <Col span="12">
            <Button
              type="primary"
              className="modal__button modal__button-create"
              onClick={() => merchantDeleteForm.submit()}
              loading={isFetching}
            >
              {t('delete')}
            </Button>
          </Col>
          <Col span="12">
            <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isFetching: state.merchants.fetching,
  isAppTwoFa: state.merchants.isAppTwoFa,
  isEmailTwoFa: state.merchants.isEmailTwoFa,
});

const mapDispatchToProps = dispatch => ({
  deleteMerchant: deleteMerchant(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  merchantsResetTwoFa: () => dispatch(merchantsResetTwoFa()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDeleteModal);
