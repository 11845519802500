export const transactionsErrorTranslations = (t, text) => {
  
  if (text.includes('execution reverted')) {
    return t('homePage.transactions.backErrors.transBackEndErrorReverted', { currency: text.split(' ')[4] });
  }

  if (text.includes("merchant") && text.includes("wallet")) {
    const arrayFromText = text.split(' ');
    return t('homePage.transactions.backErrors.transBackEndErrorMerchWallet', {
      number: arrayFromText[3],
      currency: arrayFromText[15],
      replenishAmount: arrayFromText[23],
      replenishAmountCurrency: arrayFromText[24],
    });
  }

  return text;
};
