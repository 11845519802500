import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Modal } from 'antd';
import cn from 'classnames';
import TokenIcon from '../../../../components/TokenIcon';
import formatCurrency from '../../../../utils/currencyFormatter';
import network from '../../../../utils/transactionScans';
import { tokenNodeTypeObj } from '../../../../utils/tokenNodeTypes';

import './style.scss';

const Transaction = ({
  transaction,
  isFromTransactionsByUser,
  userSearchParams,
  networkFilter,
  userName,
  currencies,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showMultiAddressesModal, setShowMultiAddressesModal] = useState(false);
  const [transactionNodeType, setTransactionNodeType] = useState('');


  return (
    <>
      {transaction.info.multisend && (
        <Modal
          centered={true}
          onCancel={() => setShowMultiAddressesModal(false)}
          title="Multisend transactions:"
          wrapClassName="transaction__modal"
          width={400}
          visible={showMultiAddressesModal}
        >
          <div className="transaction__modal-wrap">
            {transaction.info.multisend.map(item => (
              <div className="transaction__modal-item">
                <div className="transaction__modal-label">Address:</div>
                <div className="transaction__modal-value">{item.address}</div>
                <div className="transaction__modal-label">Amount:</div>
                <div>
                  <span className="transaction__modal-value">{`${item.value} ${transaction.info.currency}`}</span>
                  {' - '}
                  <span className="transaction__modal-value">{formatCurrency(+item.usd)}</span>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
      <div className="fee-transaction">
        {!isFromTransactionsByUser ? (
          <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
            <Col span={1}>
              <div className="fee-transaction__logo">
                <TokenIcon tokenName={transaction.info.currency} />
              </div>
            </Col>
            <Col span={7}>
              <div className="fee-transaction__type">{transaction.type}</div>
            </Col>
            <Col span={7}>
              <div className="fee-transaction__date">{moment(transaction.createdAt).format('MMM D, YYYY, HH:mm;')}</div>
            </Col>
            <Col span={3}>
              <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}>
                {transaction.systemStatus}
              </div>
            </Col>
            <Col span={6}>
              <div className="fee-transaction__amount">
                <div className="fee-transaction__amount_tokens">
                {transaction.info.amount.value}{' '}
                      {transaction.info.amount.value || transaction.info.amount.value === 0
                        ? transaction.info.currency
                        : ''} {' '}
                        {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}

                        {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currencyType === 'token' && transaction.info.nodeType === 'optimism') ? transaction.info.currency === 'OP' ? null : 'OP' : null }

                        {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currency === 'ETH' && transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'optimism') ?  'OP' : null}

                        {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'arbitrum') ? transaction.info.currency === 'arbitrum' ? null : 'ARB' : null}

                        {(transaction.info.amount.value || transaction.info.amount.value === 0) && ((transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC' || transaction.info.currency === 'ETH') && transaction.info.currencyType === 'token' && transaction.info.nodeType === 'arbitrum') ? 'ARB' : null}

                        {(transaction.info.amount.value || transaction.info.amount.value === 0) && ((transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC') && transaction.info.currencyType === 'token' && transaction.info.nodeType === 'matic') ? 'MATIC' : null}
                </div>
                <div className="fee-transaction__amount_usd">{formatCurrency(+transaction.info.amount.usd)}</div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
            <Col span={5}>
              <div className="fee-transaction__profile">{userName}</div>
            </Col>
            <Col span={1}>
              <div className={cn('fee-transaction__logo', { 'user-transactions': isFromTransactionsByUser })}>
                <TokenIcon tokenName={transaction.info.currency} />
              </div>
            </Col>

            <Col span={4}>
              <div className="fee-transaction__type">{transaction.type}</div>
            </Col>
            <Col span={5}>
              <div className="fee-transaction__date">{moment(transaction.createdAt).format('MMM D, YYYY, HH:mm;')}</div>
            </Col>
            <Col span={2}>
              <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}>
                {transaction.systemStatus}
              </div>
            </Col>
            <Col span={7}>
              <div className="fee-transaction__amount">
                <div className="fee-transaction__amount_tokens">
                  {transaction.info.amount.value}{' '}
                  {transaction.info.amount.value || transaction.info.amount.value === 0 ? transaction.info.currency : ''}
             {' '}
                  {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}

                  {transaction.info.currencyType === 'token' && transaction.info.nodeType === 'optimism' ?
              transaction.info.currency === 'OP' ? null : 'OP' : null }

              {transaction.info.currency === 'ETH' && transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'optimism' ? 'OP' : null}

              {transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'arbitrum' ? transaction.info.currency === 'arbitrum' ? null : 'ARB' : null}

              {(transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC' || transaction.info.currency === 'ETH') && transaction.info.currencyType === 'token' && transaction.info.nodeType === 'arbitrum' ? 'ARB' : null}

              {(transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC') && transaction.info.currencyType === 'token' && transaction.info.nodeType === 'matic' ? 'MATIC' : null}
                </div>
                <div className="fee-transaction__amount_usd">{formatCurrency(+transaction.info.amount.usd)}</div>
              </div>
            </Col>
          </Row>
        )}

        {transaction.errorMessage && (
          <div className="fee-transaction__error-wrapper">
            <div className="fee-transaction__error-title">Transaction error</div>
            <div className="fee-transaction__error-description">{transaction.errorMessage}</div>
          </div>
        )}
        <div className={`transaction__details ${isOpened && 'transaction__details_opened'}`}>
          <Row>
            <Col>
              <div className="fee-transaction__details-id">
                <b>Payment ID</b>: {transaction._id}
              </div>
              <div className="fee-transaction__details-to">
                <b>To</b>:{' '}
                {transaction.info.multisend && transaction.info.multisend.length ? (
                  <span className="transaction__modal-toggle" onClick={() => setShowMultiAddressesModal(true)}>
                    Show multisend addresses
                  </span>
                ) : (
                  transaction.info.to
                )}
              </div>
              <div className="fee-transaction__details-from">
                <b>From</b>: {transaction.info.from}
              </div>
              <div className="fee-transaction__details-fee">
                <b>Miner Fee</b>: {transaction.info.minerFee.value} {transaction.info.minerFee.currency} ⸺{' '}
                {`${formatCurrency(+transaction.info.minerFee.usd)}`}
              </div>
              {transaction.info.systemFee && (
                <div className="fee-transaction__details-fee">
                  <b>System Fee</b>: {transaction.info.systemFee.amount} {transaction.info.systemFee.currency} ⸺{' '}
                  {`${formatCurrency(+transaction.info.systemFee.amountUSD)}`}
                </div>
              )}
              {transaction.checkout.charge && transaction.checkout.charge.outsideOrderId && (
                <div className="transaction__details-click-id">
                  <b>clickId</b>: <span>{transaction.checkout.charge.outsideOrderId}</span>
                </div>
              )}
              <div className="fee-transaction__details-hash">
                <>
                  <b>Hash</b>:{' '}
                  <a
                    target="_blank"
                    href={
                      transaction.type === 'InternalTransfer'
                        ? undefined
                        : `${network[networkFilter][transaction.info.nodeType]}${transaction.info.hashs[0]}`
                    }
                  >
                    {transaction.info.hashs[0]}
                  </a>
                </>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Transaction);

// export default Transaction;
