import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import formatCurrency from '../../../utils/currencyFormatter';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import refreshIcon from '../../../img/refresh.svg';
import './style.scss';

const TotalBalance = ({
  merchantBalances,
  setIsHideZeroBalances,
  balancesTotal,
  getMerchantBalances,
  merchantId,
  networkFilter,
  balancesFetching,
}) => {
  const [totalBalance, setTotalBalance] = useState(0);
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (merchantBalances && merchantBalances.length) {
  //     const sum = merchantBalances.reduce((sum, balance) => (sum += balance.balance.usd), 0);
  //     setTotalBalance(sum);

  //     if (sum <= 0) {
  //       setIsHideZeroBalances(false);
  //     } else {
  //       setIsHideZeroBalances(true);
  //     }
  //   }
  // }, [merchantBalances]);

  return (
    <div className="total-balance">
      <div className="total-balance__info">
        <div className="total-balance__info-wrapper">
          <span className="total-balance__title">{t('totalBalance')}</span>
          {balancesTotal && !balancesFetching ? (
            <button type="button" onClick={() => getMerchantBalances(merchantId, networkFilter, true)}>
              <img src={refreshIcon} alt="Refresh" />
            </button>
          ) : null}
        </div>
        {!balancesFetching ? (
          <>
            {balancesTotal ? (
              <>
                <span className="total-balance__balance">{balancesTotal?.btc || 0} BTC</span>
                <span className="total-balance__balance_usd">
                  ≈{balancesTotal?.usd ? formatCurrency(balancesTotal?.usd) : `$0.00`}
                </span>
              </>
            ) : null}
          </>
        ) : (
          <div className="total-balance__loader">
            <p>...{t('loadingData')}</p>
            <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 20 }} spin />} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalBalance;
