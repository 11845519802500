import defaultCoin from '../img/defaultCoin.svg';

const getTokenIcon = async name => {
  const src = name.toLowerCase();
  // const url = 'https://raw.githubusercontent.com/Sam-OConnor/tokens/main/assets/';
  // const extensions = ['png', 'jpg', 'jpeg'];
  // try {
  //   const resp = await Promise.all(extensions.map(el => fetch(`${url}${src}.${el}`)));
  //   const successItem = resp.find(el => el.status === 200);
  //   if (successItem) {
  //     return successItem.url;
  //   } else return defaultCoin;
  // } catch (err) {}

  try {
    const url = 'https://assets.coinpricelist.io/images/cryptocurrency-icons/svg/color/';
    const resp = await fetch(`${url}${src}.svg`);
    if(resp.status === 200) {
      return resp.url
    }
  }catch(err){
    console.log(err);
    return defaultCoin;
  }
};

export default getTokenIcon;
