import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sider from './Sider';
import useWindowSize from '../utils/resizeHook';
import cn from 'classnames';
import './LayoutWrapper.scss';
// import CpayTour from '../components/CpayTour';
import Footer from './Footer';

const LayoutWrapper = ({ children }) => {
  const { currentWidth } = useWindowSize();
  const [siderState, setSiderState] = useState(
    localStorage.getItem('sidebarMode') ? localStorage.getItem('sidebarMode') : false
  );

  useEffect(() => {
    if (currentWidth < 1360) {
      localStorage.setItem('sidebarMode', false);
      setSiderState(false);
    } else {
      localStorage.setItem('sidebarMode', true);
      setSiderState(true);
    }
  }, [currentWidth]);

  const toggleCollapseSider = () => {
    localStorage.setItem('sidebarMode', !siderState);
    setSiderState(!siderState);
  };
  return (
    <div className="main-layout">
      {/* {currentWidth >= 1024 && <CpayTour />} */}
      <Header toggleCollapseSider={toggleCollapseSider} siderState={siderState} />
      <div className="main-layout__container">
        {currentWidth < 1360 && (
          <div
            className={cn('main-layout__box-shadow', { ['main-layout__box-shadow-opened']: siderState })}
            onClick={toggleCollapseSider}
          />
        )}
        <Sider siderState={siderState} />
        <div className="main-layout__content-wrap">
          <div className="main-layout__content">
            {children}
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutWrapper;
