import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

//--GET CHECKOUT DATA
export const GET_CHECKOUT_DATA_REQUEST = 'GET_CHECKOUT_DATA_REQUEST';
export const GET_CHECKOUT_DATA_SUCCESS = 'GET_CHECKOUT_DATA_SUCCESS';
export const GET_CHECKOUT_DATA_FAIL = 'GET_CHECKOUT_DATA_FAIL';

const getCheckoutDataRequest = () => ({
  type: GET_CHECKOUT_DATA_REQUEST,
});

const getCheckoutDataSuccess = payload => ({
  type: GET_CHECKOUT_DATA_SUCCESS,
  payload,
});

const getCheckoutDataFail = payload => ({
  type: GET_CHECKOUT_DATA_FAIL,
  payload,
});

export const getCheckoutData = (identifier, recipient) => async dispatch => {
  dispatch(getCheckoutDataRequest());
  try {
    const response = await cryptoApi.getCheckoutData(identifier, recipient);
    dispatch(getCheckoutDataSuccess(response));
  } catch (error) {
    dispatch(getCheckoutDataFail(error));
    dispatch(notificationsErrorShow(error.data));
    throw error;
  }
};

//--CREATE CHARGE ID
export const CREATE_CHARGE_ID_REQUEST = 'CREATE_CHARGE_ID_REQUEST';
export const CREATE_CHARGE_ID_SUCCESS = 'CREATE_CHARGE_ID_SUCCESS';
export const CREATE_CHARGE_ID_RESET = 'CREATE_CHARGE_ID_RESET';
export const CREATE_CHARGE_ID_FAIL = 'CREATE_CHARGE_ID_FAIL';
export const SET_CHARGE_ID = 'SET_CHARGE_ID';

const createChargeIdRequest = () => ({
  type: CREATE_CHARGE_ID_REQUEST,
});

const createChargeIdSuccess = payload => ({
  type: CREATE_CHARGE_ID_SUCCESS,
  payload,
});

export const createChargeIdReset = () => ({
  type: CREATE_CHARGE_ID_RESET,
});

const createChargeIdFail = payload => ({
  type: CREATE_CHARGE_ID_FAIL,
  payload,
});

const setChargeIdFromStorage = payload => ({ type: SET_CHARGE_ID, payload });

export const setChargeId = id => dispatch => {
  dispatch(setChargeIdFromStorage(id));
};
export const createChargeId = (identifier, clickId) => async dispatch => {
  dispatch(createChargeIdRequest());
  try {
    const response = await cryptoApi.createChargeId(identifier, clickId);
    dispatch(createChargeIdSuccess(response));
    localStorage.setItem(`${identifier}`, response.data.id);
  } catch (error) {
    dispatch(createChargeIdFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--CREATE SALE TOKEN CHARGE ID
export const CREATE_SALE_TOKEN_CHARGE_ID_REQUEST = 'CREATE_SALE_TOKEN_CHARGE_ID_REQUEST';
export const CREATE_SALE_TOKEN_CHARGE_ID_SUCCESS = 'CREATE_SALE_TOKEN_CHARGE_ID_SUCCESS';
export const CREATE_SALE_TOKEN_CHARGE_ID_RESET = 'CREATE_SALE_TOKEN_CHARGE_ID_RESET';
export const CREATE_SALE_TOKEN_CHARGE_ID_FAIL = 'CREATE_SALE_TOKEN_CHARGE_ID_FAIL';
export const SET_SALE_TOKEN_CHARGE_ID = 'SET_SALE_TOKEN_CHARGE_ID';

const createSaleTokenChargeIdRequest = () => ({
  type: CREATE_SALE_TOKEN_CHARGE_ID_REQUEST,
});

const createSaleTokenChargeIdSuccess = payload => ({
  type: CREATE_SALE_TOKEN_CHARGE_ID_SUCCESS,
  payload,
});

export const createSaleTokenChargeIdReset = () => ({
  type: CREATE_SALE_TOKEN_CHARGE_ID_RESET,
});

const createSaleTokenChargeIdFail = payload => ({
  type: CREATE_SALE_TOKEN_CHARGE_ID_FAIL,
  payload,
});

const setSaleTokenChargeIdFromStorage = payload => ({ type: SET_SALE_TOKEN_CHARGE_ID, payload });

export const setSaleTokenChargeId = id => dispatch => {
  dispatch(setSaleTokenChargeIdFromStorage(id));
};
export const createSaleTokenChargeId = (identifier, params) => async dispatch => {
  dispatch(createSaleTokenChargeIdRequest());
  try {
    const response = await cryptoApi.createSakeTokenChargeId(identifier, params);
    dispatch(createSaleTokenChargeIdSuccess(response));
    localStorage.setItem(`${identifier}`, response.data.id);
  } catch (error) {
    dispatch(createSaleTokenChargeIdFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--GET PAYMENT DATA
export const GET_CHARGE_DATA_REQUEST = 'GET_CHARGE_DATA_REQUEST';
export const GET_CHARGE_DATA_SUCCESS = 'GET_CHARGE_DATA_SUCCESS';
export const GET_CHARGE_DATA_RESET = 'GET_CHARGE_DATA_RESET';
export const GET_CHARGE_DATA_FAIL = 'GET_CHARGE_DATA_FAIL';

const getChargeDataRequest = () => ({
  type: GET_CHARGE_DATA_REQUEST,
});

const getChargeDataSuccess = payload => ({
  type: GET_CHARGE_DATA_SUCCESS,
  payload,
});

export const getChargeDataReset = () => ({
  type: GET_CHARGE_DATA_RESET,
});

const getChargeDataFail = payload => ({
  type: GET_CHARGE_DATA_FAIL,
  payload,
});

export const getChargeData = (chargeId, params) => async dispatch => {
  dispatch(getChargeDataRequest());
  try {
    const response = await cryptoApi.getChargeData(chargeId, params);
    dispatch(getChargeDataSuccess(response));
    if (new Date(response.data.expiredDate) <= new Date()) {
      localStorage.removeItem('chId');
    }
  } catch (error) {
    dispatch(getChargeDataFail());
    dispatch(notificationsErrorShow(error.data));
  }
};

//--GET SALE TOKEN PAYMENT DATA
export const GET_SALE_TOKEN_CHARGE_DATA_REQUEST = 'GET_SALE_TOKEN_CHARGE_DATA_REQUEST';
export const GET_SALE_TOKEN_CHARGE_DATA_SUCCESS = 'GET_SALE_TOKEN_CHARGE_DATA_SUCCESS';
export const GET_SALE_TOKEN_CHARGE_DATA_RESET = 'GET_SALE_TOKEN_CHARGE_DATA_RESET';
export const GET_SALE_TOKEN_CHARGE_DATA_FAIL = 'GET_SALE_TOKEN_CHARGE_DATA_FAIL';

const getSaleTokenChargeDataRequest = () => ({
  type: GET_SALE_TOKEN_CHARGE_DATA_REQUEST,
});

const getSaleTokenChargeDataSuccess = payload => ({
  type: GET_SALE_TOKEN_CHARGE_DATA_SUCCESS,
  payload,
});

export const getSaleTokenChargeDataReset = () => ({
  type: GET_SALE_TOKEN_CHARGE_DATA_RESET,
});

const getSaleTokenChargeDataFail = payload => ({
  type: GET_SALE_TOKEN_CHARGE_DATA_FAIL,
  payload,
});

export const getSaleTokenChargeData = chargeId => async dispatch => {
  dispatch(getSaleTokenChargeDataRequest());
  try {
    const response = await cryptoApi.getSaleTokenChargeData(chargeId);
    dispatch(getSaleTokenChargeDataSuccess(response));
    if (new Date(response.data.expiredDate) <= new Date()) {
      localStorage.removeItem('chId');
    }
    localStorage.removeItem('isRedirected');

  } catch (error) {
    dispatch(getSaleTokenChargeDataFail());
    dispatch(notificationsErrorShow(error.data));
  }
};
