import qs from 'qs';
import fetchData from '../fetch';

class CryptoPayApi {
  async login(email, password, isAppTwoFa, emailTwoFaKey, recaptcha) {
    const response = await fetchData(`user/login`, {
      headers: {
        recaptcha,
      },
      body: {
        email,
        password,
        twoFactorToken: isAppTwoFa ? isAppTwoFa : undefined,
        emailOtp: emailTwoFaKey ? emailTwoFaKey : undefined,
      },
    });
    return response;
  };

  deleteAccount = async (emailTwoFaKey, twoFactorToken) => {
    const response = await fetchData('user/profile', {
      method: 'DELETE',
      body: {
        twoFactorToken: twoFactorToken,
        emailOtp: emailTwoFaKey
      }
    });
    return response;
  }

  loginWithSocials = async (accessToken, network, role) => {
    const response = await fetchData('user/social', {
      body: {
        accessToken,
        network: network,
        roles: [role],
      },
    });

    return response;
  };

  loginWithMetamask = async (address, signature, roles) => {
    const response = await fetchData('user/metamask', {
      body: {
        address,
        signature,
        roles,
      },
    });

    return response;
  };

  loginByPhone = async (phone, password, verifyCode, recaptcha) => {
    const response = await fetchData('user/login-by-phone', {
      headers: {
        recaptcha,
      },
      body: {
        phone,
        password,
        verifyCode,
      },
    });
    return response;
  };

  logout = async () => {
    const response = await fetchData(`user/logout`, {
      method: 'POST',
    });
    return response;
  };

  signUpByPhone = async (body, recaptcha) => {
    const response = await fetchData('user/register-by-phone', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  getTransactions = async (merchantId, params) => {
    const response = await fetchData(`transaction/${merchantId}/list?${qs.stringify(params)}`);
    return response;
  };

  getMainWallets = async merchantId => {
    const response = await fetchData(`wallet/${merchantId}/main`);
    return response;
  };

  getMerchantWallets = async (merchantId, params, positiveBalance) => {
    const response = await fetchData(`wallet/${merchantId}?${qs.stringify({ ...params, positiveBalance})}`);
    return response;
  };

  getUser = async () => {
    const response = await fetchData(`user/profile`);
    return response;
  };

  editUser = async body => {
    const response = await fetchData(`user/profile`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  changePassword = async (password, repeatPassword, emailOtp, twoFactorToken) => {
    const response = await fetchData('user/profile/change-password', {
      body: {
        password,
        repeatPassword,
        emailOtp: emailOtp ? emailOtp : undefined,
        twoFactorToken: twoFactorToken ? twoFactorToken : undefined
      },
    });
    return response;
  };

  signUp = async (email, password, emailTwoFaKey, inviterId, recaptcha) => {
    const response = await fetchData('user/register', {
      headers: {
        recaptcha,
      },
      body: {
        email,
        password,
        inviterId,
        emailOtp: emailTwoFaKey ? emailTwoFaKey : undefined,
        roles: ['corporate']
      },
    });
    return response;
  };

  sendPassword = async (body, recaptcha) => {
    const response = await fetchData('user/forgotten', {
      headers: {
        recaptcha,
      },
      body,
    });
    return response;
  };

  forgottenPassword = async (token, body) => {
    const response = await fetchData(`user/forgotten/${token}`, {
      body,
    });
    return response;
  };

  resetPassword = async body => {
    const response = await fetchData('user/restore-from-backup', {
      body,
    });
    return response;
  };

  resendPassword = async () => {
    const response = await fetchData('user/email/resend', {
      method: 'POST',
    });
    return response;
  };

  verifyEmail = async secretKey => {
    const response = await fetchData(`user/email/verify/${secretKey}`, {
      method: 'PATCH',
    });
    return response;
  };

  emailChangeVerify = async secretKey => {
    const response = await fetchData(`user/changeEmail/verify/${secretKey}`, {
      method: 'POST',
    });
    return response;
  };

  confirmBackup = async words => {
    const response = await fetchData('user/confirm-backup', {
      body: { words },
    });
    return response;
  };

  getMerchantsList = async () => {
    const response = await fetchData('merchant/list?limit=20');
    return response;
  };

  getMerchantData = async merchantId => {
    const response = await fetchData(`merchant/${merchantId}`);
    return response;
  };

  getMerchantBalances = async (merchantId, networkFilter, positiveBalance) => {
    const response = await fetchData(
      `merchant/${merchantId}/balance?${qs.stringify({ typeNetwork: networkFilter, positiveBalance })}`
    );
    return response;
  };

  createMerchant = async body => {
    const response = await fetchData('merchant', {
      body,
    });
    return response;
  };

  editMerchantData = async (merchantId, body) => {
    const response = await fetchData(`merchant/${merchantId}`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  changeMerchantNetwork = async (merchantId, body) => {
    const response = await fetchData(`merchant/${merchantId}/network`, {
      method: 'PATCH',
      body,
    });
    return response;
  };

  deleteMerchant = async (merchantId, appTwoFaCode, emailTwoFaCode) => {
    const response = await fetchData(`merchant/${merchantId}`, {
      method: 'DELETE',
      body: {
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
      },
    });
    return response;
  };

  getMerchantApiKeys = async merchantId => {
    const response = await fetchData(`merchant/${merchantId}/api-key`, {
      method: 'POST',
    });
    return response;
  };

  createMerchantWallet = async (merchantId, walletData) => {
    const response = await fetchData(`wallet/${merchantId}/create-wallet`, {
      body: walletData,
    });
    return response;
  };

  getWalletPrivateKey = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode) => {
    const response = await fetchData(`wallet/private-key/${merchantId}/${walletId}`, {
      body: {
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
      },
    });
    return response;
  };

  deleteMerchantWallet = async (merchantId, walletId, appTwoFaCode, emailTwoFaCode) => {
    const response = await fetchData(`wallet/${merchantId}/${walletId}`, {
      method: 'DELETE',
      body: {
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
      },
    });
    return response;
  };

  setWalletAsMain = async (merchantId, walletId, currencyId) => {
    const response = await fetchData(`wallet/${merchantId}/${walletId}`, {
      method: 'PATCH',
      body: {
        currencyId,
        main: true,
      },
    });
    return response;
  };

  makeWithdraw = async (from, to, amount, merchantId, currencyToken, appTwoFaCode, emailTwoFaCode) => {
    const response = await fetchData(`withdrawal/${merchantId}/${from}`, {
      method: 'POST',
      body: {
        to,
        amount,
        currencyToken,
        twoFactorToken: appTwoFaCode ? appTwoFaCode : undefined,
        emailOtp: emailTwoFaCode ? emailTwoFaCode : undefined,
      },
    });
    return response;
  };

  withdrawalEstimateMax = async (merchantId, walletId, data) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/estimateMax`, {
      method: 'POST',
      body: data,
    });
    return response;
  };

  getCurrencies = async merchantId => {
    const response = await fetchData(`currency?${qs.stringify({ merchantId })}`);
    return response;
  };

  getCurrenciesRate = async () => {
    const response = await fetchData('currency/rate');
    return response;
  };

  getFeeEstimate = async (merchantId, walletId, to, amount, currencyToken) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/estimate`, {
      method: 'POST',
      body: {
        to,
        amount,
        currencyToken,
      },
    });
    return response;
  };

  //---CHECKOUTS

  getCheckoutsList = async (merchantId, params) => {
    const response = await fetchData(`checkout/${merchantId}?${qs.stringify(params)}`);
    return response;
  };

  createCheckoutDonation = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/donation`, {
      method: 'POST',
      body: body,
      isFormData: true,
    });
    return response;
  };

  createCheckoutSale = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/sale`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  createCheckoutSellCurrency = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/saleToken`, {
      method: 'POST',
      body,
      isFormData: true,
    });
    return response;
  };

  deleteCheckout = async (merchantId, checkoutId) => {
    const response = await fetchData(`checkout/${merchantId}/${checkoutId}`, {
      method: 'DELETE',
    });
    return response;
  };

  patchCheckout = async data => {
    const response = await fetchData(`checkout/${data.merchantId}/${data.checkoutId}/${data.type}`, {
      method: 'PATCH',
      body: data.body,
      isFormData: true,
    });
    return response;
  };

  getEstimateMax = async (merchantId, body) => {
    const response = await fetchData(`checkout/${merchantId}/saleToken/estimateMax`, {
      body: body,
    });
    return response;
  };

  //---CHECKOUTS PAYMENTS

  getCheckoutData = async (identifier, recipient) => {
    const response = await fetchData(`checkout-client/${identifier}${recipient ? `?recipient=${recipient}` : ''}`);
    return response;
  };

  createChargeId = async (identifier, clickId) => {
    const response = await fetchData(`checkout-client/${identifier}/charge${clickId ? `?clickId=${clickId}` : ''}`, {
      method: 'POST',
    });
    return response;
  };

  createSakeTokenChargeId = async (identifier, params) => {
    const response = await fetchData(`checkout-client/${identifier}/saleTokenCharge?${qs.stringify(params)}`, {
      method: 'POST',
    });
    return response;
  };

  getChargeData = async (chargeId, params) => {
    const response = await fetchData(`checkout-client/${chargeId}/charge?${qs.stringify(params)}`);
    return response;
  };

  getSaleTokenChargeData = async chargeId => {
    const response = await fetchData(`checkout-client/${chargeId}/saleTokenCharge`);
    return response;
  };

  saveCustomerDetails = async (chargeId, body) => {
    const response = await fetchData(`checkout-client/${chargeId}/customerDetails`, {
      body,
    });
    return response;
  };

  getSwapHistory = async (merchantId, body) => {
    const response = await fetchData(`swap/${merchantId}/history?${qs.stringify(body)}`);
    return response;
  };

  getSwapOffers = async body => {
    const response = await fetchData(`swap/estimate?${qs.stringify(body)}`);
    return response;
  };

  makeSwap = async body => {
    const response = await fetchData(`swap`, { body });
    return response;
  };

  getMultisendSystemFee = async (merchantId, walletId, body) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/estimateSystemFee`, { body });
    return response;
  };
  multisendApprove = async (merchantId, walletId, estimationId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/approve`, {
      body: {
        estimationId,
      },
    });
    return response;
  };
  getMultisendMinerFee = async (merchantId, walletId, estimationId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend/estimateMinerFee`, {
      body: {
        estimationId,
      },
    });
    return response;
  };
  multisendWithdraw = async (merchantId, walletId, estimationId, appTwoFa, emailTwoFa) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/multisend`, {
      body: {
        estimationId,
        twoFactorToken: appTwoFa,
        emailOtp: emailTwoFa,
      },
    });
    return response;
  };
  getWithdrawalInfo = async (merchantId, walletId) => {
    const response = await fetchData(`withdrawal/${merchantId}/${walletId}/withdrawal-info`,)
    return response;
  }
  installMetamask = async (address, signature) => {
    const response = await fetchData(`user/installMetamask`, {
      body: {
        address,
        signature,
      },
    });
    return response;
  };
  removeMetamask = async (address, signature) => {
    const response = await fetchData(`user/removeMetamask`, {
      body: {
        address,
        signature,
      },
    });
    return response;
  };
  orderCard = async (body) => {
    const response = await fetchData('user/residence', {
      method: 'POST',
      body
    });
    return response;
  }
}

export const cryptoApi = new CryptoPayApi();
