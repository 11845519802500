import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
export const MERCHANT_DATA_GET = 'MERCHANT_DATA_GET';
export const MERCHANT_DATA_SET = 'MERCHANT_DATA_SET';
export const MERCHANT_DATA_EDIT = 'MERCHANT_DATA_EDIT';
export const MERCHANT_BALANCES_GET = 'MERCHANT_BALANCES_GET';
export const MERCHANT_BALANCES_SET = 'MERCHANT_BALANCES_SET';
export const MERCHANT_DATA_ERROR = 'MERCHANT_DATA_ERROR';
export const GET_MERCHANT_API_KEYS_REQUEST = 'GET_MERCHANT_API_KEYS_REQUEST';
export const GET_MERCHANT_API_KEYS_SUCCESS = 'GET_MERCHANT_API_KEYS_SUCCESS';
export const GET_MERCHANT_API_KEYS_FAIL = 'GET_MERCHANT_API_KEYS_FAIL';

// ACTIONS GENERATORS
export const merchantDataGet = () => ({
  type: MERCHANT_DATA_GET,
  fetching: true,
});

export const merchantDataSet = data => ({
  type: MERCHANT_DATA_SET,
  fetching: false,
  payload: data,
});

export const merchantDataEdit = () => ({
  type: MERCHANT_DATA_EDIT,
  fetching: true,
});

export const merchantBalancesGet = () => ({
  type: MERCHANT_BALANCES_GET,
  fetching: true,
});

export const merchantBalancesSet = data => ({
  type: MERCHANT_BALANCES_SET,
  fetching: false,
  payload: data,
});

export const merchantDataError = () => ({
  type: MERCHANT_DATA_ERROR,
});

export const getMerchantApiKeysRequest = () => ({
  type: GET_MERCHANT_API_KEYS_REQUEST,
  fetching: true,
});

export const getMerchantApiKeysSuccess = data => ({
  type: GET_MERCHANT_API_KEYS_SUCCESS,
  payload: data,
  fetching: false,
});

export const getMerchantApiKeysFail = () => ({
  type: GET_MERCHANT_API_KEYS_FAIL,
});

// ASYNC ACTIONS
export const getMerchantData = dispatch => async merchantId => {
  dispatch(merchantDataGet());
  let merchantData;

  try {
    merchantData = await cryptoApi.getMerchantData(merchantId);
  } catch (err) {
    dispatch(merchantDataError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (merchantData && merchantData.data) {
    dispatch(merchantDataSet({ ...merchantData.data }));
    return;
  }

  dispatch(merchantDataError());
};

export const editMerchantData = dispatch => async (merchantId, body) => {
  dispatch(merchantDataEdit());
  let merchantData;

  try {
    merchantData = await cryptoApi.editMerchantData(merchantId, body);
  } catch (err) {
    dispatch(merchantDataError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (merchantData && merchantData.data) {
    dispatch(merchantDataSet({ ...merchantData.data }));
    dispatch(notificationsSuccessShow({}));
    return;
  }

  dispatch(merchantDataError());
};

export const getMerchantBalances =
  dispatch =>
  async (merchantId, networkFilter, positiveBalance = false) => {
    dispatch(merchantBalancesGet());
    let merchantBalances;

    try {
      merchantBalances = await cryptoApi.getMerchantBalances(merchantId, networkFilter, positiveBalance);
    } catch (err) {
      dispatch(merchantDataError());
      dispatch(notificationsErrorShow(err.data));
    }

    if (merchantBalances && merchantBalances.data) {
      dispatch(merchantBalancesSet(merchantBalances.data));
      return;
    }

    dispatch(merchantDataError());
  };

export const getMerchantApiKeys = dispatch => async merchantId => {
  dispatch(getMerchantApiKeysRequest());
  let response;

  try {
    response = await cryptoApi.getMerchantApiKeys(merchantId);
  } catch (err) {
    dispatch(getMerchantApiKeysFail());
    dispatch(notificationsErrorShow(err.data));
  }

  if (response && response.data) {
    dispatch(getMerchantApiKeysSuccess(response.data));
  }

  dispatch(getMerchantApiKeysFail());
};
