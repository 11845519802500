import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Select } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { getMerchantData, editMerchantData, getMerchantApiKeys } from '../../redux/actions/merchantData';
import useWindowSize from '../../utils/resizeHook';

import './MerchantDataForm.scss';

const MerchantDataForm = ({
  userRole,
  merchantData,
  getMerchantData,
  editMerchantData,
  getMerchantApiKeys,
  merchantApiKeys,
  isFetching,
}) => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantCompanyName, setMerchantCompanyName] = useState('');
  const [merchantCompanyUrl, setMerchantCompanyUrl] = useState('');
  const [merchantCompanyEmail, setMerchantCompanyEmail] = useState('');
  const [merchantNotificationEmail, setMerchantNotificationEmail] = useState('');
  const [merchantNotificationUrl, setMerchantNotificationUrl] = useState('');
  const [merchantCountry, setMerchantCountry] = useState('');
  const [merchantCity, setMerchantCity] = useState('');
  const [merchantAddress, setMerchantAddress] = useState('');
  const [merchantNetwork, setMerchantNetwork] = useState('');
  const [isFetchingLocal, setIsFetchingLocal] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [form] = Form.useForm();
  const { merchantId } = useParams();
  const { t , i18n} = useTranslation();
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    getMerchantData(merchantId);
  }, []);

  useEffect(() => {
    if (merchantApiKeys.publicKey && merchantApiKeys.privateKey) {
      setPublicKey(merchantApiKeys.publicKey);
      setPrivateKey(merchantApiKeys.privateKey);
    }
  }, [merchantApiKeys]);

  useEffect(() => {
    setMerchantName(merchantData.name);
    setMerchantCompanyName(merchantData.companyName);
    setMerchantCompanyUrl(merchantData.companyUrl);
    setMerchantCompanyEmail(merchantData.companyEmail);
    setMerchantNotificationEmail(merchantData.notificationEmail);
    setMerchantNotificationUrl(merchantData.notificationUrl);
    setMerchantCountry(merchantData.country);
    setMerchantCity(merchantData.city);
    setMerchantAddress(merchantData.address);
    setMerchantNetwork(merchantData.typeNetwork);

    form.resetFields();
  }, [merchantData]);

  const handleFormSubmit = () => {
    setIsFetchingLocal(true);
    const { status, fee, middleWallet } = merchantData;

    const formData = {
      name: merchantName,
      companyName: merchantCompanyName,
      companyUrl: merchantCompanyUrl,
      companyEmail: merchantCompanyEmail,
      notificationEmail: merchantNotificationEmail,
      notificationUrl: merchantNotificationUrl,
      country: merchantCountry,
      city: merchantCity,
      address: merchantAddress,
      typeNetwork: merchantNetwork,
      status,
      fee,
      middleWallet,
    };

    editMerchantData(merchantId, formData).then(() => setIsFetchingLocal(false));
  };

  return (
    <div className="component merchant-data">
      <div className="component__title">{t('merchants.merchantData')}</div>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        className="form merchant-data-form"
        initialValues={{
          merchantName: merchantData.name,
          merchantCompanyName: merchantData.companyName,
          merchantCompanyUrl: merchantData.companyUrl,
          merchantCompanyEmail: merchantData.companyEmail,
          merchantNotificationEmail: merchantData.notificationEmail,
          merchantNotificationUrl: merchantData.notificationUrl,
          merchantCountry: merchantData.country,
          merchantCity: merchantData.city,
          merchantAddress: merchantData.address,
          merchantNetwork: merchantData.typeNetwork,
        }}
      >
        <Row>
          <Col span={currentWidth >= 1024 ? 18 : 24}>
            {userRole === 'corporate' && (
              <>
                <span className="form__title">API</span>
                <Row gutter={[20, 0]}>
                  {merchantApiKeys.publicKey && merchantApiKeys.privateKey ? (
                    <Col span="24">
                      <div className="merchant-data-form__api-key">
                        <b>{t('publicKey')}:</b> {publicKey}
                      </div>
                      <div className="merchant-data-form__api-key">
                        <b>{t('privateKey')}:</b> {privateKey}
                      </div>
                    </Col>
                  ) : (
                    <Col span={currentWidth >= 1024 ? 7 : 24}>
                      <Button type="primary" className={cn("form__button",{'es': i18n.language === 'es'})} onClick={() => getMerchantApiKeys(merchantId)}>
                        {t('merchants.newApiKeys')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            )}

            <span className="form__title">{t('merchants.commonInformation')}</span>
            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  label={t('merchants.merchantName')}
                  name="merchantName"
                  className="form__item merchant-data-form__name"
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.merchantName')}
                    onChange={e => setMerchantName(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantNetwork"
                  label={t('merchants.merchantsNetwork')}
                  className="form__item"
                  // rules={[{ required: true, message: 'Please input merchant name!' }]}
                >
                  <Select onChange={value => setMerchantNetwork(value)}>
                    <Select.Option value="testnet">{t('testnet')}</Select.Option>
                    <Select.Option value="mainnet">{t('mainnet')}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  label={t('merchants.companyWebsite')}
                  name="merchantCompanyUrl"
                  className="form__item merchant-data-form__website"
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.companyWebsite')}
                    onChange={e => setMerchantCompanyUrl(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  label={t('merchants.companyName')}
                  name="merchantCompanyName"
                  className="form__item merchant-data-form__website"
                >
                  <Input
                    className="form__input"
                    placeholder={t('merchants.companyName')}
                    onChange={e => setMerchantCompanyName(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item label={t('country')} name="merchantCountry" className="form__item merchant-data-form__name">
                  <Input
                    className="form__input"
                    placeholder={t('country')}
                    onChange={e => setMerchantCountry(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item label={t('city')} name="merchantCity" className="form__item merchant-data-form__website">
                  <Input
                    className="form__input"
                    placeholder={t('city')}
                    onChange={e => setMerchantCity(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span="24">
                <Form.Item label={t('address')} name="merchantAddress" className="form__item merchant-data-form__name">
                  <Input
                    className="form__input"
                    placeholder={t('address')}
                    onChange={e => setMerchantAddress(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantCompanyEmail"
                  label={t('merchants.publicEmailForMerchantsClients')}
                  rules={[{ type: 'email' }]}
                  className="form__item merchant-data-form__public-email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('email')}
                    onChange={e => setMerchantCompanyEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantNotificationEmail"
                  label={t('merchants.emailForNotifications')}
                  rules={[{ type: 'email' }]}
                  className="form__item merchant-data-form__email"
                >
                  <Input
                    className="form__input"
                    placeholder={t('email')}
                    onChange={e => setMerchantNotificationEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 0]}>
              <Col span={currentWidth >= 1024 ? 12 : 24}>
                <Form.Item
                  name="merchantNotificationUrl"
                  label={t("merchants.callbackURL")}
                  className="form__item merchant-data-form__public-email"
                >
                  <Input
                    className="form__input"
                    placeholder={t("merchants.callbackURL")}
                    onChange={e => setMerchantNotificationUrl(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={currentWidth >= 1024 ? 5 : 24}>
            <Button
              type="primary"
              className="form__button"
              onClick={handleFormSubmit}
              loading={isFetchingLocal && isFetching}
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  userRole: state.user.data.role,
  merchantData: state.merchantData.data,
  merchantApiKeys: state.merchantData.apiKeys,
  isFetching: state.merchantData.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantData: getMerchantData(dispatch),
  editMerchantData: editMerchantData(dispatch),
  getMerchantApiKeys: getMerchantApiKeys(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDataForm);
