import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import SwapFilters from './SwapFilters';
import SwapItem from './SwapItem';
import SwapItemMobile from './SwapItemMobile';
import SwapDetailsMobile from './SwapDetailsMobile';
import SwapEmpty from './SwapEmpty';
import { LoadingOutlined } from '@ant-design/icons';
import { getSwapHistory } from '../../redux/actions/swap';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../utils/resizeHook';
import './style.scss';

const defaultHistoryFilters = {
  search: '',
  currencyFromId: '',
  currencyToId: '',
  order: 'DESC',
  limit: 30,
};

const SwapHistory = ({
  swapHistoryData,
  merchantId,
  getSwapHistory,
  swapHistoryFetching,
  currencies,
  networkFilter,
}) => {
  const [historyFilters, setHistoryFilters] = useState(defaultHistoryFilters);
  const [transactionDetailsData, setTransactionDetailsData] = useState(null);
  const [resetFilters, setResetFilters] = useState(null);
  const { page, pages, countItems, entities } = swapHistoryData || {};
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    merchantId &&
      getSwapHistory(merchantId, {
        ...historyFilters,
        currencyFromId: historyFilters.currencyFromId || undefined,
        currencyToId: historyFilters.currencyToId || undefined,
      });
  }, [merchantId, historyFilters]);

  return (
    <div className="block-for-component">
      <div className="swap-history">
        <div className="swap-history__header">
          <div className="swap-history__title">{t('swap.title')}</div>
          <Link className="swap-history__new" to="/swap/create">
            {t('swap.new')}
          </Link>
        </div>

        {transactionDetailsData && (
          <SwapDetailsMobile
            transactionDetailsData={transactionDetailsData}
            setTransactionDetailsData={setTransactionDetailsData}
            currencies={currencies}
            networkFilter={networkFilter}
          />
        )}

        <SwapFilters
          currencies={currencies}
          historyFilters={historyFilters}
          setHistoryFilters={setHistoryFilters}
          defaultHistoryFilters={defaultHistoryFilters}
          setResetFilters={setResetFilters}
          countItems={countItems}
          swapHistoryFetching={swapHistoryFetching}
        />

        {swapHistoryFetching ? (
          <div className="swap-history__loader">
            <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
            <span>{t('swap.loading')}</span>
          </div>
        ) : entities && entities.length ? (
          <>
            <div className="swap-history__legend">
              <div className="swap-history__legend-exchangeId">{t('swap.exchangeId')}</div>
              <div className="swap-history__legend-date">{t('swap.date')}</div>
              <div className="swap-history__legend-currency">{t('currency')}</div>
              <div className="swap-history__legend-status">{t('status')}</div>
            </div>

            <div id="swap-scroll" className="swap-history__list">
              <div className="swap-history__list-scroller">
                <InfiniteScroll
                  dataLength={entities.length}
                  next={() =>
                    getSwapHistory(merchantId, {
                      ...historyFilters,
                      currencyFromId: historyFilters.currencyFromId || undefined,
                      currencyToId: historyFilters.currencyToId || undefined,
                      page: page + 1,
                    })
                  }
                  hasMore={entities.length < countItems && page < pages}
                  loader={
                    <div className="swap-history__loader">
                      <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
                      <span>{t('swap.loading')}</span>
                    </div>
                  }
                  scrollableTarget="swap-scroll"
                >
                  {entities.map(swapItem => {
                    return currentWidth > 590 ? (
                      <SwapItem swapItem={swapItem} currencies={currencies} networkFilter={networkFilter} />
                    ) : (
                      <SwapItemMobile
                        swapItem={swapItem}
                        setTransactionDetailsData={setTransactionDetailsData}
                        currencies={currencies}
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </>
        ) : (
          <SwapEmpty
            resetFilters={resetFilters}
            historyFilters={historyFilters}
            defaultHistoryFilters={defaultHistoryFilters}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  swapHistoryData: state.swap.history.data,
  swapHistoryFetching: state.swap.history.fetching,
  merchantId: state.transactions.merchantId,
  currencies: state.currencies.data,
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  getSwapHistory: getSwapHistory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapHistory);
