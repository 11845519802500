import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['not-found']}>
      <div className={styles['not-found__code']}>404</div>
      <div className={styles['not-found__title']}>{t('pageNotFound')}</div>
      <div className={styles['not-found__text']}>
        Please check that you have entered in the correct address, you could also return to the homepage and try again.
      </div>
      <Link to="/">
        <Button className={styles['not-found__button']}>{t('swap.backHome')}</Button>
      </Link>
    </div>
  );
};

export default PageNotFound;
