import {
  //--GET CHECKOUTS LIST
  GET_CHECKOUTS_LIST_REQUEST,
  GET_CHECKOUTS_LIST_SUCCESS,
  GET_CHECKOUTS_LIST_FAIL,
  //--CREATE CHECKOUT DONATION
  CREATE_CHECKOUT_DONATION_REQUEST,
  CREATE_CHECKOUT_DONATION_SUCCESS,
  CREATE_CHECKOUT_DONATION_FAIL,
  //--CREATE CHECKOUT SALE
  CREATE_CHECKOUT_SALE_REQUEST,
  CREATE_CHECKOUT_SALE_SUCCESS,
  CREATE_CHECKOUT_SALE_FAIL,
  //--CREATE CHECKOUT SELL CURRENCY
  CREATE_CHECKOUT_SELL_CURRENCY_REQUEST,
  CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS,
  CREATE_CHECKOUT_SELL_CURRENCY_FAIL,
  //--DELETE CHECKOUT
  DELETE_CHECKOUT_REQUEST,
  DELETE_CHECKOUT_SUCCESS,
  DELETE_CHECKOUT_FAIL,
  //--PATCH CHECKOUT
  PATCH_CHECKOUT_REQUEST,
  PATCH_CHECKOUT_SUCCESS,
  PATCH_CHECKOUT_FAIL,
  //--SET DETAILED CHECKOUT
  SET_DETAILED_CHECKOUT,
  SET_EDIT_CHECKOUT,
} from '../actions/checkouts';

const defaultState = {
  get: { data: null, fetching: false, page: 1, pages: null, countItem: null },
  create: { data: null, fetching: false },
  delete: { data: null, fetching: false },
  patch: { data: null, fetching: false },
  detailedCheckout: null,
  editCheckout: null,
  limit: 5,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CHECKOUTS_LIST_REQUEST:
      return {
        ...state,
        get: { ...state.get, fetching: true },
      };
    case GET_CHECKOUTS_LIST_SUCCESS:
      const { entities, page, pages, countItem } = action.payload.data;
      return {
        ...state,
        get: { ...state.get, data: entities, fetching: false, page, pages, countItem },
      };
    case GET_CHECKOUTS_LIST_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case CREATE_CHECKOUT_DONATION_REQUEST:
    case CREATE_CHECKOUT_SALE_REQUEST:
    case CREATE_CHECKOUT_SELL_CURRENCY_REQUEST:
      return {
        ...state,
        create: { ...state.create, fetching: true },
      };
    case CREATE_CHECKOUT_DONATION_SUCCESS:
    case CREATE_CHECKOUT_SALE_SUCCESS:
    case CREATE_CHECKOUT_SELL_CURRENCY_SUCCESS:
      return {
        ...state,
        create: { ...state.create, fetching: false },
        detailedCheckout: action.payload.data,
      };
    case CREATE_CHECKOUT_DONATION_FAIL:
    case CREATE_CHECKOUT_SALE_FAIL:
    case CREATE_CHECKOUT_SELL_CURRENCY_FAIL:
      return {
        ...state,
        create: { ...state.create, fetching: false },
      };
    case DELETE_CHECKOUT_REQUEST:
      return {
        ...state,
        // fetching: true,
      };
    case DELETE_CHECKOUT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_CHECKOUT_FAIL:
      return {
        ...state,
        // fetching: false,
      };
    case PATCH_CHECKOUT_REQUEST:
      return {
        ...state,
        patch: { ...state.patch, fetching: true },
      };
    case PATCH_CHECKOUT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          data: state.get.data.map(el => (el._id === action.payload.data._id ? action.payload.data : el)),
        },
        patch: { ...state.patch, fetching: false },
      };
    case PATCH_CHECKOUT_FAIL:
      return {
        ...state,
        patch: { ...state.patch, fetching: false },
      };
    case SET_DETAILED_CHECKOUT:
      return {
        ...state,
        detailedCheckout: action.payload,
      };
    case SET_EDIT_CHECKOUT:
      return {
        ...state,
        editCheckout: action.payload,
      };
    default:
      return state;
  }
}
