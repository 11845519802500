const networks = {
  testnet: {
    btc: 'https://live.blockcypher.com/btc-testnet/tx/',
    eth: 'https://sepolia.etherscan.io/tx/',
    bsc: 'https://testnet.bscscan.com/tx/',
    trx: 'https://shasta.tronscan.org/#/transaction/',
    dot: 'https://westend.subscan.io/extrinsic/',
    ksm: 'https://rococo.subscan.io/extrinsic/',
    matic: 'https://mumbai.polygonscan.com/tx/',
    optimism: 'https://goerli-optimism.etherscan.io/tx/',
    arbitrum:  'https://goerli.arbiscan.io/tx/'
  },
  mainnet: {
    btc: 'https://live.blockcypher.com/btc/tx/',
    eth: 'https://etherscan.io/tx/',
    bsc: 'https://bscscan.com/tx/',
    trx: 'https://tronscan.io/#/transaction/',
    dot: 'https://polkadot.subscan.io/extrinsic/',
    ksm: 'https://kusama.subscan.io/extrinsic/',
    matic: 'https://polygonscan.com/tx/',
    optimism: 'https://optimistic.etherscan.io/tx/',
    arbitrum: 'https://arbiscan.io/tx/'
  },
};

export default networks;
