import React from 'react';
import NumberFormat from 'react-number-format';
import TokenIcon from '../../../../components/TokenIcon';
import './styles.scss';

const TopFive = ({ title, drawData, prefix }) => {
  const { data, fetching } = drawData;
  return (
    <div className="dashboard-topfive">
      <h2 className="dashboard-topfive__title">{title}</h2>
      <div className="dashboard-topfive__list">
          {data &&
            data.map((el,index) => {
              const { symbol, currency, volume, count } = el;
              return (
                <div className="dashboard-topfive__list-item" key={volume ? symbol+volume+index : symbol+count+index}>
                  <TokenIcon tokenName={symbol} className="dashboard-topfive__list-item-img" />
                  <p className="dashboard-topfive__list-item-name">{currency}</p>
                  <NumberFormat
                    value={volume || count || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={prefix}
                    className="dashboard-topfive__list-item-volume"
                    decimalScale={2} 
                  />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default TopFive;
