import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RangePicker } from '../../../components/FormFields';
import { Form } from 'antd';
import { ButtonPrimary } from '../../../components/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getMarketingInfo } from '../../../../redux/actions/adminPanel/adminMarketingInfoDownload';

const DashboardHeader = ({ getMarketingInfo, marketingInfoFetching }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [error, setError] = useState(null);

  const handleFormSubmit = () => {
    !formValues || form?.getFieldsValue(true)?.dateForFiltering?.length < 2 || !formValues.dateForFiltering
      ? setError(true)
      : getMarketingInfo(form.getFieldsValue(true));

    setFormValues(null);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 2000);
    }
  }, [error]);

  return (
    <div className="admin-marketing-container">
      <Form
        form={form}
        name="admin-marketing-form"
        initialValues={{
          dateForFiltering: [],
        }}
        onFieldsChange={() => setFormValues(form.getFieldsValue(true))}
        className="admin-marketing-form"
      >
        <RangePicker
          name="dateForFiltering"
          placeholder="Choose date for filtering"
          submitAction={form.submit}
          formValues={formValues}
          withInitial={undefined}
        />

        <ButtonPrimary
          className="admin-users__add-user-button admin-users__add-user-button__download-emails"
          type="submit"
          htmlType="submit"
          onClick={handleFormSubmit}
        >
          {!marketingInfoFetching ? (
            'Download stats'
          ) : (
            <div className="admin-users__loader">
              <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 16 }} spin />} />
            </div>
          )}
        </ButtonPrimary>
      </Form>

      {error && (
        <div className="admin-marketing-form-error">
          <p>Please, insert the dates first</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  marketingInfoFetching: state.adminMarketingInfoDownload.fetching,
});

const mapDispatchToProps = {
  getMarketingInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
