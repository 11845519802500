import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { translateError, extractAmountFromErrorMsg } from './helpers';
import SwapNewCard from '../SwapNew/SwapNewCard';
import TokenIcon from '../TokenIcon';
import { makeSwap, resetExchangeError } from '../../redux/actions/swap';
import { Modal } from 'antd';
import Button from '../Button';
import { ReactComponent as ArrowRightIcon } from '../../img/arrow-right-blue.svg';
import { ReactComponent as SwapTickSuccessIcon } from '../../img/swap-tick-success.svg';
import { ReactComponent as SwapTickFailIcon } from '../../img/swap-tick-fail.svg';
import { ReactComponent as FloatingIcon } from '../../img/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../img/swap-fixed.svg';
import { ReactComponent as ChartIcon } from '../../img/chart-icon.svg';
import getSwapPartnerIcon from '../../utils/swapPartnerIcons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './style.scss';

const SwapConfirm = ({
  fromId,
  toId,
  currencyFromId,
  currencyToId,
  amount,
  summaryRate,
  setSummaryRate,
  partner,
  fixed,
  currencies,
  makeSwap,
  swapData,
  swapError,
  swapFetching,
  swapOffers,
  updatedOffers,
  swapOffersReset,
  selectedPartnerRateId,
  setSwapOffersType,
  setSelectedPartner,
  setSelectedPartnerRateId,
  setShowSwapOffers,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersUpdating,
  sort,
  type,
  handleErrorMsgClick,
  setSwapAmountFromError,
}) => {
  const [showUpdateRatesBanner, setShowUpdateRatesBanner] = useState(false);
  const [currencyFromName, setCurrencyFromName] = useState('');
  const [currencyToName, setCurrencyToName] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const history = useHistory();
  const offersUpdateTimer = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
  }, []);

  useEffect(() => () => offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current), []);

  useEffect(() => {
    if (showConfirmModal) {
      offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
    } else {
      setUpdateTimer();
    }
  }, [showConfirmModal]);

  useEffect(() => {
    if (currencies && currencies.length && currencyFromId && currencyToId) {
      const fromCurrency = currencies.find(currency => currency._id === currencyFromId);
      const toCurrency = currencies.find(currency => currency._id === currencyToId);
      setCurrencyFromName(fromCurrency);
      setCurrencyToName(toCurrency);
    }
  }, [currencies, currencyFromId, currencyToId]);

  useEffect(() => {
    if (updatedOffers && updatedOffers.length) {
      const currentOffer = swapOffers.find(offer => offer.partner === partner && offer.fixed === fixed);
      const updatedOffer = updatedOffers.find(offer => offer.partner === partner && offer.fixed === fixed);

      let hasDifference = false;

      const objA = {
        duration: updatedOffer.duration,
        fixed: updatedOffer.fixed,
        partner: updatedOffer.partner,
        toAmount: updatedOffer.toAmount,
      };

      const objB = {
        duration: currentOffer.duration,
        fixed: currentOffer.fixed,
        partner: currentOffer.partner,
        toAmount: currentOffer.toAmount,
      };

      if (
        objA.duration !== objB.duration ||
        objA.fixed !== objB.fixed ||
        objA.partner !== objB.partner ||
        objA.toAmount !== objB.toAmount
      ) {
        hasDifference = true;
      }

      if (hasDifference) setShowUpdateRatesBanner(true);
    }
  }, [updatedOffers]);

  useEffect(() => {
    swapOffers &&
      swapOffers.length &&
      setSummaryRate(swapOffers.find(offer => offer.partner === partner && offer.fixed === fixed).toAmount);
  }, [swapOffers]);

  const setUpdateTimer = () => {
    setShowUpdateRatesBanner(false);
    offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
    offersUpdateTimer.current = setInterval(
      () => updateSwapOffers({ fromId: currencyFromId, toId: currencyToId, amount, sort, type }),
      15000
    );
  };

  useEffect(() => {
    if (swapError) {
      extractAmountFromErrorMsg(swapError, setSwapAmountFromError);
    }
  }, [swapError]);

  return (
    <>
      <Modal
        visible={showConfirmModal}
        footer={null}
        className="swap-confirm-modal"
        onCancel={() => setShowConfirmModal(false)}
        centered={true}
        closable={false}
        maskClosable={false}
      >
        <div className="swap-confirm-modal__title">{t('swap.confirm.title')}</div>
        <div className="swap-confirm-modal__body">
          {swapFetching ? (
            <>
              <div className="swap-confirm-modal__loader">
                <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
                <div className="swap-confirm-modal__message">{t('swap.loading')}</div>
              </div>
            </>
          ) : swapError ? (
            <>
              <div className="swap-confirm-modal__icon">
                <SwapTickFailIcon />
              </div>
              <div className="swap-confirm-modal__status">{t('swap.confirm.wrong')}</div>
              <div className="swap-confirm-modal__message">
                {translateError(t, swapError).map(item => (
                  <p
                    className={
                      swapError.includes('Miner Fee')
                        ? 'swap-confirm-modal__error-miner'
                        : 'swap-confirm-modal__error-maximum'
                    }
                  >
                    {item}
                  </p>
                ))}
                {/* {typeof swapError === 'string' || !swapError ? swapError : swapError.join('\n')} */}
              </div>
              <div className="swap-confirm-modal__fail-buttons">
                <Button
                  type="secondary"
                  onClick={() => {
                    // setSwapOffersType('');
                    // setSelectedPartner(undefined);
                    // setSelectedPartnerRateId('');
                    // setShowSwapOffers(false);
                    handleErrorMsgClick();
                  }}
                  className="swap-confirm-modal__fail-buttons-retry"
                >
                  {t('swap.confirm.backToStepOne')}
                </Button>
                <Button
                  onClick={() => {
                    swapOffersReset();
                    history.push('/swap');
                  }}
                  className="swap-confirm-modal__fail-buttons-back"
                >
                  {t('swap.confirm.backToHistory')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="swap-confirm-modal__icon">
                <SwapTickSuccessIcon />
              </div>
              <div className="swap-confirm-modal__status">{t('swap.confirm.swapSuccess')}</div>
              <div className="swap-confirm-modal__message">{t('swap.confirm.transaction')}</div>
              <div className="swap-confirm-modal__success-buttons">
                <Button
                  onClick={() => {
                    swapOffersReset();
                    history.push('/swap');
                  }}
                >
                  {t('swap.confirm.backToHistory')}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <div className="swap-confirm__wrapper">
        <SwapNewCard title={t('swap.summary')}>
          {showUpdateRatesBanner && (
            <div className="swap-confirm-banner">
              <div className="swap-confirm-banner__left">
                <ChartIcon className="swap-confirm-banner__icon" />
                <div>
                  <div className="swap-confirm-banner__title">{t('swap.confirm.exchangeRate')}</div>
                  <div className="swap-confirm-banner__subtitle">{t('swap.confirm.continue')}</div>
                </div>
              </div>
              <div className="swap-confirm-banner__right">
                <Button
                  className="swap-confirm-banner__button"
                  loading={swapOffersUpdating}
                  disabled={swapOffersUpdating}
                  onClick={() => {
                    setShowUpdateRatesBanner(false);
                    swapOffersUpdateApply();
                  }}
                >
                  {t('swap.confirm.updateRate')}
                </Button>
              </div>
            </div>
          )}
          <div className="swap-confirm">
            <div className="swap-confirm__value">
              <div className="swap-confirm__send">
                <div className="swap-confirm__value-title">{t('swap.confirm.youSend')}</div>
                <div className="swap-confirm__value-wrap">
                  {currencyFromName && (
                    <div className="swap-confirm__value-icon">
                      {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                    </div>
                  )}
                  <div>
                    <span className="swap-confirm__value-amount">{amount}</span>
                    <span className="swap-confirm__value-currency">{currencyFromName.title}</span>
                  </div>
                </div>
              </div>
              <ArrowRightIcon className="swap-confirm__value-arrow" />
              <div className="swap-confirm__get">
                <div className="swap-confirm__value-title">{t('swap.confirm.youGet')}</div>
                <div className="swap-confirm__value-wrap">
                  {currencyToName && (
                    <div className="swap-confirm__value-icon">
                      {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                    </div>
                  )}
                  <div>
                    <span className="swap-confirm__value-amount">{summaryRate}</span>
                    <span className="swap-confirm__value-currency">{currencyToName.title}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="swap-confirm__rate">
              <div className="swap-confirm__rate-title">{t('swap.confirm.exchange')}:</div>
              <div className="swap-confirm__rate-flex">
                <div className="swap-confirm__rate-info">
                  <div className="swap-confirm__rate-icon">
                    {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                  </div>
                  <div>
                    <span className="swap-confirm__rate-amount">1</span>
                    <span className="swap-confirm__rate-currency">{currencyFromName.title}</span>
                  </div>
                </div>
                <span className="swap-confirm__rate-divider">-</span>
                <div className="swap-confirm__rate-info">
                  <div className="swap-confirm__rate-icon">
                    {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                  </div>
                  <div>
                    <span className="swap-confirm__rate-amount">{summaryRate / amount}</span>
                    <span className="swap-confirm__rate-currency">{currencyToName.title}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="swap-confirm__partner-info">
              <div className="swap-confirm__partner-info-name">
                <div className="swap-confirm__partner-info-title">{t('swap.offerItem.partner')}:</div>
                <div className="swap-confirm__partner-info-data">
                  <img src={getSwapPartnerIcon(partner)} alt={partner} /> {partner}
                </div>
              </div>
              <div className="swap-confirm__partner-info-type">
                <div className="swap-confirm__partner-info-title">{t('swap.offerItem.type')}:</div>
                {fixed && selectedPartnerRateId ? (
                  <div className="swap-confirm__partner-info-data">
                    <FixedIcon /> {t('swap.offerItem.fixed')}
                  </div>
                ) : (
                  <div className="swap-confirm__partner-info-data">
                    <FloatingIcon /> {t('swap.offerItem.floating')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </SwapNewCard>
        <div className="swap-wallets__button">
          <Button
            disabled={showUpdateRatesBanner}
            onClick={() => {
              setShowConfirmModal(true);
              makeSwap({
                fromId,
                toId,
                currencyFromId,
                currencyToId,
                amount: +amount,
                partner,
                fixed,
                rateId: selectedPartnerRateId,
              });
            }}
          >
            {t('swap.swap')}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  currencies: state.currencies.data,
  swapData: state.swap.exchange.data,
  swapError: state.swap.exchange.error,
  swapFetching: state.swap.exchange.fetching,
});

const mapDispatchToProps = dispatch => ({
  makeSwap: makeSwap(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapConfirm);
