import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/login';
import { Col, Row } from 'antd';
import Button from '../Button';
import PersonalInfoForm from '../PersonalInfoForm/PersonalInfoForm';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import EmailResend from '../EmailResend/EmailResend';
import EmailTwoFa from '../EmailTwoFa/EmailTwoFa';
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import AppTwoFaForm from '../AppTwoFaForm/AppTwoFaForm';
import BackupPhraseForm from '../BackupPhraseForm/BackupPhraseForm';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';
import './ProfileEdit.scss';
import LoginMetamask from '../LoginSocialMedia/Metamask/LoginMetamask';

const ProfileEdit = ({ userData, logout }) => {
  const { t } = useTranslation();

  return (
    <div className="component component_form-padding">
      <Row align="middle">
        <Col>
          <div className="component__title">{t('profile.profile')}</div>
        </Col>
        <Col className="profile-col">
          <div className="profile__button-wrapper">
            <LoginMetamask />
            <Button className="profile__logout" onClick={logout}>
              {t('logOut')}
            </Button>
          </div>
        </Col>
      </Row>
      <LanguageSelector />
      <PersonalInfoForm />
      <div className="border-line"></div>
      <ChangePasswordForm />
      <div className="border-line"></div>
      <EmailResend isEmailConfirmed={userData.emailConfirmed} isUserHasEmail={!!userData.email} />
      <div className="border-line"></div>
      <EmailTwoFa
        isEmailConfirmed={userData.emailConfirmed}
        isEmailTwoFactorEnabled={userData.emailTwoFaStatus}
        isUserHasEmail={!!userData.email}
      />
      <div className="border-line"></div>
      <AppTwoFaForm isTwoFactorEnabled={userData.totpTwoFaStatus} />
      <div className="border-line"></div>
      <BackupPhraseForm isBackupEnabled={userData.backupPhrase} isUserHasEmail={!!userData.email} />
      <div className="border-line"></div>
      <DeleteAccount />
    </div>
  );
};

const mapStateToProps = state => ({
  userData: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  logout: logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
