import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import AppTwoFaEnableModal from '../AppTwoFaModals/AppTwoFaEnableModal';
import AppTwoFaDisableModal from '../AppTwoFaModals/AppTwoFaDisableModal';
import useWindowSize from '../../utils/resizeHook';

const AppTwoFaForm = ({ isTwoFactorEnabled }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  return (
    <div className="profile-block">
      <span className="profile__title">
        {t('profile.twoFactorAuthentication')} (
        <span className={isTwoFactorEnabled ? 'profile__title_enabled' : 'profile__title_disabled'}>
          {isTwoFactorEnabled ? t('profile.enabled') : t('profile.disabled')}
        </span>
        )
      </span>
      <span className="profile__subtitle">{t('profile.useTwoFaFor')}</span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          {isTwoFactorEnabled ? <AppTwoFaDisableModal /> : <AppTwoFaEnableModal />}
        </Col>
      </Row>
    </div>
  );
};

export default AppTwoFaForm;
