import React from 'react';
import SwapWalletItem from '../SwapWalletItem';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SwapWalletsEmpty from '../SwapWalletsEmpty';
import cn from 'classnames';
import './style.scss';

const SwapWalletsList = ({
  exchangeWallets,
  exchangeWalletsFetching,
  activeWalletId,
  handleSelect,
  setSelectedWalletBalance,
  currenciesArray,
  exchangeFields,
  currencyFieldIsEdited,
  currencyNumber,
}) => {
  return (
    <React.Fragment key={activeWalletId}>
      <div
        className={cn('swap-wallets-list', {
          'swap-wallets-list_selected': activeWalletId,
        })}
      >
        {!exchangeWalletsFetching ||
        (currencyFieldIsEdited !== 'none' && currencyFieldIsEdited !== currencyNumber && exchangeWalletsFetching) ? (
          exchangeWallets && exchangeWallets.entities.length ? (
            exchangeWallets.entities.map(wallet =>
              wallet.tokens.length ? (
                wallet.tokens.map(token => (
                  <SwapWalletItem
                    wallet={token}
                    id={wallet._id}
                    address={wallet.address}
                    isMain={wallet.main}
                    activeWalletId={activeWalletId}
                    handleSelect={handleSelect}
                    balance={token.balance.value}
                    setSelectedWalletBalance={setSelectedWalletBalance}
                    currenciesArray={currenciesArray}
                  />
                ))
              ) : (
                <SwapWalletItem
                  wallet={wallet}
                  id={wallet._id}
                  address={wallet.address}
                  isMain={wallet.main}
                  activeWalletId={activeWalletId}
                  handleSelect={handleSelect}
                  balance={wallet.balance.value}
                  setSelectedWalletBalance={setSelectedWalletBalance}
                  currenciesArray={currenciesArray}
                  exchangeFields={exchangeFields}
                />
              )
            )
          ) : (
            <SwapWalletsEmpty />
          )
        ) : (
          <>
            {currencyFieldIsEdited === currencyNumber || currencyFieldIsEdited === 'none' ? (
              <Spin
                className="swap-wallets-list__loader"
                indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />}
              />
            ) : null}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default SwapWalletsList;
