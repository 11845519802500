import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TokenIcon from '../TokenIcon';
import { getMerchantData } from '../../redux/actions/merchantData';
import tickCircleIcon from '../../img/tickCircle.svg';
// import dropdownIcon from '../../img/chevron-down.svg';
import { ReactComponent as DropdownIcon } from '../../img/chevron-down.svg';
import dropdownIcon from '../../img/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import './style.scss';

const removeKey = (key, { [key]: removed, ...rest }) => rest;

const CurrencyDropdown = ({
  currencies,
  merchantCurrencies,
  setFunc,
  multiple = false,
  allCurrencies = true,
  excludeCurrencies = [],
  defaultValues,
  noTokens,
  currenciesFetching,
  merchantId,
  getMerchantData,
  individualTokens = false,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (merchantId && !merchantCurrencies) {
      getMerchantData(merchantId);
    }
  }, [merchantId, merchantCurrencies]);

  useEffect(() => {
    if (defaultValues && defaultValues.length && currencies.length && !currenciesFetching) {
      const result = defaultValues.reduce((obj, item) => {
        obj[item] = currencies.find(currency => currency._id === item);
        return obj;
      }, {});

      setSelectedCurrency(result);
    }
  }, [defaultValues, currencies]);

  const handleSelect = currency => {
    if (multiple) {
      let value;
      let isRemoving;

      if (!selectedCurrency[currency._id]) {
        isRemoving = false;
      } else {
        isRemoving = true;
      }

      if (currency.currencyType === 'currency' || individualTokens) {
        if (isRemoving) {
          if (!individualTokens) {
            value = Object.values(selectedCurrency).reduce((acc, item) => {
              return item.nodeType !== currency.nodeType ? { ...acc, [item._id]: item } : acc;
            }, {});
          } else {
            value = removeKey(currency._id, selectedCurrency);
          }
        } else {
          value = { ...selectedCurrency, [currency._id]: currency };
        }
      } else if (currency.currencyType === 'token' && !individualTokens) {
        if (isRemoving) {
          value = removeKey(currency._id, selectedCurrency);
        } else {
          const currencyToAdd = currencies.find(
            item => currency.nodeType === item.nodeType && item.currencyType === 'currency'
          );

          value = { ...selectedCurrency, [currency._id]: currency, [currencyToAdd._id]: currencyToAdd };
        }
      }

      setSelectedCurrency(value);
      setFunc(Object.keys(value));
    } else {
      setSelectedCurrency({ [currency._id]: currency });
      setFunc(currency._id);
    }
  };

  return (
    <div className="currencies-dropdown" onMouseLeave={() => setIsOpened(false)}>
      {/* <img
        src={dropdownIcon}
        className={`currencies-dropdown__arrow ${isOpened ? 'currencies-dropdown__arrow_opened' : ''}`}
        alt="Dropdows"
        onClick={() => setIsOpened(!isOpened)}
      /> */}
      <DropdownIcon
        className={`currencies-dropdown__arrow ${isOpened ? 'currencies-dropdown__arrow_opened' : ''}`}
        alt="Dropdows"
        onClick={() => setIsOpened(!isOpened)}
      />
      <div className="currencies-dropdown__selected" onClick={() => setIsOpened(!isOpened)}>
        {Object.values(selectedCurrency).length
          ? Object.values(selectedCurrency).map(
              currency =>
                currency && (
                  <div className="currencies-dropdown__selected-item">
                    <TokenIcon tokenName={currency.name} className="currencies-dropdown__logo" />
                    {currency.title}
                  </div>
                )
            )
          : t('wallets.chooseCurrency')}
      </div>
      {isOpened && (
        <div className="currencies-dropdown__list">
          <div className="currencies-dropdown__search">
            <input
              type="text"
              placeholder={t('search')}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
          {currencies.map(currency => {
            const isMatch =
              currency.title.toLowerCase().includes(searchValue.toLowerCase()) ||
              currency.name.toLowerCase().includes(searchValue.toLowerCase());

            if (
              (noTokens && currency.currencyType === 'token') ||
              (!allCurrencies && !merchantCurrencies.find(merchantCurrency => merchantCurrency === currency._id)) ||
              excludeCurrencies.find(excludedCurrency => excludedCurrency === currency._id)
            ) {
              return false;
            } else {
              return (
                <div
                  className={`currencies-dropdown__item ${
                    searchValue && !isMatch ? 'currencies-dropdown__item_hidden' : ''
                  }`}
                  onClick={() => handleSelect(currency)}
                >
                  {selectedCurrency[currency._id] && (
                    <img src={tickCircleIcon} className="currencies-dropdown__tick" alt="selected" />
                  )}
                  <TokenIcon tokenName={currency.name} className="currencies-dropdown__logo" />
                  <div className="currencies-dropdown__title">{currency.title}</div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  currencies: state.currencies.data,
  merchantCurrencies: state.merchantData.data.currencies,
  currenciesFetching: state.currencies.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantData: getMerchantData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDropdown);
