import React from 'react';
import cn from 'classnames';
import './styles.scss';

const Hamburger = ({ opened, handler }) => {
  return (
    <div className={cn('hamburger-menu', { ['hamburger-menu__opened']: opened })} onClick={handler}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
