import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const antIcon = <LoadingOutlined style={{ color: '#fff', fontSize: 14 }} spin />;

const Button = ({ className, children, loading, ...props }) => {
  return (
    <button className={`button ${className} ${loading ? 'button_loading' : ''}`} {...props}>
      <Spin className="button_spin" indicator={antIcon} />
      {React.Children.toArray(children).map(child => child)}
    </button>
  );
};

export default Button;
