import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Button from '../Button';
import { resendEmailConfirmation } from '../../redux/actions/user';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';

const EmailResend = ({ isEmailConfirmed, emailConfirmFetching, isUserHasEmail, resendEmailConfirmation }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  return (
    <div className="profile-block">
      <span className="profile__title">
        {t('confirmEmail')} (
        <span className={isEmailConfirmed ? 'profile__title_enabled' : 'profile__title_disabled'}>
          {isEmailConfirmed ? t('profile.confirmed') : t('profile.notConfirmed')}
        </span>
        )
      </span>
      <span className="profile__subtitle">
        {isUserHasEmail ? t('profile.confirmEmailForSecurity') : t('profile.completeEmail')}
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <Button
            type="primary"
            className="profile__button"
            onClick={resendEmailConfirmation}
            loading={emailConfirmFetching}
            disabled={emailConfirmFetching || isEmailConfirmed || !isUserHasEmail}
          >
            {t('profile.sendLink')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  emailConfirmFetching: state.user.emailConfirmFetching,
});

const mapDispatchToProps = dispatch => ({
  resendEmailConfirmation: resendEmailConfirmation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailResend);
