import React from 'react';
import { Form, Row, Col, Dropdown, Radio } from 'antd';
import Button from '../../../../components/Button';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import emptyImage from '../../../../img/empty.svg';
import Transaction from '../Transaction';

const UserTransactionsMarkUpList = ({
  arrayData,
  fetchingFlag,
  countItem,
  isFromTransactionsByUser,
  moreTransactionsFetching,
  handleLoadMoreClick,
  fetchingMoreTransactionsByUser,
  userSearchParams,
  userName,
}) => {
  return (
    <>
      {!fetchingFlag ? (
        <>
          {arrayData?.length ? (
            <Row className="fee-transactions__legend">
              {isFromTransactionsByUser && <Col span={5}>Profile</Col>}
              <Col span={!isFromTransactionsByUser ? 1 : 4}>Type</Col>
              <Col span={!isFromTransactionsByUser ? 7 : 5} offset={!isFromTransactionsByUser ? 7 : 1}>
                Data/Time
              </Col>
              <Col span={2}>Status</Col>
              <Col span={7} className="fee-transactions__legend-amount">
                Amount
              </Col>
            </Row>
          ) : null}
          <div className="fee-transactions-list">
            {arrayData?.length ? (
              arrayData.map(transaction => (
                <Transaction
                  key={transaction._id}
                  transaction={transaction}
                  isFromTransactionsByUser={isFromTransactionsByUser}
                  userSearchParams={userSearchParams}
                  userName={userName}
                />
              ))
            ) : (
              <div className="fee-transactions-list__empty">
                <span className="fee-transactions-list__empty-title">All your transactions will show up here.</span>
                <img src={emptyImage} className="fee-transactions-list__empty-image" alt="empty" />
              </div>
            )}
          </div>
          {arrayData?.length && arrayData.length < countItem ? (
            <>
              <Button
                type="button"
                className="fee-transactions__load-more"
                loading={!isFromTransactionsByUser ? moreTransactionsFetching : fetchingMoreTransactionsByUser}
                onClick={handleLoadMoreClick}
              >
                Load more
              </Button>
            </>
          ) : null}
        </>
      ) : (
        <div className="fee-transactions__loader">
          <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
        </div>
      )}
    </>
  );
};

export default UserTransactionsMarkUpList;
