import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { Spin } from 'antd';
import { logout } from '../../../redux/actions/login';
import { LoadingOutlined } from '@ant-design/icons';
import profileIcon from '../../../img/profileCircle.svg';
import useWindowSize from '../../../utils/resizeHook';

const antIcon = <LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />;

const HeaderUser = ({ userData, logout }) => {
  const { isLg } = useWindowSize();
  return (
    <>
      {userData.name && userData.name.length > 0 ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Link to="/">Switch to personal</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/profile">Profile</Link>
              </Menu.Item>
              <Menu.Item>
                <div onClick={logout}>Log Out</div>
              </Menu.Item>
            </Menu>
          }
          placement="topCenter"
          arrow
        >
          <div className="header__user-name">
            <img src={profileIcon} alt="" />
            {isLg && <div>{`${userData.name} ${userData.surname || ''}`}</div>}
          </div>
        </Dropdown>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userData: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  logout: logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUser);
