import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Row, Col, Checkbox } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { createMerchantWallet } from '../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import CurrencyDropdown from '../CurrencyDropdown';

const WalletCreateNew = ({
  isFetching,
  merchantId,
  networkFilter,
  typeWallet,
  notificationsErrorShow,
  createMerchantWallet,
  handleCancel,
  page,
  walletsFilters,
}) => {
  const [walletCurrency, setWalletCurrency] = useState([]);
  const [walletSetMain, setWalletSetMain] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (!walletCurrency) {
      notificationsErrorShow({ message: "Currency is required"});
      return;
    }

    createMerchantWallet(
      merchantId,
      typeWallet,
      {
        setMain: walletSetMain,
        typeWallet: typeWallet,
        currenciesIds: walletCurrency
      },
      page,
      walletsFilters,
      networkFilter
    ).then(() => handleCancel());
  };

  return (
    <>
      <CurrencyDropdown
        setFunc={setWalletCurrency}
        multiple={true}
        allCurrencies={false}
        noTokens={typeWallet === 'merchant'}
        merchantId={merchantId}
        individualTokens={typeWallet !== 'merchant' ? true : false}
      />

      {typeWallet === 'merchant' && (
        <div className="modal__set-default">
          <Checkbox onChange={e => setWalletSetMain(e.target.checked)}>{t('wallets.setAsDefault')}</Checkbox>
        </div>
      )}

      <Row className="modal__buttons-wrapper">
        <Col>
          <Button
            type="primary"
            className="modal__button modal__button-create"
            onClick={handleSubmit}
            loading={isFetching}
          >
            {t('create')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  page: state.merchantWallets.page,
  isFetching: state.merchantWallets.modalFetching,
  walletsFilters: formValueSelector(props.filtersFormName)(state, 'search', 'order', 'limit', 'currencyIds'),
});

const mapDispatchToProps = dispatch => ({
  createMerchantWallet: createMerchantWallet(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletCreateNew);
