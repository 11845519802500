import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import Button from '../Button';
import { useHistory } from 'react-router-dom';

import { showWelcomeModal } from '../../redux/actions/user';

import logo from '../../img/logo.svg';
import '../../layout/Modal.scss';
import './style.scss';

const WelcomeModal = ({ isShowWelcomeModal, setShowWelcomeModal}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={820}
      visible={isShowWelcomeModal}
      onCancel={() => {
        setShowWelcomeModal(false);
        //history.push('/');
      }}
      footer={null}
      className="modal welcome-modal"
    >
      <div className="welcome-modal__logo">
        <img src={logo} alt="Logo" />
      </div>
      <span className="welcome-modal__title">{t('welcomeModal.title')}</span>
      <p className="welcome-modal__text">{t('welcomeModal.text')}</p>
      {/* <a href="https://docs.cpay.world/" target="_blank">
        <Button type="primary" className="welcome-modal__button">
          {t('welcomeModal.help')}
        </Button>
      </a> */}
      <Button
        type="secondary"
        className="welcome-modal__button"
        onClick={() => {
          setShowWelcomeModal(false);
          // history.push('/');
        }}
      >
        {t('welcomeModal.ok')}
      </Button>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isShowWelcomeModal: state.user.showWelcomeModal,
});

const mapDispatchToProps = dispatch => ({
  setShowWelcomeModal: data => dispatch(showWelcomeModal(data)),
  // setShowAppTour: data => dispatch(showAppTour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
