import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
// import { Popover } from 'antd';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import {tokenNodeTypeObj} from '../../../utils/tokenNodeTypes';
import './style.scss';

const SwapWalletItem = ({
  wallet,
  id,
  address,
  isMain,
  activeWalletId,
  handleSelect,
  balance,
  setSelectedWalletBalance,
  currenciesArray
}) => {
  // const [showAddressPopover, setShowAddressPopover] = useState(false);
  // const addressPopoverTimer = useRef(null);

  // useEffect(
  //   () => () => {
  //     clearTimeout(addressPopoverTimer);
  //     addressPopoverTimer.current = null;
  //   },
  //   []
  // );

  // const addressCopyHandler = () => {
  //   navigator.clipboard.writeText(wallet.address);
  //   setShowAddressPopover(true);
  //   addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  // };

  const { t } = useTranslation();
  const [currencyProperties, setCurrencyProperties] = useState(null);

  useEffect(()=>{
    setCurrencyProperties(currenciesArray?.find(currency=>wallet.currencyId === currency._id));
  },[wallet?.currencyId])

  return (
    <div
      className={cn('swap-wallet-item', {
        ['swap-wallet-item_active']: activeWalletId === id,
      })}
      onClick={() => {
        handleSelect(id);
        setSelectedWalletBalance && setSelectedWalletBalance(+balance);
      }}
    >
      <div className="swap-wallet-item__left">
        <div className="swap-wallet-item__icon">
          <TokenIcon tokenName={wallet.currency} />
        </div>
        <div className="swap-wallet-item__info">
          <div className="swap-wallet-item__name">
            {wallet.currency} {isMain ? `(${t('swap.newSwap.default')})` : ''} {currencyProperties?.currencyType === 'token' ? tokenNodeTypeObj[currencyProperties?.nodeType] : null}
          </div>
          {/* <Popover title="" content="Copied" trigger="click" visible={showAddressPopover}> */}
          <div className="swap-wallet-item__address" /*onClick={addressCopyHandler}*/>{address}</div>
          {/* </Popover> */}
        </div>
      </div>
      <div className="swap-wallet-item__right">
        <div className="swap-wallet-item__tokens">
          {wallet.balance.value} {wallet.currency}
        </div>
        <div className="swap-wallet-item__fiat">{formatCurrency(wallet.balance.usd)}</div>
      </div>
    </div>
  );
};

export default SwapWalletItem;
