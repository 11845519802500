const releaseNotesArray = t => {
  return [
    {
      version: "3.0",
      date: "October 04, 2023",
      month: 'October',
      time: '04, 2023',
      sections: [
        {
          title: t('releases.new'),
          changes: [
            "Added links to the mobile application",
            "Added debit card pre-order form",
            "Added new blockchain - Arbitrum with ETH ARB currency and ARB/USDT/USDC tokens",
            "Added new blockchain - Optimism with ETH OP currency and OP/USDT/USDC tokens",
            "Added USDT/USDC tokens to the Polygon network",
            "Updated registration process",
            "Various fixes and improvements that improve performance, UX and security of the system",
            
            ]
      }]
    },
    {
      version: "2.0",
      date: "August 21, 2023",
      month: 'August',
      time: '21, 2023',
      sections: [
        {
          title: t('releases.new'),
          changes: [ t('releases.versionTwo.sections.first.changes.1'),]
      }]
    },
  ];
};

export default releaseNotesArray;
