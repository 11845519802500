import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import TokenIcon from '../../../../../components/TokenIcon';
import formatCurrency from '../../../../../utils/currencyFormatter';
import { connect } from 'react-redux';
import network from '../../../../../utils/transactionScans';
import { tokenNodeTypeObj } from '../../../../../utils/tokenNodeTypes';

import './style.scss';

const Transaction = ({ transaction, networkFilter, currencies }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [transactionNodeType, setTransactionNodeType] = useState('')

  return (
    <div className="fee-transaction">
      <Row align="middle" onClick={() => setIsOpened(!isOpened)}>
        <Col span={1}>
          <div className="fee-transaction__logo">
            <TokenIcon tokenName={transaction.info.currency} alt="Currency logo" />
          </div>
        </Col>
        <Col span={7}>
          <div className="fee-transaction__type">{transaction.type}</div>
        </Col>
        <Col span={7}>
          <div className="fee-transaction__date">{moment(transaction.createdAt).format('MMM D, YYYY, HH:mm;')}</div>
        </Col>
        <Col span={3}>
          <div className={`transaction__status transaction__status_${transaction.systemStatus.toLowerCase()}`}>
            {transaction.systemStatus}
          </div>
        </Col>
        <Col span={6}>
          <div className="fee-transaction__amount">
            <div className="fee-transaction__amount_tokens">
            {transaction.info.amount.value}{' '}
              {transaction.info.amount.value || transaction.info.amount.value === 0 ? transaction.info.currency : ''}
              {' '}  

              {transaction.info.currencyType === 'token' ? tokenNodeTypeObj[transaction.info.nodeType] : null}

              {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currencyType === 'token' && transaction.info.nodeType === 'optimism') ? transaction.info.currency === 'OP' ? null : 'OP' : null } {' '}

              {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currency === 'ETH' && transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'optimism') ?  'OP' : null}

              {(transaction.info.amount.value || transaction.info.amount.value === 0) && (transaction.info.currencyType === 'currency' && transaction.info.nodeType === 'arbitrum') ? transaction.info.currency === 'arbitrum' ? null : 'ARB' : null}

              {(transaction.info.amount.value || transaction.info.amount.value === 0) && ((transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC' || transaction.info.currency === 'ETH') && transaction.info.currencyType === 'token' && transaction.info.nodeType === 'arbitrum') ? 'ARB' : null}

             {(transaction.info.amount.value || transaction.info.amount.value === 0) && ((transaction.info.currency === 'USDT' || transaction.info.currency === 'USDC') && transaction.info.currencyType === 'token' && transaction.info.nodeType) === 'matic' ? 'MATIC' : null}
            </div>
            <div className="fee-transaction__amount_usd">{formatCurrency(+transaction.info.amount.usd)}</div>
          </div>
        </Col>
      </Row>
      {transaction.errorMessage && (
        <div className="fee-transaction__error-wrapper">
          <div className="fee-transaction__error-title">Transaction error</div>
          <div className="fee-transaction__error-description">{transaction.errorMessage}</div>
        </div>
      )}
      <div className={`transaction__details ${isOpened && 'transaction__details_opened'}`}>
        <Row>
          <Col>
            <div className="fee-transaction__details-id">
              <b>Payment ID</b>: {transaction._id}
            </div>
            <div className="fee-transaction__details-to">
              <b>To</b>: {transaction.info.to}
            </div>
            <div className="fee-transaction__details-from">
              <b>From</b>: {transaction.info.from}
            </div>
            <div className="fee-transaction__details-fee">
              <b>Miner Fee</b>: {transaction.info.minerFee.value} {transaction.info.minerFee.currency} ⸺{' '}
              {transaction.info.minerFee.usd ? `${formatCurrency(+transaction.info.minerFee.usd)}` : 'USD'}
            </div>
            {transaction.info.systemFee && (
              <div className="fee-transaction__details-fee">
                <b>System Fee</b>: {transaction.info.systemFee.amount} {transaction.info.systemFee.currency} ⸺{' '}
                {`${formatCurrency(+transaction.info.systemFee.amountUSD)}`}
              </div>
            )}
            <div className="fee-transaction__details-hash">
              <b>Hash</b>:{' '}
              <a
                target="_blank"
                href={
                  transaction.type === 'InternalTransfer'
                    ? null
                    : `${network[networkFilter][transaction.info.nodeType]}${transaction.info.hashs[0]}`
                }
              >
                {transaction.info.hashs[0]}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  currencies: state.currencies.data,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
