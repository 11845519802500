import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { RangePicker } from '../../../components/FormFields';
import moment from 'moment';
import './styles.scss';
import { getLocalStorageData } from '../helpers';
import { Radio } from 'antd';

const TableData = ({
  title,
  drawData,
  propName,
  action,
  type = 'users',
  redirectTo,
  countItems,
  prefix,
  typeNetwork,
  sharedDate,
  setSharedDate,
  setChangedFieldsArray,
  changedFieldsArray,
  changeAllDataPickers,
  onChangeHandler,
  datesForAllExceptUsers
}) => {
  const [selectedData, setSelectedData] = useState(
    () => getLocalStorageData(type)
    // localStorage.getItem(`adm-date-${type}`)
    //   ? JSON.parse(localStorage.getItem(`adm-date-${type}`)).map(el => moment(el))
    //   : [moment().startOf('month'), moment().endOf('month')]
  );
  const [pickerOpened, setPickerOpened] = useState(false);
  const history = useHistory();
  const { data, fetching } = drawData;
  const { total, statistics } = data || {};
  // const savedDate = localStorage.getItem(`adm-date-${type}`)
  //   ? JSON.parse(localStorage.getItem(`adm-date-${type}`)).map(el => moment(el))
  //   : null;

  const savedDate = getLocalStorageData(type);

  const handler = e => {
    if (type === 'users') {
      if (changeAllDataPickers) {
        setSharedDate(e);
        setChangedFieldsArray([]);
        localStorage.setItem(
          `adm-date-all`,
          JSON.stringify([e[0].format('YYYY-MM-DD') + 'T00:00:01', e[1].format('YYYY-MM-DD') + 'T23:59:59'])
        );
        return;
      }
    }
    localStorage.setItem(
      `adm-date-${type}`,
      JSON.stringify([e[0].format('YYYY-MM-DD') + 'T00:00:01', e[1].format('YYYY-MM-DD') + 'T23:59:59'])
    );

    setSelectedData(e);
    setChangedFieldsArray(prev => [...prev, type]);
  };

  useEffect(() => {
    selectedData &&
      action({
        dateFrom: selectedData[0]?.format('YYYY-MM-DD') + 'T00:00:01',
        dateTo: selectedData[1]?.format('YYYY-MM-DD') + 'T23:59:59',
        type: type === 'users' ? undefined : type,
        typeNetwork: typeNetwork,
      });
  }, [selectedData, typeNetwork]);

  useEffect(() => {
    sharedDate &&
      action({
        dateFrom: sharedDate[0]?.format('YYYY-MM-DD') + 'T00:00:01',
        dateTo: sharedDate[1]?.format('YYYY-MM-DD') + 'T23:59:59',
        type: type === 'users' ? undefined : type,
        typeNetwork: typeNetwork,
      });
  }, [sharedDate, typeNetwork]);

  useEffect(() => {
    const key = `adm-date-${type}`;

    if(type === 'users') return;

    if (changeAllDataPickers && localStorage.getItem(`adm-date-${type}`)) {
      return localStorage.removeItem(key);
    }
  }, [changeAllDataPickers]);

 
  return (
    <div className="dashboard-table-data">
      <div className="dashboard-table-data__header">
        <h2 className="dashboard-table-data__title">{title}</h2>

        {type === 'users' ? (
          <div className="admin-dashboard-container-radio-button">
            <p>Apply dates to:</p>
            <Radio.Group onChange={onChangeHandler} value={changeAllDataPickers}>
              <Radio value={true}>all tables</Radio>
              <Radio value={false}>users only</Radio>
            </Radio.Group>
          </div>
        ) : null}
        <RangePicker
          onBlur={() => {
            // console.log('close');
            pickerOpened && setPickerOpened(false);
          }}
          bordered={false}
          allowEmpty={false}
          allowClear={false}
          withInitial={true}
          onFocus={() => {
            // console.log('opened');
            !pickerOpened && setPickerOpened(true);
          }}
          open={pickerOpened}
          format="DD.MM.YYYY"
          onChange={e => handler(e)}
          defaultValue={savedDate ? savedDate : [moment().startOf('month'), moment().endOf('month')]}
          value={sharedDate && !changedFieldsArray.includes(type) ? sharedDate :  datesForAllExceptUsers && type !== 'users' ? datesForAllExceptUsers : selectedData}
          ranges={{
            Today: [moment(), moment()],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
          }}
        />
      </div>
      <div className="dashboard-table-data__table-content">
        <table className={`dashboard-table-data__table ${type === 'users' ? 'users' : null}`}>
          <tbody>
            <tr>
              <th>Data</th>
              {statistics &&
                statistics.map(el => (
                  <>
                    {type === 'users' ? (
                      <Tooltip title={'Click to show'} placement="top">
                        <td
                          key={el.time}
                          onClick={() =>
                            redirectTo && history.push(`${redirectTo}?from=${el.timeStamp.from}&to=${el.timeStamp.to}`)
                          }
                        >
                          {el.time}
                        </td>
                      </Tooltip>
                    ) : (
                      <td
                        key={el.time}
                        onClick={() =>
                          redirectTo && history.push(`${redirectTo}?from=${el.timeStamp.from}&to=${el.timeStamp.to}`)
                        }
                      >
                        {el.time}
                      </td>
                    )}
                  </>
                ))}
            </tr>
            <tr>
              <th>{propName}</th>
              {statistics &&
                statistics.map(el => (
                  <td key={el.count || el.amount}>
                    <NumberFormat
                      value={el.count || el.amount || 0}
                      displayType={'text'}
                      thousandSeparator={' '}
                      decimalScale={2}
                      prefix={prefix}
                    />
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
        <div className="dashboard-table-data__total">
          <div className="dashboard-table-data__total-title">Total</div>
          <NumberFormat
            value={total || 0}
            displayType={'text'}
            thousandSeparator={' '}
            className="dashboard-table-data__total-count"
            prefix={prefix}
            decimalScale={2}
          />
        </div>
      </div>
      {countItems ? <div className="dashboard-table-data__info">{countItems} users all time</div> : null}
    </div>
  );
};

export default TableData;
