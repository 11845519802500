import React, { useState, useEffect, useRef } from 'react';
import * as bip39 from 'bip39';
import { Row, Col, Popover, Tooltip } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import lockIcon from '../../../img/lock.svg';

const BackupPhraseStepOne = ({ backupWords, setBackupWords, okButtonAction, handleCancel }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isWordsOpen, setIsWordsOpen] = useState(false);
  const timer = useRef(null);
  const { t } = useTranslation();

  useEffect(
    () => () => {
      clearTimeout(timer);
      timer.current = null;
    },
    []
  );

  const revealSecretWords = () => {
    const mnemonic = bip39.generateMnemonic();
    setBackupWords(mnemonic.split(' '));
    setIsWordsOpen(true);
  };

  const copyHandler = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    setShowTooltip(false);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  return (
    <React.Fragment>
      <div className="modal-backup__tip">{t('profile.writeDownPhrase')}</div>
      <div className={'modal-backup__secret-words'}>
        {isWordsOpen ? (
          <Tooltip
          title={t('clickToCopy')}
          visible={showTooltip}
          onVisibleChange={isVisible => setShowTooltip(isVisible)}
        >
          <Popover content={t('copied')} trigger="click" visible={showPopover} hidden={true}>
            <span
              className="modal-backup__words-string"
              onClick={e => copyHandler(backupWords.map(word => word).join(' '), e)}
              style={{cursor: 'pointer'}}
            >
              {backupWords.map(word => word).join(' ')}
            </span>
          </Popover>
        </Tooltip>
        ) : (
          <div className="modal-backup__placeholder" onClick={revealSecretWords}>
            <img src={lockIcon} alt="lock" />
            <span className="modal-backup__secret-tip">{t('profile.clickToRevealWords')}</span>
          </div>
        )}
      </div>
      <div className="modal__warning">
        <span className="modal__warning_red">{t('important')}</span>
        {t('profile.writeDownWords')}
      </div>

      <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-create" onClick={okButtonAction}>
            {t('profile.gotIt')}
          </Button>
        </Col>
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('profile.doItLater')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BackupPhraseStepOne;
