import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrivateRoute = ({ component: Component, userData, userRole, children, location, ...rest }) => {
  const query = useQuery();
  
  if (userData) {
    if (!userRole) {
      return null;
    } else if (location.pathname.indexOf('admin') >= 0 && userRole !== 'admin') {
      return <Redirect to="/" />;
    } else {
      return <Route {...rest}>{children}</Route>;
    }
  } else {
    return <Redirect to={`/login${query.get('lang') ? `?lang=${query.get('lang')}` : ''}`} />;
  }
};

const mapStateToProps = state => ({
  userData: state.login.data,
  userRole: state.user.data.role,
});

export default connect(mapStateToProps)(withRouter(PrivateRoute));
