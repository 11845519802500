import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import CurrencySelector from '../components/Withdraw/CurrencySelector';
import WalletsList from '../components/Withdraw/WalletsList';
import WithdrawForm from '../components/Withdraw/WithdrawForm';
import Multisend from '../components/Withdraw/Multisend';
import { getMerchantBalances } from '../redux/actions/merchantData';
import { getWithdrawWallets } from '../redux/actions/withdraw';
import useWindowSize from '../utils/resizeHook';

const Withdraw = ({
  merchantId,
  networkFilter,
  merchantBalances,
  balancesFetching,
  currencies,
  withdrawWallets,
  walletsFetching,
  getMerchantBalances,
  getWithdrawWallets,
  maxAmount,
  balancesTotal
}) => {
  const [currency, setCurrency] = useState('');
  const [from, setFrom] = useState('');
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  const [multisendMode, setMultisendMode] = useState(false);
  const { currentWidth } = useWindowSize();

  const walletListRef = useRef(null);

  useEffect(()=>{
    walletListRef?.current &&  walletListRef.current.scrollIntoView({behavior: "smooth"});
  },[currency])


  useEffect(() => {
    setCurrency('');
    setFrom('');
    setSelectedWallet(null);
    setTo('');
    setAmount('');

    if (merchantId) {
      getMerchantBalances(merchantId, networkFilter, true);
      getWithdrawWallets(merchantId, '', networkFilter, true);
    }
  }, [merchantId, networkFilter]);

  useEffect(() => {
    if (merchantId) {
      getWithdrawWallets(merchantId, currency, networkFilter, true);
      if(!currency) window.scrollTo({top:0});
    }
  }, [currency]);

  useEffect(() => {
    setAmount(maxAmount);
  }, [maxAmount]);


  return !multisendMode ? (
    <>
      <Row gutter={[12, 0]}>
        {from ? (
          <Col span="24">
            <WithdrawForm
              merchantId={merchantId}
              currency={currency}
              setCurrency={setCurrency}
              currencies={currencies}
              from={from}
              setFrom={setFrom}
              to={to}
              setTo={setTo}
              amount={amount}
              setAmount={setAmount}
              selectedWallet={selectedWallet}
              setSelectedWallet={setSelectedWallet}
            />
          </Col>
        ) : (
          <>
            <Col span={currentWidth >= 1024 ? 8 : 24}>
              <CurrencySelector
                merchantBalances={merchantBalances}
                balancesFetching={balancesFetching}
                currencies={currencies}
                currency={currency}
                setCurrency={setCurrency}
                balancesTotal={balancesTotal}
                getMerchantBalances={getMerchantBalances}
                merchantId={merchantId}
                networkFilter={networkFilter}
              />
            </Col>
            <Col span={currentWidth >= 1024 ? 16 : 24}>
              <WalletsList
                withdrawWallets={withdrawWallets}
                walletsFetching={walletsFetching}
                selectedWallet={selectedWallet}
                setSelectedWallet={setSelectedWallet}
                from={from}
                setFrom={setFrom}
                setCurrency={setCurrency}
                multisendMode={multisendMode}
                setMultisendMode={setMultisendMode}
                walletListRef={walletListRef}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  ) : (
    <Multisend multisendMode={multisendMode} setMultisendMode={setMultisendMode} />
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  merchantBalances: state.merchantData.balances.balances || [],
  balancesFetching: state.merchantData.fetching,
  currencies: state.currencies.data,
  withdrawWallets: state.withdraw.wallets.data,
  walletsFetching: state.withdraw.wallets.fetching,
  maxAmount: state.withdraw.maxAmount,
  balancesTotal: state.merchantData.balances.total
});

const mapDispatchToProps = dispatch => ({
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawWallets: getWithdrawWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
