import React, { useEffect } from 'react'
import {  Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { countriesList } from '../../utils/countriesList';
import Button from '../Button';
import { cryptoApi } from '../../service/cryptopay-api';

import './style.scss';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

  const VerificationStep = ({nextStep, fullName, setFullName, countryResidence,  setCountryResidence, countryShipping, setCountryShipping}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setFullName(fullName ?? '')
    setCountryResidence(countryResidence ?? '')
    setCountryShipping(countryShipping ?? '')
  })

  const isDisabled = fullName === "" || countryResidence === '' || countryShipping === ''

  const submitCard = async () => {
     await cryptoApi.orderCard({
      userName: fullName,
      residenceCountry: countryResidence,
      shippingCountry: countryShipping,
    }).then(() => nextStep()).catch((err) => console.log('err', err))
  }


  return (
    <div className='verification-step'>
    <Form
       {...layout}
        name="basic"
        className="modal-form card-form"
      >
      <Form.Item label={t("orderCard.stepOne.name")} name="fullName" className="modal-form__label card-label">
          <Input
            placeholder={t("orderCard.stepOne.name")}
            className="modal-form__input"
            onChange={e =>setFullName(e.target.value.trim())}
          />

        </Form.Item>
        <Form.Item
          label={t("orderCard.stepOne.residence")}
          name="countryResidence"
          className="modal-form__label card-label"
        >
          <Select placeholder={t("orderCard.stepOne.chooseResidence")} onChange={value => setCountryResidence(value)}>
           {countriesList?.map(country =>
            <Select.Option key={country.name} value={country.name}>{country.name}</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
         label={t("orderCard.stepOne.shipping")}
          name="countryShipping"
          className="modal-form__label card-label"
        >
          <Select placeholder={t("orderCard.stepOne.chooseShipping")} onChange={value => setCountryShipping(value)}>
          {countriesList?.map(country =>
            <Select.Option key={country.name} value={country.name}>{country.name}</Select.Option>
            )}
          </Select>
        </Form.Item>

        <Button
            className="modal__button modal__button-next"
            disabled={isDisabled}
            onClick={() => submitCard()}
          >
            {t("orderCard.stepOne.button")}
          </Button>
      </Form>

    {!isDisabled && (
      <p>{t('orderCard.stepOne.textInfo')} {' '}
      <a href='https://www.yomi.finance/privacy-policy' target='_blank' rel="noopener noreferrer">{t('orderCard.stepOne.privacy')}</a> {t('orderCard.stepOne.and')} {' '}
      <a href='https://www.yomi.finance/terms-of-use' target='_blank' rel="noopener noreferrer">{t('orderCard.stepOne.tos')}</a> </p>
    )}
    </div>
  )
}


export default VerificationStep;
