import {
  MERCHANT_DATA_GET,
  MERCHANT_DATA_SET,
  MERCHANT_DATA_EDIT,
  MERCHANT_BALANCES_GET,
  MERCHANT_BALANCES_SET,
  MERCHANT_DATA_ERROR,
  GET_MERCHANT_API_KEYS_REQUEST,
  GET_MERCHANT_API_KEYS_SUCCESS,
  GET_MERCHANT_API_KEYS_FAIL,
} from '../actions/merchantData';

export default function reducer(
  state = {
    data: {},
    balances: {},
    apiKeys: {},
    fetching: false,
  },
  action
) {
  switch (action.type) {
    case MERCHANT_DATA_GET:
    case MERCHANT_DATA_EDIT:
    case MERCHANT_BALANCES_GET:
    case GET_MERCHANT_API_KEYS_REQUEST:
      return {
        ...state,
        fetching: action.fetching,
        apiKeys: {},
      };
    case MERCHANT_DATA_SET:
      return {
        ...state,
        data: action.payload,
        fetching: action.fetching,
        apiKeys: {},
      };
    case MERCHANT_BALANCES_SET:
      return {
        ...state,
        balances: action.payload,
        fetching: action.fetching,
      };
    case GET_MERCHANT_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: action.payload,
        fetching: action.fetching,
      };
    case MERCHANT_DATA_ERROR:
    case GET_MERCHANT_API_KEYS_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
