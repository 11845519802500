import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, InputNumber } from 'antd';
import cn from 'classnames';
import Button from '../../Button';
import {
  makeWithdraw,
  withdrawResetTwoFa,
  getWithdrawalEstimateMax,
  withdrawalEstimateMaxReset,
} from '../../../redux/actions/withdraw';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import WithdrawTwoFaConfirm from './WithdrawTwoFaConfirm';
import { cryptoApi } from '../../../service/cryptopay-api';
import './style.scss';
import repeatIcon from '../../../img/repeat.svg';
import withdrawalIcon from '../../../img/arrow-right.svg';
import TokenIcon from '../../TokenIcon';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import useWindowSize from '../../../utils/resizeHook';

function WithdrawForm({
  merchantId,
  setCurrency,
  currencies,
  setFrom,
  to,
  setTo,
  amount,
  setAmount,
  selectedWallet,
  setSelectedWallet,
  fetching,
  fetchingMaxAmount,
  makeWithdraw,
  withdrawResetTwoFa,
  getWithdrawalEstimateMax,
  withdrawalEstimateMaxReset,
  notificationsErrorShow,
}) {
  const [exchange, setExchange] = useState(0);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const [fees, setFees] = useState(null);
  const [decimalsLimit, setDecimalsLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const [withdrawalInfo, setWithdrawalInfo] = useState();
  const [form] = Form.useForm();
  const ratesFetchTimer = useRef();
  const { currentWidth } = useWindowSize();
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
  }, []);

  useEffect(() => () => ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current), []);

  useEffect(() => {
    if (to) setShowWarning(false);
  }, [to]);

  useEffect(() => {
    const currenctCurrency = currencies.find(currency => selectedWallet.currencyId === currency._id);
    setDecimalsLimit(currenctCurrency ? currenctCurrency.decimals : 18);

    return () => withdrawalEstimateMaxReset();
  }, []);

  useEffect(() => {
    if (amount && to) {
      getFeeEstimate(
        merchantId,
        selectedWallet._id,
        to,
        `${amount}`,
        selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined
      );
    } else {
      setFees(null);
    }
  }, [to, amount]);
  const getWithdrawalInfo = async (merchantId, walletId) => {
    const response = await cryptoApi.getWithdrawalInfo(merchantId, walletId);
    const data = response.data;
    return setWithdrawalInfo(data);
  };

  useEffect(() => {
    if (merchantId && selectedWallet._id) {
      getWithdrawalInfo(merchantId, selectedWallet._id);
    }
  }, [merchantId, selectedWallet._id]);

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };

  const getCurrenciesRate = async () => {
    const response = await cryptoApi.getCurrenciesRate();
    return response;
  };

  const getFeeEstimate = async (merchantId, walletId, to, amount, currencyToken) => {
    try {
      const response = await cryptoApi.getFeeEstimate(merchantId, walletId, to, amount, currencyToken);
      return setFees(response && response.data ? response.data : null);
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
    }
  };

  const clearWithdrawState = () => {
    form.setFieldsValue({
      walletTo: '',
      exchange: 0,
    });
    setFrom('');
    setTo('');
    setAmount('');
    setCurrency('');
    setSelectedWallet(null);
    setExchange(0);
  };

  const handleSubmit = () => {
    makeWithdraw(
      selectedWallet._id,
      to,
      `${amount}`,
      merchantId,
      selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined
    ).then(response => {
      if (response && response.data) {
        if (response.data.twoFactorToken || response.data.emailSent) {
          setShowTwoFaModal(true);
        }
      }
    });
  };

  const convertTokensToUsd = async value => {
    const rates = await getCurrenciesRate();

    if (rates && rates.data) {
      setExchange(value * +rates.data[selectedWallet.currency] || 0);
      form.setFieldsValue({
        exchange: value * +rates.data[selectedWallet.currency] || 0,
      });
    }
  };

  const convertUsdToTokens = async value => {
    if (+value) {
      setExchange(value);
      const rates = await getCurrenciesRate();

      if (rates && rates.data) {
        setAmount((value / +rates.data[selectedWallet.currency]).toFixed(decimalsLimit) || 0);
        setShowDecimalsWarning(false);
      }
    } else {
      setAmount('');
      setExchange(0);
    }
  };

  const handleEstimateMax = e => {
    const { _id: walletId } = selectedWallet;
    const data = {
      to,
      currencyToken: selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
    };

    if (!to) {
      setShowWarning(true);
    }

    if (merchantId && to && selectedWallet) {
      getWithdrawalEstimateMax(merchantId, walletId, data);
    }
  };

  useEffect(() => {
    if (amount) {
      setShowDecimalsWarning(false);
      setFetchTimer(convertTokensToUsd, amount);
    }
  }, [amount]);

  const systemFeeWithdrawal = withdrawalInfo?.systemFeeWithdrawal ?? 0;

  return (
    <React.Fragment>
      <WithdrawTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={makeWithdraw}
        fetching={fetching}
        clearWithdrawState={clearWithdrawState}
        body={[
          selectedWallet._id,
          to,
          `${amount}`,
          merchantId,
          selectedWallet.hasOwnProperty('holdBalance') ? selectedWallet.currencyId : undefined,
        ]}
        withdrawResetTwoFa={withdrawResetTwoFa}
      />
      <div className="component" id="topComponent">
        <div className="component__title">{t('withdraw.sendMoney')}</div>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            walletSelect: selectedWallet.address,
            exchange: exchange,
          }}
          className="form settings-form"
        >
          <Row>
            <Col span={currentWidth >= 1024 ? 15 : 24}>
              <Row gutter={[20, 0]} align="bottom">
                <Col
                  span={currentWidth >= 1024 ? 12 : 24}
                  className={cn('settings-form__exchange-wrapper', {
                    'settings-form__exchange-wrapper_warning': showWarning,
                  })}
                >
                  <div className="settings-form__selected-wallet-label">{t('currency')}:</div>
                  <div className="settings-form__selected-wallet">
                    <TokenIcon tokenName={selectedWallet.currency} />
                    <div className="settings-form__selected-wallet-info">
                      {selectedWallet.currency} - {selectedWallet.balance.value}
                    </div>
                  </div>
                  <img src={withdrawalIcon} alt="exchange" className="settings-form__exchange-wrapper-icon" />
                </Col>
                <Col span={currentWidth >= 1024 ? 12 : 24}>
                  <Form.Item
                    label={`${t('withdraw.sendTo')}:`}
                    name="walletTo"
                    className="form__item"
                    onChange={e => setTo(e.target.value)}
                  >
                    <Input className="form__input" placeholder={t('wallets.walletAddress')} />
                    {showWarning ? <span className="max-warning">Fill in the recipient's address first</span> : ''}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={currentWidth >= 1024 ? 12 : 24} className="settings-form__exchange-wrapper">
                  <label className="withdraw-amount">
                    <div className="settings-form__exchange-wrapper_label withdraw-amount__label">
                      {t('withdraw.enterAmount')}:{' '}
                      <div className="settings-form__exchange-wrapper_min-value">
                        Minimum {withdrawalInfo?.minimumWithdrawalUsd ?? '0'}$
                      </div>
                    </div>
                    <div className="withdraw-amount__input-wrapper">
                      <input
                        type="number"
                        value={amount}
                        placeholder={t('amount')}
                        className="form__input settings-form__input-usd withdraw-amount__input"
                        onChange={e => {
                          if (e.target.value < 0) {
                            setAmount('');
                            return;
                          }

                          if (
                            !e.target.value.toString().split('.')[1] ||
                            e.target.value.toString().split('.')[1].length <= decimalsLimit
                          ) {
                            setAmount(e.target.value);
                            setFetchTimer(convertTokensToUsd, e.target.value);
                            setShowDecimalsWarning(false);
                          } else {
                            setShowDecimalsWarning(true);
                            setAmount(amount);
                            setFetchTimer(convertTokensToUsd, amount);
                          }
                        }}
                      />
                      <img
                        src={repeatIcon}
                        alt="exchange"
                        className={cn('settings-form__exchange-wrapper-icon-bottom', {
                          'settings-form__exchange-wrapper-icon-animated': fetchingMaxAmount,
                        })}
                      />
                      <div className="max-container" onClick={handleEstimateMax}>
                        <span className="max-text">max</span>
                      </div>
                    </div>
                    {showDecimalsWarning && (
                      <span className="withdraw-amount__decimals-limit">
                        The maximum decimal value for {selectedWallet.currency} is {decimalsLimit}.
                      </span>
                    )}
                  </label>
                </Col>
                <Col span={currentWidth >= 1024 ? 12 : 24}>
                  <Form.Item label={`${t('withdraw.exchangeRate')} in USD:`} name="exchange" className="form__item">
                    <NumberFormat
                      value={exchange}
                      className="form__input settings-form__input-usd"
                      thousandSeparator={true}
                      prefix={'$'}
                      onValueChange={(values, sourceInfo) => {
                        if (sourceInfo.event) {
                          setFetchTimer(convertUsdToTokens, values.value);
                        }
                      }}
                      onFocus={e => e.target.select()}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowEmptyFormatting={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="withdraw__buttons">
                <Col span={currentWidth >= 1024 ? 8 : 11}>
                  <Button type="secondary" className="form__button" onClick={clearWithdrawState}>
                    {t('back')}
                  </Button>
                </Col>
                <Col span={currentWidth >= 1024 ? 8 : 11} offset={currentWidth >= 1024 ? 8 : 1}>
                  <Button type="primary" className="form__button" onClick={handleSubmit} loading={fetching}>
                    {t('submit')}
                  </Button>
                </Col>
              </Row>
              <div className="settings-form__system-fee">{t('wallets.payment', { systemFeeWithdrawal })}</div>
            </Col>
          </Row>
        </Form>
        <div className="withdraw-fee">
          <div className="withdraw-fee__title">{t('total')}</div>
          <div className="withdraw-fee__value">
            <span className="withdraw-fee__description">{t('wallets.feeCalc')}</span>
            <span>
              {amount ? `${amount} ${selectedWallet.currency}` : ''}
              {fees ? ` + ${fees.minerFee} ${fees.currencyMinerFee} + ${fees.systemFee} ${fees.currencyFee}` : ''}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  fetching: state.withdraw.fetching,
  fetchingMaxAmount: state.withdraw.fetchingMaxAmount,
});

const mapDispatchToProps = dispatch => ({
  makeWithdraw: makeWithdraw(dispatch),
  withdrawalEstimateMaxReset: () => dispatch(withdrawalEstimateMaxReset()),
  withdrawResetTwoFa: () => dispatch(withdrawResetTwoFa()),
  getWithdrawalEstimateMax: getWithdrawalEstimateMax(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawForm);
