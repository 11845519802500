import React, {useState, useEffect} from 'react'
import Modal from '../../adminPanel/components/Modal'
import StartStep from './StartStep';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './style.scss';
import VerificationStep from './VerificationStep';
import CodeStep from './CodeStep';

const OrderCard = ({showCardModal, setShowCardModal, userData}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [fullName, setFullName] = useState('')
    const [countryResidence, setCountryResidence] = useState('')
    const [countryShipping, setCountryShipping] = useState('')

    const { t } = useTranslation();
    const history = useHistory()

    useEffect(() => {
      if (userData.residenceCountry && userData.shippingCountry) {
        setCurrentStep(3)
      }
    }, [userData]);

    const nextStep = () => {
        setCurrentStep(currentStep + 1)

        if (currentStep === 3) {
            handleClose()
            history.push('/');
            setCurrentStep(0)
        }
     }


    const currentTitle = currentStep === 1 ? t('orderCard.stepOne.header') : currentStep === 2 ? t('orderCard.stepTwo.header') : t('orderCard.stepZero.header')

    const handleClose = () => {
        setShowCardModal(false)
        setCurrentStep(0)
    }

  return (
    <Modal
    title={currentTitle}
    width={360}
    visible={showCardModal}
    onCancel={handleClose}
    maskClosable={false}
    footerComponent={null}
    >
        <div className='step-container'>
            {currentStep === 0 ? <StartStep currentStep={currentStep} nextStep={nextStep} /> : null }
            {currentStep === 1 ?
            <VerificationStep
            nextStep={nextStep}
            fullName={fullName}
            setFullName={setFullName}
            countryResidence={countryResidence}
            setCountryResidence={setCountryResidence}
            countryShipping={countryShipping}
            setCountryShipping={setCountryShipping}
            />  : null}
            {currentStep === 2 ? <CodeStep
            nextStep={nextStep}
            fullName={fullName}
            setFullName={setFullName}
            countryResidence={countryResidence}
            setCountryResidence={setCountryResidence}
            countryShipping={countryShipping}
            setCountryShipping={setCountryShipping}
             /> : null}
            {currentStep === 3 ? <StartStep currentStep={currentStep} nextStep={nextStep} /> : null }
        </div>

    </Modal>
  )
}

export default OrderCard