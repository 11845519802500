import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';
import { showReleaseNotes } from '../../redux/actions/releaseNotes';
import releaseNotesArray from './releaseNotesArray';
import { ReactComponent as ReleaseNotesIcon } from '../../img/realeaseNotes.svg';
import './style.scss';

const ReleaseNotes = ({ showReleaseNotes }) => {
  const [menu, setMenu] = useState([]);
  const { t, i18n } = useTranslation();

  const releases = releaseNotesArray(t);

  useEffect(() => {
    setMenu(
      <Menu>
        {releases.map(release => {
          return (
            <Menu.Item onClick={() => showReleaseNotes(release)}>
              <div className="release-notes__item">
                <span className="release-notes__version">
                  {t('releaseNotes.version')} {release.version}
                </span>
                <span className="release-notes__date">
                  {t(release.month.toLowerCase())} {t(release.time.toLowerCase())}
                </span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [i18n.language]);

  return (
    <div
      className={cn('release-notes', {
        ['release-notes__new']: localStorage.getItem('yomiLastVersion') !== process.env.REACT_APP_VERSION,
      })}
    >
      <Dropdown overlay={menu} trigger="click" placement="bottomCenter">
        <ReleaseNotesIcon />
      </Dropdown>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  showReleaseNotes: showReleaseNotes(dispatch),
});

export default connect(null, mapDispatchToProps)(ReleaseNotes);
