import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tooltip, Popover } from 'antd';
import TEXT from '../Text';
import moment from 'moment';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import emptyImage from '../../../img/empty.svg';
import { ReactComponent as SortingArrow } from '../../../img/adminPanel/sorting-arrow.svg';

import './style.scss';

const UsersList = ({ usersList, setSelectedUserId, usersFetching, handleClick }) => {
  const [sortingIsClicked, setSortingIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { t } = useTranslation();

  const timer = useRef(null);

  const handleChange = e => {
    setSortingIsClicked(prev => !prev);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  const copyHandler = (email, phone, metamaskAddress, e) => {
    const credential = email || phone || metamaskAddress;
    e.stopPropagation();
    navigator.clipboard.writeText(credential);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 500);
  };

  const mouseEnterHandler = userId => {
    setCurrentUser(userId);
    setShowTooltip(true);
    setShowPopover(false);
  };

  return (
    <div className="admin-users__list">
      <div className="admin-users__list-row admin-users__list-row_legend">
        <div className="admin-users__list-item">
          <TEXT.grayBold>Profile</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Email/Phone/Metamask address</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Date of registration</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item">
          <TEXT.grayBold>Date of last login</TEXT.grayBold>
        </div>
        {/* <div className="admin-users__list-item">
          <TEXT.grayBold>Role</TEXT.grayBold>
        </div> */}
        <div className="admin-users__list-item">
          <TEXT.grayBold>Ref ID</TEXT.grayBold>
        </div>
        <div className="admin-users__list-item admin-users__balance">
          <TEXT.grayBold>
            Mainnet <br /> Balance
          </TEXT.grayBold>
          <div className={cn('sorting-icon', { 'sorting-icon-clicked': sortingIsClicked })}>
            <Dropdown
              placement="bottomRight"
              trigger={['click', 'hover']}
              onVisibleChange={handleChange}
              overlay={
                <Menu>
                  <Menu.Item onClick={e => handleClick('DESC')}>From highest to lowest</Menu.Item>
                  <Menu.Item onClick={e => handleClick('ASC')}>From lowest to highest</Menu.Item>
                </Menu>
              }
            >
              <SortingArrow className="icon" />
            </Dropdown>
          </div>
        </div>
      </div>

      {!usersFetching ? (
        <>
          {usersList.length ? (
            usersList.map(user => (
              <div className="admin-users__list-row" key={user.id}>
                <div className="admin-users__list-item">
                  <div
                    className={`admin-users__color-status ${
                      user.isActive ? 'admin-users__color-status_active' : 'admin-users__color-status_inactive'
                    }`}
                  ></div>
                  <TEXT.darkBold>
                    {user.name}
                    <br />
                    {user.surname}
                  </TEXT.darkBold>
                </div>

                <Tooltip title={t('clickToCopy')} visible={user.id === currentUser && showTooltip} placement="top">
                  <Popover
                    title=""
                    content={t('copied')}
                    trigger="click"
                    visible={user.id === currentUser && showPopover}
                    hidden={true}
                  >
                    <div
                      className={`admin-users__list-item admin-users__email`}
                      onClick={e => copyHandler(user.email, user.phone, user.metamask?.address, e)}
                      onMouseEnter={() => mouseEnterHandler(user.id)}
                      onMouseLeave={() => setCurrentUser(null)}
                    >
                      <TEXT.darkBold>{user.email || user.phone || user?.metamask?.address}</TEXT.darkBold>
                      {user.email && (
                        <TEXT.grayBoldSmall>{user.emailConfirmed ? 'Confirmed' : 'Not confirmed'}</TEXT.grayBoldSmall>
                      )}
                    </div>
                  </Popover>
                </Tooltip>

                <div className="admin-users__list-item">
                  <TEXT.darkBold>{moment(user.createdAt).format('MMMM D, YYYY, HH:mm')}</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>
                    {user.lastLogin ? moment(user.lastLogin).format('MMMM D, YYYY, HH:mm') : '-'}
                  </TEXT.darkBold>
                </div>
                {/* <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.role || '-'}</TEXT.darkBold>
                </div> */}
                <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.refId || '-'}</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <TEXT.darkBold>{user.balanceUSD.toFixed(2) || 0}$</TEXT.darkBold>
                </div>
                <div className="admin-users__list-item">
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <a onClick={() => setSelectedUserId(user.id)}>Profile</a>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-edit/${user.id}`}>Edit</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-merchants/${user.id}`}>Merchants</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to={`/admin/user-transactions/${user.id}`}>Transactions</Link>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <span className="admin-users__actions">...</span>
                  </Dropdown>
                </div>
              </div>
            ))
          ) : (
            <div className="admin-users__empty">
              <span className="admin-users__empty-title">Users will show up here.</span>
              <img src={emptyImage} className="admin-users__empty-image" alt="empty" />
            </div>
          )}
        </>
      ) : (
        <div className="admin-users__loader">
          <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default UsersList;
