import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { sendPassword } from '../../../redux/actions/user';
import emailIcon from '../../../img/email.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from '../style.module.scss';

const SendPasswordForm = ({ sendPassword }) => {
  const [fetching, setFetching] = useState(false);
  const recaptchaRef = useRef();
  const { t } = useTranslation();

  const handleFormSubmit = async values => {
    const recaptcha = await recaptchaRef.current.executeAsync();

    const formData = {
      email: values.email.trim(),
    };

    try {
      setFetching(true);
      await sendPassword(formData, recaptcha);
      setFetching(false);
    } catch (err) {
      setFetching(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['login-wrapper__title']}>{t('auth.resetPassword')}</div>

      <Form initialValues={{ email: '' }} onFinish={handleFormSubmit} className={styles['login-form']}>
        <span className={styles['login-form__label']}>{t('email')}</span>
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input
            className={styles['login-form__input']}
            placeholder={t('email')}
            suffix={<img src={emailIcon} alt="email icon" />}
          />
        </Form.Item>

        <Button className={styles['login-form__button']} type="submit" disabled={fetching} loading={fetching}>
          {t('auth.sendPassword')}
        </Button>

        <Link to="password-reset">
          <Button
            className={`${styles['login-form__button']} ${styles['login-form__button-secondary']}`}
            type="secondary"
            disabled={fetching}
          >
            {t('auth.resetPassword')}
          </Button>
        </Link>

        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
      </Form>

      <div className={styles['login-form__auth-links']}>
        <Link to="/login">{t('logIn')}</Link>
        <Link to="/sign-up">{t('signUp')}</Link>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  sendPassword: sendPassword(dispatch),
});

export default connect(null, mapDispatchToProps)(SendPasswordForm);
