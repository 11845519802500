import React, { useState, useEffect } from 'react';
import getTokenIcon from '../../utils/getTokenIcon';
import defaultCoin from '../../img/defaultCoin.svg';

const TokenIcon = ({ tokenName, ...props }) => {
  const [iconSrc, setIconSrc] = useState(defaultCoin);

  useEffect(() => {
    let isMounted = true;
    getTokenIcon(tokenName).then(response => isMounted && setIconSrc(response));
    return () => {
      isMounted = false;
    };
  }, [tokenName]);

  return <img src={iconSrc} alt={`${tokenName} icon`} {...props} style={{scale:tokenName === 'BUSD' ? '2.1' : null}}/>;
};

export default TokenIcon;
