import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Dropdown, Radio } from 'antd';
import { getUserTransactions, getMoreUserTransactions } from '../../../redux/actions/adminPanel/adminUserTransactions';
import { useTranslation } from 'react-i18next';
import { InputSearch, RangePicker } from '../FormFields';
import CurrencyDropdown from '../CurrencyDropdown';
import { DownOutlined } from '@ant-design/icons';
import {
  getAdminTransactionsByUser,
  getAdminMoreTransactionsByUser,
} from '../../../redux/actions/adminPanel/adminTransactionsByUser';
import { useHistory } from 'react-router-dom';
import UserTransactionsMarkUpList from './UserTransactionsMarkUpList';
import { dateFormatterForRequest } from './helpers';
import { setAdminFiltersTransactions } from '../../../redux/actions/adminPanel/adminPageFilters';
import './style.scss';

const UserTransactionsList = ({
  transactions,
  getUserTransactions,
  transactionsFetching,
  getMoreUserTransactions,
  moreTransactionsFetching,
  countItem,
  page,
  typeNetwork,
  userSearchParams,
  getAdminTransactionsByUser,
  isFromTransactionsByUser,
  entitiesOfTransactionsByUser,
  fetchingTransactionsByUser,
  countTransactionsByUser,
  pageTransactionsByUser,
  fetchingMoreTransactionsByUser,
  getAdminMoreTransactionsByUser,
  userName,
  setAdminFiltersTransactions,
  adminFiltersTransactions
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [form] = Form.useForm();
  const [chosenCurrencyTitle, setCurrencyTitle] = useState(null);
  const [currencyObject, setCurrencyObject] = useState(null);

  useEffect(() => {
    const formValues = form.getFieldsValue();
    let formattedData;

    if(adminFiltersTransactions){
      handleTransactionsOnFiltersChange({ ...adminFiltersTransactions, selectedCurrencyObj:undefined });
      return;
    }

    if (formValues?.dateRange?.length > 0) {
      formattedData = dateFormatterForRequest(formValues);
    }

    if (!isFromTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getUserTransactions({ ...formattedData, typeNetwork })
        : getUserTransactions({ ...formValues, typeNetwork });
    }

    if (userSearchParams && isFromTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getAdminTransactionsByUser({ ...formattedData, user: userSearchParams, typeNetwork })
        : getAdminTransactionsByUser({ ...formValues, user: userSearchParams, typeNetwork });
    }
  }, [typeNetwork, userSearchParams]);

  const setCurrency = value => {
    form.setFieldsValue({ currencyId: value });
  };

  const setCurrencyName = currencyName => {
    setCurrencyTitle(currencyName);
  };

  const handleLoadMoreClick = () => {
    const formValues = form.getFieldsValue();
    const formattedData =
      formValues.dateRange && formValues.dateRange.length ? dateFormatterForRequest(formValues) : formValues;

    if (isFromTransactionsByUser && !fetchingMoreTransactionsByUser) {
      formValues?.dateRange?.length > 0
        ? getAdminMoreTransactionsByUser({
          ...formattedData,
          user: userSearchParams,
          typeNetwork,
          page: pageTransactionsByUser + 1,
        })
        : getAdminMoreTransactionsByUser({
          ...formValues,
          user: userSearchParams,
          typeNetwork,
          page: pageTransactionsByUser + 1,
        });
      return;
    }

    if (!isFromTransactionsByUser && !moreTransactionsFetching) {
      formValues?.dateRange?.length > 0
        ? getMoreUserTransactions({ ...formattedData, page: page + 1, typeNetwork })
        : getMoreUserTransactions({ ...formValues, page: page + 1, typeNetwork });
    }
  };

  const handleTransactionsOnFiltersChange = data => {
    if (data?.dateRange?.length > 0) {
      const formattedData = dateFormatterForRequest(data);
      !isFromTransactionsByUser
        ? getUserTransactions({ ...formattedData, typeNetwork })
        : getAdminTransactionsByUser({ ...formattedData, typeNetwork, user: userSearchParams });
      return;
    }

    !isFromTransactionsByUser
      ? getUserTransactions({ ...data, typeNetwork })
      : getAdminTransactionsByUser({ ...data, typeNetwork, user: userSearchParams });
  };


  const [initialFormState, setInitialFormState] = useState({
    search: adminFiltersTransactions?.search || '',
    order: adminFiltersTransactions?.order || 'DESC',
    dateRange: adminFiltersTransactions?.dateRange || [],
    type: adminFiltersTransactions?.type || 'replenishment,withdrawal,multisend,systemFee,Error',
    currencyId: adminFiltersTransactions?.currencyId,
  });

  console.log('initialFormState', initialFormState)

 
  useEffect(() => {
    const unlisten = history.listen(() => {
      console.log('User is leaving the page');
      setAdminFiltersTransactions({ 
        dateRange: [],
        search: '',
        order: 'DESC',
        type: 'replenishment,withdrawal,multisend,systemFee,Error',
        currencyId: undefined,
        selectedCurrencyObj: undefined
      });

    });

    return () => {
      console.log('Component is unmounting');
      unlisten();
      setAdminFiltersTransactions({
      dateRange: [],
      search: '',
      order: 'DESC',
      type: 'replenishment,withdrawal,multisend,systemFee,Error',
      currencyId: undefined,
      selectedCurrencyObj: undefined
    });
    };
  }, [history]);

  return (
    <div className="fee-transactions">
      <Form
        form={form}
        name="admin-fee-transactions-form"
        initialValues={initialFormState}
        onFinish={values => {
          setAdminFiltersTransactions({ 
            dateRange: form.getFieldValue('dateRange'), 
            search:form.getFieldValue('search'), 
            order: form.getFieldValue('order'),
            type: form.getFieldValue('type'),
            currencyId: form.getFieldValue('currencyId'),
            selectedCurrencyObj: currencyObject
          });

          handleTransactionsOnFiltersChange({ ...values })
        }
        }
      >
        <div className="fee-transactions__filters">
          <InputSearch
            name="search"
            onSearch={() => {
              form.submit();
            }}
            onReset={() => {
              form.setFieldsValue({ search: '' });
              setAdminFiltersTransactions({ search: '' })
              form.submit();
            }}
            // disabled={isFromTransactionsByUser}
          />
          <RangePicker 
          formValues={form.getFieldValue('dateRange')} 
          name="dateRange" 
           placeholder="Choose date of transaction" 
           submitAction={form.submit} 
           clearAction={() => {
            form.setFieldsValue({ dateRange: '' });
            setAdminFiltersTransactions({ dateRange: '' })
          }} />
          <div className="transaction-filters__sorting">
            <Dropdown
              overlay={
                <div className="transaction-filters__currencies-dropdown">
                  <Form.Item name="order">
                    <Radio.Group onChange={form.submit}>
                      <Radio value="DESC">Newest on top</Radio>
                      <Radio value="ASC">Oldest on top</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="dropdown-divider"></div>
                  <Form.Item name="type">
                    <Radio.Group onChange={form.submit}>
                      <Radio value="replenishment,withdrawal,multisend,systemFee,Error">
                        All
                      </Radio>
                      <Radio value="replenishment">Replenishment</Radio>
                      <Radio value="withdrawal">Withdrawal</Radio>
                      <Radio value="multisend">Multisend</Radio>
                      <Radio value="systemFee">System Fee</Radio>
                      <Radio value="Error">Error</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              }
            >
              <div className="transaction-filters__currencies-button">
                Sorting <DownOutlined />
              </div>
            </Dropdown>
          </div>
          <Form.Item name="currencyId" valuePropName="merchantCurrencies">
            <CurrencyDropdown 
                    setFunc={setCurrency} 
                    onChangeAction={form.submit}                     
                    setCurrencyName={setCurrencyName}
                    setCurrencyObject={setCurrencyObject}
                    preselectedCurrency={adminFiltersTransactions?.selectedCurrencyObj}
            />
          </Form.Item>
        </div>
        {!isFromTransactionsByUser ? (
          <UserTransactionsMarkUpList
            arrayData={transactions}
            fetchingFlag={transactionsFetching}
            typeNetwork={typeNetwork}
            form={form}
            page={page}
            countItem={countItem}
            handleLoadMoreClick={handleLoadMoreClick}
          />
        ) : (
          <UserTransactionsMarkUpList
            arrayData={entitiesOfTransactionsByUser}
            fetchingFlag={fetchingTransactionsByUser}
            typeNetwork={typeNetwork}
            form={form}
            page={pageTransactionsByUser}
            countItem={countTransactionsByUser}
            handleLoadMoreClick={handleLoadMoreClick}
            isFromTransactionsByUser
            userSearchParams={userSearchParams}
            userName={userName}
          />
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: state.adminUserTransactions.entities,
  transactionsFetching: state.adminUserTransactions.fetching,
  moreTransactionsFetching: state.adminUserTransactions.fetchingMore,
  countItem: state.adminUserTransactions.countItem,
  page: state.adminUserTransactions.page,
  typeNetwork: state.networkFilter,
  currentUserTransactionsData: state.adminTransactionsByUser,
  entitiesOfTransactionsByUser: state.adminTransactionsByUser.entities,
  fetchingTransactionsByUser: state.adminTransactionsByUser.fetching,
  countTransactionsByUser: state.adminTransactionsByUser.countItem,
  pageTransactionsByUser: state.adminTransactionsByUser.page,
  fetchingMoreTransactionsByUser: state.adminTransactionsByUser.fetchingMore,
  adminFiltersTransactions: state.adminFilters.transactions
});

const mapDispatchToProps = {
  getUserTransactions,
  getMoreUserTransactions,
  getAdminTransactionsByUser,
  getAdminMoreTransactionsByUser,
  setAdminFiltersTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionsList);
