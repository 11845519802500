import {
  TRANSACTIONS_GET,
  TRANSACTIONS_GET_MORE,
  TRANSACTIONS_SET,
  TRANSACTIONS_SET_MORE,
  TRANSACTIONS_RESET_PAGE,
  TRANSACTIONS_SET_ACTIVE_MERCHANT,
  TRANSACTIONS_ERROR,
} from '../actions/transactions';

export default function reducer(
  state = {
    data: [],
    countItem: null,
    merchantId: '',
    fetching: false,
    loadMoreFetching: false,
    page: 1,
    pages: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case TRANSACTIONS_GET:
      return {
        ...state,
        fetching: action.fetching,
      };
    case TRANSACTIONS_GET_MORE:
      return {
        ...state,
        loadMoreFetching: action.loadMoreFetching,
      };
    case TRANSACTIONS_SET:
      return {
        ...state,
        data: action.payload.entities,
        countItem: action.payload.countItem,
        fetching: action.fetching,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case TRANSACTIONS_SET_MORE:
      return {
        ...state,
        data: [...state.data, ...action.payload.entities],
        countItem: action.payload.countItem,
        loadMoreFetching: action.loadMoreFetching,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case TRANSACTIONS_RESET_PAGE:
      return {
        ...state,
        page: 1,
        pages: null,
      };
    case TRANSACTIONS_SET_ACTIVE_MERCHANT:
      return {
        ...state,
        merchantId: action.payload,
      };
    case TRANSACTIONS_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
