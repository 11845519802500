import {
  WITHDRAW_ADMIN_SYSTEM_FEE_REQUEST,
  WITHDRAW_ADMIN_SYSTEM_FEE_SUCCESS,
  WITHDRAW_ADMIN_SYSTEM_FEE_ERROR,
  WITHDRAW_ADMIN_CHANGE_TWO_FA_STATUS,
  WITHDRAW_ADMIN_CHANGE_EMAIL_TWO_FA_STATUS,
  WITHDRAW_ADMIN_RESET_TWO_FA,
  WITHDRAW_ADMIN_ESTIMATE_MAX_REQUEST,
  WITHDRAW_ADMIN_ESTIMATE_MAX_SUCCESS,
  WITHDRAW_ADMIN_ESTIMATE_MAX_ERROR,
  WITHDRAW_ADMIN_ESTIMATE_MAX_RESET,
} from '../../actions/adminPanel/adminFeeWithdraw';

export default function reducer(
  state = {
    fetching: false,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    maxAmount: null,
    fetchingMaxAmount: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case WITHDRAW_ADMIN_SYSTEM_FEE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case WITHDRAW_ADMIN_SYSTEM_FEE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case WITHDRAW_ADMIN_SYSTEM_FEE_ERROR:
      return {
        ...state,
        fetching: false,
      };
    case WITHDRAW_ADMIN_CHANGE_TWO_FA_STATUS:
      return {
        ...state,
        fetching: false,
        isAppTwoFa: action.payload,
      };
    case WITHDRAW_ADMIN_CHANGE_EMAIL_TWO_FA_STATUS:
      return {
        ...state,
        fetching: false,
        isEmailTwoFa: action.payload,
      };
    case WITHDRAW_ADMIN_RESET_TWO_FA:
      return {
        ...state,
        fetching: false,
        isAppTwoFa: false,
        isEmailTwoFa: false,
      };

    case WITHDRAW_ADMIN_ESTIMATE_MAX_REQUEST:
      return {
        ...state,
        fetchingMaxAmount: true,
        maxAmount: null,
        error: null,
      };

    case WITHDRAW_ADMIN_ESTIMATE_MAX_SUCCESS:
      return {
        ...state,
        fetchingMaxAmount: false,
        maxAmount: action.payload.amount,
      };

    case WITHDRAW_ADMIN_ESTIMATE_MAX_ERROR:
      return {
        ...state,
        fetchingMaxAmount: false,
        error: action.payload.data.message,
      };
    case WITHDRAW_ADMIN_ESTIMATE_MAX_RESET:
      return {
        ...state,
        maxAmount: null,
        error: null,
      };
    default:
      return state;
  }
}
