import React from 'react';

import Card from '../../components/Card';
import TEXT from '../../components/Text';
import UserTransactionsList from '../../components/UserTransactionsList';
import './style.scss';

const Transactions = () => (
  <div className="admin-user-transactions">
    <Card>
      <TEXT.pageTitle>User's Transactions</TEXT.pageTitle>
      <UserTransactionsList />
    </Card>
  </div>
);

export default Transactions;
