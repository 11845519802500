import React from 'react';
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import Button from "../Button";
import { deleteUser } from "../../redux/actions/user";
import { connect } from "react-redux";

const DeleteAccountStepOne = ({ okButtonAction, handleCancel, deleteUser }) => {
  const { t } = useTranslation();

  const deleteAccount = async () => {
    const res = await deleteUser();

    if (res && res.data.message && res.status === 'fail') {
      handleCancel();
    } else {
      okButtonAction();
    }
  }

  return (
    <div className="deleteAccount">
      <div>{t('profile.deleteConfirm')}</div>
      <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-create" onClick={deleteAccount} >
            {t('delete')}
          </Button>
        </Col>
        <Col span="12">
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel} >
            {t('cancelButton')}
          </Button>
        </Col>
      </Row>
    </div>
  )
};

const mapDispatchToProps = dispatch => ({
  deleteUser: deleteUser(dispatch),
});

export default connect(null, mapDispatchToProps)(DeleteAccountStepOne);