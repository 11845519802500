import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Modal } from 'antd';
import Button from '../Button';
import ChangePasswordModal from "./ChangePasswordModal";
import { cancelChangePassword, changePassword } from "../../redux/actions/user";
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import { notificationsErrorShow } from "../../redux/actions/notifications";

const ChangePasswordForm = ({ cancelChangePassword, passwordFetching, changePassword, notificationsErrorShow }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  const handleOpen = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    if (!passwordFetching) {
      form.resetFields();
      setIsVisible(false);
      setNewPassword('');
      setConfirmNewPassword('');
    }
  }, [passwordFetching]);

  const handleCancel = e => {
    setIsVisible(false);
    form.resetFields();
    setNewPassword('');
    setConfirmNewPassword('');
    cancelChangePassword();
  };

  const handlePasswordFormSubmit = async () => {
    if (newPassword.trim() === confirmNewPassword.trim()) {
      if (newPassword !== '' && confirmNewPassword !== '') {
        const res = await changePassword(newPassword.trim(), confirmNewPassword.trim());

        if (res && res.data.message) {
          return;
        } else {
          setIsVisible(true);
        }
      } else {
        notificationsErrorShow({ message: t('emptyPassword') });
      }
    } else {
      notificationsErrorShow({ message: t('errorPassword') });
    }
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        className="form"
      >
        <span className="form__title">{t('password')}</span>
        <span className="form__subtitle">{t('profile.passwordUsedToAccessToAccount')}</span>
        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 6 : 24}>
            <Form.Item label={t('newPassword')} name="profileNewPassword" className="form__item">
              <Input.Password
                className="form__input"
                placeholder={t('newPassword')}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={currentWidth >= 1024 ? 6 : 24}>
            <Form.Item label={t('confirmPassword')} name="profileConfirmPassword" className="form__item">
              <Input.Password
                className="form__input"
                placeholder={t('confirmPassword')}
                onChange={e => setConfirmNewPassword(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 0]}>
          <Col span={currentWidth >= 1024 ? 4 : 24}>
            <Button
              type="submit"
              className="form__button"
              onClick={handlePasswordFormSubmit}
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        width={534}
        title={t('confirm2fa')}
        visible={isVisible}
        onOk={handleOpen}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete"
      >
        <ChangePasswordModal isVisible={isVisible} password={newPassword.trim()} confirmNewPassword={confirmNewPassword.trim()} />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  userDataFetching: state.user.fetching,
  passwordFetching: state.user.passwordFetching,
});

const mapDispatchToProps = dispatch => ({
  changePassword: changePassword(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
  cancelChangePassword: cancelChangePassword(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
