import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input } from 'antd';
import Button from '../Button';
import { disableTwoFactor } from '../../redux/actions/twoFactor';
import { changeTwoFactorStatus } from '../../redux/actions/user';
import { useTranslation } from 'react-i18next';
import '../../layout/Modal.scss';

const AppTwoFaDisableModal = ({ appTwoFaFetching, disableTwoFactor, changeTwoFactorStatus }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const response = await disableTwoFactor(twoFactorCode);
    if (response) {
      changeTwoFactorStatus(false);
      setIsVisible(false);
    }
  };

  const handleCancel = e => {
    setIsVisible(false);
  };

  return (
    <React.Fragment>
      <Button type="primary" className="form__button" onClick={() => setIsVisible(true)}>
        {t('profile.disableTwoFa')}
      </Button>
      <Modal
        width={534}
        title={t('profile.disableTwoFa')}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-form"
        >
          <Form.Item label={t('auth.keyFromGA')} name="authCode" className="modal-form__label">
            <Input
              placeholder={t('auth.keyFromGA')}
              className="modal-form__input"
              maxLength="6"
              onChange={e => setTwoFactorCode(e.target.value)}
            />
          </Form.Item>
        </Form>
        <Row className="modal__buttons-wrapper">
          <Col span="12">
            <Button
              loading={appTwoFaFetching}
              disabled={appTwoFaFetching}
              type="primary"
              className="modal__button modal__button-create"
              onClick={handleSubmit}
            >
              {t('disable')}
            </Button>
          </Col>
          <Col span="12">
            <Button
              type="primary"
              className="modal__button modal__button-cancel"
              onClick={handleCancel}
              disabled={appTwoFaFetching}
            >
              {t('cancel')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appTwoFaFetching: state.twoFactor.appTwoFaFetching,
});

const mapDispatchToProps = dispatch => ({
  disableTwoFactor: disableTwoFactor(dispatch),
  changeTwoFactorStatus: changeTwoFactorStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppTwoFaDisableModal);
