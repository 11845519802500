import React from 'react';
import { Modal as AntdModal } from 'antd';
import './style.scss';

const Modal = ({ title, visible, width, footerComponent, onCancel, maskClosable = true, children }) => {
  return (
    <AntdModal
      title={title}
      className="admin-modal"
      visible={visible}
      destroyOnClose={true}
      width={width}
      // onOk={handleOk}
      onCancel={onCancel}
      footer={footerComponent}
      maskClosable={maskClosable}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
