import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/resizeHook";
import DeleteAccountModal from "./DeleteAccountModal";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  return (
    <div className="profile-block">
      <span className="profile__title">
        {t('deleteAccount')}
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <DeleteAccountModal />
        </Col>
      </Row>
    </div>
  )
};

export default DeleteAccount;