import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

export const SWAP_HISTORY_GET = 'SWAP_HISTORY_GET';
export const SWAP_HISTORY_SET = 'SWAP_HISTORY_SET';
export const SWAP_HISTORY_ERROR = 'SWAP_HISTORY_ERROR';

export const swapHistoryGet = page => ({
  type: SWAP_HISTORY_GET,
  page,
});

export const swapHistorySet = data => ({
  type: SWAP_HISTORY_SET,
  payload: data,
});

export const swapHistoryError = () => ({
  type: SWAP_HISTORY_ERROR,
});

export const getSwapHistory = dispatch => async (merchantId, body) => {
  dispatch(swapHistoryGet(body.page));

  try {
    const response = await cryptoApi.getSwapHistory(merchantId, body);

    if (response && response.data) {
      dispatch(swapHistorySet(response.data));
    }
  } catch (error) {
    dispatch(swapHistoryError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const SWAP_EXCHANGE_WALLETS_GET = 'SWAP_EXCHANGE_WALLETS_GET';
export const SWAP_EXCHANGE_WALLETS_SET = 'SWAP_EXCHANGE_WALLETS_SET';
export const SWAP_EXCHANGE_WALLETS_RESET = 'SWAP_EXCHANGE_WALLETS_RESET';
export const SWAP_EXCHANGE_WALLETS_ERROR = 'SWAP_EXCHANGE_WALLETS_ERROR';

export const swapExchangeWalletsGet = () => ({
  type: SWAP_EXCHANGE_WALLETS_GET,
});

export const swapExchangeWalletsSet = data => ({
  type: SWAP_EXCHANGE_WALLETS_SET,
  payload: data,
});

export const swapExchangeWalletsReset = () => ({
  type: SWAP_EXCHANGE_WALLETS_RESET,
});

export const swapExchangeWalletsError = () => ({
  type: SWAP_EXCHANGE_WALLETS_ERROR,
});

export const getSwapExchangeWallets = dispatch => async (merchantId, params) => {
  const { currencyIds } = params;

  dispatch(swapExchangeWalletsGet());

  try {
    const wallets = await Promise.all(
      currencyIds.map(currencyId => cryptoApi.getMerchantWallets(merchantId, { ...params, currencyIds: currencyId }))
    );

    if (wallets && wallets[0].data && wallets[1].data) {
      dispatch(swapExchangeWalletsSet([wallets[0].data, wallets[1].data]));
    }
  } catch (err) {
    dispatch(swapExchangeWalletsError());
    dispatch(notificationsErrorShow(err.data));
  }
};

export const SWAP_EXCHANGE_WALLET_GET = 'SWAP_EXCHANGE_WALLET_GET';
export const SWAP_EXCHANGE_WALLET_SET = 'SWAP_EXCHANGE_WALLET_SET';

export const swapExchangeWalletGet = () => ({
  type: SWAP_EXCHANGE_WALLET_GET,
});

export const swapExchangeWalletSet = data => ({
  type: SWAP_EXCHANGE_WALLET_SET,
  payload: data,
});

export const getSwapExchangeWallet= dispatch => async (merchantId, params, currencyFieldIsEdited) => {

  dispatch(swapExchangeWalletGet());

  try {
    const wallets = await cryptoApi.getMerchantWallets(merchantId, { ...params, currencyIds: params.currencyIds });

    if (wallets && wallets?.data?.entities) {
      dispatch(swapExchangeWalletSet({data:wallets.data,number:currencyFieldIsEdited}))
    }

  } catch (err) {
    dispatch(swapExchangeWalletsError());
    dispatch(notificationsErrorShow(err.data));
  }
};

export const SWAP_OFFERS_GET = 'SWAP_OFFERS_GET';
export const SWAP_OFFERS_SET = 'SWAP_OFFERS_SET';
export const SWAP_OFFERS_RESET = 'SWAP_OFFERS_RESET';
export const SWAP_OFFERS_ERROR = 'SWAP_OFFERS_ERROR';

export const swapOffersGet = () => ({
  type: SWAP_OFFERS_GET,
});

export const swapOffersSet = data => ({
  type: SWAP_OFFERS_SET,
  payload: data,
});

export const swapOffersReset = () => ({
  type: SWAP_OFFERS_RESET,
});

export const swapOffersError = () => ({
  type: SWAP_OFFERS_ERROR,
});

export const getSwapOffers = dispatch => async body => {
  dispatch(resetSwapOffersUpdatedData());
  dispatch(swapOffersGet());

  try {
    const response = await cryptoApi.getSwapOffers(body);

    if (response && response.data) {
      dispatch(swapOffersSet(response.data));
    }
  } catch (error) {
    dispatch(swapOffersError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const SWAP_OFFERS_UPDATE_GET = 'SWAP_OFFERS_UPDATE_GET';
export const SWAP_OFFERS_UPDATE_SET = 'SWAP_OFFERS_UPDATE_SET';
export const SWAP_OFFERS_UPDATE_APPLY = 'SWAP_OFFERS_UPDATE_APPLY';
export const SWAP_OFFERS_UPDATE_ERROR = 'SWAP_OFFERS_UPDATE_ERROR';
export const RESET_SWAP_OFFERS_UPDATED_DATA = 'RESET_SWAP_OFFERS_UPDATED_DATA';


export const swapOffersUpdateGet = () => ({
  type: SWAP_OFFERS_UPDATE_GET,
});

export const swapOffersUpdateSet = data => ({
  type: SWAP_OFFERS_UPDATE_SET,
  payload: data,
});

export const swapOffersUpdateApply = () => ({
  type: SWAP_OFFERS_UPDATE_APPLY,
});

export const swapOffersUpdateError = () => ({
  type: SWAP_OFFERS_UPDATE_ERROR,
});

export const resetSwapOffersUpdatedData=()=>({
  type: RESET_SWAP_OFFERS_UPDATED_DATA
});

export const updateSwapOffers = dispatch => async body => {
  dispatch(swapOffersUpdateGet());

  try {
    const response = await cryptoApi.getSwapOffers(body);

    if (response && response.data) {
      dispatch(swapOffersUpdateSet(response.data));
    }
  } catch (error) {
    dispatch(swapOffersUpdateError());
    dispatch(notificationsErrorShow(error.data));
  }
};

export const SWAP_EXCHANGE_GET = 'SWAP_EXCHANGE_GET';
export const SWAP_EXCHANGE_SET = 'SWAP_EXCHANGE_SET';
export const SWAP_EXCHANGE_ERROR = 'SWAP_EXCHANGE_ERROR';
export const RESET_SWAP_EXCHANGE_ERROR = 'RESET_SWAP_EXCHANGE_ERROR';

export const swapExchangeGet = () => ({
  type: SWAP_EXCHANGE_GET,
});

export const swapExchangeSet = data => ({
  type: SWAP_EXCHANGE_SET,
  payload: data,
});

export const swapExchangeError = data => ({
  type: SWAP_EXCHANGE_ERROR,
  payload: data,
});

export const resetExchangeError = () => ({
  type: RESET_SWAP_EXCHANGE_ERROR
});

export const makeSwap = dispatch => async body => {
  dispatch(swapExchangeGet());

  try {
    const response = await cryptoApi.makeSwap(body);

    if (response && response.status === 'success') {
      dispatch(swapExchangeSet(response.data));
    }
  } catch (error) {
    dispatch(swapExchangeError(error.data.message));
  }
};
