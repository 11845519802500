import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { forgottenPassword } from '../../../redux/actions/user';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import PasswordChanged from './PasswordChanged';
import PasswordStrength from '../../PasswordStrength';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.scss';

const NewPasswordForm = ({ forgottenPassword, notificationsErrorShow }) => {
  const [password, setPassword] = useState('');
  const [responseToken, setResponseToken] = useState('');
  const { token } = useParams();
  const { t } = useTranslation();

  const handleFormSubmit = async values => {
    if (values.password === values.repeatPassword) {
      const response = await forgottenPassword(token, {
        password: values.password.trim(),
        repeatPassword: values.repeatPassword.trim(),
      });

      if (response && response.token) {
        setResponseToken(response.token);
      }
    } else {
      notificationsErrorShow({
        message: t('validation.passwordsShouldMatch'),
      });
    }
  };

  return (
    <div className={styles['login-wrapper']}>
      {responseToken ? (
        <PasswordChanged responseToken={responseToken} />
      ) : (
        <>
          <div className={styles['login-wrapper__title']}>{t('auth.resetPassword')}</div>
          <Form
            initialValues={{ password: '', repeatPassword: '' }}
            onFinish={handleFormSubmit}
            className={styles['login-form']}
          >
            <span className={styles['login-form__label']}>{t('newPassword')}</span>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('validation.required') }]}
              className={styles['login-form__item']}
            >
              <Input.Password
                className={styles['login-form__input']}
                placeholder={t('newPassword')}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Item>

            <span className={styles['login-form__label']}>{t('confirmPassword')}</span>
            <Form.Item
              name="repeatPassword"
              rules={[{ required: true, message: t('validation.required') }]}
              className={styles['login-form__item']}
            >
              <Input.Password className={styles['login-form__input']} placeholder={t('confirmPassword')} />
            </Form.Item>

            <PasswordStrength password={password} />

            <Button className={styles['login-form__button']} type="submit">
              {t('logIn')}
            </Button>

            <div className={styles['login-form__auth-links']}>
              <Link to="/login">{t('logIn')}</Link>
              <Link to="/sign-up">{t('signUp')}</Link>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

// const mapStateToProps = state => ({
//   fetchingUser: state.login.fetching,
// });

const mapDispatchToProps = dispatch => ({
  forgottenPassword: forgottenPassword(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(
  // mapStateToProps,
  null,
  mapDispatchToProps
)(NewPasswordForm);
