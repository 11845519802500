import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Select, Popover, Spin } from 'antd';
import TokenIcon from '../../TokenIcon';
import { cryptoApi } from '../../../service/cryptopay-api';
import formatCurrency from '../../../utils/currencyFormatter';
import { ReactComponent as SearchIcon } from '../../../img/swap-search.svg';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-select-arrow.svg';
import './style.scss';

const SwapAmountField = ({
  currencies,
  merchantId,
  title,
  exchangeField,
  handleInputChange,
  handleCurrencyChange,
  merchantBalances,
  merchantBalance,
  disabled,
  walletFromId,
  exchangeWalletsFrom,
  withMax,
  swapOffers,
  swapOffersFetching,
  swapOffersReset,
  setMaxAmountFetching,
  notificationsErrorShow,
  swapOffersUpdating,
  polkaDotLimitWarning,
  setPolkaDotLimitWarning,
  youSendFiledValue,
  setDataIsFrozen,
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [showMaxButton, setShowMaxButton] = useState(false);
  const [decimalLimit, setDecimalLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const { t } = useTranslation();

  const [data, setData] = useState(merchantBalances);

  useEffect(() => {
    setData(merchantBalances)
  }, [merchantBalances])

  const getMaxAmount = async () => {
    setMaxAmountFetching(true);
    const selectedWallet = exchangeWalletsFrom.entities.find(wallet => wallet._id === walletFromId);

    try {
      const response = await cryptoApi.withdrawalEstimateMax(merchantId, walletFromId, {
        to: selectedWallet.address,
        currencyToken: selectedWallet.tokens.length ? selectedWallet.tokens[0].currencyId : undefined,
        isSwap: selectedWallet.currency === 'BTC' ? true : undefined,
      });

      if (response && response.status === 'success') {
        handleInputChange(`${response.data.amount}`, exchangeField.index);
      }
    } catch (error) {
      notificationsErrorShow({ message: error.data.message });
    }

    setMaxAmountFetching(false);
  };

  useEffect(() => {
    if (exchangeField.index === 0 && exchangeField.selectedCurrency) {
      setShowDecimalsWarning(false);
    }
  }, [exchangeField.selectedCurrency]);

  useEffect(() => {
    if (currencies && currencies.length && exchangeField.selectedCurrency) {
      setDecimalLimit(currencies.find(currency => currency._id === exchangeField.selectedCurrency).decimals);
    }
  }, [currencies, exchangeField.selectedCurrency]);

  useEffect(() => {
    if (withMax && merchantId && walletFromId && exchangeWalletsFrom.entities.length) {
      setShowMaxButton(true);
    } else {
      setShowMaxButton(false);
    }
  }, [withMax, merchantId, walletFromId, exchangeWalletsFrom]);


  useEffect(()=>{
    if((youSendFiledValue && youSendFiledValue !== 0) && exchangeField?.index === 1 && exchangeField?.selectedCurrency === "62e7c3669c167b624bd84f37" && exchangeField?.value < 1.03){
      setPolkaDotLimitWarning(true);
      return;
    } 
    setPolkaDotLimitWarning(false);
  },[exchangeField]);

  return (
    <div className="swap-amount-field">
      <div className="swap-amount-field__title">
        {title}
        {showMaxButton && (
          <div className="swap-amount-field__max" onClick={!swapOffersUpdating && getMaxAmount}>
            Max
          </div>
        )}
      </div>
      <div className="swap-amount-field__input">
        <input
          type="number"
          placeholder={t('swap.enterAmount')}
          min={0}
          value={exchangeField.value}
          disabled={disabled || swapOffersUpdating}
          onChange={e => {
            setShowDecimalsWarning(false);

            if (+e.target.value < 0) {
              handleInputChange(0, exchangeField.index);
              exchangeField.index === 0 && handleInputChange(0, 1);
              swapOffersReset();
              return;
            } else if (+e.target.value === 0) {
              exchangeField.index === 0 && handleInputChange(0, 1);
              swapOffersReset();
            }

            if (!e.target.value.split('.')[1] || e.target.value.split('.')[1].length <= decimalLimit) {
              handleInputChange(e.target.value, exchangeField.index);
            } else {
              setShowDecimalsWarning(true);
              handleInputChange(exchangeField.value, exchangeField.index);
            }
          }}
        />
        <Select
          className={cn('swap-amount-field__select', {
            ['swap-amount-field__select_opened']: isDropdownOpened,
          })}
          disabled={swapOffersUpdating}
          showSearch
          optionFilterProp="children"
          suffixIcon={!isDropdownOpened ? <SelectArrowIcon /> : <SearchIcon />}
          open={isDropdownOpened}
          onDropdownVisibleChange={setIsDropdownOpened}
          value={
            data.length ? (
              exchangeField.selectedCurrency
            ) : (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                    }}
                    spin
                  />
                }
              />
            )
          }
          onChange={value => {
            handleCurrencyChange(value, exchangeField.index);
            setDataIsFrozen(false);
          }}
          dropdownClassName="swap-amount-field__dropdown"
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'br'],
          }}
          filterOption={(input, option) =>
            {
             return (option?.title ?? "").toLowerCase().includes(input.toLowerCase())
            }
          }
        >
          {data?.length &&
            data
              .sort((balance1, balance2) => balance2.balance.usd - balance1.balance.usd)
              .reduce((result, balance) => {
                const currency = currencies.find(currency => currency._id === balance.currency.id);

                if (currency.supportSwap && currency.name !== 'BNB') {
                  result.push(
                    <Select.Option value={currency._id} title={currency.title} key={currency._id}>
                      <div className="swap-amount-field__option-wrap">
                        <div className="swap-amount-field__option-currency">
                          <div className="swap-amount-field__token-icon">
                            <TokenIcon tokenName={currency.name} />
                          </div>
                          {currency.title}
                        </div>
                        <div className="swap-amount-field__option-balance">
                          <span>{balance.balance.value}</span>
                          <span>{formatCurrency(balance.balance.usd)}</span>
                        </div>
                      </div>
                    </Select.Option>
                  );
                }

                return result;
              }, [])}
        </Select>
      </div>
      <div className="swap-amount-field__balance">
        {merchantBalance && merchantBalance.currency.name} {t('swap.exchange.balance')}{' '}
        <span className="swap-amount-field__amount">{merchantBalance ? merchantBalance.balance.value : '-'}</span>
      </div>
      {exchangeField.index === 0 &&
        swapOffers &&
        swapOffers.length &&
        !swapOffersFetching &&
        swapOffers[0].fromAmount === +exchangeField.value &&
        +exchangeField.value < swapOffers[0].min &&
        !showDecimalsWarning && (
          <Popover title="" content={t('swap.clickToSelect')} className="detailed-checkout__popover">
            <span
              className="swap-amount-field__warning swap-amount-field__warning_min"
              onClick={() => handleInputChange(`${swapOffers[0].min}`, exchangeField.index)}
            >{`min ${swapOffers[0].min}`}</span>
          </Popover>
        )}

      {exchangeField.index === 0 && showDecimalsWarning && (
        <span className="swap-amount-field__warning swap-amount-field__warning">
          {`${t('swap.maxAmount')} ${
            currencies.find(currency => currency._id === exchangeField.selectedCurrency).name
          } ${t('swap.is')} ${decimalLimit}.`}
        </span>
      )}

    {exchangeField.index === 1 && polkaDotLimitWarning ? (
        <span className="swap-amount-field__warning swap-amount-field__warning">
          {t('swap.polkaDotLimit')}
        </span>
      ) : null}
    </div>
  );
};

export default SwapAmountField;
