import React, { useState, useEffect, useRef } from 'react';
import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import './style.scss';

const CurrencyItem = ({ currencyId, setCurrencyId, currencyBalance, title }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const timer = useRef(null);
  const {t} = useTranslation();

  useEffect(() => () => {
    clearTimeout(timer);
    timer.current = null;
  });

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  const copyHandler = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  return (
    <div
      className={`currency-item ${currencyId === currencyBalance.currency.id && 'currency-item_active'}`}
      onClick={() => {
        document.getElementsByClassName('main-layout__content-wrap')[0].scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setCurrencyId(currencyBalance.currency.id);
      }}
    >
      <div className="currency-item_left">
        <div className="currency-item__name-wrap">
          <TokenIcon className="currency-item__logo" tokenName={currencyBalance.currency.name} />
          <div className="currency-item__title">{title}</div>
        </div>
        <Tooltip title={t('clickToCopy')} visible={showTooltip} onVisibleChange={isVisible => setShowTooltip(isVisible)}>
          <Popover content={t("copied")}
            trigger="click" visible={showPopover} hidden={true}>
            <div className="currency-item__wallet" onClick={event => copyHandler(currencyBalance.wallet, event)}>
              {truncate(currencyBalance.wallet, 14, '..')}
            </div>
          </Popover>
        </Tooltip>
      </div>
      <div className="currency-item_right">
        <div className="currency-item__amount">
          {currencyBalance.balance.value} {currencyBalance.currency.name}
        </div>
        <div className="currency-item__usd">{formatCurrency(currencyBalance.balance.usd)}</div>
      </div>
    </div>
  );
};

export default CurrencyItem;
