import React from 'react';
import formatCurrency from '../../../../../utils/currencyFormatter';
import TokenIcon from '../../../../TokenIcon';
import './style.scss';

const CurrencyItem = ({ currency, setCurrency, currencyItem, title, currencyRef }) => {
  return (
    <div
      className={`withdraw-currency-item ${currency === currencyItem._id && 'withdraw-currency-item_active'}`}
      onClick={() =>  {
        currencyRef.current = currency;
        setCurrency(currencyItem._id);
      }}
    >
      <div className="withdraw-currency-item_left">
        <div className="currency-item__name-wrap">
          <TokenIcon className="withdraw-currency-item__logo" tokenName={currencyItem.name} />
          <div className="withdraw-currency-item__title">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyItem;
