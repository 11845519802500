import moment from "moment";

export const clearLocalStorage=()=>{
    const array=['users', 'Withdrawal', 'Replenishment', 'SystemFee', 'all']
    array.forEach(element => {
        localStorage.removeItem(`adm-date-${element}`);
    });

    localStorage.removeItem('dates-status')
   
};

export const getLocalStorageData=(type)=>{
    if(localStorage.getItem('adm-date-all')){
        return JSON.parse(localStorage.getItem(`adm-date-all`)).map(el => moment(el))
    }

    if(localStorage.getItem(`adm-date-${type}`)){
        return JSON.parse(localStorage.getItem(`adm-date-${type}`)).map(el => moment(el))
    }

    return [moment().startOf('month'), moment().endOf('month')];
}