import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import { loginWithSocialMedia } from '../../redux/actions/login';
import useWindowSize from '../../utils/resizeHook';
import LoginMetamask from './Metamask/LoginMetamask';
import metamaskAailabilityHook from '../../utils/metamaskHook';
import i18next from 'i18next';

import './styles.scss';

const customStyle = {
  backgroundColor: 'rgb(255, 255, 255)',
  display: 'inline-flex',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 0.54)',
  boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
  padding: '0px',
  borderRadius: '8px',
  border: '1px solid transparent',
  fontSsize: '14px',
  fontWeight: '500',
  fontFamily: 'Roboto, sans-serif',
};

const LoginSocialMedia = ({ loginWithSocialMedia, userType }) => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { currentWidth } = useWindowSize();
  const { metamaskAuthIsShown } = metamaskAailabilityHook();

 const checkId = () => { console.log('CHECK ID')}

  const logInSocials = response => {
      loginWithSocialMedia(response.credential, 'google', userType);
      checkId()
  };

  const loginWithGoogleFailed = () => {
    notificationsErrorShow('Something went wrong. Please, try again');
  };


  return (
    <div className="login-options-socials">
      <div onClick={checkId} id={pathname === '/login' ? 'login_google' : 'signup_google'} style={{paddingBottom: '2px'}}>
      <GoogleLogin
        onSuccess={logInSocials}
        onError={loginWithGoogleFailed}
        locale={i18next.language}
        text={pathname === '/login' ? 'signin_with' : 'signup_with'}
        width={310}
      />
      </div>

      {metamaskAuthIsShown ? <LoginMetamask userType={userType} /> : null}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  loginWithSocialMedia: loginWithSocialMedia(dispatch),
  notificationsErrorShow: notificationsErrorShow,
});

export default connect(null, mapDispatchToProps)(LoginSocialMedia);
