import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CurrencyItem from './CurrencyItem';
import emptyImage from '../../../../img/empty.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const CurrencySelector = ({ currencies, currenciesFetching, currency, setCurrency, currencyRef }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <div className="withdraw-currencies">
          <div className="withdraw-currencies__title">{t('multisendWithdraw.tokenMultipayment')}</div>
          <div className="withdraw-currencies__items-wrapper">
            {!currenciesFetching ? (
              currencies.length ? (
                currencies.map(
                  currencyItem =>
                    currencyItem.supportMultisend && (
                      <CurrencyItem
                        currency={currency}
                        setCurrency={setCurrency}
                        key={currencyItem._id}
                        currencyItem={currencyItem}
                        title={currencyItem.title}
                        currencyRef={currencyRef}
                      />
                    )
                )
              ) : (
                <div className="withdraw-wallets__empty">
                  <span className="transactions-list__empty-title">{t('wallets.emptyList')}</span>
                  <img src={emptyImage} className="transactions-list__empty-image" alt="empty" />
                </div>
              )
            ) : (
              <div className="withdraw-currencies__loader">
                <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 24 }} spin />} />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CurrencySelector;
