import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
export const MERCHANTS_GET = 'MERCHANTS_GET';
export const MERCHANTS_SET = 'MERCHANTS_SET';
export const MERCHANTS_CREATE = 'MERCHANTS_CREATE';
export const MERCHANTS_DELETE = 'MERCHANTS_DELETE';
export const MERCHANTS_ERROR = 'MERCHANTS_ERROR';
export const MERCHANT_NETWORK_CHANGE_REQUEST = 'MERCHANT_NETWORK_CHANGE_REQUEST';
export const MERCHANT_NETWORK_CHANGE_SUCCESS = 'MERCHANT_NETWORK_CHANGE_SUCCESS';
export const MERCHANT_NETWORK_CHANGE_ERROR = 'MERCHANT_NETWORK_CHANGE_ERROR';
export const MERCHANTS_SET_APP_TWO_FA = 'MERCHANTS_SET_APP_TWO_FA';
export const MERCHANTS_SET_EMAIL_TWO_FA = 'MERCHANTS_SET_EMAIL_TWO_FA';
export const MERCHANTS_RESET_TWO_FA = 'MERCHANTS_RESET_TWO_FA';

// ACTIONS GENERATORS
export const merchantsGet = () => ({
  type: MERCHANTS_GET,
  fetching: true,
});

export const merchantsSet = data => ({
  type: MERCHANTS_SET,
  fetching: false,
  payload: data,
});

export const merchantsCreate = () => ({
  type: MERCHANTS_CREATE,
  fetching: true,
});

export const merchantsDelete = () => ({
  type: MERCHANTS_DELETE,
  fetching: true,
});

export const merchantsError = () => ({
  type: MERCHANTS_ERROR,
});

export const merchantNetworkChangeRequest = merchantId => ({
  type: MERCHANT_NETWORK_CHANGE_REQUEST,
  fetching: true,
  payload: merchantId,
});

export const merchantNetworkChangeSuccess = () => ({
  type: MERCHANT_NETWORK_CHANGE_SUCCESS,
  fetching: false,
});

export const merchantNetworkChangeError = () => ({
  type: MERCHANT_NETWORK_CHANGE_ERROR,
});

export const merchantsSetAppTwoFa = isAppTwoFa => ({
  type: MERCHANTS_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const merchantsSetEmailTwoFa = isEmailTwoFa => ({
  type: MERCHANTS_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const merchantsResetTwoFa = () => ({
  type: MERCHANTS_RESET_TWO_FA,
});

// ASYNC ACTIONS
export const getMerchants = dispatch => async () => {
  dispatch(merchantsGet());
  let merchants;

  try {
    merchants = await cryptoApi.getMerchantsList();
  } catch (err) {
    dispatch(merchantsError());
    dispatch(notificationsErrorShow(err.data));
    return;
  }

  if (merchants && merchants.data) {
    dispatch(merchantsSet({ ...merchants.data }));
    return;
  }

  dispatch(merchantsError());
};

export const createMerchant = dispatch => async body => {
  dispatch(merchantsCreate());
  let merchant;

  try {
    merchant = await cryptoApi.createMerchant(body);
  } catch (err) {
    dispatch(merchantsError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (merchant && merchant.data) {
    dispatch(getMerchants(dispatch));
    dispatch(notificationsSuccessShow({}));
    return;
  }

  dispatch(merchantsError());
};

export const deleteMerchant = dispatch => async (merchantId, appTwoFaCode, emailTwoFaCode) => {
  dispatch(merchantsDelete());
  let response;

  try {
    response = await cryptoApi.deleteMerchant(merchantId, appTwoFaCode, emailTwoFaCode);
  } catch (err) {
    dispatch(merchantsError());
    dispatch(notificationsErrorShow(err.data || err));
  }

  if (response && response.message) {
    dispatch(merchantsResetTwoFa());
    dispatch(getMerchants(dispatch));
    dispatch(notificationsSuccessShow({}));
    return true;
  }

  if (response && response.data.twoFactorToken && response.data.emailSent) {
    dispatch(merchantsSetEmailTwoFa(true));
    dispatch(merchantsSetAppTwoFa(true));
    return;
  }

  dispatch(merchantsError());
};

export const changeMerchantNetwork = dispatch => async (merchantId, body) => {
  dispatch(merchantNetworkChangeRequest(merchantId));
  let response;

  try {
    response = await cryptoApi.changeMerchantNetwork(merchantId, body);

    if (response && response.data) {
      dispatch(merchantNetworkChangeSuccess());
      getMerchants(dispatch)();
    }
  } catch (err) {
    dispatch(merchantNetworkChangeError());
    dispatch(notificationsErrorShow(err.data));
  }
};
