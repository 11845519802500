import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';
import Button from '../Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { editUser } from '../../redux/actions/user';
import useWindowSize from '../../utils/resizeHook';

const PersonalInfo = ({ userData, userDataFetching, editUser }) => {
  const [userEmail, setUserEmail] = useState(userData.email);
  const [userPhone, setUserPhone] = useState(userData.phone);
  const [userName, setUserName] = useState(userData.name);
  const [userSurname, setUserSurname] = useState(userData.surname);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    setUserEmail(userData.email);
    setUserPhone(userData.phone);
    setUserName(userData.name);
    setUserSurname(userData.surname);

    form.resetFields();
  }, [userData]);

  const handleFormSubmit = () => {
    const formData = {
      email: userEmail,
      phone: userPhone,
      name: userName,
      surname: userSurname,
    };

    editUser(formData);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="form"
      initialValues={{
        userEmail: userData.email,
        userPhone: userData.phone,
        userName: userData.name,
        userSurname: userData.surname,
      }}
    >
      <span className="form__title">{t('profile.emailAndPhone')}</span>
      <span className="form__subtitle">{t('profile.verifiedEmailUsedToSignIn')}</span>
      <span className="form__subtitle">
        {t('profile.yourCurrentEmail')} <b>{userData.email}</b>
      </span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item name="userEmail" label={t('newEmail')} rules={[{ type: 'email' }]} className="form__item">
            <Input className="form__input" placeholder={t('newEmail')} onChange={e => setUserEmail(e.target.value)} />
          </Form.Item>
        </Col>

        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item label={t('phone')} name="userPhone" className="form__item">
            <PhoneInput
              containerClass="form__input form__input-phone"
              country={'us'}
              onChange={phone => setUserPhone(`+${phone}`)}
            />
          </Form.Item>
        </Col>
      </Row>

      <span className="form__title">{t('profile.personalInfo')}</span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item label={t('firstName')} name="userName" className="form__item">
            <Input className="form__input" placeholder={t('firstName')} onChange={e => setUserName(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <Form.Item label={t('lastName')} name="userSurname" className="form__item">
            <Input className="form__input" placeholder={t('lastName')} onChange={e => setUserSurname(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <Button
            type="primary"
            className="form__button"
            onClick={handleFormSubmit}
            disable={userDataFetching}
            loading={userDataFetching}
          >
            {t('submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => ({
  userData: state.user.data,
  userDataFetching: state.user.fetching,
});

const mapDispatchToProps = dispatch => ({
  editUser: editUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
