import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SwapNewCard from '../components/SwapNew/SwapNewCard';
import SwapForm from '../components/SwapNew/SwapForm';
import SwapWalletsList from '../components/SwapNew/SwapWalletsList';
import SwapOffers from '../components/SwapOffers';
import SwapConfirm from '../components/SwapConfirm';
import SwapBack from '../components/SwapNew/SwapBack';
import Button from '../components/Button';
import SwapNetworkWarn from '../components/SwapNew/SwapNetworkWarn';
import { ReactComponent as WalletIcon } from '../img/wallet.svg';
import { ReactComponent as WarningIcon } from '../img/info-circle-warning.svg';
import { useHistory } from 'react-router-dom';
import {
  getSwapOffers,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersReset,
  swapExchangeWalletsReset,
  resetExchangeError
} from '../redux/actions/swap';
import { useTranslation } from 'react-i18next';
import './SwapNew.scss'

const SwapNew = ({
  exchangeWallets,
  exchangeWalletsFetching,
  swapOffers,
  updatedOffers,
  getSwapOffers,
  swapOffersReset,
  swapOffersFetching,
  swapWalletsReset,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersUpdating,
  currenciesArray,
  resetExchangeError
}) => {
  const [walletFromId, setWalletFromId] = useState('');
  const [walletToId, setWalletToId] = useState('');
  const [selectedWalletBalance, setSelectedWalletBalance] = useState(0);
  const [showBalanceWarning, setShowBalanceWarning] = useState(false);
  const [exchangeWalletsFrom, exchangeWalletsTo] = exchangeWallets;
  const [showSwapOffers, setShowSwapOffers] = useState(false);
  const [swapOffersSort, setSwapOffersSort] = useState('rate');
  const [swapOffersType, setSwapOffersType] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedSwapFixed, setSelectedSwapFixed] = useState(undefined);
  const [selectedPartnerRateId, setSelectedPartnerRateId] = useState('');
  const [summaryRate, setSummaryRate] = useState(0);
  const [swapAmountFromError, setSwapAmountFromError] = useState(null);
  const [exchangeFields, setExchangeFields] = useState([
    {
      index: 0,
      value: 0,
      selectedCurrency: '',
    },
    {
      index: 1,
      value: 0,
      selectedCurrency: '',
    },
  ]);
  const [polkaDotLimitWarning, setPolkaDotLimitWarning] = useState(false);
  const [dataIsFrozen, setDataIsFrozen] = useState(false);

  const ratesFetchTimer = useRef();
  const history = useHistory();
  const youSendAmountRef = useRef(null);

  const [currencyFieldIsEdited, setCurrencyFieldIsEdited] = useState('none');

  useEffect(
    () => () => {
      ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
      swapOffersReset();
      swapWalletsReset();
    },
    []
  );

  useEffect(() => {
    if (swapOffers && swapOffers.length) {
      setExchangeFields([
        {
          ...exchangeFields[0],
        },
        {
          ...exchangeFields[1],
          value: swapOffers[0].toAmount,
        },
      ]);
    }
  }, [swapOffers]);

  useEffect(() => {
    if (
      walletFromId &&
      exchangeFields[0].value &&
      +exchangeFields[0].value &&
      `${exchangeFields[0].value}`.split('').pop() !== '.'
    ) {
      setShowBalanceWarning(+exchangeFields[0].value > selectedWalletBalance ? true : false);
    }
  }, [walletFromId]);

  useEffect(() => {
    if (exchangeFields[0].value && +exchangeFields[0].value && `${exchangeFields[0].value}`.split('').pop() !== '.') {
      setShowBalanceWarning(+exchangeFields[0].value > selectedWalletBalance ? true : false);

      setFetchTimer(getSwapOffers, {
        fromId: exchangeFields[0].selectedCurrency,
        toId: exchangeFields[1].selectedCurrency,
        amount: exchangeFields[0].value,
        sort: swapOffersSort || undefined,
        type: swapOffersType || undefined,
      });
    }
  }, [
    exchangeFields[0].value,
    exchangeFields[0].selectedCurrency,
    exchangeFields[1].selectedCurrency,
    swapOffersSort,
    swapOffersType,
  ]);

  useEffect(() => {
    if (!exchangeFields[0].value && exchangeFields[0].selectedCurrency && exchangeFields[1].selectedCurrency) {
      setExchangeFields([
        {
          ...exchangeFields[0],
        },
        {
          ...exchangeFields[1],
          value: 0,
        },
      ]);
    }
  }, [exchangeFields[0].value]);


  useEffect(() => {
    if (exchangeFields[0].value === 0 || exchangeFields[0].value === "") setPolkaDotLimitWarning(false);
    if(swapAmountFromError) setSwapAmountFromError(null);
  }, [exchangeFields[0].value]);


  useEffect(() => {
    if (exchangeWalletsFrom && exchangeWalletsFrom.entities.length) {
      setWalletFromId(exchangeWalletsFrom.entities[0]._id);
      if (exchangeWalletsFrom.entities[0].tokens.length) {
        setSelectedWalletBalance(+exchangeWalletsFrom.entities[0].tokens[0].balance.value);
      } else {
        setSelectedWalletBalance(+exchangeWalletsFrom.entities[0].balance.value);
      }
    }
  }, [exchangeWalletsFrom]);

  useEffect(()=>{
    if (exchangeWalletsTo && exchangeWalletsTo.entities.length) {
      setWalletToId(exchangeWalletsTo.entities[0]._id);
    }
  },[exchangeWalletsTo])

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };
  const { t } = useTranslation();

  const handleErrorMsgClick=()=>{
    setSelectedPartner(undefined);
    setSelectedPartnerRateId('');
    resetExchangeError();
    youSendAmountRef.current = null;
  }

  useEffect(()=>{
    if(swapAmountFromError) {
      youSendAmountRef.current = exchangeFields[0].value;
      setExchangeFields([{...exchangeFields[0],value:swapAmountFromError},{...exchangeFields[1]}]);
    }
  },[swapAmountFromError]);

  useEffect(()=>{
    if(polkaDotLimitWarning) setShowSwapOffers(false);
  },[polkaDotLimitWarning])

  return selectedPartner && selectedSwapFixed !== undefined ? (
    <div className='swap-confirm'>
      <SwapBack
        action={() => {
          // setSwapOffersType('');
          setSelectedPartner(undefined);
          setSelectedPartnerRateId('');
        }}
        maxWidth={790}
      />
      <SwapConfirm
        fromId={walletFromId}
        toId={walletToId}
        currencyFromId={exchangeFields[0].selectedCurrency}
        currencyToId={exchangeFields[1].selectedCurrency}
        amount={youSendAmountRef.current ? youSendAmountRef.current : exchangeFields[0].value}
        summaryRate={summaryRate}
        setSummaryRate={setSummaryRate}
        partner={selectedPartner}
        fixed={selectedSwapFixed}
        selectedPartnerRateId={selectedPartnerRateId}
        swapOffers={swapOffers}
        updatedOffers={updatedOffers}
        swapOffersReset={swapOffersReset}
        setSwapOffersType={setSwapOffersType}
        setSelectedPartner={setSelectedPartner}
        setSelectedPartnerRateId={setSelectedPartnerRateId}
        setShowSwapOffers={setShowSwapOffers}
        updateSwapOffers={updateSwapOffers}
        swapOffersUpdateApply={swapOffersUpdateApply}
        swapOffersUpdating={swapOffersUpdating}
        sort={swapOffersSort || undefined}
        type={swapOffersType || undefined}
        handleErrorMsgClick={handleErrorMsgClick}
        setSwapAmountFromError={setSwapAmountFromError}
      />
    </div>
  ) : (
    <>
      <div className="swap-wallets">
        {/* <SwapNetworkWarn /> */}
        <SwapBack
          action={() => {
            history.push('/swap');
          }}
          maxWidth={790}
        />
        <SwapNewCard title={t('swap.steps.stepOne')} className="swap-exchange-wrap">
          <SwapForm
            exchangeFields={exchangeFields}
            setExchangeFields={setExchangeFields}
            walletFromId={walletFromId}
            exchangeWalletsFrom={exchangeWalletsFrom}
            swapOffers={swapOffers}
            swapOffersFetching={swapOffersFetching}
            swapOffersReset={swapOffersReset}
            swapOffersUpdating={swapOffersUpdating}
            polkaDotLimitWarning={polkaDotLimitWarning}
            setPolkaDotLimitWarning={setPolkaDotLimitWarning}
            dataIsFrozen={dataIsFrozen}
            setDataIsFrozen={setDataIsFrozen}
            swapAmountFromError={swapAmountFromError}
            setSwapAmountFromError={setSwapAmountFromError}
            youSendAmountRef={youSendAmountRef}
            setCurrencyFieldIsEdited={setCurrencyFieldIsEdited}
            currencyFieldIsEdited={currencyFieldIsEdited}
          />
        </SwapNewCard>
        {showBalanceWarning && (
          <div className="swap-wallets__warning">
            <WarningIcon />
            {t('swap.newSwap.warning')}
          </div>
        )}
        <SwapNewCard
          title={t('swap.steps.stepTwo')}
          buttonComponent={
            exchangeWalletsFrom &&
            exchangeWalletsFrom.entities.length > 1 && (
              <div
                className="swap-new-card__button"
                onClick={() => {
                  setWalletFromId('');
                  setSelectedWalletBalance(0);
                }}
              >
                <WalletIcon />
                {t('swap.newSwap.change')}
              </div>
            )
          }
        >
          <SwapWalletsList
            exchangeWallets={exchangeWalletsFrom}
            activeWalletId={walletFromId}
            handleSelect={setWalletFromId}
            setSelectedWalletBalance={setSelectedWalletBalance}
            exchangeWalletsFetching={exchangeWalletsFetching}
            currenciesArray={currenciesArray}
            currencyFieldIsEdited={currencyFieldIsEdited}
            currencyNumber={0}
          />
        </SwapNewCard>

        <SwapNewCard
          title={t('swap.steps.stepThree')}
          buttonComponent={
            exchangeWalletsTo &&
            exchangeWalletsTo.entities.length > 1 && (
              <div className="swap-new-card__button" onClick={() => !swapOffersUpdating && setWalletToId('')}>
                <WalletIcon />
                {t('swap.newSwap.change')}
              </div>
            )
          }
        >
          <SwapWalletsList
            exchangeWallets={exchangeWalletsTo}
            activeWalletId={walletToId}
            handleSelect={setWalletToId}
            exchangeWalletsFetching={exchangeWalletsFetching}
            swapOffersUpdating={swapOffersUpdating}
            currenciesArray={currenciesArray}
            currencyFieldIsEdited={currencyFieldIsEdited}
            currencyNumber={1}
          />
        </SwapNewCard>
        {!showSwapOffers && ( 
          <div className="swap-wallets__button">
            <Button
              disabled={!walletFromId || !walletToId || !+exchangeFields[0].value || exchangeFields[1].value === 0  || polkaDotLimitWarning}
              onClick={() => setShowSwapOffers(true)}
            > 
              {t('swap.newSwap.offers')}
            </Button>
          </div>
        )}

        {showSwapOffers  && (
          <SwapOffers
            swapOffers={swapOffersType === 'fixed' ? swapOffers.filter(offer=>offer.id) : swapOffers}
            updatedOffers={updatedOffers}
            setSwapOffersSort={setSwapOffersSort}
            setSwapOffersType={setSwapOffersType}
            setSelectedPartner={setSelectedPartner}
            setSelectedSwapFixed={setSelectedSwapFixed}
            setSelectedPartnerRateId={setSelectedPartnerRateId}
            setSummaryRate={setSummaryRate}
            swapOffersFetching={swapOffersFetching}
            amount={exchangeFields[0].value}
            fromId={exchangeFields[0].selectedCurrency}
            toId={exchangeFields[1].selectedCurrency}
            sort={swapOffersSort || undefined}
            type={swapOffersType || undefined}
            updateSwapOffers={updateSwapOffers}
            swapOffersUpdateApply={swapOffersUpdateApply}
            swapOffersUpdating={swapOffersUpdating}
            showBalanceWarning={showBalanceWarning}
            setDataIsFrozen={setDataIsFrozen}
            swapOffersSort={swapOffersSort}
            swapOffersType={swapOffersType}
            setSwapAmountFromError={setSwapAmountFromError}
            swapAmountFromError={swapAmountFromError}
            polkaDotLimitWarning={polkaDotLimitWarning}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  exchangeWallets: state.swap.exchangeWallets.data,
  exchangeWalletsFetching: state.swap.exchangeWallets.fetching,
  swapOffers: state.swap.offers.data,
  updatedOffers: state.swap.updatedOffers.data,
  swapOffersFetching: state.swap.offers.fetching,
  swapOffersUpdating: state.swap.updatedOffers.fetching,
  currenciesArray: state.currencies.data
});

const mapDispatchToProps = dispatch => ({
  getSwapOffers: getSwapOffers(dispatch),
  updateSwapOffers: updateSwapOffers(dispatch),
  swapOffersUpdateApply: () => dispatch(swapOffersUpdateApply()),
  swapOffersReset: () => dispatch(swapOffersReset()),
  swapWalletsReset: () => dispatch(swapExchangeWalletsReset()),
  resetExchangeError: () => dispatch(resetExchangeError())
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapNew);
