const formatCurrency = (amount, fiatCurrency = 'USD') => {
  try {
    return amount || amount === 0
      ? amount.toLocaleString('en-US', {
          style: 'currency',
          currency: fiatCurrency,
        })
      : `${amount} ${fiatCurrency}`;
  } catch (err) {
    return `${amount} ${fiatCurrency}`;
  }
};

export default formatCurrency;

// right func, but need to check double $ across the app
// const formatCurrency = (amount, fiatCurrency = 'USD') => {
//   try {
//     return amount || amount === 0
//       ? amount.toLocaleString('en-US', {
//           style: 'currency',
//           currency: fiatCurrency,
//         })
//       : `${amount} ${fiatCurrency}`;
//   } catch (err) {
//     return `${amount} ${fiatCurrency}`;
//   }
// };

// export default formatCurrency;
