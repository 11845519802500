import React, {useState, useEffect} from 'react'
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import { cryptoApi } from '../../service/cryptopay-api';
import Button from '../Button';
import { Timer } from './Timer';


const CodeStep = ({
  nextStep, fullName, setFullName, countryResidence,  setCountryResidence, countryShipping, setCountryShipping
}) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [isIncorrect, setIsIncorrect] = useState(false)
  const [showTimer, setShowTimer] = useState(true)

  useEffect(() => {
    setFullName(fullName ?? '')
    setCountryResidence(countryResidence ?? '')
    setCountryShipping(countryShipping ?? '')
  })


const resendEmail = async () => {
  await cryptoApi.orderCard({})
  setShowTimer(true)
}

  const confirmCode = async () => {
    await cryptoApi.orderCard({
      userName: fullName,
      residenceCountry: countryResidence,
      shippingCountry: countryShipping,
      emailOtp: otp
    }).then(() => nextStep()).catch((err) => setIsIncorrect(true))
  }

  const myDate = new Date()
  const dateInTwoMin = myDate.setMinutes(myDate.getMinutes() + 2)

  return (
    <div className='code-step'>
      <h5>{t("orderCard.stepTwo.description")}</h5>
      <div className='code-step__input'>
      <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      inputStyle={{border: (isIncorrect) ? '1px solid  red' : '1px solid #BFBFBF'}}
      renderInput={(props) => <input autoComplete="one-time-code" {...props} />}
    />
      </div>
   {(isIncorrect) && <p style={{color: '#E53D60'}}>{t("orderCard.stepTwo.codeError")}</p> }
    {(!isIncorrect) &&  <p>{t("orderCard.stepTwo.code")}</p> }

    <div className='code-step__btn'>
    <Button
             className="modal__button modal__button-secondary"
             disabled={showTimer}
            onClick={() => resendEmail()}
          >
            {t("orderCard.stepTwo.button1")}
          </Button>
      <Button className="modal__button modal__button-next"  onClick={() => confirmCode()}>  {t("orderCard.stepTwo.button2")} </Button>
    </div>
    {showTimer ? <span>  {t("orderCard.stepTwo.resend")} <Timer validUntil={dateInTwoMin}  onExpire={() => setShowTimer(false) } /></span> : null}
    </div>
  )
}

export default CodeStep