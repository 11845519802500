import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import DeleteAccountStepOne from "./DeleteAccountStepOne";
import DeleteAccountStepTwo from "./DeleteAccountStepTwo";
import { cancelDeleteTwoFactorToken } from "../../redux/actions/user";
import { connect } from "react-redux";

const DeleteAccountModal = ({ cancelDeleteTwoFactorToken }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [deleteStep, setDeleteStep] = useState(0);
  const { t, i18n } = useTranslation();

  const handleOpen = () => {
    setIsVisible(true);
  }

  const handleCancel = e => {
    setIsVisible(false);
    cancelDeleteTwoFactorToken();
    setDeleteStep(0);
  };

  const handleNext = () => {
    if (deleteStep < 2) {
      setDeleteStep(deleteStep + 1);
    } else {
      setIsVisible(false);
    }
  };

  const deleteAccountModals = [
    {
      title: t('deleteAccountSm'),
      component: (
        <DeleteAccountStepOne
          okButtonAction={handleNext}
          handleCancel={handleCancel}
        />
      )
    },
    {
      title: t('confirm2fa'),
      component: <DeleteAccountStepTwo />
    }
  ];

  return (
    <React.Fragment>
      <Button
        type="primary"
        className="profile__button"
        onClick={handleOpen}
      >
        {t('deleteAccountSm')}
      </Button>
      <Modal
        width={534}
        title={deleteAccountModals[deleteStep].title}
        visible={isVisible}
        onOk={handleOpen}
        onCancel={handleCancel}
        footer={null}
        className="modal modal-delete"
      >
        {deleteAccountModals[deleteStep].component}
      </Modal>
    </React.Fragment>
  )
};

const mapDispatchToProps = dispatch => ({
  cancelDeleteTwoFactorToken: cancelDeleteTwoFactorToken(dispatch),
});

export default connect(null, mapDispatchToProps)(DeleteAccountModal);