import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import BackupPhraseModal from '../BackupPhraseModal/BackupPhraseModal';
import useWindowSize from '../../utils/resizeHook';

const BackupPhraseForm = ({ isBackupEnabled, isUserHasEmail }) => {
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();

  return (
    <div className="profile-block">
      <p className="profile__title">
        {t('profile.backupPhrase')} (
        <span className={isBackupEnabled ? 'profile__title_enabled' : 'profile__title_disabled'}>
          {isBackupEnabled ? t('profile.enabled') : t('profile.disabled')}
        </span>
        )
      </p>
      <span className="profile__subtitle">{isUserHasEmail ? t('profile.backupPhraseDescription') : t('profile.completeEmail')}</span>
      <Row gutter={[20, 0]}>
        <Col span={currentWidth >= 1024 ? 4 : 24}>
          <BackupPhraseModal isBackupEnabled={isBackupEnabled} isUserHasEmail={isUserHasEmail} />
        </Col>
      </Row>
    </div>
  );
};

export default BackupPhraseForm;
