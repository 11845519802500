import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import UserTransactionsList from '../../components/UserTransactionsList';
import { resetAdminUserState, getAdminUserById } from '../../../redux/actions/adminPanel/adminUsers';

const TransactionsByUser = ({ getAdminUserById, resetAdminUserState, currentUserData }) => {
  const { userId } = useParams();
  const { phone, email, name, surname, metamask } = currentUserData || {};

  useEffect(() => () => resetAdminUserState(), []);

  useEffect(() => {
    if (userId) {
      getAdminUserById(userId);
    }
  }, [userId]);

  return (
    <div className="admin-user-transactions">
      <Card>
        <TEXT.pageTitle>Transactions by user</TEXT.pageTitle>
        <UserTransactionsList userSearchParams={email || phone || metamask?.address} userName={name && surname ? `${name} ${surname}` : name }  isFromTransactionsByUser/>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUserData: state.adminUsers.editableUser.data,
});

const mapDispatchToProps = {
  getAdminUserById,
  resetAdminUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsByUser);
