import {
  MERCHANTS_GET,
  MERCHANTS_SET,
  MERCHANTS_CREATE,
  MERCHANTS_DELETE,
  MERCHANT_NETWORK_CHANGE_REQUEST,
  MERCHANT_NETWORK_CHANGE_SUCCESS,
  MERCHANT_NETWORK_CHANGE_ERROR,
  MERCHANTS_SET_APP_TWO_FA,
  MERCHANTS_SET_EMAIL_TWO_FA,
  MERCHANTS_RESET_TWO_FA,
  MERCHANTS_ERROR,
} from '../actions/merchants';

export default function reducer(
  state = {
    data: [],
    fetching: false,
    page: 1,
    pages: null,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    quickNetworkSwitcherState: { merchantId: '', fetching: false },
  },
  action
) {
  switch (action.type) {
    case MERCHANTS_GET:
    case MERCHANTS_CREATE:
    case MERCHANTS_DELETE:
      return {
        ...state,
        fetching: action.fetching,
      };
    case MERCHANTS_SET:
      return {
        ...state,
        data: action.payload.merchants,
        fetching: action.fetching,
        page: action.payload.page,
        pages: action.payload.pages,
        quickNetworkSwitcherState: { merchantId: '', fetching: false },
      };
    case MERCHANT_NETWORK_CHANGE_REQUEST:
      return {
        ...state,
        quickNetworkSwitcherState: { merchantId: action.payload, fetching: true },
      };
    case MERCHANT_NETWORK_CHANGE_ERROR:
    case MERCHANT_NETWORK_CHANGE_SUCCESS:
      return {
        ...state,
      };
    case MERCHANTS_SET_APP_TWO_FA:
      return {
        ...state,
        isAppTwoFa: action.isAppTwoFa,
        fetching: false,
      };
    case MERCHANTS_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.isEmailTwoFa,
        fetching: false,
      };
    case MERCHANTS_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.isEmailTwoFa,
        fetching: false,
      };
    case MERCHANTS_RESET_TWO_FA:
      return {
        ...state,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        fetching: false,
      };
    case MERCHANTS_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
