import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tooltip, Popover } from 'antd';
import TokenIcon from '../../../components/TokenIcon';
import { tokenColors } from '../../../utils/tokenIcons';
import { useTranslation } from 'react-i18next';
import formatCurrency from '../../../utils/currencyFormatter';

const Wallet = ({ wallet }) => {
  const [isTokensOpened, setIsTokensOpened] = useState(true);
  const [hasTokens, setHasTokens] = useState(false);
  const [walletTokens, setWalletTokens] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const { t } = useTranslation();
  const timer = useRef(null);

  const [walletListHasTokenIndex, setWalletListHasTokenIndex] = useState(null);
  const [showPopOverHasToken, setShowPopOverHasToken] = useState(false);
  const [showTooltipHasToken, setShowTooltipHasToken] = useState(false);


  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  useEffect(() => {
    showPopOverHasToken && setShowTooltipHasToken(false);
  }, [showPopOverHasToken]);

  useEffect(() => {
    const filtered = wallet.tokens.filter(token => token.balance.usd > 0);
    setHasTokens(!!filtered.length);
    setWalletTokens(filtered);
  }, [wallet]);

  const resetTimers = () => {
    setShowPopover(false);
    setShowPopOverHasToken(false);

    setWalletListHasTokenIndex(null);
  }

  const copyHandler = (e) => {
    navigator.clipboard.writeText(wallet.address);

    if (e.currentTarget.dataset.name === 'walletFromHasTokenList') {
      setShowPopOverHasToken(true);
    }
    else {
      setShowPopover(true);
    }
    timer.current = setTimeout(() => resetTimers(), 500);
  };

  return (
    <div
      className={`fee-wallets-list__wallet ${hasTokens ? 'fee-wallets-list__wallet_hasTokens' : ''}`}
      onMouseEnter={e =>
      (e.currentTarget.style.background =
        !hasTokens || !isTokensOpened ? tokenColors[wallet.currency].bgColor : 'transparent')
      }
      onMouseLeave={e => (e.currentTarget.style.background = 'transparent')}
    >
      {hasTokens ? (
        <div
          className={`fee-wallets-list__dropdown-icon ${isTokensOpened ? 'fee-wallets-list__dropdown-icon_opened' : ''
            }`}
          onClick={() => setIsTokensOpened(!isTokensOpened)}
        >
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 6.12598L8 10.126L12 6.12598"
              stroke={tokenColors[wallet?.currency]?.color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        ''
      )}
      <Row
        align="middle"
        onMouseEnter={e =>
        (e.currentTarget.style.background =
          hasTokens && isTokensOpened ? tokenColors[wallet.currency].bgColor : 'transparent')
        }
        onMouseLeave={e => (e.currentTarget.style.background = 'transparent')}
      >
        <Col span={1}>
          <TokenIcon tokenName={wallet.currency} className="fee-wallets-list__logo" />
        </Col>
        <Col span={13}>
          <Tooltip
            title={t('clickToCopyAddress')}
            visible={showTooltip}
            onVisibleChange={isVisible => setShowTooltip(isVisible)}
          >
            <Popover
              title=""
              content={t('copied')}
              trigger="click"
              visible={showPopover}
              className="detailed-checkout__popover"
            >
              <div
                className="fee-wallets-list__address"
                style={{ color: tokenColors[wallet?.currency]?.color }}
                onClick={(e) => copyHandler(e)}
              >
                {wallet.address}
              </div>
            </Popover>
          </Tooltip>
        </Col>
        <Col span={10}>
          <div className="fee-wallets-list__balance">
            <div className="fee-wallets-list__balance-token">
              {wallet.balance.value} {wallet.currency} {' '}

              {wallet.currency === 'ETH' && wallet.currencyType === 'currency' && wallet.nodeType === 'optimism' ?  'OP' : null}

              {wallet.currencyType === 'currency' && wallet.nodeType === 'arbitrum' ? wallet.name === 'arbitrum' ? null : 'ARB' : null}
            </div>
            <div className="fee-wallets-list__balance-usd">{formatCurrency(wallet.balance.usd)}</div>
          </div>
        </Col>
      </Row>
      {isTokensOpened && (
        <div className="fee-wallets-list__tokens-dropdown">
          {walletTokens.map((token, index) => {
            return (
              <Row
                key={token.currencyId}
                align="middle"
                className="fee-wallets-list__token"
                onMouseEnter={e => (e.currentTarget.style.background = tokenColors[token.currency].bgColor)}
                onMouseLeave={e => (e.currentTarget.style.background = 'transparent')}
              >
                <Col span={1}>
                  <TokenIcon tokenName={token.currency} className="fee-wallets-list__logo" />
                </Col>
                <Col span={13}>

                  <Tooltip title={t('clickToCopyAddress')} visible={showTooltipHasToken && walletListHasTokenIndex === index}
                    onVisibleChange={isVisible => {
                      setWalletListHasTokenIndex(index);
                      setShowTooltipHasToken(true);
                    }
                    }>
                    <Popover
                      title=""
                      content={t('copied')}
                      trigger="click"
                      visible={showPopOverHasToken && index === walletListHasTokenIndex}
                      className="detailed-checkout__popover"
                    >
                      <div
                        className="fee-wallets-list__address"
                        style={{ color: tokenColors[token.currency]?.color }}
                        onClick={(e) => copyHandler(e, index)}
                        data-name="walletFromHasTokenList"
                        onMouseLeave={e => {
                          setWalletListHasTokenIndex(null);
                          setWalletListHasTokenIndex(null);
                        }
                        }
                      >
                        {wallet.address}
                      </div>
                    </Popover>
                  </Tooltip>

                </Col>
                <Col span={10}>
                  <div className="fee-wallets-list__balance">
                    <div className="fee-wallets-list__balance-token">
                      {token.balance.value} {token.currency}
                    </div>
                    <div className="fee-wallets-list__balance-usd">{formatCurrency(token.balance.usd)}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Wallet;
