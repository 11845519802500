import { T } from 'antd/lib/upload/utils';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIconSvg } from '../../../img/empty-search.svg';
import { ReactComponent as ResetSvg } from '../../../img/reset.svg';
import './style.scss';

const SwapEmpty = ({ resetFilters, historyFilters, defaultHistoryFilters }) => {
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (
      historyFilters.search === defaultHistoryFilters.search &&
      historyFilters.currencyFromId === defaultHistoryFilters.currencyFromId &&
      historyFilters.currencyToId === defaultHistoryFilters.currencyToId &&
      historyFilters.order === defaultHistoryFilters.order
    ) {
      setIsFiltersActive(false);
    } else {
      setIsFiltersActive(true);
    }
  }, []);

  return (
    <div className="swap-empty">
      <div className="swap-empty__icon">
        <SearchIconSvg />
      </div>
      <div className="swap-empty__text">
        {isFiltersActive
          ? t('swap.noResults')
          : t('swap.emptyHistory')}
      </div>
      {isFiltersActive ? (
        <div className="swap-empty__reset" onClick={resetFilters}>
          <ResetSvg /> {t('swap.resetSearch')}
        </div>
      ) : (
        <Link className="swap-empty__new" to="/swap/create">
          {t('swap.new')}
        </Link>
      )}
    </div>
  );
};

export default SwapEmpty;
