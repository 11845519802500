import React from 'react';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.scss';

const EmailVerified = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['email-verified']}>
        <div className={styles['email-verified__title']}>{t('messages.emailHasBeenVerified')}</div>
        <div className={styles['email-verified__subtitle']}>{t('messages.profileActivated')}</div>
        <Button type="primary" className={styles['login-form__button']} onClick={handleClick}>
          {t('messages.browseCryptopay')}
        </Button>
      </div>
    </div>
  );
};

export default EmailVerified;
