import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsSuccessShow, notificationsErrorShow } from './notifications';

// CONSTANTS
export const WITHDRAW_WALLETS_GET = 'WITHDRAW_WALLETS_GET';
export const WITHDRAW_WALLETS_SET = 'WITHDRAW_WALLETS_SET';
export const WITHDRAW_SET_APP_TWO_FA = 'WITHDRAW_SET_APP_TWO_FA';
export const WITHDRAW_SET_EMAIL_TWO_FA = 'WITHDRAW_SET_EMAIL_TWO_FA';
export const WITHDRAW_RESET_TWO_FA = 'WITHDRAW_RESET_TWO_FA';
export const WITHDRAW_CREATE_TRANSACTION = 'WITHDRAW_CREATE_TRANSACTION';
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR';
export const WITHDRAW_ESTIMATE_MAX_REQUEST = 'WITHDRAW_ESTIMATE_MAX_REQUEST';
export const WITHDRAW_ESTIMATE_MAX_SUCCESS = 'WITHDRAW_ESTIMATE_MAX_SUCCESS';
export const WITHDRAW_ESTIMATE_MAX_ERROR = 'WITHDRAW_ESTIMATE_MAX_ERROR';
export const WITHDRAW_ESTIMATE_MAX_RESET = 'WITHDRAW_ESTIMATE_MAX_RESET';

export const withdrawWalletsGet = () => ({
  type: WITHDRAW_WALLETS_GET,
});

export const withdrawWalletsSet = data => ({
  type: WITHDRAW_WALLETS_SET,
  payload: data,
});

export const withdrawSetAppTwoFa = isAppTwoFa => ({
  type: WITHDRAW_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const withdrawSetEmailTwoFa = isEmailTwoFa => ({
  type: WITHDRAW_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const withdrawResetTwoFa = () => ({
  type: WITHDRAW_RESET_TWO_FA,
});

export const withdrawCreateTransaction = () => ({
  type: WITHDRAW_CREATE_TRANSACTION,
});

export const withdrawError = () => ({
  type: WITHDRAW_ERROR,
});

export const getWithdrawWallets =
  dispatch =>
  async (merchantId, currencyIds, networkFilter, positiveBalance = false) => {
    dispatch(withdrawWalletsGet());
    let wallets;

    try {
      wallets = await cryptoApi.getMerchantWallets(
        merchantId,
        {
          order: 'DESC',
          limit: 99999,
          typeWallet: 'merchant',
          currencyIds: currencyIds || undefined,
          typeNetwork: networkFilter,
        },
        positiveBalance
      );
    } catch (err) {
      dispatch(withdrawError());
      dispatch(notificationsErrorShow(err.data));
    }

    if (wallets && wallets.data.entities) {
      dispatch(withdrawWalletsSet([...wallets.data.entities]));
      return;
    }

    dispatch(withdrawError());
  };

export const makeWithdraw =
  dispatch => async (from, to, amount, merchantId, currencyToken, appTwoFaCode, emailTwoFaCode) => {
    dispatch(withdrawCreateTransaction());
    let response;

    try {
      response = await cryptoApi.makeWithdraw(
        from,
        to,
        amount,
        merchantId,
        currencyToken,
        appTwoFaCode,
        emailTwoFaCode
      );
    } catch (err) {
      dispatch(withdrawError());
      dispatch(notificationsErrorShow(err.data));
    }

    if ((response && response.data.id) || (response && response.data)) {
      dispatch(withdrawResetTwoFa());
      response && response.data?.id && dispatch(notificationsSuccessShow({}));
      return response;
    }

    if (response && response.data.twoFactorToken && response.data.emailSent) {
      dispatch(withdrawSetEmailTwoFa(true));
      dispatch(withdrawSetAppTwoFa(true));
      return response;
    }
  };

// withdrawal max estimation
export const withdrawalEstimateMaxRequest = () => ({
  type: WITHDRAW_ESTIMATE_MAX_REQUEST,
});

export const withdrawalEstimateMaxSuccess = payload => ({
  type: WITHDRAW_ESTIMATE_MAX_SUCCESS,
  payload,
});

export const withdrawalEstimateMaxError = payload => ({
  type: WITHDRAW_ESTIMATE_MAX_ERROR,
  payload,
});

export const withdrawalEstimateMaxReset = () => ({
  type: WITHDRAW_ESTIMATE_MAX_RESET,
});

export const getWithdrawalEstimateMax = dispatch => async (merchantId, walletId, data) => {
  dispatch(withdrawalEstimateMaxRequest());

  try {
    const response = await cryptoApi.withdrawalEstimateMax(merchantId, walletId, data);
    dispatch(withdrawalEstimateMaxSuccess(response.data));
  } catch (error) {
    dispatch(withdrawalEstimateMaxError(error));
    dispatch(notificationsErrorShow(error.data));
  }
};
