import React from 'react';
import './style.scss';

const TextTag = ({ className, children }) => <span className={`text ${className}`}>{children}</span>;

const TEXT = {
  pageTitle({ color, ...rest }) {
    return <TextTag className="text__page-title" {...rest} />;
  },
  sectionTitle({ color, ...rest }) {
    return <TextTag className="text__section-title" {...rest} />;
  },
  sectionDescription({ color, ...rest }) {
    return <TextTag className="text__section-description" {...rest} />;
  },
  button({ color, ...rest }) {
    return <TextTag className="text__button" {...rest} />;
  },
  gray({ color, ...rest }) {
    return <TextTag className="text__gray" {...rest} />;
  },
  grayBold({ color, ...rest }) {
    return <TextTag className="text__gray-bold" {...rest} />;
  },
  grayBoldSmall({ color, ...rest }) {
    return <TextTag className="text__gray-bold-small" {...rest} />;
  },
  darkBold({ color, ...rest }) {
    return <TextTag className="text__dark-bold" {...rest} />;
  },
  label({ color, ...rest }) {
    return <TextTag className="text__label" {...rest} />;
  },
  darkBig({ color, ...rest }) {
    return <TextTag className="text__dark-big" {...rest} />;
  },
};

export default TEXT;
