import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { setActiveMerchant } from '../../redux/actions/transactions';
import { getMerchants } from '../../redux/actions/merchants';
import NetworkFilter from '../NetworkFilter';
import './MerchantSelector.scss';
import arrowIcon from '../../img/arrow-blue.svg';

const MerchantSelector = ({ merchantId, merchants, getMerchants, setActiveMerchant }) => {
  const [activeMerchantName, setActiveMerchantName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  useEffect(() => {
    if (merchants && merchants.length > 0) {
      const storageMerchantId = localStorage.getItem('merchantId');
      if (storageMerchantId && merchants.find(merchant => merchant._id === storageMerchantId)) {
        setActiveMerchant(storageMerchantId);
      } else {
        setActiveMerchant(merchants[0]._id);
      }
    }
  }, [merchants, setActiveMerchant]);

  const handleSelectMerchant = merchantId => {
    setActiveMerchant(merchantId);
  };

  return (
    <>
      {/* <NetworkFilter /> */}
      <Dropdown
        overlay={
          <Menu>
            {merchants.map((merchant, index) => {
              if (merchant._id === merchantId && merchant.name !== activeMerchantName) {
                setActiveMerchantName(merchant.name);
              }

              return (
                <Menu.Item key={index}>
                  <a
                    onClick={() => handleSelectMerchant(merchant._id)}
                    className={merchant._id === merchantId ? 'merchant-selector_active' : ''}
                  >
                    {merchant.name}
                  </a>
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <div className="header__merc-button">
          <span>{activeMerchantName || t('merchants.selectMerchant')}</span>
          <img src={arrowIcon} alt="dropdown" />
        </div>
      </Dropdown>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  merchants: state.merchants.data,
});

const mapDispatchToProps = dispatch => ({
  getMerchants: getMerchants(dispatch),
  setActiveMerchant: setActiveMerchant(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSelector);
