import React, { useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import { changePassword } from '../../redux/actions/user';
import { connect } from 'react-redux';
import styles from '../AuthForms/style.module.scss';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

const ChangePasswordModal = ({
  isVisible,
  changePassword,
  isChangeEmailTwoFa,
  isChangeTwoFactorToken,
  password,
  confirmNewPassword,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
    }
  }, [isVisible]);

  const handleSubmit = async values => {
    try {
      await changePassword(
        password,
        confirmNewPassword,
        values.emailTwoFaKey ? values.emailTwoFaKey.trim() : undefined,
        values.twoFactorToken ? values.twoFactorToken.trim() : undefined
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('auth.keyFromEmail')}
          name="emailTwoFaKey"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          // hidden={!isChangeEmailTwoFa || isChangeTwoFactorToken}
        >
          <AuthCode
            autoFocus={true}
            containerClassName={styles['login-form__2fa']}
            inputClassName={styles['login-form__2fa-input']}
          />
        </Form.Item>

        <Form.Item
          label={t('auth.keyFromGA')}
          name="twoFactorToken"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
          // hidden={!isChangeTwoFactorToken}
        >
          <AuthCode
            autoFocus={false}
            containerClassName={styles['login-form__2fa']}
            inputClassName={styles['login-form__2fa-input']}
          />
        </Form.Item>

        <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
          <Col span="24">
            <Button className={`modal__button modal__button-create modal__button-center`} type="submit">
              {t('confirm')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  isChangeEmailTwoFa: state.user.isChangeEmailTwoFa,
  isChangeTwoFactorToken: state.user.isChangeTwoFactorToken,
});

const mapDispatchToProps = dispatch => ({
  changePassword: changePassword(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
