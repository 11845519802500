import React, { useState, useEffect } from 'react';
import { cryptoApi } from '../../service/cryptopay-api';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import EmailVerified from '../AuthForms/EmailVerified';
import JwtDecode from 'jwt-decode';
import { softLogin } from '../../redux/actions/login';
import jwt from 'jwt-decode';

const EmailChangeVerify = ({ softLogin }) => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    emailChangeVerify();
  }, [token]);

  const handleClick = () => {
    if (emailVerified) {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const decodedToken = JwtDecode(authToken);
        if (Date.now() < decodedToken.exp * 1000) softLogin(authToken);
      }
    }

    setIsRedirect(true);
  };

  const emailChangeVerify = async () => {
    cryptoApi
      .emailChangeVerify(token)
      .then(response => {
        if (response && response.data && response.data.token) {
          localStorage.setItem('authToken', response.data.token);
          setEmailVerified(true);
        } else {
          setIsRedirect(true);
        }
      })
      .catch(error => {
        setIsRedirect(true);
      });
  };

  return (
    <>
      {isRedirect && <Redirect to="/" />}

      {/* {emailVerified && <EmailVerified setIsRedirect={setIsRedirect} />} */}
      {emailVerified && <EmailVerified handleClick={handleClick} />}
    </>
  );
};

const mapDispatchToProps = {
  softLogin,
};

export default connect(null, mapDispatchToProps)(EmailChangeVerify);
