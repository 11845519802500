import React from 'react'
import { useTimer } from 'react-timer-hook'

export const Timer = ({ validUntil, onExpire }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(validUntil),
    autoStart: true,
    onExpire,
  })

  const formatNumber = (number) => (number.toString().length < 2 ? `0${number}` : number)

  return (
    <>
      {minutes}:{formatNumber(seconds)}
    </>
  )
}
