import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HomeCurrency from '../components/HomeCurrency';
import HomeTransactions from '../components/HomeTransactions';
import CheckoutsLink from '../components/CheckoutsLink';
import { getTransactions, getMoreTransactions } from '../redux/actions/transactions';
import { getMerchantBalances } from '../redux/actions/merchantData';
import useWindowSize from '../utils/resizeHook';
import './Home.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = ({
  merchantId,
  networkFilter,
  transactions,
  transactionsFetching,
  moreTransactionsFetching,
  countItem,
  merchantBalances,
  currencies,
  balancesFetching,
  getTransactions,
  getMoreTransactions,
  getMerchantBalances,
  balancesTotal,
}) => {
  const query = useQuery();

  const defaultParams = {
    search: query.get('checkoutId') || '',
    order: 'DESC',
    currencyId: '',
    type: 'replenishment,withdrawal,Error,multisend',
    page: 1,
    limit: 7,
    typeNetwork: networkFilter,
  };

  const [currencyId, setCurrencyId] = useState(defaultParams.currencyId);
  const [search, setSearch] = useState(defaultParams.search);
  const [order, setOrder] = useState(defaultParams.order);
  const [type, setType] = useState(defaultParams.type);
  const [page, setPage] = useState(defaultParams.page);
  const [isHideZeroBalances, setIsHideZeroBalances] = useState(
    JSON.parse(localStorage.getItem('yomiHideZeroBalances')) || false
  );
  const limit = defaultParams.limit;
  const { currentWidth } = useWindowSize();

  useEffect(() => {
    if (merchantId) {
      getMerchantBalances(merchantId, networkFilter, true);
      setSearch(defaultParams.search);
      setOrder(defaultParams.order);
      setType(defaultParams.type);
      setPage(defaultParams.page);

      getTransactions(merchantId, { ...defaultParams, currencyId: currencyId || undefined });
    }
  }, [merchantId, networkFilter]);

  useEffect(() => {
    setPage(defaultParams.page);
    if (merchantId) {
      getTransactions(merchantId, {
        search,
        order,
        currencyId: currencyId || undefined,
        type,
        page: defaultParams.page,
        limit,
        typeNetwork: networkFilter,
      });
    }
  }, [currencyId, order, type]);

  useEffect(() => {
    if (page > 1) {
      getMoreTransactions(merchantId, {
        search,
        order,
        currencyId: currencyId || undefined,
        type,
        page,
        limit,
        typeNetwork: networkFilter,
      });
    }
  }, [page]);

  const submitSearch = search => {
    setSearch(search);
    setPage(defaultParams.page);
    getTransactions(merchantId, {
      search,
      order,
      currencyId: currencyId || undefined,
      type,
      page: defaultParams.page,
      limit,
      typeNetwork: networkFilter,
    });
  };

  const resetFilters = () => {
    query.delete('checkoutId');
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);

    setSearch('');
    setOrder(defaultParams.order);
    setType(defaultParams.type);
    setPage(defaultParams.page);

    getTransactions(merchantId, { ...defaultParams, currencyId: currencyId || undefined, search: '' });
  };

  return (
    <div className="home-page">
      <div className="home-page__left">
        {/* {currentWidth < 1024 && <CheckoutsLink />} */}
        <HomeCurrency
          merchantBalances={merchantBalances}
          currencies={currencies}
          balancesFetching={balancesFetching}
          currencyId={currencyId}
          setCurrencyId={setCurrencyId}
          isHideZeroBalances={isHideZeroBalances}
          setIsHideZeroBalances={setIsHideZeroBalances}
          balancesTotal={balancesTotal}
          getMerchantBalances={getMerchantBalances}
          merchantId={merchantId}
          networkFilter={networkFilter}
        />
      </div>
      <div className="home-page__right">
        {/* {currentWidth >= 1024 && <CheckoutsLink />} */}
        <HomeTransactions
          currencyId={currencyId}
          merchantBalances={merchantBalances}
          transactions={transactions}
          transactionsFetching={transactionsFetching}
          moreTransactionsFetching={moreTransactionsFetching}
          currencies={currencies}
          networkFilter={networkFilter}
          countItem={countItem}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          order={order}
          setOrder={setOrder}
          type={type}
          setType={setType}
          submitSearch={submitSearch}
          resetFilters={resetFilters}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  transactions: state.transactions.data,
  transactionsFetching: state.transactions.fetching,
  moreTransactionsFetching: state.transactions.loadMoreFetching,
  countItem: state.transactions.countItem,
  merchantBalances: state.merchantData.balances.balances || [],
  currencies: state.currencies.data,
  balancesFetching: state.merchantData.fetching,
  balancesTotal: state.merchantData.balances.total,
});

const mapDispatchToProps = dispatch => ({
  getTransactions: getTransactions(dispatch),
  getMoreTransactions: getMoreTransactions(dispatch),
  getMerchantBalances: getMerchantBalances(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
