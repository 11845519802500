import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import menu from '../../img/menu.svg';
import logo from '../../img/logo.svg';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import NetworkFilter from '../../components/NetworkFilter';
import './Header.scss';

function Header({ toggleCollapseSider }) {
  return (
    <div className="admin-header">
      <Row align="middle" gutter={[32, 0]}>
        <Col flex="90px">
          <a className="admin-header__toggle-sider" onClick={toggleCollapseSider}>
            <img src={menu} alt="menu" />
          </a>
        </Col>
        <Col className="admin-header__logo">
          <Link to="/admin">
            <img src={logo} alt="logo" />
          </Link>
        </Col>
        <Col flex="auto">
          <div className="admin-header__network-filter-wrap">
            <NetworkFilter />
          </div>
        </Col>
        <Col className="admin-header__user">
          <div className="admin-header__controls-wrapper">
            <HeaderUser />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
