import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, DatePicker, Select } from 'antd';
import TEXT from '../Text';
import PhoneInputLib from 'react-phone-input-2';
import resetIcon from '../../../img/reset.svg';
import 'react-phone-input-2/lib/style.css';
import './style.scss';

export const TextInput = ({ name, label, className, rules, ...rest }) => {
  return (
    <div>
      <TEXT.label>{label}</TEXT.label>
      <Form.Item name={name} className="form-field__form-item" rules={rules}>
        <Input className={`form-field form-field__text ${className || ''}`} {...rest} />
      </Form.Item>
    </div>
  );
};

export const TextPassword = ({ name, label, className, rules, ...rest }) => {
  return (
    <div>
      <TEXT.label>{label}</TEXT.label>
      <Form.Item name={name} className="form-field__form-item" rules={rules}>
        <Input.Password className={`form-field form-field__password ${className || ''}`} {...rest} />
      </Form.Item>
    </div>
  );
};

export const PhoneInput = ({ name, label, className, rules, ...rest }) => {
  return (
    <div>
      <TEXT.label>{label}</TEXT.label>
      <Form.Item name="phone" className="form-field__phone" rules={rules}>
        <PhoneInputLib country={'us'} {...rest} />
      </Form.Item>
    </div>
  );
};

export const InputSearch = ({ name, className, onReset, rules, ...rest }) => {
  return (
    <Form.Item name={name} className="form-field__form-item" rules={rules}>
      <Input.Search
        placeholder="Search"
        className={`form-field form-field__search ${className || ''}`}
        {...rest}
        addonAfter={
          onReset ? (
            <a onClick={onReset} className="form-field__reset-button">
              <img src={resetIcon} alt="Reset" />
            </a>
          ) : null
        }
      />
    </Form.Item>
  );
};

export const PercentInput = ({ name, className, rules, ...rest }) => {
  return (
    <Form.Item name={name} className="form-field__form-item" rules={rules}>
      <InputNumber
        min={0}
        max={100}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        className={`form-field form-field__percent-input ${className || ''}`}
        {...rest}
      />
    </Form.Item>
  );
};

export const FiatAmountInput = ({ name, className, rules, ...rest }) => {
  return (
    <Form.Item name={name} className="form-field__form-item" rules={rules}>
      <InputNumber
        min={0}
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
        className={`form-field form-field__fiat-amount-input ${className || ''}`}
        {...rest}
      />
    </Form.Item>
  );
};

export const RangePicker = ({
  name,
  nameFrom,
  nameTo,
  placeholder,
  className,
  setAction,
  submitAction,
  formValues = null,
  withInitial,
  value,
  clearAction,
  ...rest
}) => {
  const [hasValues, setHasValues] = useState(false);
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [initialValueFlag, setInitialValue] = useState(withInitial);
  useEffect(() => {
    if (Array.isArray(formValues) && formValues.length) {
      setHasValues(true);
      return;
    }

    if (formValues && (!formValues[name] || !formValues[name].length)) {
      setHasValues(false);
    }
  }, [formValues]);

  return (
    <div className="form-field__range-picker-wrapper">
      <Form.Item name={name} className="form-field__form-item">
        <DatePicker.RangePicker
          separator="-"
          suffixIcon={false}
          format="DD-MM-YYYY"
          placeholder=""
          className={`form-field form-field__range-picker ${className || ''}`}
          onOpenChange={state => setIsCalendarOpened(state)}
          onChange={values => {
            setHasValues(!!values);
            if (initialValueFlag && clearAction) {
              clearAction();
            }
            submitAction();
            setInitialValue(null);
          }}
          value={value}
          {...rest}
        />
      </Form.Item>
      {!initialValueFlag && !isCalendarOpened && !hasValues ? (
        <div className="form-field__range-picker-placeholder">{placeholder}</div>
      ) : null}
    </div>
  );
};

export const InputSelect = ({ name, className, options, ...rest }) => {
  return (
    <Form.Item name={name} className="form-field__form-item">
      <Select className={`form-field form-field__select ${className || ''}`} {...rest}>
        {options.map((option, index) => (
          <Select.Option key={index} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
