import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import WalletsList from '../../components/Withdraw/WalletsList';
import WithdrawForm from '../../components/Withdraw/WithdrawForm';
import { getAdminFeeWallets, getMoreAdminFeeWallets } from '../../../redux/actions/adminPanel/adminFeeWallets';
import { ButtonPrimary } from '../../components/Button';
import FeeTransactionsList from '../../components/Withdraw/FeeTransactionsList';
import './style.scss';

const Withdraw = ({
  currencies,
  feeWallets,
  feeWalletsFetching,
  feeWalletsFetchingMore,
  countItem,
  page,
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
  typeNetwork,
  maxAmount,
}) => {
  const [from, setFrom] = useState('');
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  const [isTransactionsActive, setIsTransactionsActive] = useState(false);

  useEffect(() => {
    !isTransactionsActive && getAdminFeeWallets({ typeNetwork, positiveBalance: true });
  }, [isTransactionsActive, typeNetwork]);

  useEffect(() => {
    setAmount(maxAmount);
  }, [maxAmount]);

  return (
    <div className="admin-fee-withdraw">
      <Card>
        <TEXT.pageTitle>{isTransactionsActive ? 'System fee transaction' : 'Withdrawal'}</TEXT.pageTitle>
        {!from && (
          <div className="admin-fee-withdraw__tabs-switcher">
            <div
              className={`admin-fee-withdraw__tabs-switcher-item ${
                isTransactionsActive ? '' : 'admin-fee-withdraw__tabs-switcher-item_active'
              }`}
              onClick={() => setIsTransactionsActive(false)}
            >
              <TEXT.gray>Wallets</TEXT.gray>
            </div>
            <div
              className={`admin-fee-withdraw__tabs-switcher-item ${
                isTransactionsActive ? 'admin-fee-withdraw__tabs-switcher-item_active' : ''
              }`}
              onClick={() => setIsTransactionsActive(true)}
            >
              <TEXT.gray>Transaction list</TEXT.gray>
            </div>
          </div>
        )}
        {isTransactionsActive ? (
          <FeeTransactionsList />
        ) : from ? (
          <WithdrawForm
            currencies={currencies}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            amount={amount}
            setAmount={setAmount}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
          />
        ) : (
          <>
            <WalletsList
              withdrawWallets={feeWallets}
              walletsFetching={feeWalletsFetching}
              selectedWallet={selectedWallet}
              setSelectedWallet={setSelectedWallet}
              from={from}
              setFrom={setFrom}
            />
            {feeWallets.length && feeWallets.length < countItem ? (
              <div className="admin-fee-withdraw__load-more">
                <ButtonPrimary
                  loading={feeWalletsFetchingMore}
                  onClick={() => getMoreAdminFeeWallets({ page: page + 1, typeNetwork, positiveBalance: true })}
                >
                  Load More
                </ButtonPrimary>
              </div>
            ) : null}
          </>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currencies: state.currencies.data,
  feeWallets: state.adminFeeWallets.entities,
  feeWalletsFetching: state.adminFeeWallets.fetching,
  feeWalletsFetchingMore: state.adminFeeWallets.fetchingMore,
  countItem: state.adminFeeWallets.countItem,
  page: state.adminFeeWallets.page,
  typeNetwork: state.networkFilter,
  maxAmount: state.adminFeeWithdraw.maxAmount,
});

const mapDispatchToProps = {
  getAdminFeeWallets,
  getMoreAdminFeeWallets,
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
