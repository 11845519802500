import React, { useState } from 'react';
import { Modal } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { ReactComponent as PlusIcon } from '../../img/plus.svg';
import { useTranslation } from 'react-i18next';
import WalletModalType from './WalletModalType';
import WalletCreateNew from './WalletCreateNew';
import WalletAddExisting from './WalletAddExisting';

import './WalletCreateModal.scss';

const WalletCreateModal = ({ merchantId, typeWallet, createMode, filtersFormName, networkFilter }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [walletCreateMode, setWalletCreateMode] = useState(createMode || 'type');
  const { t } = useTranslation();

  const handleCancel = e => {
    setIsVisible(false);
    setWalletCreateMode(createMode || 'type');
  };

  return (
    <React.Fragment>
      <Button type="secondary" className="page-header__button-create" onClick={() => setIsVisible(true)}>
        {/* <img className="page-header__button-create-icon" src={plusIcon} alt="add" /> */}
        <PlusIcon className="page-header__button-create-icon" alt="add" />
        <span>{t('wallets.createNewWallet')}</span>
      </Button>
      <Modal
        title={walletCreateMode !== 'type' && t('wallets.addWalletMode', { mode: walletCreateMode })}
        visible={isVisible}
        onOk={handleCancel}
        closable={walletCreateMode !== 'type'}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        className={`modal modal__wallet-create modal__wallet-create_${walletCreateMode}`}
        destroyOnClose={true}
      >
        {walletCreateMode === 'type' && <WalletModalType setWalletCreateMode={setWalletCreateMode} />}

        {walletCreateMode === 'new' /*|| walletCreateMode === 'existing'*/ && (
          <WalletCreateNew
            merchantId={merchantId}
            typeWallet={typeWallet}
            handleCancel={handleCancel}
            filtersFormName={filtersFormName}
            networkFilter={networkFilter}
          />
        )}

        {walletCreateMode === 'existing' && (
          <WalletAddExisting
            merchantId={merchantId}
            networkFilter={networkFilter}
            typeWallet={typeWallet}
            filtersFormName={filtersFormName}
            handleCancel={handleCancel}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default WalletCreateModal;
