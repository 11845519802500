import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import AuthCode from 'react-auth-code-input';
import { notificationsErrorShow } from '../../../redux/actions/notifications';

import '../../../layout/Modal.scss';
import styles from '../../AuthForms/style.module.scss';

const WithdrawTwoFaConfirm = ({
  isVisible,
  setIsVisible,
  action,
  isAppTwoFa,
  isEmailTwoFa,
  clearWithdrawState,
  fetching,
  body,
  withdrawResetTwoFa,
  notificationsErrorShow,
}) => {
  // const [twoFactorCode, setTwoFactorCode] = useState('');
  // const [emailTwoFactorCode, setEmailTwoFactorCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawConfirmForm] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = async values => {
    const { twoFactorCode, emailTwoFactorCode } = values;
    setIsLoading(true);
    const response = await action(...body, twoFactorCode, emailTwoFactorCode);

    if (response && response.data.id) {
      clearWithdrawState();
      setIsVisible(false);
    }

    if (response && response.status === 'fail') {
      if (isAppTwoFa && !twoFactorCode) {
        notificationsErrorShow({ message: t('validation.twoFaRequired') });
      }

      if (isEmailTwoFa && !emailTwoFactorCode) {
        notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
      }
    }

    setIsLoading(false);
  };

  const handleCancel = e => {
    setIsVisible(false);
    withdrawConfirmForm.resetFields();
    withdrawResetTwoFa();
  };

  return (
    <React.Fragment>
      <Modal
        width={534}
        title={t('withdraw.enterTwoFa')}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={withdrawConfirmForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromEmail')}
              name="emailTwoFactorCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={true}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromGA')}
              name="twoFactorCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={false}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
          </>
        </Form>
        <Row className="modal__buttons-wrapper">
          <Col span="12">
            <Button
              loading={isLoading || fetching}
              type="primary"
              className="modal__button modal__button-create"
              onClick={() => withdrawConfirmForm.submit()}
            >
              {t('withdraw.confirm')}
            </Button>
          </Col>
          <Col span="12">
            <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
              {t('withdraw.cancel')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.withdraw.isAppTwoFa,
  isEmailTwoFa: state.withdraw.isEmailTwoFa,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawTwoFaConfirm);
