import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { getMerchants, changeMerchantNetwork } from '../../redux/actions/merchants';
import Merchant from '../Merchant/Merchant';

import './MerchantList.scss';

const MerchantList = ({ merchants, userRole, quickNetworkSwitcherState, getMerchants, changeMerchantNetwork }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  return (
    <React.Fragment>
      {merchants && merchants.length > 0 ? (
        <>
          {merchants.map((merchant, index) => (
            <Merchant
              merchant={merchant}
              isLastMerchant={merchants.length < 2}
              userRole={userRole}
              quickNetworkSwitcherState={quickNetworkSwitcherState}
              changeMerchantNetwork={changeMerchantNetwork}
              key={index}
            />
          ))}
          {merchants.length === 20 && (
            <span className="merchant-list__limit-message">{t('merchants.merchantsLimit')}</span>
          )}
        </>
      ) : (
        <div className="component">
          <Empty description={t("nodata")}/>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  merchants: state.merchants.data,
  userRole: state.user.data.role,
  quickNetworkSwitcherState: state.merchants.quickNetworkSwitcherState,
});

const mapDispatchToProps = dispatch => ({
  getMerchants: getMerchants(dispatch),
  changeMerchantNetwork: changeMerchantNetwork(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantList);
