import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import logoImage from '../../img/logo.svg';
import loginBg from '../../img/login-bg.png';
import LanguageSelector from '../../components/LanguageSelector';
import styles from './style.module.scss';
import useWindowSize from '../../utils/resizeHook';


// children
const AuthPagesWrapper = ({ childComponent: ChildComponent, withWelcomeBlock }) => {
  const [isHideWelcomeBlock, setIsHideWelcomeBlock] = useState(!withWelcomeBlock);
  const [accountType, setAccountType] = useState(null);
  const { isSm } = useWindowSize();

  const toggleWelcomeBlock = status => setIsHideWelcomeBlock(status);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className={styles['wrapper']}>
        <div className={styles['form-wrapper']}>
          <div className={styles['form-wrapper__header']}>
            <a href="https://www.yomi.finance/">
              <img src={logoImage} alt="Yomi logo" />
            </a>
            <LanguageSelector />
          </div>
          {/* {children} */}
          <ChildComponent
              toggleWelcomeBlock={toggleWelcomeBlock}
              setAccountType={setAccountType}
              accountType={accountType}
            />
        </div>
        <div className={styles['wrapper__image']}>
          <img src={loginBg} alt="Yomi cards" />
        </div>
      </div>
    </>
  );
};

export default AuthPagesWrapper;
