import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';

import '../../../../layout/Modal.scss';
import styles from '../../../../components/AuthForms/style.module.scss';

const WithdrawTwoFaConfirm = ({
  isVisible,
  setIsVisible,
  action,
  isAppTwoFa,
  isEmailTwoFa,
  clearWithdrawState,
  fetching,
  walletId,
  body,
  withdrawResetTwoFa,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawConfirmForm] = Form.useForm();

  const handleSubmit = async values => {
    const { twoFactorCode, emailTwoFactorCode } = values;

    setIsLoading(true);
    const response = await action(walletId, {
      ...body,
      twoFactorToken: twoFactorCode || undefined,
      emailOtp: emailTwoFactorCode || undefined,
    });

    if (response && response.data.id) {
      clearWithdrawState();
      setIsVisible(false);
    }

    setIsLoading(false);
  };

  const handleCancel = e => {
    setIsVisible(false);
    withdrawConfirmForm.resetFields();
    withdrawResetTwoFa();
  };

  return (
    <React.Fragment>
      <Modal
        width={534}
        title="Enter 2FA"
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <Form
          form={withdrawConfirmForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromEmail')}
              name="emailCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={true}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: t('validation.required') }]}
              label={t('auth.keyFromGA')}
              name="authCode"
              className={styles['login-form__item']}
            >
              <AuthCode
                autoFocus={false}
                containerClassName={styles['login-form__2fa']}
                inputClassName={styles['login-form__2fa-input']}
              />
            </Form.Item>
          </>
        </Form>
        <Row className="modal__buttons-wrapper">
          <Col span="12">
            <Button
              loading={isLoading || fetching}
              type="primary"
              className="modal__button modal__button-create"
              onClick={() => withdrawConfirmForm.submit()}
            >
              Confirm
            </Button>
          </Col>
          <Col span="12">
            <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.adminFeeWithdraw.isAppTwoFa,
  isEmailTwoFa: state.adminFeeWithdraw.isEmailTwoFa,
});

export default connect(mapStateToProps)(WithdrawTwoFaConfirm);
