import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import Button from '../../Button';
import { signUp } from '../../../redux/actions/user';
import PasswordStrength from '../../PasswordStrength';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../../img/email.svg';
import LoginSocialMedia from '../../LoginSocialMedia';
import styles from '../style.module.scss';
import AuthCode from "react-auth-code-input";
import { ReactComponent as ArrowBackIcon } from '../../../img/swap-back.svg';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignupForm = ({ signUp}) => {
  const [password, setPassword] = useState('');
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const query = useQuery();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (query.get('inviterId')) {
      localStorage.setItem('cpayInviterId', query.get('inviterId'));
    }
  }, []);

  useEffect(() => () => form.resetFields(), []);

  const handleFormSubmit = async values => {
    const recaptcha = await recaptchaRef.current.executeAsync();
    const formData = form.getFieldsValue(true);
    try {
      if(step === 0){
        await signUp(
          values.email.trim(),
          values.password.trim(),
          values.emailTwoFaKey && values.emailTwoFaKey.trim(),
          query.get('inviterId') || localStorage.getItem('cpayInviterId') || undefined,
          recaptcha
        )
        setStep(1)
      }

      if (step === 1) {
       await signUp(
        formData.email,
        formData.password,
        values.emailTwoFaKey && values.emailTwoFaKey.trim(),
        query.get('inviterId') || localStorage.getItem('cpayInviterId') || undefined,
        recaptcha
      )
      }
    } catch(err) {
      console.error(err)
    }
    recaptchaRef.current.reset(); // fixed, should be here instead on catch
    }


  return (
    <>
      <Form
        form={form}
        initialValues={{ email: query.get('email') || '', password: '', emailTwoFaKey: '' }}
        onFinish={handleFormSubmit}
        className={styles['login-form']}
      >
        {step === 0 &&
         <>
         <span className={styles['login-form__label']}>{t('email')}</span>
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input
            className={styles['login-form__input']}
            placeholder={t('email')}
            suffix={<img src={emailIcon} alt="email icon" />}
          />
        </Form.Item>

        <span className={styles['login-form__label']}>{t('password')}</span>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('validation.required') }]}
          className={styles['login-form__item']}
        >
          <Input.Password
            className={styles['login-form__input']}
            placeholder={t('password')}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>

         <PasswordStrength password={password} />
        </>}

      {step === 1 && <>
      <div className={styles["back"]}>
      <div className={styles["back__button"]} onClick={() => setStep(0)}>
        <ArrowBackIcon /> {t('swap.backButton')}
      </div>
    </div>
        <span className={styles['login-form__label']}>{t('auth.keyFromEmail')}</span>
              <Form.Item
                name="emailTwoFaKey"
                rules={[{ required: true, message: t('validation.required') }]}
                className={styles['login-form__item']}
              >
                <AuthCode containerClassName={styles['login-form__2fa']} inputClassName={styles['login-form__2fa-input']} />
              </Form.Item>

      </>}

        <Button className={styles['login-form__button']} type="submit" id={step === 1 ? "signup_email" : null}>
          {t('submit')}
        </Button>

        <span className={styles['login-form__divider']}>Or</span>

        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY} size="invisible" />
      </Form>
      <LoginSocialMedia userType="corporate" />
    </>
  );
};

const mapStateToProps = state => ({
  isFetching: state.user.fetching,
});

const mapDispatchToProps = dispatch => ({
  signUp: signUp(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
