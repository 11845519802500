import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showReleaseNotes, hideReleaseNotes } from '../../../redux/actions/releaseNotes';
import { Modal } from 'antd';
import releaseNotesArray from '../releaseNotesArray';
import './style.scss';

const ReleaseNotesModal = ({ releaseNote, showReleaseNotes, hideReleaseNotes, sessionData, isShowWelcomeModal }) => {
  const { t } = useTranslation();

  const releases = releaseNotesArray(t);

  const location = useLocation();
  useEffect(() => {
    const userLastVersion = localStorage.getItem('yomiLastVersion');
    const appCurrentVersion = process.env.REACT_APP_VERSION;

    if (!isShowWelcomeModal && sessionData && userLastVersion !== appCurrentVersion && (!localStorage.getItem('yomiLastVersion'))) {
      showReleaseNotes(releases.find(release => release.version === process.env.REACT_APP_VERSION));
    }
  }, [sessionData]);

  const handleCloseModal = () => {
    localStorage.setItem('yomiLastVersion', process.env.REACT_APP_VERSION);
    hideReleaseNotes();
  };

  return location.pathname.indexOf('checkout/') >= 0 &&
    location.pathname.indexOf('sale-token-checkout/') >= 0 ? null : (
    <Modal
      title={
        <>
          <div className="release-notes-modal__title">YOMI {releaseNote && releaseNote.version}</div>
          {releaseNote && (
            <span className="release-notes-modal__subtitle">
              {t(releaseNote.month.toLowerCase())} {t(releaseNote.time.toLowerCase())}{' '}
            </span>
          )}
        </>
      }
      className="release-notes-modal"
      visible={releaseNote}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={null}
    >
      {releaseNote &&
        releaseNote.sections.map((section, index) => (
          <ul className="release-notes-modal__list">
            {section.title}
            {section.changes.map(change => (
              <li className="release-notes-modal__list-item" key={index}>
                {change}
              </li>
            ))}
          </ul>
        ))}

      {releaseNote && releaseNote.link ? (
        <a className="release-notes-modal__read-more" href={releaseNote && releaseNote.link} target="_blank">
          {t('releases.more')}
        </a>
      ) : (
        <></>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  releaseNote: state.releaseNotes,
});

const mapDispatchToProps = dispatch => ({
  showReleaseNotes: showReleaseNotes(dispatch),
  hideReleaseNotes: hideReleaseNotes(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesModal);
