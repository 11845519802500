import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Field, Form as ReduxForm, reduxForm } from 'redux-form';
import { Input, Dropdown, Row, Col, Checkbox, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../utils/resizeHook';
import resetIcon from '../../img/reset.svg';
import './WalletsFilters.scss';

const RenderSearch = ({ input, placeholder, onSearch, className, addonAfter, disabled }) => {
  return (
    <Input.Search
      disabled={disabled}
      {...input}
      placeholder={placeholder}
      onSearch={onSearch}
      className={className}
      addonAfter={addonAfter}
    />
  );
};

const RenderCheckbox = ({ input, options, disabled }) => {
  return <Checkbox.Group disabled={disabled} {...input} options={options} />;
};

const RenderRadio = ({ input, options, disabled }) => {
  return (
    <Radio.Group disabled={disabled} {...input}>
      {options.map((option, index) => (
        <Radio key={index} value={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

const WalletsFilters = ({ currencies, submitFilters, resetFilters, fetching, merchantName }) => {
  const [isCurrenciesVisible, setIsCurrenciesVisible] = useState(false);
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  const { currentWidth } = useWindowSize();
  const formRef = useRef(null);
  const { t } = useTranslation();

  const handleReset = () => {
    formRef.current.props._reduxForm.reset();
    resetFilters();
  };

  return (
    <ReduxForm ref={formRef} onSubmit={e => e.preventDefault()}>
      <div className="wallet-filters">
        <Row gutter={[20]}>
          <Col span={currentWidth >= 1024 ? 11 : 24} className="component__title wallet-filters__title">
            Manage {t('wallets.wallets').toLowerCase()}
          </Col>
          <Col span={currentWidth >= 1024 ? 7 : 24} className="wallet-filters__search">
            <Field
              disabled={fetching}
              name="search"
              component={RenderSearch}
              placeholder={t('wallets.placeholder')}
              onSearch={submitFilters}
              onFocus={e => e.preventDefault()}
              onBlur={e => e.preventDefault()}
              className="wallet-filters__search-field"
              addonAfter={
                <a onClick={handleReset} className="wallet-filters__reset-button">
                  <img src={resetIcon} alt="Reset" />
                </a>
              }
            />
          </Col>
          <Col span={currentWidth >= 1024 ? 3 : 12} className="wallet-filters__currencies">
            <Dropdown
              onVisibleChange={visibilityState => setIsCurrenciesVisible(visibilityState)}
              visible={isCurrenciesVisible}
              overlay={
                <div className="wallet-filters__currencies-dropdown">
                  <Field
                    disabled={fetching}
                    name="currencyIds"
                    options={currencies
                      .filter(currency => currency.currencyType === 'currency')
                      .map(currency => ({ label: currency.title, value: currency._id }))}
                    component={RenderCheckbox}
                    onFocus={e => e.preventDefault()}
                    onBlur={e => e.preventDefault()}
                  />
                  <div className="wallet-filters__currencies-dropdown_apply" onClick={!fetching && submitFilters}>
                    {t('apply')}
                  </div>
                </div>
              }
            >
              <div className="wallet-filters__currencies-button">
                {t('currency')} <DownOutlined />
              </div>
            </Dropdown>
          </Col>
          <Col span={currentWidth >= 1024 ? 3 : 12} className="wallet-filters__sorting">
            <Dropdown
              onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
              visible={isSortingVisible}
              overlay={
                <div className="wallet-filters__currencies-dropdown">
                  <Field
                    disabled={fetching}
                    name="order"
                    options={[
                      { label: t('first'), value: 'DESC' },
                      { label: t('last'), value: 'ASC' },
                    ]}
                    component={RenderRadio}
                    onFocus={e => e.preventDefault()}
                    onBlur={e => e.preventDefault()}
                  />
                </div>
              }
            >
              <div className="wallet-filters__currencies-button">
                {t('sorting')} <DownOutlined />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </ReduxForm>
  );
};

const FiltersFormContainer = reduxForm({ destroyOnUnmount: true })(WalletsFilters);

export default connect((state, { formName }) => ({
  form: formName,
}))(FiltersFormContainer);
