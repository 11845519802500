import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';
import moment from 'moment';

// GET USERS LIST

export const GET_ADMIN_USERS_REQUEST = 'GET_ADMIN_USERS_REQUEST';
export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';

export const getAdminUsersRequest = () => ({
  type: GET_ADMIN_USERS_REQUEST,
});

export const getAdminUsersSuccess = payload => ({
  type: GET_ADMIN_USERS_SUCCESS,
  payload,
});

export const getAdminUsers =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminUsersRequest());

    try {
      const response = await adminApi.getAdminUsers({
        ...body,
        search: body.search ? body.search.trim() : '',
        dateOfSignUp: undefined,
        dateOfSignIn: undefined,
        fromSignUp:
          body.dateOfSignUp && body.dateOfSignUp[0]
            ? moment(body.dateOfSignUp[0]).format('YYYY-MM-DD') + 'T00:00:01'
            : undefined,
        toSignUp:
          body.dateOfSignUp && body.dateOfSignUp[1]
            ? moment(body.dateOfSignUp[1]).format('YYYY-MM-DD') + 'T23:59:59'
            : undefined,
        fromSignIn:
          body.dateOfSignIn && body.dateOfSignIn[0]
            ? moment(body.dateOfSignIn[0]).format('YYYY-MM-DD') + 'T00:00:01'
            : undefined,
        toSignIn:
          body.dateOfSignIn && body.dateOfSignIn[1]
            ? moment(body.dateOfSignIn[1]).format('YYYY-MM-DD') + 'T23:59:59'
            : undefined,
        order: body.order || 'DESC',
        sort: body.sort || undefined,
        emailConfirmed: body.emailConfirmed || undefined
      });
      dispatch(getAdminUsersSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

// GET USER INFO BY ID

export const GET_ADMIN_USER_BY_ID_REQUEST = 'GET_ADMIN_USER_BY_ID_REQUEST';
export const GET_ADMIN_USER_BY_ID_SUCCESS = 'GET_ADMIN_USER_BY_ID_SUCCESS';

export const getAdminUserByIdRequest = () => ({
  type: GET_ADMIN_USER_BY_ID_REQUEST,
});

export const getAdminUserByIdSuccess = payload => ({
  type: GET_ADMIN_USER_BY_ID_SUCCESS,
  payload,
});

export const getAdminUserById = userId => async dispatch => {
  dispatch(getAdminUserByIdRequest());

  try {
    const response = await adminApi.getAdminUserById(userId);
    dispatch(getAdminUserByIdSuccess(response));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// UPDATE USER INFO

export const UPDATE_ADMIN_USER_REQUEST = 'UPDATE_ADMIN_USER_BY_ID_REQUEST';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_BY_ID_SUCCESS';

export const updateAdminUserRequest = () => ({
  type: UPDATE_ADMIN_USER_REQUEST,
});

export const updateAdminUserSuccess = payload => ({
  type: UPDATE_ADMIN_USER_SUCCESS,
  payload,
});

export const updateAdminUser = (userId, body) => async dispatch => {
  dispatch(getAdminUserByIdRequest());

  try {
    const response = await adminApi.updateAdminUser(userId, body);
    dispatch(getAdminUserByIdSuccess(body));
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// RESET USER DATA STATE

export const RESET_ADMIN_USER_STATE = 'RESET_ADMIN_USER_STATE';

export const resetAdminUserState = () => ({
  type: RESET_ADMIN_USER_STATE,
});

// CREATE NEW USER

export const CREATE_ADMIN_NEW_USER_REQUEST = 'CREATE_ADMIN_NEW_USER_REQUEST';
export const CREATE_ADMIN_NEW_USER_SUCCESS = 'CREATE_ADMIN_NEW_USER_SUCCESS';

export const createAdminNewUserRequest = () => ({
  type: CREATE_ADMIN_NEW_USER_REQUEST,
});

export const createAdminNewUserSuccess = () => ({
  type: CREATE_ADMIN_NEW_USER_SUCCESS,
});

export const createAdminNewUser = body => async dispatch => {
  dispatch(createAdminNewUserRequest());

  try {
    const response = await adminApi.createAdminNewUser(body);
    dispatch(createAdminNewUserSuccess());
    dispatch(notificationsSuccessShow({}));
    return response;
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// GET USER'S MERCHANTS

export const GET_ADMIN_USER_MERCHANTS_REQUEST = 'GET_ADMIN_USER_MERCHANTS_REQUEST';
export const GET_ADMIN_USER_MERCHANTS_SUCCESS = 'GET_ADMIN_USER_MERCHANTS_SUCCESS';

export const getAdminUserMerchantsRequest = () => ({
  type: GET_ADMIN_USER_MERCHANTS_REQUEST,
});

export const getAdminUserMerchantsSuccess = payload => ({
  type: GET_ADMIN_USER_MERCHANTS_SUCCESS,
  payload,
});

export const getUserMerchants = userId => async dispatch => {
  dispatch(getAdminUserMerchantsRequest());

  try {
    const response = await adminApi.getUserMerchants(userId);
    dispatch(getAdminUserMerchantsSuccess(response.data.merchants));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

// CHANGE URES'S MERCHANT SYSTEM FEE

export const UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REQUEST = 'UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REQUEST';
export const UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_SUCCESS = 'UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_SUCCESS';
export const UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_REQUEST = 'UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_REQUEST';
export const UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_SUCCESS = 'UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_SUCCESS';


export const updateAdminMerchantSystemFeeRequest = () => ({
  type: UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REQUEST,
});

export const updateAdminMerchantSystemFeeSuccess = () => ({
  type: UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_SUCCESS,
});

export const updateAdminMerchantSystemFeeReplenishRequest = () => ({
  type: UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_REQUEST,
});

export const updateAdminMerchantSystemFeeReplenishSuccess = () => ({
  type: UPDATE_ADMIN_MERCHANT_SYSTEM_FEE_REPLENISH_SUCCESS,
});

export const updateMerchantsSystemFee = (merchantId, systemFeePercent) => async dispatch => {
  dispatch(updateAdminMerchantSystemFeeRequest());

  try {
    const response = await adminApi.updateMerchantsSystemFee(merchantId, systemFeePercent);
    dispatch(updateAdminMerchantSystemFeeSuccess());
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};

export const updateMerchantsSystemFeeReplenish = (merchantId, systemFeeReplenish) => async dispatch => {
  dispatch(updateAdminMerchantSystemFeeReplenishRequest());

  try {
    const response = await adminApi.updateMerchantsSystemFeeReplenish(merchantId, systemFeeReplenish);
    dispatch(updateAdminMerchantSystemFeeReplenishSuccess());
    dispatch(notificationsSuccessShow({}));
  } catch (error) {
    dispatch(notificationsErrorShow(error.data));
  }
};
