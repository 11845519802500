import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import backIcon from '../img/arrow-back.svg';
import useWindowSize from '../utils/resizeHook';
import { Link } from 'react-router-dom';
import './PageHeader.scss';

export default function PageHeader({ pageHeaderText, CreateModalComponent, showBackButton }) {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  return (
    <div className="page-header" align="middle">
      {pageHeaderText && <p className="page-header__tip">{pageHeaderText}</p>}
      {/* {CreateModalComponent && showBackButton && (
        <Link to="/merchants" className="page-header__back">
          <img src={backIcon} alt="Back" />
          <span>{t('goBack')}</span>
        </Link>
      )} */}
      {CreateModalComponent && <CreateModalComponent />}
    </div>
  );
}
