import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TEXT from '../Text';
import './style.scss';

const Button = ({ children, className, loading, ...rest }) => {
  return (
    <button className={`admin-button ${loading ? 'admin-button_loading' : ''} ${className}`} {...rest}>
      {loading && (
        <Spin
          className="admin-button__spin"
          indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 14 }} spin />}
        />
      )}
      {children}
    </button>
  );
};

export const ButtonPrimary = ({ children, className, ...rest }) => {
  return (
    <Button type="button" className={`admin-button__primary ${className || ''}`} {...rest}>
      <TEXT.button>{children}</TEXT.button>
    </Button>
  );
};

export const ButtonSecondary = ({ children, className, ...rest }) => {
  return (
    <Button type="button" className={`admin-button__secondary ${className || ''}`} {...rest}>
      <TEXT.button>{children}</TEXT.button>
    </Button>
  );
};
