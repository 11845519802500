import React from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../utils/resizeHook';
import { ReactComponent as FloatingIcon } from '../../../img/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../../img/swap-fixed.svg';
import getSwapPartnerIcon from '../../../utils/swapPartnerIcons';
import SwapOfferTrustscore from '../SwapOfferTrustscore';
import './style.scss';

const OfferItem = ({ offer, index, handleExchange, showUpdateRatesBanner, showBalanceWarning, showBestLabel }) => {
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();

  const offerTypes = {
    true: t('swap.offerItem.fixed'),
    false: t('swap.offerItem.floating'),
  };

  return currentWidth > 590 ? (
    <div className="offer-item">
      <div className="offer-item__row">
        <div className="offer-item__column offer-item__rate">
          <span className="offer-item__column-name">
            {t('swap.offerItem.rate')}{' '}
            {showBestLabel && <span className="offer-item__best">{t('swap.offerItem.best')}</span>}
          </span>
          <span className="offer-item__column-value">
            {offer.min && offer.fromAmount < offer.min
              ? `min ${offer.min} ${offer.fromCurrency.toUpperCase()}`
              : offer.max && offer.fromAmount > offer.max
              ? `max ${offer.max} ${offer.fromCurrency.toUpperCase()}`
              : offer.toAmount}
          </span>
        </div>
        <div className="offer-item__column offer-item__type">
          <span className="offer-item__column-name">{t('swap.offerItem.type')}</span>
          <span className="offer-item__column-value">
            {offer.fixed && offer.id ? <FixedIcon /> : <FloatingIcon />} {offerTypes[offer.id ? offer.fixed : false]}
          </span>
        </div>
      </div>

      <div className="offer-item__row">
        <div className="offer-item__column offer-item__eta">
          <span className="offer-item__column-name">ETA</span>
          <span className="offer-item__column-value">{offer.duration} min</span>
        </div>
        <div className="offer-item__column offer-item__support-rate">
          <span className="offer-item__column-name">{t('swap.offerItem.support')}</span>
          <span className="offer-item__column-value">
            <div className="offer-item__support-rate-bar" data-score={offer.supportRate}></div>
          </span>
        </div>
      </div>

      <div className="offer-item__row">
        <div className="offer-item__column offer-item__partner">
          <span className="offer-item__column-name">{t('swap.offerItem.partner')}</span>
          <span className="offer-item__column-value">
            <img src={getSwapPartnerIcon(offer.partner)} alt={offer.partner} /> {offer.partner}
          </span>
        </div>

        <div className="offer-item__column offer-item__trust-score">
          <span className="offer-item__column-name">{t('swap.offerItem.score')}</span>
          <span className="offer-item__column-value">
            <SwapOfferTrustscore partnerName={offer.partner} />
          </span>
        </div>
      </div>

      <div className="offer-item__exchange">
        <button
          type="button"
          className="offer-item__exchange-button"
          disabled={
            showBalanceWarning ||
            showUpdateRatesBanner ||
            (offer.min && offer.fromAmount < offer.min) ||
            (offer.max && offer.fromAmount > offer.max)
          }
          onClick={() => handleExchange(offer.partner, offer.fixed, offer.toAmount, offer.id)}
        >
          {t('swap.offerItem.exchange')}
        </button>
      </div>
    </div>
  ) : (
    <div className="offer-item">
      <div className="offer-item-mobile__info">
        <div className="offer-item-mobile__row">
          <div>
            <div className="offer-item__column offer-item__partner">
              <span className="offer-item__column-value">
                <img src={getSwapPartnerIcon(offer.partner)} alt={offer.partner} /> {offer.partner}
              </span>
            </div>

            <div className="offer-item__column offer-item__rate">
              <span className="offer-item__column-value">
                {offer.min && offer.fromAmount < offer.min
                  ? `min ${offer.min} ${offer.fromCurrency.toUpperCase()}`
                  : offer.max && offer.fromAmount > offer.max
                  ? `max ${offer.max} ${offer.fromCurrency.toUpperCase()}`
                  : offer.toAmount}
              </span>
            </div>
          </div>

          <div className="offer-item__exchange">
            <button
              type="button"
              className="offer-item__exchange-button"
              disabled={
                showBalanceWarning ||
                showUpdateRatesBanner ||
                (offer.min && offer.fromAmount < offer.min) ||
                (offer.max && offer.fromAmount > offer.max)
              }
              onClick={() => handleExchange(offer.partner, offer.fixed, offer.toAmount, offer.id)}
            >
              {t('swap.offerItem.exchange')}
            </button>
          </div>
        </div>

        <div className="offer-item-mobile__row">
          <div className="offer-item__column offer-item__trust-score">
            <span className="offer-item__column-name">{t('swap.offerItem.score')}</span>
            <span className="offer-item__column-value">
              <SwapOfferTrustscore partnerName={offer.partner} />
            </span>
          </div>
          <div className="offer-item__column offer-item__support-rate">
            <span className="offer-item__column-name">{t('swap.offerItem.support')}</span>
            <span className="offer-item__column-value">
              <div className="offer-item__support-rate-bar" data-score={offer.supportRate}></div>
            </span>
          </div>
        </div>

        <div className="offer-item-mobile__row">
          <div className="offer-item__column offer-item__type">
            <span className="offer-item__column-name">{t('swap.offerItem.type')}</span>
            <span className="offer-item__column-value">
              {offer.fixed && offer.id ? <FixedIcon /> : <FloatingIcon />} {offerTypes[offer.id ? offer.fixed : false]}
            </span>
          </div>
          <div className="offer-item__column offer-item__eta">
            <span className="offer-item__column-name">ETA</span>
            <span className="offer-item__column-value">{offer.duration} min</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferItem;
