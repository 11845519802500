import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Card from '../../components/Card';
import TEXT from '../../components/Text';
import { InputSearch, RangePicker, InputSelect } from '../../components/FormFields';
import UsersList from '../../components/UsersList';
import Pagination from '../../components/Pagination';
import UserInfoModal from '../../components/UserInfoModal';
import AddUserModal from '../../components/AddUserModal';
import { ButtonPrimary } from '../../components/Button';
import { getAdminUsers } from '../../../redux/actions/adminPanel/adminUsers';
import { getAdminUsersEmailsList } from '../../../redux/actions/adminPanel/adminUsersEmailsDownload';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import { setAdminFiltersUsers } from '../../../redux/actions/adminPanel/adminPageFilters';
import './style.scss';

const Users = ({
  usersList,
  usersCurrentPage,
  totalUsers,
  usersFetching,
  getAdminUsers,
  getAdminUsersEmailsList,
  emailsFetching,
  setAdminFiltersUsers,
  adminFiltersUsers,
}) => {
  const [form] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState('DESC');
  const history = useHistory();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  let dateParam = undefined;
  if (Object.keys(query).length !== 0 && query.constructor === Object) {
    dateParam = query;
  }
  const adminUsersRef = useRef(null);

  useEffect(() => {
    if (!adminFiltersUsers) {
      getAdminUsers({
        dateOfSignUp: dateParam ? [moment.utc(dateParam?.from), moment.utc(dateParam?.to)] : undefined,
        search: undefined,
      });
      return;
    }

    getAdminUsers({ ...adminFiltersUsers });
  }, []);

  useEffect(() => {
    adminUsersRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const sortUsersByBalanceRequest = order => {
    getAdminUsers({ sort: 'balanceUSD', page: +usersCurrentPage, order });
  };

  const dateOfSignUpDateParams = dateParam
    ? [moment.utc(dateParam.from), moment.utc(dateParam.to)]
    : adminFiltersUsers?.dateOfSignUp || null;

  return (
    <>
      <div className="admin-users" ref={adminUsersRef}>
        <Card>
          <TEXT.pageTitle>Users list</TEXT.pageTitle>
          <Form
            form={form}
            name="admin-users-form"
            initialValues={{
              search: adminFiltersUsers?.search || '',
              dateOfSignUp: dateOfSignUpDateParams,
              dateOfSignIn: adminFiltersUsers?.dateOfSignIn || null,
              emailConfirmed: adminFiltersUsers?.emailConfirmed || undefined,
            }}
            onFieldsChange={() => setFormValues(form.getFieldsValue(true))}
            onFinish={() => {
              getAdminUsers(form.getFieldsValue(true));
              setAdminFiltersUsers({ ...form.getFieldsValue(true) });
            }}
          >
            <div className="admin-users__filters">
              <InputSearch
                name="search"
                onSearch={() => {
                  localStorage.setItem('searchForUsers', form?.getFieldsValue()?.search);
                  form.submit();
                }}
                onReset={() => {
                  localStorage.removeItem('searchForUsers');
                  form.resetFields();
                  form.setFieldsValue({ search: '' });
                  form.submit();
                }}
              />
              <RangePicker
                name="dateOfSignUp"
                placeholder="Choose date of registration"
                submitAction={form.submit}
                formValues={formValues}
                withInitial={dateParam || adminFiltersUsers?.dateOfSignUp}
                clearAction={() => {
                  form.setFieldsValue({ dateOfSignUp: '' });
                  setAdminFiltersUsers({ dateOfSignUp: '' });
                }}
              />
              <RangePicker
                name="dateOfSignIn"
                placeholder="Choose date of last login"
                submitAction={form.submit}
                formValues={formValues}
                withInitial={adminFiltersUsers?.dateOfSignIn}
                clearAction={() => {
                  form.setFieldsValue({ dateOfSignIn: '' });
                  setAdminFiltersUsers({ dateOfSignIn: '' });
                }}
              />
              <InputSelect
                name="emailConfirmed"
                dropdownMatchSelectWidth={false}
                placeholder="Choose user email status"
                options={[
                  { value: 'true', label: 'Email confirmed' },
                  { value: 'false', label: 'Email  not confirmed' },
                  { value: null, label: 'All' },
                ]}
                onChange={form.submit}
                style={{
                  width: 180,
                }}
                className="search-email"
              />
              <div className="admin-users__add-user__button-container">
                <ButtonPrimary className="admin-users__add-user-button" onClick={() => setShowAddUserModal(true)}>
                  Add user
                </ButtonPrimary>
                <ButtonPrimary
                  className="admin-users__add-user-button admin-users__add-user-button__download-emails"
                  onClick={() => {
                    getAdminUsersEmailsList(formValues);
                  }}
                >
                  {!emailsFetching ? (
                    'Download emails'
                  ) : (
                    <div className="admin-users__loader">
                      <Spin indicator={<LoadingOutlined style={{ color: '#4D7F70', fontSize: 16 }} spin />} />
                    </div>
                  )}
                </ButtonPrimary>
              </div>
            </div>
            <UsersList
              usersList={usersList}
              setSelectedUserId={setSelectedUserId}
              usersFetching={usersFetching}
              handleClick={order => {
                sortUsersByBalanceRequest(order);
                setSort('balanceUSD');
                setOrder(order);
              }}
            />
            {usersList.length ? (
              <Pagination
                current={+usersCurrentPage}
                total={totalUsers}
                getEntities={props => getAdminUsers({ ...props, order, sort })}
                getFormValues={form.getFieldsValue}
              />
            ) : null}
          </Form>
        </Card>
      </div>
      <UserInfoModal selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} />
      <AddUserModal
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        updateUsersList={() => form.submit()}
      />
    </>
  );
};

const mapStateToProps = state => ({
  usersList: state.adminUsers.entities,
  usersCurrentPage: state.adminUsers.page,
  totalUsers: state.adminUsers.countItems,
  usersFetching: state.adminUsers.fetching,
  emailsFetching: state.adminUsersEmailsDownload.fetching,
  adminFiltersUsers: state.adminFilters.users,
});

const mapDispatchToProps = {
  getAdminUsers,
  getAdminUsersEmailsList,
  setAdminFiltersUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
